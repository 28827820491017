/* Global style guide for UI Lib */
/**
 * Browser support
 */
/**
 * Typography
 */
@import url("https://fonts.googleapis.com/css?family=Rajdhani:300,400,500,600,700&subset=latin-ext");
/**
 * Colors
 */
/**
 * Page -- UILib Specific Variables
 */
/**
 * Card -- UILib Specific Variables
 */
@media only screen and (min-width: 769px) {
  .project-detail-border {
    width: 100vw;
    min-width: 1366px;
    height: 1px;
    background-color: #cccccc; }
  .hanging-nav-bar-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    height: 80px;
    width: 100%;
    background-color: #ffffff;
    border-bottom: 1px solid #eaeaea;
    z-index: 10000; }
    .hanging-nav-bar-wrapper .nav-bar-container {
      box-sizing: border-box;
      width: 1366px;
      height: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center; }
      .hanging-nav-bar-wrapper .nav-bar-container .nav-bar-header {
        font-size: 24px;
        font-weight: 700;
        color: #4a4a4a;
        cursor: pointer; }
      .hanging-nav-bar-wrapper .nav-bar-container .nav-bar-right-pane {
        box-sizing: border-box;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center; }
        .hanging-nav-bar-wrapper .nav-bar-container .nav-bar-right-pane .section-wrapper {
          height: 100%; }
          .hanging-nav-bar-wrapper .nav-bar-container .nav-bar-right-pane .section-wrapper ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
            height: 100%;
            display: flex;
            flex-direction: row;
            align-items: center; }
            .hanging-nav-bar-wrapper .nav-bar-container .nav-bar-right-pane .section-wrapper ul li {
              box-sizing: border-box;
              display: block;
              cursor: pointer;
              padding: 10px 15px;
              font-size: 16px;
              line-height: 26px;
              font-weight: 600;
              color: #4a4a4a; }
            .hanging-nav-bar-wrapper .nav-bar-container .nav-bar-right-pane .section-wrapper ul .active {
              color: #0033ff; }
        .hanging-nav-bar-wrapper .nav-bar-container .nav-bar-right-pane .statistics-wrapper {
          box-sizing: border-box;
          height: 100%;
          padding: 14px 30px;
          display: flex;
          flex-direction: row;
          align-items: center; }
          .hanging-nav-bar-wrapper .nav-bar-container .nav-bar-right-pane .statistics-wrapper .statistics-item {
            box-sizing: border-box;
            padding: 0 20px;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center; }
            .hanging-nav-bar-wrapper .nav-bar-container .nav-bar-right-pane .statistics-wrapper .statistics-item:first-child {
              border-right: 1px solid #eaeaea; }
            .hanging-nav-bar-wrapper .nav-bar-container .nav-bar-right-pane .statistics-wrapper .statistics-item .header {
              font-size: 20px;
              line-height: 20px;
              margin: 0;
              font-weight: 700; }
            .hanging-nav-bar-wrapper .nav-bar-container .nav-bar-right-pane .statistics-wrapper .statistics-item .subheader {
              font-size: 14px;
              line-height: 14px;
              margin: 0;
              font-weight: 500; }
        .hanging-nav-bar-wrapper .nav-bar-container .nav-bar-right-pane .buy-action {
          cursor: pointer;
          top: -0.5px;
          height: calc(100% + 1px);
          width: 200px;
          background-color: #33cc00;
          color: white;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          font-size: 16px;
          line-height: 26px;
          font-weight: 600; }
  .project-detail-page-wrapper {
    box-sizing: border-box;
    width: 1366px;
    margin: 0 auto;
    padding-top: 60px;
    padding-bottom: 60px; }
    .project-detail-page-wrapper .buy-shares {
      display: none; }
    .project-detail-page-wrapper .header-zone {
      width: 1180px;
      margin: 0 auto 80px auto;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center; }
      .project-detail-page-wrapper .header-zone .left-pane .tag-zone {
        display: flex;
        flex-direction: row;
        margin-bottom: 10px; }
        .project-detail-page-wrapper .header-zone .left-pane .tag-zone .tag {
          margin-right: 10px; }
      .project-detail-page-wrapper .header-zone .left-pane .plant-header {
        font-size: 44px;
        font-weight: 700; }
      .project-detail-page-wrapper .header-zone .left-pane .city {
        display: none; }
      .project-detail-page-wrapper .header-zone .left-pane .plant-owner {
        font-size: 16px;
        font-weight: 600; }
      .project-detail-page-wrapper .header-zone .left-pane .project-count {
        font-size: 16px;
        font-weight: 500; }
      .project-detail-page-wrapper .header-zone .right-pane .project-statistics {
        border: 1px solid #eaeaea;
        border-radius: 8px;
        padding: 15px 0;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        align-items: center; }
        .project-detail-page-wrapper .header-zone .right-pane .project-statistics .viewer-zone {
          padding: 0 30px;
          border-right: 1px solid #eaeaea; }
          .project-detail-page-wrapper .header-zone .right-pane .project-statistics .viewer-zone .header {
            font-size: 24px;
            line-height: 1;
            font-weight: 700; }
          .project-detail-page-wrapper .header-zone .right-pane .project-statistics .viewer-zone .subheader {
            font-size: 16px;
            font-weight: 500; }
        .project-detail-page-wrapper .header-zone .right-pane .project-statistics .backer-zone {
          padding: 0 30px; }
          .project-detail-page-wrapper .header-zone .right-pane .project-statistics .backer-zone .header {
            font-size: 24px;
            line-height: 1;
            font-weight: 700; }
          .project-detail-page-wrapper .header-zone .right-pane .project-statistics .backer-zone .subheader {
            font-size: 16px;
            font-weight: 500; }
    .project-detail-page-wrapper .hero-zone {
      position: relative;
      width: 1180px;
      margin: 0 auto 60px auto; }
      .project-detail-page-wrapper .hero-zone .highlight {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        box-sizing: border-box;
        position: absolute;
        top: -40px;
        left: 0;
        height: 60px;
        background-color: #0033ff;
        border-radius: 8px;
        z-index: 1; }
        .project-detail-page-wrapper .hero-zone .highlight .icon {
          font-size: 12px;
          margin: 15px 10px auto 15px; }
        .project-detail-page-wrapper .hero-zone .highlight .text {
          font-size: 16px;
          margin: 10px 50px auto 0px; }
      .project-detail-page-wrapper .hero-zone .hero-wrapper {
        box-sizing: border-box;
        position: relative;
        z-index: 10;
        width: 100%;
        height: 400px;
        background-color: #ffffff;
        border: 1px solid #eaeaea;
        border-radius: 8px;
        display: flex;
        flex-direction: row;
        align-items: flex-start; }
        .project-detail-page-wrapper .hero-zone .hero-wrapper .general-information {
          width: 15%;
          height: 100%;
          padding-right: 15px;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          align-items: flex-start; }
          .project-detail-page-wrapper .hero-zone .hero-wrapper .general-information .information {
            box-sizing: border-box;
            width: 100%;
            padding-left: 20px;
            padding-bottom: 15px;
            border-bottom: 1px solid #eaeaea; }
            .project-detail-page-wrapper .hero-zone .hero-wrapper .general-information .information:last-child {
              border-bottom: none; }
            .project-detail-page-wrapper .hero-zone .hero-wrapper .general-information .information .header {
              font-size: 14px;
              font-weight: 500;
              color: #999999; }
            .project-detail-page-wrapper .hero-zone .hero-wrapper .general-information .information .subheader {
              font-size: 16px;
              font-weight: 600;
              color: #000000; }
        .project-detail-page-wrapper .hero-zone .hero-wrapper .project-images {
          position: relative;
          width: 50%;
          height: 100%; }
          .project-detail-page-wrapper .hero-zone .hero-wrapper .project-images .image {
            position: relative;
            top: -0.5px;
            width: 100%;
            height: calc(100% + 1px);
            object-fit: cover; }
        .project-detail-page-wrapper .hero-zone .hero-wrapper .project-information {
          width: 35%;
          height: 100%;
          padding-left: 30px;
          padding-right: 30px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start; }
          .project-detail-page-wrapper .hero-zone .hero-wrapper .project-information .information-zone {
            width: 100%;
            display: flex;
            flex-direction: column; }
            .project-detail-page-wrapper .hero-zone .hero-wrapper .project-information .information-zone .information-line {
              width: 100%;
              border-bottom: 1px solid #eaeaea;
              padding: 20px 0 20px 0; }
              .project-detail-page-wrapper .hero-zone .hero-wrapper .project-information .information-zone .information-line:last-child {
                border-bottom: none; }
              .project-detail-page-wrapper .hero-zone .hero-wrapper .project-information .information-zone .information-line .header {
                font-size: 16px;
                font-weight: 500;
                color: #999999; }
              .project-detail-page-wrapper .hero-zone .hero-wrapper .project-information .information-zone .information-line .subheader {
                font-size: 24px;
                font-weight: 600;
                color: #000000; }
              .project-detail-page-wrapper .hero-zone .hero-wrapper .project-information .information-zone .information-line .extended-subheader {
                font-size: 30px; }
            .project-detail-page-wrapper .hero-zone .hero-wrapper .project-information .information-zone .flex-information-line {
              display: flex;
              flex-direction: row;
              align-items: flex-start; }
              .project-detail-page-wrapper .hero-zone .hero-wrapper .project-information .information-zone .flex-information-line .information {
                width: 60%; }
                .project-detail-page-wrapper .hero-zone .hero-wrapper .project-information .information-zone .flex-information-line .information:last-child {
                  width: 40%; }
          .project-detail-page-wrapper .hero-zone .hero-wrapper .project-information .cta-zone {
            width: 100%;
            display: flex;
            flex-direction: column;
            margin-bottom: 25px; }
            .project-detail-page-wrapper .hero-zone .hero-wrapper .project-information .cta-zone .button {
              height: 40px;
              font-size: 16px;
              font-weight: 600;
              line-height: 20px; }
            .project-detail-page-wrapper .hero-zone .hero-wrapper .project-information .cta-zone .info {
              font-size: 14px;
              line-height: 1.45;
              margin-top: 5px; }
    .project-detail-page-wrapper .buy-shares-zone {
      width: 1180px;
      height: 170px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      color: #ffffff; }
      .project-detail-page-wrapper .buy-shares-zone .action-zone {
        box-sizing: border-box;
        width: 100%;
        height: 130px;
        background-color: #0033ff;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        padding: 40px 60px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center; }
        .project-detail-page-wrapper .buy-shares-zone .action-zone .roi-zone {
          display: flex;
          width: 85%;
          flex-direction: row;
          align-items: center; }
          .project-detail-page-wrapper .buy-shares-zone .action-zone .roi-zone .input-zone {
            position: relative; }
            .project-detail-page-wrapper .buy-shares-zone .action-zone .roi-zone .input-zone input {
              box-sizing: border-box;
              outline: none;
              padding: 10px;
              width: 300px;
              min-width: 300px;
              height: 50px;
              border: none;
              border-radius: 4px;
              font-size: 24px;
              font-weight: 500;
              margin-right: 40px; }
              .project-detail-page-wrapper .buy-shares-zone .action-zone .roi-zone .input-zone input::placeholder {
                color: #d2d2d2; }
            .project-detail-page-wrapper .buy-shares-zone .action-zone .roi-zone .input-zone input:placeholder-shown + label {
              display: none; }
            .project-detail-page-wrapper .buy-shares-zone .action-zone .roi-zone .input-zone input:placeholder-shown {
              padding-left: 10px; }
            .project-detail-page-wrapper .buy-shares-zone .action-zone .roi-zone .input-zone input:not(placeholder-shown) {
              padding-left: 30px; }
            .project-detail-page-wrapper .buy-shares-zone .action-zone .roi-zone .input-zone input:not(placeholder-shown) + label {
              position: absolute;
              top: 12px;
              left: 10px;
              font-size: 24px;
              color: #000000;
              font-weight: 500;
              z-index: 100; }
            .project-detail-page-wrapper .buy-shares-zone .action-zone .roi-zone .input-zone input[type='number']::-webkit-outer-spin-button,
            .project-detail-page-wrapper .buy-shares-zone .action-zone .roi-zone .input-zone input[type='number']::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0; }
            .project-detail-page-wrapper .buy-shares-zone .action-zone .roi-zone .input-zone input[type='number'] {
              -moz-appearance: textfield; }
          .project-detail-page-wrapper .buy-shares-zone .action-zone .roi-zone .calculation-zone {
            display: flex;
            flex-direction: column;
            align-items: flex-start; }
            .project-detail-page-wrapper .buy-shares-zone .action-zone .roi-zone .calculation-zone .header {
              font-size: 16px;
              font-weight: 500; }
            .project-detail-page-wrapper .buy-shares-zone .action-zone .roi-zone .calculation-zone .content {
              font-size: 24px;
              font-weight: 500; }
      .project-detail-page-wrapper .buy-shares-zone .information-zone {
        box-sizing: border-box;
        width: 100%;
        height: 40px;
        background-color: #3366ff;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        padding-top: 9px; }
        .project-detail-page-wrapper .buy-shares-zone .information-zone .text {
          font-size: 16px;
          font-weight: 500; }
    .project-detail-page-wrapper .project-overview-zone {
      width: 1180px;
      margin: 0 auto;
      margin-top: 100px; }
      .project-detail-page-wrapper .project-overview-zone .header {
        text-align: left;
        font-size: 30px;
        font-weight: 600;
        margin-bottom: 40px; }
      .project-detail-page-wrapper .project-overview-zone .project-overview-card-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start; }
        .project-detail-page-wrapper .project-overview-zone .project-overview-card-wrapper .overview-card {
          box-sizing: border-box;
          position: relative;
          width: 342px;
          height: 215px;
          border: 1px solid #eaeaea;
          border-radius: 8px; }
          .project-detail-page-wrapper .project-overview-zone .project-overview-card-wrapper .overview-card .header-zone {
            width: 100%;
            box-sizing: border-box;
            height: 60px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            padding-left: 30px;
            margin: 0; }
            .project-detail-page-wrapper .project-overview-zone .project-overview-card-wrapper .overview-card .header-zone .icon {
              font-size: 16px;
              margin-right: 10px; }
            .project-detail-page-wrapper .project-overview-zone .project-overview-card-wrapper .overview-card .header-zone .card-header {
              font-size: 24px;
              font-weight: 600; }
          .project-detail-page-wrapper .project-overview-zone .project-overview-card-wrapper .overview-card .separator {
            position: absolute;
            top: 60px;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: #eaeaea; }
          .project-detail-page-wrapper .project-overview-zone .project-overview-card-wrapper .overview-card .content-zone {
            box-sizing: border-box;
            padding: 30px 30px 20px 30px;
            height: calc(100% - 60px);
            display: flex;
            flex-direction: column;
            align-items: flex-start; }
            .project-detail-page-wrapper .project-overview-zone .project-overview-card-wrapper .overview-card .content-zone .subheader-zone {
              display: flex;
              flex-direction: row;
              width: 100%;
              margin-bottom: 10px; }
              .project-detail-page-wrapper .project-overview-zone .project-overview-card-wrapper .overview-card .content-zone .subheader-zone .flex-content {
                width: 60%; }
              .project-detail-page-wrapper .project-overview-zone .project-overview-card-wrapper .overview-card .content-zone .subheader-zone .content {
                text-align: left;
                font-size: 16px;
                font-weight: 500; }
              .project-detail-page-wrapper .project-overview-zone .project-overview-card-wrapper .overview-card .content-zone .subheader-zone .content-bold {
                font-weight: 700; }
            .project-detail-page-wrapper .project-overview-zone .project-overview-card-wrapper .overview-card .content-zone .short-description {
              font-size: 14px;
              line-height: 1.2;
              font-weight: 500;
              text-align: left;
              color: #999999;
              margin-bottom: 20px; }
            .project-detail-page-wrapper .project-overview-zone .project-overview-card-wrapper .overview-card .content-zone .details-cta {
              font-size: 14px;
              line-height: 1.43;
              font-weight: 600;
              text-align: left;
              color: #4a4a4a;
              cursor: pointer; }
    .project-detail-page-wrapper .project-information-zone {
      width: 1180px;
      margin: 0 auto;
      margin-top: 100px; }
      .project-detail-page-wrapper .project-information-zone .header {
        text-align: left;
        font-size: 30px;
        font-weight: 600;
        margin-bottom: 40px; }
      .project-detail-page-wrapper .project-information-zone .general-project-information-zone {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin-bottom: 45px; }
        .project-detail-page-wrapper .project-information-zone .general-project-information-zone .information {
          border-right: 1px solid #eaeaea;
          padding-right: 30px;
          padding-left: 30px;
          padding-top: 5px;
          padding-bottom: 5px; }
          .project-detail-page-wrapper .project-information-zone .general-project-information-zone .information:first-child {
            padding-left: 0; }
          .project-detail-page-wrapper .project-information-zone .general-project-information-zone .information:last-child {
            padding-right: 0;
            border-right: none; }
          .project-detail-page-wrapper .project-information-zone .general-project-information-zone .information .header {
            font-size: 16px;
            line-height: 1.25;
            font-weight: 500;
            color: #999999;
            margin: 0; }
          .project-detail-page-wrapper .project-information-zone .general-project-information-zone .information .subheader {
            font-size: 16px;
            line-height: 1.25;
            font-weight: 600;
            color: #000000; }
      .project-detail-page-wrapper .project-information-zone .map-project-information-zone {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        height: 250px;
        width: 100%; }
        .project-detail-page-wrapper .project-information-zone .map-project-information-zone .map {
          min-width: 338px;
          height: 100%; }
          .project-detail-page-wrapper .project-information-zone .map-project-information-zone .map:first-child {
            margin-right: 30px; }
          .project-detail-page-wrapper .project-information-zone .map-project-information-zone .map:nth-child(2) {
            margin-right: 40px; }
        .project-detail-page-wrapper .project-information-zone .map-project-information-zone .map-placeholder {
          min-width: 338px;
          height: 100%;
          background-color: #eaeaea;
          cursor: pointer; }
          .project-detail-page-wrapper .project-information-zone .map-project-information-zone .map-placeholder:first-child {
            margin-right: 30px; }
          .project-detail-page-wrapper .project-information-zone .map-project-information-zone .map-placeholder:nth-child(2) {
            margin-right: 40px; }
        .project-detail-page-wrapper .project-information-zone .map-project-information-zone .land-information-wrapper {
          width: 300px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: space-around; }
          .project-detail-page-wrapper .project-information-zone .map-project-information-zone .land-information-wrapper .information-line {
            box-sizing: border-box;
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            border-bottom: 1px solid #eaeaea;
            padding-right: 20px;
            padding-bottom: 20px; }
            .project-detail-page-wrapper .project-information-zone .map-project-information-zone .land-information-wrapper .information-line:last-child {
              border-bottom: none; }
            .project-detail-page-wrapper .project-information-zone .map-project-information-zone .land-information-wrapper .information-line .information-full {
              flex: 1; }
              .project-detail-page-wrapper .project-information-zone .map-project-information-zone .land-information-wrapper .information-line .information-full .header {
                font-size: 16px;
                line-height: 1.25;
                font-weight: 500;
                color: #999999;
                margin: 0; }
              .project-detail-page-wrapper .project-information-zone .map-project-information-zone .land-information-wrapper .information-line .information-full .subheader {
                font-size: 16px;
                line-height: 1.25;
                font-weight: 600;
                color: #000000; }
            .project-detail-page-wrapper .project-information-zone .map-project-information-zone .land-information-wrapper .information-line .information {
              flex: 0.5; }
              .project-detail-page-wrapper .project-information-zone .map-project-information-zone .land-information-wrapper .information-line .information .header {
                font-size: 16px;
                line-height: 1.25;
                font-weight: 500;
                color: #999999;
                margin: 0; }
              .project-detail-page-wrapper .project-information-zone .map-project-information-zone .land-information-wrapper .information-line .information .subheader {
                font-size: 16px;
                line-height: 1.25;
                font-weight: 600;
                color: #000000; }
    .project-detail-page-wrapper .mobile-project-information-zone {
      display: none; }
    .project-detail-page-wrapper .roadmap-zone {
      position: relative;
      width: 1180px;
      margin: 145px auto 0 auto; }
      .project-detail-page-wrapper .roadmap-zone .highlight {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        box-sizing: border-box;
        position: absolute;
        top: -40px;
        left: 0;
        height: 60px;
        background-color: #ff6600;
        border-radius: 8px;
        z-index: 1; }
        .project-detail-page-wrapper .roadmap-zone .highlight .icon {
          font-size: 12px;
          margin: 15px 10px auto 15px; }
        .project-detail-page-wrapper .roadmap-zone .highlight .text {
          font-size: 16px;
          margin: 10px 50px auto 0px; }
      .project-detail-page-wrapper .roadmap-zone .roadmap-div {
        position: relative;
        z-index: 10;
        height: 400px;
        width: 100%;
        border-radius: 8px;
        background-color: #fafafa; }
    .project-detail-page-wrapper .financial-details-zone {
      width: 1180px;
      margin: 0 auto;
      margin-top: 100px; }
      .project-detail-page-wrapper .financial-details-zone .header {
        text-align: left;
        font-size: 30px;
        font-weight: 600;
        margin-bottom: 30px; }
      .project-detail-page-wrapper .financial-details-zone .divided-tab-panel {
        display: flex;
        flex-direction: row; }
      .project-detail-page-wrapper .financial-details-zone .data-column {
        display: flex;
        flex-direction: row;
        width: 33%; }
        .project-detail-page-wrapper .financial-details-zone .data-column .tab-content-vertical {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start; }
          .project-detail-page-wrapper .financial-details-zone .data-column .tab-content-vertical .general-tab-header {
            font-size: 20px;
            font-weight: 700;
            text-align: left;
            margin-bottom: 20px; }
          .project-detail-page-wrapper .financial-details-zone .data-column .tab-content-vertical .tab-content {
            display: flex;
            flex-direction: row; }
            .project-detail-page-wrapper .financial-details-zone .data-column .tab-content-vertical .tab-content .header-column {
              width: 50%;
              display: flex;
              flex-direction: column;
              align-items: flex-start; }
              .project-detail-page-wrapper .financial-details-zone .data-column .tab-content-vertical .tab-content .header-column .header {
                font-size: 16px;
                line-height: 2;
                color: #999999;
                margin: 0;
                font-weight: 500; }
            .project-detail-page-wrapper .financial-details-zone .data-column .tab-content-vertical .tab-content .value-column {
              width: 50%;
              display: flex;
              flex-direction: column;
              align-items: flex-start; }
              .project-detail-page-wrapper .financial-details-zone .data-column .tab-content-vertical .tab-content .value-column .value {
                font-size: 16px;
                line-height: 2;
                color: #000000;
                margin: 0;
                font-weight: 600; }
      .project-detail-page-wrapper .financial-details-zone .costs-zone {
        margin: 40px 0 0 0;
        border: 1px solid #eaeaea;
        border-radius: 8px;
        box-sizing: border-box;
        width: 100%;
        display: flex;
        flex-direction: row; }
        .project-detail-page-wrapper .financial-details-zone .costs-zone .graph-area {
          width: 50%;
          border-right: 1px solid #eaeaea;
          box-sizing: border-box;
          padding: 20px;
          font-size: 24px; }
        .project-detail-page-wrapper .financial-details-zone .costs-zone .you-should-know-area {
          width: 50%;
          box-sizing: border-box;
          padding: 30px 40px; }
          .project-detail-page-wrapper .financial-details-zone .costs-zone .you-should-know-area .header {
            font-size: 24px;
            font-weight: 700;
            margin: 0;
            margin-bottom: 20px; }
          .project-detail-page-wrapper .financial-details-zone .costs-zone .you-should-know-area .content {
            font-size: 14px;
            line-height: 20px;
            font-weight: 500;
            text-align: left;
            margin-bottom: 50px;
            padding-right: 80px; }
          .project-detail-page-wrapper .financial-details-zone .costs-zone .you-should-know-area .description {
            font-size: 14px;
            line-height: 20px;
            font-weight: 500;
            text-align: left;
            color: #999999; }
    .project-detail-page-wrapper .mobile-financial-details-zone {
      display: none; }
    .project-detail-page-wrapper .technology-details-zone {
      width: 1180px;
      margin: 0 auto;
      margin-top: 100px; }
      .project-detail-page-wrapper .technology-details-zone .header {
        text-align: left;
        font-size: 30px;
        font-weight: 600;
        margin-bottom: 30px; }
      .project-detail-page-wrapper .technology-details-zone .technology-tabs {
        padding: 0; }
        .project-detail-page-wrapper .technology-details-zone .technology-tabs .technology-tab-content-wrapper {
          display: flex;
          flex-direction: row;
          width: 100%; }
          .project-detail-page-wrapper .technology-details-zone .technology-tabs .technology-tab-content-wrapper .data-column {
            display: flex;
            flex-direction: row;
            width: 50%;
            padding: 40px 30px; }
            .project-detail-page-wrapper .technology-details-zone .technology-tabs .technology-tab-content-wrapper .data-column .tab-content-vertical {
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: flex-start; }
              .project-detail-page-wrapper .technology-details-zone .technology-tabs .technology-tab-content-wrapper .data-column .tab-content-vertical .general-tab-header {
                font-size: 20px;
                font-weight: 700;
                text-align: left;
                margin-bottom: 20px; }
              .project-detail-page-wrapper .technology-details-zone .technology-tabs .technology-tab-content-wrapper .data-column .tab-content-vertical .tab-content {
                display: flex;
                flex-direction: row; }
                .project-detail-page-wrapper .technology-details-zone .technology-tabs .technology-tab-content-wrapper .data-column .tab-content-vertical .tab-content .header-column {
                  width: 50%;
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start; }
                  .project-detail-page-wrapper .technology-details-zone .technology-tabs .technology-tab-content-wrapper .data-column .tab-content-vertical .tab-content .header-column .header {
                    font-size: 16px;
                    line-height: 2;
                    color: #999999;
                    margin: 0;
                    font-weight: 500; }
                .project-detail-page-wrapper .technology-details-zone .technology-tabs .technology-tab-content-wrapper .data-column .tab-content-vertical .tab-content .value-column {
                  width: 50%;
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start; }
                  .project-detail-page-wrapper .technology-details-zone .technology-tabs .technology-tab-content-wrapper .data-column .tab-content-vertical .tab-content .value-column .value {
                    font-size: 16px;
                    line-height: 2;
                    color: #000000;
                    margin: 0;
                    font-weight: 600; }
          .project-detail-page-wrapper .technology-details-zone .technology-tabs .technology-tab-content-wrapper .description-column {
            width: 50%;
            box-sizing: border-box;
            background-color: #0033ff;
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
            padding: 30px 40px; }
            .project-detail-page-wrapper .technology-details-zone .technology-tabs .technology-tab-content-wrapper .description-column .header {
              color: #ffffff;
              font-size: 24px;
              font-weight: 700;
              margin: 0;
              margin-bottom: 20px; }
            .project-detail-page-wrapper .technology-details-zone .technology-tabs .technology-tab-content-wrapper .description-column .content {
              color: #ffffff;
              font-size: 14px;
              line-height: 20px;
              font-weight: 500;
              text-align: left;
              margin-bottom: 50px;
              padding-right: 80px; }
        .project-detail-page-wrapper .technology-details-zone .technology-tabs .technology-tab-content-wrapper-with-3 {
          display: flex;
          flex-direction: row;
          width: 100%; }
          .project-detail-page-wrapper .technology-details-zone .technology-tabs .technology-tab-content-wrapper-with-3 .data-column {
            display: flex;
            flex-direction: row;
            width: 35%;
            padding: 40px 30px; }
            .project-detail-page-wrapper .technology-details-zone .technology-tabs .technology-tab-content-wrapper-with-3 .data-column:first-child {
              width: 30%; }
            .project-detail-page-wrapper .technology-details-zone .technology-tabs .technology-tab-content-wrapper-with-3 .data-column .tab-content-vertical {
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: flex-start; }
              .project-detail-page-wrapper .technology-details-zone .technology-tabs .technology-tab-content-wrapper-with-3 .data-column .tab-content-vertical .general-tab-header {
                font-size: 20px;
                font-weight: 700;
                text-align: left;
                margin-bottom: 20px; }
              .project-detail-page-wrapper .technology-details-zone .technology-tabs .technology-tab-content-wrapper-with-3 .data-column .tab-content-vertical .tab-content {
                display: flex;
                flex-direction: row; }
                .project-detail-page-wrapper .technology-details-zone .technology-tabs .technology-tab-content-wrapper-with-3 .data-column .tab-content-vertical .tab-content .header-column {
                  width: 50%;
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start; }
                  .project-detail-page-wrapper .technology-details-zone .technology-tabs .technology-tab-content-wrapper-with-3 .data-column .tab-content-vertical .tab-content .header-column .header {
                    font-size: 16px;
                    line-height: 2;
                    color: #999999;
                    margin: 0;
                    font-weight: 500; }
                .project-detail-page-wrapper .technology-details-zone .technology-tabs .technology-tab-content-wrapper-with-3 .data-column .tab-content-vertical .tab-content .value-column {
                  width: 50%;
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start; }
                  .project-detail-page-wrapper .technology-details-zone .technology-tabs .technology-tab-content-wrapper-with-3 .data-column .tab-content-vertical .tab-content .value-column .value {
                    font-size: 16px;
                    line-height: 2;
                    color: #000000;
                    margin: 0;
                    font-weight: 600; }
    .project-detail-page-wrapper .mobile-technology-details-zone {
      display: none; }
    .project-detail-page-wrapper .market-details-zone {
      width: 1180px;
      margin: 0 auto;
      margin-top: 100px;
      margin-bottom: 60px; }
      .project-detail-page-wrapper .market-details-zone .header {
        text-align: left;
        font-size: 30px;
        font-weight: 600;
        margin-bottom: 30px; }
      .project-detail-page-wrapper .market-details-zone .market-tabs {
        padding: 0; }
        .project-detail-page-wrapper .market-details-zone .market-tabs .market-tab-content-wrapper {
          display: flex;
          flex-direction: row;
          width: 100%; }
          .project-detail-page-wrapper .market-details-zone .market-tabs .market-tab-content-wrapper .you-should-know-column {
            width: 50%;
            box-sizing: border-box;
            border-bottom-left-radius: 8px;
            padding: 30px 40px; }
            .project-detail-page-wrapper .market-details-zone .market-tabs .market-tab-content-wrapper .you-should-know-column .header {
              font-size: 24px;
              font-weight: 700;
              margin: 0;
              margin-bottom: 20px; }
            .project-detail-page-wrapper .market-details-zone .market-tabs .market-tab-content-wrapper .you-should-know-column .content {
              font-size: 14px;
              line-height: 20px;
              font-weight: 500;
              text-align: left;
              margin-bottom: 50px;
              padding-right: 80px; }
          .project-detail-page-wrapper .market-details-zone .market-tabs .market-tab-content-wrapper .market-index-chart-area {
            width: 50%;
            min-height: 280px;
            box-sizing: border-box;
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
            padding: 30px 40px;
            border-left: 1px solid #eaeaea; }
            .project-detail-page-wrapper .market-details-zone .market-tabs .market-tab-content-wrapper .market-index-chart-area .market-index-chart-title {
              font-size: 24px;
              font-weight: 500;
              color: black;
              text-align: left; }
            .project-detail-page-wrapper .market-details-zone .market-tabs .market-tab-content-wrapper .market-index-chart-area .market-index-chart-wrapper {
              height: 100%;
              box-sizing: border-box;
              padding: 20px 0;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              align-items: flex-start; }
          .project-detail-page-wrapper .market-details-zone .market-tabs .market-tab-content-wrapper .chart-area {
            width: 50%;
            min-height: 280px;
            box-sizing: border-box;
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
            padding: 30px 40px;
            border-left: 1px solid #eaeaea; }
          .project-detail-page-wrapper .market-details-zone .market-tabs .market-tab-content-wrapper .investment-chart-area {
            width: 50%;
            min-height: 280px;
            box-sizing: border-box;
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
            padding: 30px 40px;
            border-left: 1px solid #eaeaea;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: flex-end; }
          .project-detail-page-wrapper .market-details-zone .market-tabs .market-tab-content-wrapper .data-column {
            display: flex;
            flex-direction: row;
            width: 50%;
            box-sizing: border-box;
            padding: 40px 30px; }
            .project-detail-page-wrapper .market-details-zone .market-tabs .market-tab-content-wrapper .data-column .tab-content-vertical {
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: flex-start; }
              .project-detail-page-wrapper .market-details-zone .market-tabs .market-tab-content-wrapper .data-column .tab-content-vertical .general-tab-header {
                font-size: 20px;
                font-weight: 700;
                text-align: left;
                margin-bottom: 20px; }
              .project-detail-page-wrapper .market-details-zone .market-tabs .market-tab-content-wrapper .data-column .tab-content-vertical .tab-content {
                display: flex;
                flex-direction: row; }
                .project-detail-page-wrapper .market-details-zone .market-tabs .market-tab-content-wrapper .data-column .tab-content-vertical .tab-content .header-column {
                  width: 50%;
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start; }
                  .project-detail-page-wrapper .market-details-zone .market-tabs .market-tab-content-wrapper .data-column .tab-content-vertical .tab-content .header-column .header {
                    font-size: 16px;
                    line-height: 2;
                    color: #999999;
                    margin: 0;
                    font-weight: 500; }
                .project-detail-page-wrapper .market-details-zone .market-tabs .market-tab-content-wrapper .data-column .tab-content-vertical .tab-content .value-column {
                  width: 50%;
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start; }
                  .project-detail-page-wrapper .market-details-zone .market-tabs .market-tab-content-wrapper .data-column .tab-content-vertical .tab-content .value-column .value {
                    font-size: 16px;
                    line-height: 2;
                    color: #000000;
                    margin: 0;
                    font-weight: 600; }
    .project-detail-page-wrapper .mobile-market-details-zone {
      display: none; }
    .project-detail-page-wrapper .file-zone {
      width: 1180px;
      margin: 0 auto;
      border: 1px solid #eaeaea;
      border-radius: 8px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;
      box-sizing: border-box;
      padding: 4px 12px;
      margin-top: 40px; }
      .project-detail-page-wrapper .file-zone .file-item {
        width: 25%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        box-sizing: border-box;
        padding: 20px; }
        .project-detail-page-wrapper .file-zone .file-item .icon {
          font-size: 50px; }
        .project-detail-page-wrapper .file-zone .file-item .text-area {
          box-sizing: border-box;
          padding: 0 20px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start; }
          .project-detail-page-wrapper .file-zone .file-item .text-area .header {
            font-size: 16px;
            line-height: 20px;
            font-weight: 600;
            margin: 0; }
          .project-detail-page-wrapper .file-zone .file-item .text-area .download {
            font-size: 16px;
            line-height: 20px;
            font-weight: 500;
            margin: 0;
            text-align: left;
            color: #0033ff;
            cursor: pointer; }
            .project-detail-page-wrapper .file-zone .file-item .text-area .download:hover {
              text-decoration: underline; }
  .chart-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end; }
    .chart-container .chart-wrapper {
      width: 50%;
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between; }
      .chart-container .chart-wrapper .single-chart {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center; }
        .chart-container .chart-wrapper .single-chart .header {
          width: 100%;
          text-align: center;
          font-size: 24px;
          font-weight: 700;
          margin: 0; }
        .chart-container .chart-wrapper .single-chart .subheader {
          width: 100%;
          text-align: center;
          font-size: 12px;
          line-height: 16x;
          font-weight: 500; }
    .chart-container .content-wrapper {
      box-sizing: border-box;
      padding-left: 50px;
      padding-right: 20px;
      width: 50%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start; }
      .chart-container .content-wrapper .content-difference {
        font-size: 60px;
        line-height: 40px;
        font-weight: 600;
        margin: 0; }
      .chart-container .content-wrapper .content-difference-header {
        font-size: 16px;
        line-height: 26px;
        font-weight: 500; }
      .chart-container .content-wrapper .separator {
        height: 1px;
        width: 100%;
        background-color: #979797;
        margin-top: 20px;
        margin-bottom: 20px; }
      .chart-container .content-wrapper .content-description {
        font-size: 14px;
        line-height: 20px;
        text-align: left;
        font-weight: 500; } }

@media only screen and (max-width: 768px) {
  .project-detail-border {
    width: 100vw;
    height: 1px;
    background-color: #cccccc; }
  .hanging-nav-bar-wrapper {
    display: none; }
  .project-detail-page-wrapper {
    box-sizing: border-box;
    width: 100vw;
    margin: 0 auto;
    padding-top: 20px; }
    .project-detail-page-wrapper .buy-shares {
      z-index: 10000;
      position: fixed;
      left: 0;
      bottom: 0;
      width: 100vw;
      height: 50px;
      color: #ffffff;
      background-color: #33cc00;
      font-size: 16px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
      .project-detail-page-wrapper .buy-shares .text {
        font-size: 20px;
        font-weight: 600; }
    .project-detail-page-wrapper .header-zone {
      width: 94vw;
      margin: 0 auto 20px auto;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center; }
      .project-detail-page-wrapper .header-zone .left-pane {
        flex: 1; }
        .project-detail-page-wrapper .header-zone .left-pane .tag-zone {
          display: flex;
          flex-direction: row;
          margin-bottom: 10px; }
          .project-detail-page-wrapper .header-zone .left-pane .tag-zone .tag {
            margin-right: 10px; }
        .project-detail-page-wrapper .header-zone .left-pane .plant-header {
          font-size: 32px;
          font-weight: 700; }
        .project-detail-page-wrapper .header-zone .left-pane .plant-owner {
          font-size: 16px;
          color: #4a4a4a;
          font-weight: 500; }
        .project-detail-page-wrapper .header-zone .left-pane .project-count {
          display: none; }
      .project-detail-page-wrapper .header-zone .right-pane {
        display: none; }
    .project-detail-page-wrapper .hero-zone {
      position: relative;
      width: 94vw;
      margin: 0 auto 20px auto; }
      .project-detail-page-wrapper .hero-zone .highlight {
        display: none; }
      .project-detail-page-wrapper .hero-zone .hero-wrapper {
        box-sizing: border-box;
        position: relative;
        z-index: 10;
        width: 100%;
        background-color: #ffffff;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start; }
        .project-detail-page-wrapper .hero-zone .hero-wrapper .general-information {
          width: 100%;
          margin-bottom: 20px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-start; }
          .project-detail-page-wrapper .hero-zone .hero-wrapper .general-information .not-visible {
            display: none; }
          .project-detail-page-wrapper .hero-zone .hero-wrapper .general-information .information {
            box-sizing: border-box; }
            .project-detail-page-wrapper .hero-zone .hero-wrapper .general-information .information .header {
              font-size: 14px;
              font-weight: 500;
              color: #999999; }
            .project-detail-page-wrapper .hero-zone .hero-wrapper .general-information .information .subheader {
              font-size: 16px;
              font-weight: 600;
              color: #000000; }
        .project-detail-page-wrapper .hero-zone .hero-wrapper .project-images {
          position: relative;
          width: 96%;
          margin: 0 auto;
          height: 100%;
          margin-bottom: 15px; }
          .project-detail-page-wrapper .hero-zone .hero-wrapper .project-images .image {
            position: relative;
            top: -0.5px;
            width: 100%;
            height: calc(100% + 1px);
            object-fit: cover; }
        .project-detail-page-wrapper .hero-zone .hero-wrapper .project-information {
          width: 96%;
          margin: 0 auto;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start; }
          .project-detail-page-wrapper .hero-zone .hero-wrapper .project-information .information-zone {
            width: 100%;
            display: flex;
            flex-direction: column; }
            .project-detail-page-wrapper .hero-zone .hero-wrapper .project-information .information-zone .information-line {
              width: 100%;
              border-bottom: 1px solid #eaeaea;
              padding: 20px 0 20px 0; }
              .project-detail-page-wrapper .hero-zone .hero-wrapper .project-information .information-zone .information-line:last-child {
                border-bottom: none; }
              .project-detail-page-wrapper .hero-zone .hero-wrapper .project-information .information-zone .information-line .header {
                font-size: 16px;
                font-weight: 500;
                color: #999999; }
              .project-detail-page-wrapper .hero-zone .hero-wrapper .project-information .information-zone .information-line .subheader {
                font-size: 24px;
                font-weight: 600;
                color: #000000; }
              .project-detail-page-wrapper .hero-zone .hero-wrapper .project-information .information-zone .information-line .extended-subheader {
                font-size: 30px; }
            .project-detail-page-wrapper .hero-zone .hero-wrapper .project-information .information-zone .flex-information-line {
              display: flex;
              flex-direction: row;
              align-items: flex-start; }
              .project-detail-page-wrapper .hero-zone .hero-wrapper .project-information .information-zone .flex-information-line .information {
                width: 60%; }
                .project-detail-page-wrapper .hero-zone .hero-wrapper .project-information .information-zone .flex-information-line .information:last-child {
                  width: 40%; }
          .project-detail-page-wrapper .hero-zone .hero-wrapper .project-information .cta-zone {
            display: none; }
    .project-detail-page-wrapper .buy-shares-zone {
      display: none; }
    .project-detail-page-wrapper .project-overview-zone {
      display: none; }
    .project-detail-page-wrapper .project-information-zone {
      display: none; }
    .project-detail-page-wrapper .mobile-project-information-zone {
      width: 90vw;
      margin: 0 auto 20px auto; }
      .project-detail-page-wrapper .mobile-project-information-zone .header {
        text-align: left;
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 15px; }
      .project-detail-page-wrapper .mobile-project-information-zone .map-project-information-zone {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
        margin-bottom: 15px; }
        .project-detail-page-wrapper .mobile-project-information-zone .map-project-information-zone .map {
          width: 45%;
          height: auto;
          cursor: pointer; }
        .project-detail-page-wrapper .mobile-project-information-zone .map-project-information-zone .map-placeholder {
          height: 100px;
          width: 45%;
          background-color: #eaeaea;
          cursor: pointer; }
      .project-detail-page-wrapper .mobile-project-information-zone .mobile-information-wrapper {
        display: flex;
        flex-direction: row;
        align-items: flex-start; }
        .project-detail-page-wrapper .mobile-project-information-zone .mobile-information-wrapper .zone {
          flex: 0.5; }
          .project-detail-page-wrapper .mobile-project-information-zone .mobile-information-wrapper .zone .information {
            margin-bottom: 15px; }
            .project-detail-page-wrapper .mobile-project-information-zone .mobile-information-wrapper .zone .information .header {
              font-size: 16px;
              line-height: 1.25;
              font-weight: 500;
              color: #999999;
              margin: 0; }
            .project-detail-page-wrapper .mobile-project-information-zone .mobile-information-wrapper .zone .information .subheader {
              font-size: 16px;
              line-height: 1.25;
              font-weight: 600;
              color: #000000; }
    .project-detail-page-wrapper .roadmap-zone {
      position: relative;
      width: 100vw;
      margin: 20px auto 20px auto;
      overflow-x: scroll; }
      .project-detail-page-wrapper .roadmap-zone .highlight {
        display: none; }
      .project-detail-page-wrapper .roadmap-zone .roadmap-div {
        position: relative;
        z-index: 10;
        height: 400px;
        width: 1180px;
        background-color: #fafafa; }
    .project-detail-page-wrapper .financial-details-zone {
      display: none; }
    .project-detail-page-wrapper .mobile-financial-details-zone {
      width: 94vw;
      margin: 40px auto 0 auto; }
      .project-detail-page-wrapper .mobile-financial-details-zone .header {
        text-align: left;
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 20px; }
      .project-detail-page-wrapper .mobile-financial-details-zone .data-column {
        width: 100%; }
        .project-detail-page-wrapper .mobile-financial-details-zone .data-column .data-row {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: flex-start; }
          .project-detail-page-wrapper .mobile-financial-details-zone .data-column .data-row .header {
            width: 50%;
            font-size: 14px;
            color: #999999;
            font-weight: 500;
            margin-bottom: 10px; }
          .project-detail-page-wrapper .mobile-financial-details-zone .data-column .data-row .value {
            width: 50%;
            font-size: 14px;
            color: #000000;
            font-weight: 500;
            text-align: left;
            margin-bottom: 10px; }
      .project-detail-page-wrapper .mobile-financial-details-zone .costs-zone {
        display: none; }
    .project-detail-page-wrapper .technology-details-zone {
      display: none; }
    .project-detail-page-wrapper .mobile-technology-details-zone {
      width: 94vw;
      margin: 40px auto 0 auto; }
      .project-detail-page-wrapper .mobile-technology-details-zone .header {
        text-align: left;
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 20px; }
      .project-detail-page-wrapper .mobile-technology-details-zone .data-column {
        width: 100%; }
        .project-detail-page-wrapper .mobile-technology-details-zone .data-column .data-row {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: flex-start; }
          .project-detail-page-wrapper .mobile-technology-details-zone .data-column .data-row .header {
            width: 60%;
            font-size: 14px;
            color: #999999;
            font-weight: 500;
            margin-bottom: 10px; }
          .project-detail-page-wrapper .mobile-technology-details-zone .data-column .data-row .value {
            width: 40%;
            font-size: 14px;
            color: #000000;
            font-weight: 500;
            text-align: left;
            margin-bottom: 10px; }
      .project-detail-page-wrapper .mobile-technology-details-zone .button {
        display: none; }
    .project-detail-page-wrapper .market-details-zone {
      display: none; }
    .project-detail-page-wrapper .mobile-market-details-zone {
      width: 94vw;
      margin: 0 auto 0 auto; }
      .project-detail-page-wrapper .mobile-market-details-zone .header {
        text-align: left;
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 20px; }
      .project-detail-page-wrapper .mobile-market-details-zone .market-index-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start; }
        .project-detail-page-wrapper .mobile-market-details-zone .market-index-wrapper .market-index-chart-title {
          font-size: 16px;
          font-weight: 500;
          color: black;
          text-align: left; }
        .project-detail-page-wrapper .mobile-market-details-zone .market-index-wrapper .market-index-chart-wrapper {
          width: 100%;
          height: 100%;
          min-height: 150px;
          box-sizing: border-box;
          padding: 20px 0;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start; }
      .project-detail-page-wrapper .mobile-market-details-zone .chart-area {
        width: 100%;
        min-height: 200px;
        box-sizing: border-box;
        padding: 20px 10px 10px 10px; }
      .project-detail-page-wrapper .mobile-market-details-zone .investment-chart-area {
        width: 94vw;
        min-height: 280px;
        box-sizing: border-box;
        padding: 20px 10px 10px 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: flex-end; }
      .project-detail-page-wrapper .mobile-market-details-zone .data-column {
        width: 100%; }
        .project-detail-page-wrapper .mobile-market-details-zone .data-column .data-row {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: flex-start; }
          .project-detail-page-wrapper .mobile-market-details-zone .data-column .data-row .header {
            width: 50%;
            font-size: 14px;
            color: #999999;
            font-weight: 500;
            margin-bottom: 10px; }
          .project-detail-page-wrapper .mobile-market-details-zone .data-column .data-row .value {
            width: 50%;
            font-size: 14px;
            color: #000000;
            font-weight: 500;
            text-align: left;
            margin-bottom: 10px; }
      .project-detail-page-wrapper .mobile-market-details-zone .button {
        display: none; }
    .project-detail-page-wrapper .file-zone {
      width: 94vw;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;
      box-sizing: border-box;
      padding: 4px 12px; }
      .project-detail-page-wrapper .file-zone .file-item {
        width: 50%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        box-sizing: border-box;
        padding: 5px; }
        .project-detail-page-wrapper .file-zone .file-item .icon {
          font-size: 50px; }
        .project-detail-page-wrapper .file-zone .file-item .text-area {
          box-sizing: border-box;
          padding: 0 5px 0 10px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start; }
          .project-detail-page-wrapper .file-zone .file-item .text-area .header {
            font-size: 14px;
            line-height: 20px;
            font-weight: 600;
            margin: 0; }
          .project-detail-page-wrapper .file-zone .file-item .text-area .download {
            font-size: 14px;
            line-height: 20px;
            font-weight: 500;
            margin: 0;
            text-align: left;
            color: #0033ff;
            cursor: pointer; }
            .project-detail-page-wrapper .file-zone .file-item .text-area .download:hover {
              text-decoration: underline; }
  .chart-container {
    display: none; } }
