/* Global style guide for UI Lib */
/**
 * Browser support
 */
/**
 * Typography
 */
@import url("https://fonts.googleapis.com/css?family=Rajdhani:300,400,500,600,700&subset=latin-ext");
/**
 * Colors
 */
/**
 * Page -- UILib Specific Variables
 */
/**
 * Card -- UILib Specific Variables
 */
@media only screen and (min-width: 769px) {
  .work-in-progress-zone {
    height: calc(100vh - 83px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    .work-in-progress-zone .text {
      color: #666666;
      font-size: 24px;
      font-weight: 500;
      margin-bottom: 30px; }
    .work-in-progress-zone .action-zone {
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 100%; }
      .work-in-progress-zone .action-zone .action {
        width: 175px; }
        .work-in-progress-zone .action-zone .action:first-child {
          margin-right: 50px; } }

@media only screen and (max-width: 768px) {
  .work-in-progress-zone {
    width: 90vw;
    margin: 0 auto;
    min-height: calc(100vh - 200px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    .work-in-progress-zone .text {
      color: #666666;
      font-size: 24px;
      font-weight: 500;
      margin-bottom: 30px; }
    .work-in-progress-zone .action-zone {
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 100%; }
      .work-in-progress-zone .action-zone .action {
        width: 175px; }
        .work-in-progress-zone .action-zone .action:first-child {
          margin-right: 50px; } }
