/* Global style guide for UI Lib */
/**
 * Browser support
 */
/**
 * Typography
 */
@import url("https://fonts.googleapis.com/css?family=Rajdhani:300,400,500,600,700&subset=latin-ext");
/**
 * Colors
 */
/**
 * Page -- UILib Specific Variables
 */
/**
 * Card -- UILib Specific Variables
 */
.logout-card {
  min-height: 600px;
  justify-content: flex-start;
  align-items: center;
  padding-top: 80px; }
  .logout-card .icon {
    margin-bottom: 60px; }
  .logout-card .text {
    font-size: 36px;
    color: #4a4a4a;
    font-weight: 700;
    width: 290px;
    margin: 0 auto; }
  .logout-card .action {
    margin-top: 80px;
    height: 60px;
    width: 175px; }
