/* Global style guide for UI Lib */
/**
 * Browser support
 */
/**
 * Typography
 */
@import url("https://fonts.googleapis.com/css?family=Rajdhani:300,400,500,600,700&subset=latin-ext");
/**
 * Colors
 */
/**
 * Page -- UILib Specific Variables
 */
/**
 * Card -- UILib Specific Variables
 */
html,
body {
  min-height: 100vh;
  min-width: 100vw;
  margin: 0;
  padding: 0;
  font-family: "Rajdhani", sans-serif;
  font-size: 12px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

h1 {
  font-size: 3em;
  line-height: 1.25;
  font-weight: 700;
  margin: 0; }

h2 {
  font-size: 2.5em;
  line-height: 1.25;
  font-weight: 700;
  margin: 0; }

h3 {
  font-size: 2em;
  line-height: 1.25;
  font-weight: 700;
  margin: 0; }

h4 {
  font-size: 1.67em;
  line-height: 1.25;
  font-weight: 700;
  margin: 0; }

p {
  font-size: 1.167em;
  line-height: 1.25;
  font-weight: 500;
  margin: 0; }

.text-quote {
  font-size: 2em;
  line-height: 1.44;
  font-weight: 500;
  margin: 0; }

.text-cta {
  font-size: 1.5em;
  line-height: 1.25;
  font-weight: 600;
  margin: 0; }

.text-intro {
  font-size: 1.5em;
  line-height: 1.44;
  font-weight: 500;
  margin: 0; }

.text-small {
  font-size: 0.83em;
  line-height: 1.5;
  font-weight: 500;
  margin: 0; }
