/* Global style guide for UI Lib */
/**
 * Browser support
 */
/**
 * Typography
 */
@import url("https://fonts.googleapis.com/css?family=Rajdhani:300,400,500,600,700&subset=latin-ext");
/**
 * Colors
 */
/**
 * Page -- UILib Specific Variables
 */
/**
 * Card -- UILib Specific Variables
 */
@media only screen and (min-width: 769px) {
  .tab-wrapper .tab-menu {
    position: relative;
    height: 40px;
    top: -1px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start; }
    .tab-wrapper .tab-menu .tab-menu-item {
      box-sizing: border-box;
      padding: 10px 20px;
      font-size: 16px;
      font-weight: 600;
      line-height: 1.25;
      cursor: pointer;
      border-top: 1px solid #eaeaea;
      border-bottom: 1px solid #eaeaea;
      border-right: 1px solid #eaeaea; }
      .tab-wrapper .tab-menu .tab-menu-item:first-child {
        border-top-left-radius: 8px;
        border-left: 1px solid #eaeaea; }
      .tab-wrapper .tab-menu .tab-menu-item:last-child {
        border-top-right-radius: 8px; }
    .tab-wrapper .tab-menu .tab-menu-item-active {
      color: #ffffff;
      background-color: #0033ff;
      border-radius: inherit;
      border-color: #0033ff !important; }
    .tab-wrapper .tab-menu .tab-menu-item-passive {
      color: #000000;
      background-color: #ffffff;
      border-radius: inherit; }
  .tab-wrapper .tab-panel {
    border: 1px solid #eaeaea;
    border-radius: 8px;
    border-top-left-radius: 0px;
    box-sizing: border-box;
    padding: 40px 30px;
    width: 100%;
    min-height: 300px; }
    .tab-wrapper .tab-panel .tab-panel-item {
      font-size: 20px; }
    .tab-wrapper .tab-panel .tab-panel-item-active {
      display: block; }
    .tab-wrapper .tab-panel .tab-panel-item-passive {
      display: none; } }

@media only screen and (max-width: 768px) {
  .tab-wrapper .tab-menu {
    position: relative;
    min-height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    overflow-x: scroll; }
    .tab-wrapper .tab-menu .tab-menu-item {
      box-sizing: border-box;
      text-transform: uppercase;
      margin: 0 10px;
      padding: 15px 0px;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.25;
      cursor: pointer; }
      .tab-wrapper .tab-menu .tab-menu-item:first-child {
        padding-left: 0px; }
      .tab-wrapper .tab-menu .tab-menu-item::last-child {
        padding-right: 0px; }
    .tab-wrapper .tab-menu .tab-menu-item-active {
      color: #0033ff;
      background-color: #ffffff;
      border-radius: inherit;
      border-color: #0033ff !important; }
    .tab-wrapper .tab-menu .tab-menu-item-passive {
      color: #4a4a4a;
      background-color: #ffffff;
      border-radius: inherit; }
  .tab-wrapper .tab-panel {
    box-sizing: border-box;
    padding: 15px 0px 40px 10px;
    width: 100%;
    min-height: 300px; }
    .tab-wrapper .tab-panel .tab-panel-item {
      font-size: 20px; }
    .tab-wrapper .tab-panel .tab-panel-item-active {
      display: block; }
    .tab-wrapper .tab-panel .tab-panel-item-passive {
      display: none; } }
