@import url(https://fonts.googleapis.com/css?family=Rajdhani:300,400,500,600,700&subset=latin-ext);
@import url(https://fonts.googleapis.com/css?family=Rajdhani:300,400,500,600,700&subset=latin-ext);
@import url(https://fonts.googleapis.com/css?family=Rajdhani:300,400,500,600,700&subset=latin-ext);
@import url(https://fonts.googleapis.com/css?family=Rajdhani:300,400,500,600,700&subset=latin-ext);
@import url(https://fonts.googleapis.com/css?family=Rajdhani:300,400,500,600,700&subset=latin-ext);
@import url(https://fonts.googleapis.com/css?family=Rajdhani:300,400,500,600,700&subset=latin-ext);
@import url(https://fonts.googleapis.com/css?family=Rajdhani:300,400,500,600,700&subset=latin-ext);
@import url(https://fonts.googleapis.com/css?family=Rajdhani:300,400,500,600,700&subset=latin-ext);
@import url(https://fonts.googleapis.com/css?family=Rajdhani:300,400,500,600,700&subset=latin-ext);
@import url(https://fonts.googleapis.com/css?family=Rajdhani:300,400,500,600,700&subset=latin-ext);
@import url(https://fonts.googleapis.com/css?family=Rajdhani:300,400,500,600,700&subset=latin-ext);
@import url(https://fonts.googleapis.com/css?family=Rajdhani:300,400,500,600,700&subset=latin-ext);
@import url(https://fonts.googleapis.com/css?family=Rajdhani:300,400,500,600,700&subset=latin-ext);
@import url(https://fonts.googleapis.com/css?family=Rajdhani:300,400,500,600,700&subset=latin-ext);
@import url(https://fonts.googleapis.com/css?family=Rajdhani:300,400,500,600,700&subset=latin-ext);
@import url(https://fonts.googleapis.com/css?family=Rajdhani:300,400,500,600,700&subset=latin-ext);
@import url(https://fonts.googleapis.com/css?family=Rajdhani:300,400,500,600,700&subset=latin-ext);
@import url(https://fonts.googleapis.com/css?family=Rajdhani:300,400,500,600,700&subset=latin-ext);
@import url(https://fonts.googleapis.com/css?family=Rajdhani:300,400,500,600,700&subset=latin-ext);
@import url(https://fonts.googleapis.com/css?family=Rajdhani:300,400,500,600,700&subset=latin-ext);
@import url(https://fonts.googleapis.com/css?family=Rajdhani:300,400,500,600,700&subset=latin-ext);
@import url(https://fonts.googleapis.com/css?family=Rajdhani:300,400,500,600,700&subset=latin-ext);
@import url(https://fonts.googleapis.com/css?family=Rajdhani:300,400,500,600,700&subset=latin-ext);
@import url(https://fonts.googleapis.com/css?family=Rajdhani:300,400,500,600,700&subset=latin-ext);
@import url(https://fonts.googleapis.com/css?family=Rajdhani:300,400,500,600,700&subset=latin-ext);
@import url(https://fonts.googleapis.com/css?family=Rajdhani:300,400,500,600,700&subset=latin-ext);
@import url(https://fonts.googleapis.com/css?family=Rajdhani:300,400,500,600,700&subset=latin-ext);
@import url(https://fonts.googleapis.com/css?family=Rajdhani:300,400,500,600,700&subset=latin-ext);
@import url(https://fonts.googleapis.com/css?family=Rajdhani:300,400,500,600,700&subset=latin-ext);
@import url(https://fonts.googleapis.com/css?family=Rajdhani:300,400,500,600,700&subset=latin-ext);
@import url(https://fonts.googleapis.com/css?family=Rajdhani:300,400,500,600,700&subset=latin-ext);
@import url(https://fonts.googleapis.com/css?family=Rajdhani:300,400,500,600,700&subset=latin-ext);
@import url(https://fonts.googleapis.com/css?family=Rajdhani:300,400,500,600,700&subset=latin-ext);
@import url(https://fonts.googleapis.com/css?family=Rajdhani:300,400,500,600,700&subset=latin-ext);
.loading-wrapper {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .loading-wrapper #voult {
    height: 100px;
    width: auto; }

/* Global style guide for UI Lib */
/**
 * Browser support
 */
/**
 * Typography
 */
/**
 * Colors
 */
/**
 * Page -- UILib Specific Variables
 */
/**
 * Card -- UILib Specific Variables
 */
@media only screen and (min-width: 769px) {
  .container-page {
    width: 100vw;
    min-height: 100vh;
    display: inline-table; }
  .container-content {
    margin: 0 auto;
    width: 1366px;
    height: 100%; }
  .container-background-color-blue-0 {
    background-color: #99ccff; }
  .container-background-color-blue-1 {
    background-color: #6699ff; }
  .container-background-color-blue-2 {
    background-color: #3366ff; }
  .container-background-color-blue-3 {
    background-color: #0033ff; }
  .container-background-color-blue-4 {
    background-color: #0033cc; }
  .container-background-color-blue-5 {
    background-color: #003399; }
  .container-background-color-blue-6 {
    background-color: #003366; }
  .container-background-color-green-0 {
    background-color: #99ff99; }
  .container-background-color-green-1 {
    background-color: #66ff66; }
  .container-background-color-green-2 {
    background-color: #66ff33; }
  .container-background-color-green-3 {
    background-color: #33cb00; }
  .container-background-color-green-4 {
    background-color: #33cc00; }
  .container-background-color-green-5 {
    background-color: #339900; }
  .container-background-color-green-6 {
    background-color: #336600; }
  .container-background-color-orange-0 {
    background-color: #ffcc99; }
  .container-background-color-orange-1 {
    background-color: #ff9966; }
  .container-background-color-orange-2 {
    background-color: #ff9933; }
  .container-background-color-orange-3 {
    background-color: #ff6600; }
  .container-background-color-orange-4 {
    background-color: #cc662b; }
  .container-background-color-orange-5 {
    background-color: #993300; }
  .container-background-color-orange-6 {
    background-color: #663300; }
  .container-background-color-grey-0 {
    background-color: #fafafa; }
  .container-background-color-grey-1 {
    background-color: #eaeaea; }
  .container-background-color-grey-2 {
    background-color: #cccccc; }
  .container-background-color-grey-3 {
    background-color: #999999; }
  .container-background-color-grey-4 {
    background-color: #777777; }
  .container-background-color-grey-5 {
    background-color: #666666; }
  .container-background-color-grey-6 {
    background-color: #333333; }
  .container-background-color-white {
    background-color: #ffffff; }
  .container-background-color-black {
    background-color: #000000; }
  .container-background-color-light-grey {
    background-color: #4a4a4a; }
  .container-background-color-light-pink {
    background-color: #fef0ef; }
  .container-background-color-pink {
    background-color: #fabeb9; } }

@media only screen and (max-width: 768px) {
  .container-page {
    width: 100vw;
    min-height: 100vh;
    display: inline-table;
    box-sizing: border-box;
    padding-bottom: 50px; }
  .container-content {
    margin: 0 auto;
    width: 90vw;
    height: 100%; }
  .container-background-color-blue-0 {
    background-color: #99ccff; }
  .container-background-color-blue-1 {
    background-color: #6699ff; }
  .container-background-color-blue-2 {
    background-color: #3366ff; }
  .container-background-color-blue-3 {
    background-color: #0033ff; }
  .container-background-color-blue-4 {
    background-color: #0033cc; }
  .container-background-color-blue-5 {
    background-color: #003399; }
  .container-background-color-blue-6 {
    background-color: #003366; }
  .container-background-color-green-0 {
    background-color: #99ff99; }
  .container-background-color-green-1 {
    background-color: #66ff66; }
  .container-background-color-green-2 {
    background-color: #66ff33; }
  .container-background-color-green-3 {
    background-color: #33cb00; }
  .container-background-color-green-4 {
    background-color: #33cc00; }
  .container-background-color-green-5 {
    background-color: #339900; }
  .container-background-color-green-6 {
    background-color: #336600; }
  .container-background-color-orange-0 {
    background-color: #ffcc99; }
  .container-background-color-orange-1 {
    background-color: #ff9966; }
  .container-background-color-orange-2 {
    background-color: #ff9933; }
  .container-background-color-orange-3 {
    background-color: #ff6600; }
  .container-background-color-orange-4 {
    background-color: #cc662b; }
  .container-background-color-orange-5 {
    background-color: #993300; }
  .container-background-color-orange-6 {
    background-color: #663300; }
  .container-background-color-grey-0 {
    background-color: #fafafa; }
  .container-background-color-grey-1 {
    background-color: #eaeaea; }
  .container-background-color-grey-2 {
    background-color: #cccccc; }
  .container-background-color-grey-3 {
    background-color: #999999; }
  .container-background-color-grey-4 {
    background-color: #777777; }
  .container-background-color-grey-5 {
    background-color: #666666; }
  .container-background-color-grey-6 {
    background-color: #333333; }
  .container-background-color-white {
    background-color: #ffffff; }
  .container-background-color-black {
    background-color: #000000; }
  .container-background-color-light-grey {
    background-color: #4a4a4a; }
  .container-background-color-light-pink {
    background-color: #fef0ef; }
  .container-background-color-pink {
    background-color: #fabeb9; } }

/* Global style guide for UI Lib */
/**
 * Browser support
 */
/**
 * Typography
 */
/**
 * Colors
 */
/**
 * Page -- UILib Specific Variables
 */
/**
 * Card -- UILib Specific Variables
 */
@media only screen and (min-width: 769px) {
  .navbar {
    width: 100vw;
    min-width: 1366px;
    height: 80px; }
  .navbar-fixed-top {
    top: 0;
    left: 0; }
  .navbar-background-color-blue-0 {
    background-color: #99ccff; }
  .navbar-background-color-blue-1 {
    background-color: #6699ff; }
  .navbar-background-color-blue-2 {
    background-color: #3366ff; }
  .navbar-background-color-blue-3 {
    background-color: #0033ff; }
  .navbar-background-color-blue-4 {
    background-color: #0033cc; }
  .navbar-background-color-blue-5 {
    background-color: #003399; }
  .navbar-background-color-blue-6 {
    background-color: #003366; }
  .navbar-background-color-green-0 {
    background-color: #99ff99; }
  .navbar-background-color-green-1 {
    background-color: #66ff66; }
  .navbar-background-color-green-2 {
    background-color: #66ff33; }
  .navbar-background-color-green-3 {
    background-color: #33cb00; }
  .navbar-background-color-green-4 {
    background-color: #33cc00; }
  .navbar-background-color-green-5 {
    background-color: #339900; }
  .navbar-background-color-green-6 {
    background-color: #336600; }
  .navbar-background-color-orange-0 {
    background-color: #ffcc99; }
  .navbar-background-color-orange-1 {
    background-color: #ff9966; }
  .navbar-background-color-orange-2 {
    background-color: #ff9933; }
  .navbar-background-color-orange-3 {
    background-color: #ff6600; }
  .navbar-background-color-orange-4 {
    background-color: #cc662b; }
  .navbar-background-color-orange-5 {
    background-color: #993300; }
  .navbar-background-color-orange-6 {
    background-color: #663300; }
  .navbar-background-color-grey-0 {
    background-color: #fafafa; }
  .navbar-background-color-grey-1 {
    background-color: #eaeaea; }
  .navbar-background-color-grey-2 {
    background-color: #cccccc; }
  .navbar-background-color-grey-3 {
    background-color: #999999; }
  .navbar-background-color-grey-4 {
    background-color: #777777; }
  .navbar-background-color-grey-5 {
    background-color: #666666; }
  .navbar-background-color-grey-6 {
    background-color: #333333; }
  .navbar-background-color-white {
    background-color: #ffffff; }
  .navbar-background-color-black {
    background-color: #000000; }
  .navbar-background-color-light-grey {
    background-color: #4a4a4a; }
  .navbar-background-color-light-pink {
    background-color: #fef0ef; }
  .navbar-background-color-pink {
    background-color: #fabeb9; }
  .navbar-color-blue-0 {
    color: #99ccff; }
  .navbar-color-blue-1 {
    color: #6699ff; }
  .navbar-color-blue-2 {
    color: #3366ff; }
  .navbar-color-blue-3 {
    color: #0033ff; }
  .navbar-color-blue-4 {
    color: #0033cc; }
  .navbar-color-blue-5 {
    color: #003399; }
  .navbar-color-blue-6 {
    color: #003366; }
  .navbar-color-green-0 {
    color: #99ff99; }
  .navbar-color-green-1 {
    color: #66ff66; }
  .navbar-color-green-2 {
    color: #66ff33; }
  .navbar-color-green-3 {
    color: #33cb00; }
  .navbar-color-green-4 {
    color: #33cc00; }
  .navbar-color-green-5 {
    color: #339900; }
  .navbar-color-green-6 {
    color: #336600; }
  .navbar-color-orange-0 {
    color: #ffcc99; }
  .navbar-color-orange-1 {
    color: #ff9966; }
  .navbar-color-orange-2 {
    color: #ff9933; }
  .navbar-color-orange-3 {
    color: #ff6600; }
  .navbar-color-orange-4 {
    color: #cc662b; }
  .navbar-color-orange-5 {
    color: #993300; }
  .navbar-color-orange-6 {
    color: #663300; }
  .navbar-color-grey-0 {
    color: #fafafa; }
  .navbar-color-grey-1 {
    color: #eaeaea; }
  .navbar-color-grey-2 {
    color: #cccccc; }
  .navbar-color-grey-3 {
    color: #999999; }
  .navbar-color-grey-4 {
    color: #777777; }
  .navbar-color-grey-5 {
    color: #666666; }
  .navbar-color-grey-6 {
    color: #333333; }
  .navbar-color-white {
    color: #ffffff; }
  .navbar-color-black {
    color: #000000; }
  .navbar-color-light-grey {
    color: #4a4a4a; }
  .navbar-color-light-pink {
    color: #fef0ef; }
  .navbar-color-pink {
    color: #fabeb9; } }

@media only screen and (max-width: 768px) {
  .navbar {
    width: 100vw;
    height: 80px; }
  .navbar-fixed-top {
    top: 0;
    left: 0; }
  .navbar-background-color-blue-0 {
    background-color: #99ccff; }
  .navbar-background-color-blue-1 {
    background-color: #6699ff; }
  .navbar-background-color-blue-2 {
    background-color: #3366ff; }
  .navbar-background-color-blue-3 {
    background-color: #0033ff; }
  .navbar-background-color-blue-4 {
    background-color: #0033cc; }
  .navbar-background-color-blue-5 {
    background-color: #003399; }
  .navbar-background-color-blue-6 {
    background-color: #003366; }
  .navbar-background-color-green-0 {
    background-color: #99ff99; }
  .navbar-background-color-green-1 {
    background-color: #66ff66; }
  .navbar-background-color-green-2 {
    background-color: #66ff33; }
  .navbar-background-color-green-3 {
    background-color: #33cb00; }
  .navbar-background-color-green-4 {
    background-color: #33cc00; }
  .navbar-background-color-green-5 {
    background-color: #339900; }
  .navbar-background-color-green-6 {
    background-color: #336600; }
  .navbar-background-color-orange-0 {
    background-color: #ffcc99; }
  .navbar-background-color-orange-1 {
    background-color: #ff9966; }
  .navbar-background-color-orange-2 {
    background-color: #ff9933; }
  .navbar-background-color-orange-3 {
    background-color: #ff6600; }
  .navbar-background-color-orange-4 {
    background-color: #cc662b; }
  .navbar-background-color-orange-5 {
    background-color: #993300; }
  .navbar-background-color-orange-6 {
    background-color: #663300; }
  .navbar-background-color-grey-0 {
    background-color: #fafafa; }
  .navbar-background-color-grey-1 {
    background-color: #eaeaea; }
  .navbar-background-color-grey-2 {
    background-color: #cccccc; }
  .navbar-background-color-grey-3 {
    background-color: #999999; }
  .navbar-background-color-grey-4 {
    background-color: #777777; }
  .navbar-background-color-grey-5 {
    background-color: #666666; }
  .navbar-background-color-grey-6 {
    background-color: #333333; }
  .navbar-background-color-white {
    background-color: #ffffff; }
  .navbar-background-color-black {
    background-color: #000000; }
  .navbar-background-color-light-grey {
    background-color: #4a4a4a; }
  .navbar-background-color-light-pink {
    background-color: #fef0ef; }
  .navbar-background-color-pink {
    background-color: #fabeb9; }
  .navbar-color-blue-0 {
    color: #99ccff; }
  .navbar-color-blue-1 {
    color: #6699ff; }
  .navbar-color-blue-2 {
    color: #3366ff; }
  .navbar-color-blue-3 {
    color: #0033ff; }
  .navbar-color-blue-4 {
    color: #0033cc; }
  .navbar-color-blue-5 {
    color: #003399; }
  .navbar-color-blue-6 {
    color: #003366; }
  .navbar-color-green-0 {
    color: #99ff99; }
  .navbar-color-green-1 {
    color: #66ff66; }
  .navbar-color-green-2 {
    color: #66ff33; }
  .navbar-color-green-3 {
    color: #33cb00; }
  .navbar-color-green-4 {
    color: #33cc00; }
  .navbar-color-green-5 {
    color: #339900; }
  .navbar-color-green-6 {
    color: #336600; }
  .navbar-color-orange-0 {
    color: #ffcc99; }
  .navbar-color-orange-1 {
    color: #ff9966; }
  .navbar-color-orange-2 {
    color: #ff9933; }
  .navbar-color-orange-3 {
    color: #ff6600; }
  .navbar-color-orange-4 {
    color: #cc662b; }
  .navbar-color-orange-5 {
    color: #993300; }
  .navbar-color-orange-6 {
    color: #663300; }
  .navbar-color-grey-0 {
    color: #fafafa; }
  .navbar-color-grey-1 {
    color: #eaeaea; }
  .navbar-color-grey-2 {
    color: #cccccc; }
  .navbar-color-grey-3 {
    color: #999999; }
  .navbar-color-grey-4 {
    color: #777777; }
  .navbar-color-grey-5 {
    color: #666666; }
  .navbar-color-grey-6 {
    color: #333333; }
  .navbar-color-white {
    color: #ffffff; }
  .navbar-color-black {
    color: #000000; }
  .navbar-color-light-grey {
    color: #4a4a4a; }
  .navbar-color-light-pink {
    color: #fef0ef; }
  .navbar-color-pink {
    color: #fabeb9; } }

/* Global style guide for UI Lib */
/**
 * Browser support
 */
/**
 * Typography
 */
/**
 * Colors
 */
/**
 * Page -- UILib Specific Variables
 */
/**
 * Card -- UILib Specific Variables
 */
@media only screen and (min-width: 769px) {
  .card {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
  .card-centered {
    margin: 0 auto; }
  .card-type-auth {
    box-sizing: border-box;
    border-radius: 8px;
    width: 530px;
    min-height: 675px;
    margin-top: 80px;
    padding: 60px 50px 40px 50px; }
  .card-background-color-blue-0 {
    background-color: #99ccff; }
  .card-background-color-blue-1 {
    background-color: #6699ff; }
  .card-background-color-blue-2 {
    background-color: #3366ff; }
  .card-background-color-blue-3 {
    background-color: #0033ff; }
  .card-background-color-blue-4 {
    background-color: #0033cc; }
  .card-background-color-blue-5 {
    background-color: #003399; }
  .card-background-color-blue-6 {
    background-color: #003366; }
  .card-background-color-green-0 {
    background-color: #99ff99; }
  .card-background-color-green-1 {
    background-color: #66ff66; }
  .card-background-color-green-2 {
    background-color: #66ff33; }
  .card-background-color-green-3 {
    background-color: #33cb00; }
  .card-background-color-green-4 {
    background-color: #33cc00; }
  .card-background-color-green-5 {
    background-color: #339900; }
  .card-background-color-green-6 {
    background-color: #336600; }
  .card-background-color-orange-0 {
    background-color: #ffcc99; }
  .card-background-color-orange-1 {
    background-color: #ff9966; }
  .card-background-color-orange-2 {
    background-color: #ff9933; }
  .card-background-color-orange-3 {
    background-color: #ff6600; }
  .card-background-color-orange-4 {
    background-color: #cc662b; }
  .card-background-color-orange-5 {
    background-color: #993300; }
  .card-background-color-orange-6 {
    background-color: #663300; }
  .card-background-color-grey-0 {
    background-color: #fafafa; }
  .card-background-color-grey-1 {
    background-color: #eaeaea; }
  .card-background-color-grey-2 {
    background-color: #cccccc; }
  .card-background-color-grey-3 {
    background-color: #999999; }
  .card-background-color-grey-4 {
    background-color: #777777; }
  .card-background-color-grey-5 {
    background-color: #666666; }
  .card-background-color-grey-6 {
    background-color: #333333; }
  .card-background-color-white {
    background-color: #ffffff; }
  .card-background-color-black {
    background-color: #000000; }
  .card-background-color-light-grey {
    background-color: #4a4a4a; }
  .card-background-color-light-pink {
    background-color: #fef0ef; }
  .card-background-color-pink {
    background-color: #fabeb9; }
  .card-content {
    display: flex;
    flex-direction: column;
    align-items: center; }
  .card-content-align-left {
    text-align: left; }
  .card-content-align-right {
    text-align: right; }
  .card-content-align-center {
    text-align: center; }
  .card-content-color-blue-0 {
    color: #99ccff; }
  .card-content-color-blue-1 {
    color: #6699ff; }
  .card-content-color-blue-2 {
    color: #3366ff; }
  .card-content-color-blue-3 {
    color: #0033ff; }
  .card-content-color-blue-4 {
    color: #0033cc; }
  .card-content-color-blue-5 {
    color: #003399; }
  .card-content-color-blue-6 {
    color: #003366; }
  .card-content-color-green-0 {
    color: #99ff99; }
  .card-content-color-green-1 {
    color: #66ff66; }
  .card-content-color-green-2 {
    color: #66ff33; }
  .card-content-color-green-3 {
    color: #33cb00; }
  .card-content-color-green-4 {
    color: #33cc00; }
  .card-content-color-green-5 {
    color: #339900; }
  .card-content-color-green-6 {
    color: #336600; }
  .card-content-color-orange-0 {
    color: #ffcc99; }
  .card-content-color-orange-1 {
    color: #ff9966; }
  .card-content-color-orange-2 {
    color: #ff9933; }
  .card-content-color-orange-3 {
    color: #ff6600; }
  .card-content-color-orange-4 {
    color: #cc662b; }
  .card-content-color-orange-5 {
    color: #993300; }
  .card-content-color-orange-6 {
    color: #663300; }
  .card-content-color-grey-0 {
    color: #fafafa; }
  .card-content-color-grey-1 {
    color: #eaeaea; }
  .card-content-color-grey-2 {
    color: #cccccc; }
  .card-content-color-grey-3 {
    color: #999999; }
  .card-content-color-grey-4 {
    color: #777777; }
  .card-content-color-grey-5 {
    color: #666666; }
  .card-content-color-grey-6 {
    color: #333333; }
  .card-content-color-white {
    color: #ffffff; }
  .card-content-color-black {
    color: #000000; }
  .card-content-color-light-grey {
    color: #4a4a4a; }
  .card-content-color-light-pink {
    color: #fef0ef; }
  .card-content-color-pink {
    color: #fabeb9; } }

@media only screen and (max-width: 768px) {
  .card {
    width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #ffffff !important; }
  .card-centered {
    margin: 0 auto; }
  .card-type-auth {
    box-sizing: border-box;
    border-radius: 8px;
    width: 90vw;
    min-height: 60vh;
    margin-top: 60px;
    padding: 40px 20px; }
  .card-background-color-blue-0 {
    background-color: #99ccff; }
  .card-background-color-blue-1 {
    background-color: #6699ff; }
  .card-background-color-blue-2 {
    background-color: #3366ff; }
  .card-background-color-blue-3 {
    background-color: #0033ff; }
  .card-background-color-blue-4 {
    background-color: #0033cc; }
  .card-background-color-blue-5 {
    background-color: #003399; }
  .card-background-color-blue-6 {
    background-color: #003366; }
  .card-background-color-green-0 {
    background-color: #99ff99; }
  .card-background-color-green-1 {
    background-color: #66ff66; }
  .card-background-color-green-2 {
    background-color: #66ff33; }
  .card-background-color-green-3 {
    background-color: #33cb00; }
  .card-background-color-green-4 {
    background-color: #33cc00; }
  .card-background-color-green-5 {
    background-color: #339900; }
  .card-background-color-green-6 {
    background-color: #336600; }
  .card-background-color-orange-0 {
    background-color: #ffcc99; }
  .card-background-color-orange-1 {
    background-color: #ff9966; }
  .card-background-color-orange-2 {
    background-color: #ff9933; }
  .card-background-color-orange-3 {
    background-color: #ff6600; }
  .card-background-color-orange-4 {
    background-color: #cc662b; }
  .card-background-color-orange-5 {
    background-color: #993300; }
  .card-background-color-orange-6 {
    background-color: #663300; }
  .card-background-color-grey-0 {
    background-color: #fafafa; }
  .card-background-color-grey-1 {
    background-color: #eaeaea; }
  .card-background-color-grey-2 {
    background-color: #cccccc; }
  .card-background-color-grey-3 {
    background-color: #999999; }
  .card-background-color-grey-4 {
    background-color: #777777; }
  .card-background-color-grey-5 {
    background-color: #666666; }
  .card-background-color-grey-6 {
    background-color: #333333; }
  .card-background-color-white {
    background-color: #ffffff; }
  .card-background-color-black {
    background-color: #000000; }
  .card-background-color-light-grey {
    background-color: #4a4a4a; }
  .card-background-color-light-pink {
    background-color: #fef0ef; }
  .card-background-color-pink {
    background-color: #fabeb9; }
  .card-content {
    display: flex;
    flex-direction: column;
    align-items: center; }
  .card-content-align-left {
    text-align: left; }
  .card-content-align-right {
    text-align: right; }
  .card-content-align-center {
    text-align: center; }
  .card-content-color-blue-0 {
    color: #99ccff; }
  .card-content-color-blue-1 {
    color: #6699ff; }
  .card-content-color-blue-2 {
    color: #3366ff; }
  .card-content-color-blue-3 {
    color: #0033ff; }
  .card-content-color-blue-4 {
    color: #0033cc; }
  .card-content-color-blue-5 {
    color: #003399; }
  .card-content-color-blue-6 {
    color: #003366; }
  .card-content-color-green-0 {
    color: #99ff99; }
  .card-content-color-green-1 {
    color: #66ff66; }
  .card-content-color-green-2 {
    color: #66ff33; }
  .card-content-color-green-3 {
    color: #33cb00; }
  .card-content-color-green-4 {
    color: #33cc00; }
  .card-content-color-green-5 {
    color: #339900; }
  .card-content-color-green-6 {
    color: #336600; }
  .card-content-color-orange-0 {
    color: #ffcc99; }
  .card-content-color-orange-1 {
    color: #ff9966; }
  .card-content-color-orange-2 {
    color: #ff9933; }
  .card-content-color-orange-3 {
    color: #ff6600; }
  .card-content-color-orange-4 {
    color: #cc662b; }
  .card-content-color-orange-5 {
    color: #993300; }
  .card-content-color-orange-6 {
    color: #663300; }
  .card-content-color-grey-0 {
    color: #fafafa; }
  .card-content-color-grey-1 {
    color: #eaeaea; }
  .card-content-color-grey-2 {
    color: #cccccc; }
  .card-content-color-grey-3 {
    color: #999999; }
  .card-content-color-grey-4 {
    color: #777777; }
  .card-content-color-grey-5 {
    color: #666666; }
  .card-content-color-grey-6 {
    color: #333333; }
  .card-content-color-white {
    color: #ffffff; }
  .card-content-color-black {
    color: #000000; }
  .card-content-color-light-grey {
    color: #4a4a4a; }
  .card-content-color-light-pink {
    color: #fef0ef; }
  .card-content-color-pink {
    color: #fabeb9; } }

/* Global style guide for UI Lib */
/**
 * Browser support
 */
/**
 * Typography
 */
/**
 * Colors
 */
/**
 * Page -- UILib Specific Variables
 */
/**
 * Card -- UILib Specific Variables
 */
.input-wrapper {
  display: flex;
  flex-flow: column-reverse;
  position: relative;
  margin-bottom: 10px; }
  .input-wrapper label,
  .input-wrapper input {
    -webkit-transition: all ease-in 0.15s;
    transition: all ease-in 0.15s; }
  .input-wrapper input[type='number'] {
    -moz-appearance: textfield; }
  .input-wrapper input[type='number']::-webkit-inner-spin-button,
  .input-wrapper input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0; }
  .input-wrapper .input {
    box-sizing: border-box;
    width: 100%;
    height: 50px;
    font-size: 1.5em;
    border-radius: 4px;
    border: 1px solid;
    padding-left: 15px;
    padding-right: 15px;
    outline: none;
    text-align: left; }
    .input-wrapper .input:focus {
      border-color: #3366ff; }
  .input-wrapper .input-background-color-blue-0 {
    background-color: #99ccff; }
  .input-wrapper .input-background-color-blue-1 {
    background-color: #6699ff; }
  .input-wrapper .input-background-color-blue-2 {
    background-color: #3366ff; }
  .input-wrapper .input-background-color-blue-3 {
    background-color: #0033ff; }
  .input-wrapper .input-background-color-blue-4 {
    background-color: #0033cc; }
  .input-wrapper .input-background-color-blue-5 {
    background-color: #003399; }
  .input-wrapper .input-background-color-blue-6 {
    background-color: #003366; }
  .input-wrapper .input-background-color-green-0 {
    background-color: #99ff99; }
  .input-wrapper .input-background-color-green-1 {
    background-color: #66ff66; }
  .input-wrapper .input-background-color-green-2 {
    background-color: #66ff33; }
  .input-wrapper .input-background-color-green-3 {
    background-color: #33cb00; }
  .input-wrapper .input-background-color-green-4 {
    background-color: #33cc00; }
  .input-wrapper .input-background-color-green-5 {
    background-color: #339900; }
  .input-wrapper .input-background-color-green-6 {
    background-color: #336600; }
  .input-wrapper .input-background-color-orange-0 {
    background-color: #ffcc99; }
  .input-wrapper .input-background-color-orange-1 {
    background-color: #ff9966; }
  .input-wrapper .input-background-color-orange-2 {
    background-color: #ff9933; }
  .input-wrapper .input-background-color-orange-3 {
    background-color: #ff6600; }
  .input-wrapper .input-background-color-orange-4 {
    background-color: #cc662b; }
  .input-wrapper .input-background-color-orange-5 {
    background-color: #993300; }
  .input-wrapper .input-background-color-orange-6 {
    background-color: #663300; }
  .input-wrapper .input-background-color-grey-0 {
    background-color: #fafafa; }
  .input-wrapper .input-background-color-grey-1 {
    background-color: #eaeaea; }
  .input-wrapper .input-background-color-grey-2 {
    background-color: #cccccc; }
  .input-wrapper .input-background-color-grey-3 {
    background-color: #999999; }
  .input-wrapper .input-background-color-grey-4 {
    background-color: #777777; }
  .input-wrapper .input-background-color-grey-5 {
    background-color: #666666; }
  .input-wrapper .input-background-color-grey-6 {
    background-color: #333333; }
  .input-wrapper .input-background-color-white {
    background-color: #ffffff; }
  .input-wrapper .input-background-color-black {
    background-color: #000000; }
  .input-wrapper .input-background-color-light-grey {
    background-color: #4a4a4a; }
  .input-wrapper .input-background-color-light-pink {
    background-color: #fef0ef; }
  .input-wrapper .input-background-color-pink {
    background-color: #fabeb9; }
  .input-wrapper .input-border-color-blue-0 {
    border-color: #99ccff; }
  .input-wrapper .input-border-color-blue-1 {
    border-color: #6699ff; }
  .input-wrapper .input-border-color-blue-2 {
    border-color: #3366ff; }
  .input-wrapper .input-border-color-blue-3 {
    border-color: #0033ff; }
  .input-wrapper .input-border-color-blue-4 {
    border-color: #0033cc; }
  .input-wrapper .input-border-color-blue-5 {
    border-color: #003399; }
  .input-wrapper .input-border-color-blue-6 {
    border-color: #003366; }
  .input-wrapper .input-border-color-green-0 {
    border-color: #99ff99; }
  .input-wrapper .input-border-color-green-1 {
    border-color: #66ff66; }
  .input-wrapper .input-border-color-green-2 {
    border-color: #66ff33; }
  .input-wrapper .input-border-color-green-3 {
    border-color: #33cb00; }
  .input-wrapper .input-border-color-green-4 {
    border-color: #33cc00; }
  .input-wrapper .input-border-color-green-5 {
    border-color: #339900; }
  .input-wrapper .input-border-color-green-6 {
    border-color: #336600; }
  .input-wrapper .input-border-color-orange-0 {
    border-color: #ffcc99; }
  .input-wrapper .input-border-color-orange-1 {
    border-color: #ff9966; }
  .input-wrapper .input-border-color-orange-2 {
    border-color: #ff9933; }
  .input-wrapper .input-border-color-orange-3 {
    border-color: #ff6600; }
  .input-wrapper .input-border-color-orange-4 {
    border-color: #cc662b; }
  .input-wrapper .input-border-color-orange-5 {
    border-color: #993300; }
  .input-wrapper .input-border-color-orange-6 {
    border-color: #663300; }
  .input-wrapper .input-border-color-grey-0 {
    border-color: #fafafa; }
  .input-wrapper .input-border-color-grey-1 {
    border-color: #eaeaea; }
  .input-wrapper .input-border-color-grey-2 {
    border-color: #cccccc; }
  .input-wrapper .input-border-color-grey-3 {
    border-color: #999999; }
  .input-wrapper .input-border-color-grey-4 {
    border-color: #777777; }
  .input-wrapper .input-border-color-grey-5 {
    border-color: #666666; }
  .input-wrapper .input-border-color-grey-6 {
    border-color: #333333; }
  .input-wrapper .input-border-color-white {
    border-color: #ffffff; }
  .input-wrapper .input-border-color-black {
    border-color: #000000; }
  .input-wrapper .input-border-color-light-grey {
    border-color: #4a4a4a; }
  .input-wrapper .input-border-color-light-pink {
    border-color: #fef0ef; }
  .input-wrapper .input-border-color-pink {
    border-color: #fabeb9; }
  .input-wrapper .input-error-border-color {
    border-color: #ff6600; }
  .input-wrapper .input-color-blue-0 {
    color: #99ccff; }
  .input-wrapper .input-color-blue-1 {
    color: #6699ff; }
  .input-wrapper .input-color-blue-2 {
    color: #3366ff; }
  .input-wrapper .input-color-blue-3 {
    color: #0033ff; }
  .input-wrapper .input-color-blue-4 {
    color: #0033cc; }
  .input-wrapper .input-color-blue-5 {
    color: #003399; }
  .input-wrapper .input-color-blue-6 {
    color: #003366; }
  .input-wrapper .input-color-green-0 {
    color: #99ff99; }
  .input-wrapper .input-color-green-1 {
    color: #66ff66; }
  .input-wrapper .input-color-green-2 {
    color: #66ff33; }
  .input-wrapper .input-color-green-3 {
    color: #33cb00; }
  .input-wrapper .input-color-green-4 {
    color: #33cc00; }
  .input-wrapper .input-color-green-5 {
    color: #339900; }
  .input-wrapper .input-color-green-6 {
    color: #336600; }
  .input-wrapper .input-color-orange-0 {
    color: #ffcc99; }
  .input-wrapper .input-color-orange-1 {
    color: #ff9966; }
  .input-wrapper .input-color-orange-2 {
    color: #ff9933; }
  .input-wrapper .input-color-orange-3 {
    color: #ff6600; }
  .input-wrapper .input-color-orange-4 {
    color: #cc662b; }
  .input-wrapper .input-color-orange-5 {
    color: #993300; }
  .input-wrapper .input-color-orange-6 {
    color: #663300; }
  .input-wrapper .input-color-grey-0 {
    color: #fafafa; }
  .input-wrapper .input-color-grey-1 {
    color: #eaeaea; }
  .input-wrapper .input-color-grey-2 {
    color: #cccccc; }
  .input-wrapper .input-color-grey-3 {
    color: #999999; }
  .input-wrapper .input-color-grey-4 {
    color: #777777; }
  .input-wrapper .input-color-grey-5 {
    color: #666666; }
  .input-wrapper .input-color-grey-6 {
    color: #333333; }
  .input-wrapper .input-color-white {
    color: #ffffff; }
  .input-wrapper .input-color-black {
    color: #000000; }
  .input-wrapper .input-color-light-grey {
    color: #4a4a4a; }
  .input-wrapper .input-color-light-pink {
    color: #fef0ef; }
  .input-wrapper .input-color-pink {
    color: #fabeb9; }
  .input-wrapper .label {
    position: absolute;
    top: 0;
    left: 0;
    text-transform: none;
    font-size: 1.167em; }
  .input-wrapper .input:placeholder-shown + label {
    cursor: text;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    opacity: 1;
    -webkit-transform: translate(1.25em, 0.75em) scale(1);
            transform: translate(1.25em, 0.75em) scale(1); }
  .input-wrapper .input:focus + label {
    opacity: 0; }
  .input-wrapper .input:not(:placeholder-shown) + label {
    opacity: 0; }
  .input-wrapper ::-webkit-input-placeholder {
    opacity: 0; }
  .input-wrapper :-moz-placeholder {
    opacity: 0; }
  .input-wrapper ::-moz-placeholder {
    opacity: 0; }
  .input-wrapper :-ms-input-placeholder {
    opacity: 0; }

.checkbox {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  align-items: center;
  font-size: 1em;
  height: 18px; }
  .checkbox input[type='checkbox'] {
    display: none; }
  .checkbox input[type='checkbox'] + label:before {
    font-family: 'Font Awesome 5 Free';
    font-size: 14px;
    font-weight: 400;
    line-height: 1em;
    content: '\F0C8';
    color: #666666;
    letter-spacing: 8px; }
  .checkbox input[type='checkbox']:checked + label:before {
    content: '\F14A'; }
  .checkbox label {
    font-size: 14px;
    line-height: 18px; }

.checkbox-error input[type='checkbox'] + label:before {
  color: #ff6600; }

.validation-errors {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  background-color: #fef0ef;
  border: 1px solid #fabeb9;
  border-radius: 4px;
  padding: 9px 11px;
  font-size: 1em;
  line-height: 1.5em; }
  .validation-errors .icon {
    font-size: 20px;
    margin-right: 11px;
    color: #ff6600; }
  .validation-errors span {
    text-align: left; }

@media only screen and (min-width: 769px) {
  .digit-container {
    margin-bottom: 20px; }
    .digit-container .digit {
      box-sizing: border-box;
      width: 50px;
      height: 50px;
      font-size: 3em;
      border-radius: 4px;
      border: 1px solid #eaeaea;
      text-align: center;
      margin-right: 20px; }
      .digit-container .digit:last-child {
        margin-right: 0px; }
    .digit-container .digit-error {
      border-color: #ff6600; }
    .digit-container input[type='number']::-webkit-inner-spin-button,
    .digit-container input[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
      appearance: none; }
    .digit-container input[type='number'] {
      -moz-appearance: textfield; } }

@media only screen and (max-width: 768px) {
  .digit-container {
    margin-bottom: 20px; }
    .digit-container .digit {
      box-sizing: border-box;
      width: 32px;
      height: 32px;
      font-size: 14px;
      border-radius: 4px;
      border: 1px solid #eaeaea;
      text-align: center;
      margin-right: 10px; }
      .digit-container .digit:last-child {
        margin-right: 0px; }
    .digit-container .digit-error {
      border-color: #ff6600; }
    .digit-container input[type='number']::-webkit-inner-spin-button,
    .digit-container input[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
      appearance: none; }
    .digit-container input[type='number'] {
      -moz-appearance: textfield; } }

.dropdown-container {
  display: inline-block;
  box-sizing: border-box;
  min-width: 75px;
  height: 50px;
  margin-right: 10px;
  border-radius: 4px;
  border: 1px solid;
  outline: none;
  text-align: right;
  -webkit-transition: all ease-in 0.15s;
  transition: all ease-in 0.15s; }
  .dropdown-container:focus {
    border-color: #3366ff; }
  .dropdown-container .select-container {
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; }
    .dropdown-container .select-container span {
      width: 65%;
      font-size: 1.5em;
      text-align: right;
      color: #333333; }
    .dropdown-container .select-container i {
      width: 35%;
      text-align: center;
      font-size: 1.2em;
      color: #cccccc; }
  .dropdown-container .dropdown-menu {
    background-color: #fff;
    width: 100%;
    max-height: 200px;
    margin-top: 5px;
    border: 1px solid #cccccc;
    border-radius: 4px;
    text-align: left;
    display: block;
    overflow-y: scroll;
    z-index: auto;
    position: relative;
    padding: 0;
    list-style: none;
    box-shadow: 0 2px 2px #eaeaea;
    color: #333333; }
    .dropdown-container .dropdown-menu li {
      font-size: 1.5em;
      padding: 15px 0 15px 20%;
      -webkit-transition: all ease-in 0.15s;
      transition: all ease-in 0.15s;
      cursor: pointer;
      border-bottom: 1px solid #cccccc;
      -moz-user-select: -moz-none;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none; }
      .dropdown-container .dropdown-menu li:last-child {
        border-bottom: none; }
      .dropdown-container .dropdown-menu li:hover {
        background-color: #eaeaea; }

.dropdown-container-fluid {
  width: 100%; }

.dropdown-container-background-color-blue-0 {
  background-color: #99ccff; }

.dropdown-container-background-color-blue-1 {
  background-color: #6699ff; }

.dropdown-container-background-color-blue-2 {
  background-color: #3366ff; }

.dropdown-container-background-color-blue-3 {
  background-color: #0033ff; }

.dropdown-container-background-color-blue-4 {
  background-color: #0033cc; }

.dropdown-container-background-color-blue-5 {
  background-color: #003399; }

.dropdown-container-background-color-blue-6 {
  background-color: #003366; }

.dropdown-container-background-color-green-0 {
  background-color: #99ff99; }

.dropdown-container-background-color-green-1 {
  background-color: #66ff66; }

.dropdown-container-background-color-green-2 {
  background-color: #66ff33; }

.dropdown-container-background-color-green-3 {
  background-color: #33cb00; }

.dropdown-container-background-color-green-4 {
  background-color: #33cc00; }

.dropdown-container-background-color-green-5 {
  background-color: #339900; }

.dropdown-container-background-color-green-6 {
  background-color: #336600; }

.dropdown-container-background-color-orange-0 {
  background-color: #ffcc99; }

.dropdown-container-background-color-orange-1 {
  background-color: #ff9966; }

.dropdown-container-background-color-orange-2 {
  background-color: #ff9933; }

.dropdown-container-background-color-orange-3 {
  background-color: #ff6600; }

.dropdown-container-background-color-orange-4 {
  background-color: #cc662b; }

.dropdown-container-background-color-orange-5 {
  background-color: #993300; }

.dropdown-container-background-color-orange-6 {
  background-color: #663300; }

.dropdown-container-background-color-grey-0 {
  background-color: #fafafa; }

.dropdown-container-background-color-grey-1 {
  background-color: #eaeaea; }

.dropdown-container-background-color-grey-2 {
  background-color: #cccccc; }

.dropdown-container-background-color-grey-3 {
  background-color: #999999; }

.dropdown-container-background-color-grey-4 {
  background-color: #777777; }

.dropdown-container-background-color-grey-5 {
  background-color: #666666; }

.dropdown-container-background-color-grey-6 {
  background-color: #333333; }

.dropdown-container-background-color-white {
  background-color: #ffffff; }

.dropdown-container-background-color-black {
  background-color: #000000; }

.dropdown-container-background-color-light-grey {
  background-color: #4a4a4a; }

.dropdown-container-background-color-light-pink {
  background-color: #fef0ef; }

.dropdown-container-background-color-pink {
  background-color: #fabeb9; }

.dropdown-container-border-color-blue-0 {
  border-color: #99ccff; }

.dropdown-container-border-color-blue-1 {
  border-color: #6699ff; }

.dropdown-container-border-color-blue-2 {
  border-color: #3366ff; }

.dropdown-container-border-color-blue-3 {
  border-color: #0033ff; }

.dropdown-container-border-color-blue-4 {
  border-color: #0033cc; }

.dropdown-container-border-color-blue-5 {
  border-color: #003399; }

.dropdown-container-border-color-blue-6 {
  border-color: #003366; }

.dropdown-container-border-color-green-0 {
  border-color: #99ff99; }

.dropdown-container-border-color-green-1 {
  border-color: #66ff66; }

.dropdown-container-border-color-green-2 {
  border-color: #66ff33; }

.dropdown-container-border-color-green-3 {
  border-color: #33cb00; }

.dropdown-container-border-color-green-4 {
  border-color: #33cc00; }

.dropdown-container-border-color-green-5 {
  border-color: #339900; }

.dropdown-container-border-color-green-6 {
  border-color: #336600; }

.dropdown-container-border-color-orange-0 {
  border-color: #ffcc99; }

.dropdown-container-border-color-orange-1 {
  border-color: #ff9966; }

.dropdown-container-border-color-orange-2 {
  border-color: #ff9933; }

.dropdown-container-border-color-orange-3 {
  border-color: #ff6600; }

.dropdown-container-border-color-orange-4 {
  border-color: #cc662b; }

.dropdown-container-border-color-orange-5 {
  border-color: #993300; }

.dropdown-container-border-color-orange-6 {
  border-color: #663300; }

.dropdown-container-border-color-grey-0 {
  border-color: #fafafa; }

.dropdown-container-border-color-grey-1 {
  border-color: #eaeaea; }

.dropdown-container-border-color-grey-2 {
  border-color: #cccccc; }

.dropdown-container-border-color-grey-3 {
  border-color: #999999; }

.dropdown-container-border-color-grey-4 {
  border-color: #777777; }

.dropdown-container-border-color-grey-5 {
  border-color: #666666; }

.dropdown-container-border-color-grey-6 {
  border-color: #333333; }

.dropdown-container-border-color-white {
  border-color: #ffffff; }

.dropdown-container-border-color-black {
  border-color: #000000; }

.dropdown-container-border-color-light-grey {
  border-color: #4a4a4a; }

.dropdown-container-border-color-light-pink {
  border-color: #fef0ef; }

.dropdown-container-border-color-pink {
  border-color: #fabeb9; }

.dropdown-container-error-border-color {
  border-color: #ff6600; }

.dropdown-container-color-blue-0 {
  color: #99ccff; }

.dropdown-container-color-blue-1 {
  color: #6699ff; }

.dropdown-container-color-blue-2 {
  color: #3366ff; }

.dropdown-container-color-blue-3 {
  color: #0033ff; }

.dropdown-container-color-blue-4 {
  color: #0033cc; }

.dropdown-container-color-blue-5 {
  color: #003399; }

.dropdown-container-color-blue-6 {
  color: #003366; }

.dropdown-container-color-green-0 {
  color: #99ff99; }

.dropdown-container-color-green-1 {
  color: #66ff66; }

.dropdown-container-color-green-2 {
  color: #66ff33; }

.dropdown-container-color-green-3 {
  color: #33cb00; }

.dropdown-container-color-green-4 {
  color: #33cc00; }

.dropdown-container-color-green-5 {
  color: #339900; }

.dropdown-container-color-green-6 {
  color: #336600; }

.dropdown-container-color-orange-0 {
  color: #ffcc99; }

.dropdown-container-color-orange-1 {
  color: #ff9966; }

.dropdown-container-color-orange-2 {
  color: #ff9933; }

.dropdown-container-color-orange-3 {
  color: #ff6600; }

.dropdown-container-color-orange-4 {
  color: #cc662b; }

.dropdown-container-color-orange-5 {
  color: #993300; }

.dropdown-container-color-orange-6 {
  color: #663300; }

.dropdown-container-color-grey-0 {
  color: #fafafa; }

.dropdown-container-color-grey-1 {
  color: #eaeaea; }

.dropdown-container-color-grey-2 {
  color: #cccccc; }

.dropdown-container-color-grey-3 {
  color: #999999; }

.dropdown-container-color-grey-4 {
  color: #777777; }

.dropdown-container-color-grey-5 {
  color: #666666; }

.dropdown-container-color-grey-6 {
  color: #333333; }

.dropdown-container-color-white {
  color: #ffffff; }

.dropdown-container-color-black {
  color: #000000; }

.dropdown-container-color-light-grey {
  color: #4a4a4a; }

.dropdown-container-color-light-pink {
  color: #fef0ef; }

.dropdown-container-color-pink {
  color: #fabeb9; }

/* Global style guide for UI Lib */
/**
 * Browser support
 */
/**
 * Typography
 */
/**
 * Colors
 */
/**
 * Page -- UILib Specific Variables
 */
/**
 * Card -- UILib Specific Variables
 */
.button {
  box-sizing: border-box;
  padding: 10px 25px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 3px;
  border: 1px solid;
  outline: none;
  -webkit-outline: none;
  -moz-outline: none;
  cursor: pointer; }
  .button:disabled {
    background-color: #fafafa;
    border-color: #cccccc;
    color: #cccccc;
    cursor: auto; }
  .button:hover {
    -webkit-filter: brightness(125%);
            filter: brightness(125%);
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out; }

.button-fluid {
  width: 100%; }

.button-background-color-blue-0 {
  background-color: #99ccff; }

.button-background-color-blue-1 {
  background-color: #6699ff; }

.button-background-color-blue-2 {
  background-color: #3366ff; }

.button-background-color-blue-3 {
  background-color: #0033ff; }

.button-background-color-blue-4 {
  background-color: #0033cc; }

.button-background-color-blue-5 {
  background-color: #003399; }

.button-background-color-blue-6 {
  background-color: #003366; }

.button-background-color-green-0 {
  background-color: #99ff99; }

.button-background-color-green-1 {
  background-color: #66ff66; }

.button-background-color-green-2 {
  background-color: #66ff33; }

.button-background-color-green-3 {
  background-color: #33cb00; }

.button-background-color-green-4 {
  background-color: #33cc00; }

.button-background-color-green-5 {
  background-color: #339900; }

.button-background-color-green-6 {
  background-color: #336600; }

.button-background-color-orange-0 {
  background-color: #ffcc99; }

.button-background-color-orange-1 {
  background-color: #ff9966; }

.button-background-color-orange-2 {
  background-color: #ff9933; }

.button-background-color-orange-3 {
  background-color: #ff6600; }

.button-background-color-orange-4 {
  background-color: #cc662b; }

.button-background-color-orange-5 {
  background-color: #993300; }

.button-background-color-orange-6 {
  background-color: #663300; }

.button-background-color-grey-0 {
  background-color: #fafafa; }

.button-background-color-grey-1 {
  background-color: #eaeaea; }

.button-background-color-grey-2 {
  background-color: #cccccc; }

.button-background-color-grey-3 {
  background-color: #999999; }

.button-background-color-grey-4 {
  background-color: #777777; }

.button-background-color-grey-5 {
  background-color: #666666; }

.button-background-color-grey-6 {
  background-color: #333333; }

.button-background-color-white {
  background-color: #ffffff; }

.button-background-color-black {
  background-color: #000000; }

.button-background-color-light-grey {
  background-color: #4a4a4a; }

.button-background-color-light-pink {
  background-color: #fef0ef; }

.button-background-color-pink {
  background-color: #fabeb9; }

.button-border-color-blue-0 {
  border-color: #99ccff; }

.button-border-color-blue-1 {
  border-color: #6699ff; }

.button-border-color-blue-2 {
  border-color: #3366ff; }

.button-border-color-blue-3 {
  border-color: #0033ff; }

.button-border-color-blue-4 {
  border-color: #0033cc; }

.button-border-color-blue-5 {
  border-color: #003399; }

.button-border-color-blue-6 {
  border-color: #003366; }

.button-border-color-green-0 {
  border-color: #99ff99; }

.button-border-color-green-1 {
  border-color: #66ff66; }

.button-border-color-green-2 {
  border-color: #66ff33; }

.button-border-color-green-3 {
  border-color: #33cb00; }

.button-border-color-green-4 {
  border-color: #33cc00; }

.button-border-color-green-5 {
  border-color: #339900; }

.button-border-color-green-6 {
  border-color: #336600; }

.button-border-color-orange-0 {
  border-color: #ffcc99; }

.button-border-color-orange-1 {
  border-color: #ff9966; }

.button-border-color-orange-2 {
  border-color: #ff9933; }

.button-border-color-orange-3 {
  border-color: #ff6600; }

.button-border-color-orange-4 {
  border-color: #cc662b; }

.button-border-color-orange-5 {
  border-color: #993300; }

.button-border-color-orange-6 {
  border-color: #663300; }

.button-border-color-grey-0 {
  border-color: #fafafa; }

.button-border-color-grey-1 {
  border-color: #eaeaea; }

.button-border-color-grey-2 {
  border-color: #cccccc; }

.button-border-color-grey-3 {
  border-color: #999999; }

.button-border-color-grey-4 {
  border-color: #777777; }

.button-border-color-grey-5 {
  border-color: #666666; }

.button-border-color-grey-6 {
  border-color: #333333; }

.button-border-color-white {
  border-color: #ffffff; }

.button-border-color-black {
  border-color: #000000; }

.button-border-color-light-grey {
  border-color: #4a4a4a; }

.button-border-color-light-pink {
  border-color: #fef0ef; }

.button-border-color-pink {
  border-color: #fabeb9; }

.button-color-blue-0 {
  color: #99ccff; }

.button-color-blue-1 {
  color: #6699ff; }

.button-color-blue-2 {
  color: #3366ff; }

.button-color-blue-3 {
  color: #0033ff; }

.button-color-blue-4 {
  color: #0033cc; }

.button-color-blue-5 {
  color: #003399; }

.button-color-blue-6 {
  color: #003366; }

.button-color-green-0 {
  color: #99ff99; }

.button-color-green-1 {
  color: #66ff66; }

.button-color-green-2 {
  color: #66ff33; }

.button-color-green-3 {
  color: #33cb00; }

.button-color-green-4 {
  color: #33cc00; }

.button-color-green-5 {
  color: #339900; }

.button-color-green-6 {
  color: #336600; }

.button-color-orange-0 {
  color: #ffcc99; }

.button-color-orange-1 {
  color: #ff9966; }

.button-color-orange-2 {
  color: #ff9933; }

.button-color-orange-3 {
  color: #ff6600; }

.button-color-orange-4 {
  color: #cc662b; }

.button-color-orange-5 {
  color: #993300; }

.button-color-orange-6 {
  color: #663300; }

.button-color-grey-0 {
  color: #fafafa; }

.button-color-grey-1 {
  color: #eaeaea; }

.button-color-grey-2 {
  color: #cccccc; }

.button-color-grey-3 {
  color: #999999; }

.button-color-grey-4 {
  color: #777777; }

.button-color-grey-5 {
  color: #666666; }

.button-color-grey-6 {
  color: #333333; }

.button-color-white {
  color: #ffffff; }

.button-color-black {
  color: #000000; }

.button-color-light-grey {
  color: #4a4a4a; }

.button-color-light-pink {
  color: #fef0ef; }

.button-color-pink {
  color: #fabeb9; }

/* Global style guide for UI Lib */
/**
 * Browser support
 */
/**
 * Typography
 */
/**
 * Colors
 */
/**
 * Page -- UILib Specific Variables
 */
/**
 * Card -- UILib Specific Variables
 */
.text a {
  text-decoration: none;
  color: inherit; }
  .text a:hover {
    text-decoration: underline; }

.text-align-left {
  text-align: left; }

.text-align-right {
  text-align: right; }

.text-align-center {
  text-align: center; }

.text-color-blue-0 {
  color: #99ccff; }

.text-color-blue-1 {
  color: #6699ff; }

.text-color-blue-2 {
  color: #3366ff; }

.text-color-blue-3 {
  color: #0033ff; }

.text-color-blue-4 {
  color: #0033cc; }

.text-color-blue-5 {
  color: #003399; }

.text-color-blue-6 {
  color: #003366; }

.text-color-green-0 {
  color: #99ff99; }

.text-color-green-1 {
  color: #66ff66; }

.text-color-green-2 {
  color: #66ff33; }

.text-color-green-3 {
  color: #33cb00; }

.text-color-green-4 {
  color: #33cc00; }

.text-color-green-5 {
  color: #339900; }

.text-color-green-6 {
  color: #336600; }

.text-color-orange-0 {
  color: #ffcc99; }

.text-color-orange-1 {
  color: #ff9966; }

.text-color-orange-2 {
  color: #ff9933; }

.text-color-orange-3 {
  color: #ff6600; }

.text-color-orange-4 {
  color: #cc662b; }

.text-color-orange-5 {
  color: #993300; }

.text-color-orange-6 {
  color: #663300; }

.text-color-grey-0 {
  color: #fafafa; }

.text-color-grey-1 {
  color: #eaeaea; }

.text-color-grey-2 {
  color: #cccccc; }

.text-color-grey-3 {
  color: #999999; }

.text-color-grey-4 {
  color: #777777; }

.text-color-grey-5 {
  color: #666666; }

.text-color-grey-6 {
  color: #333333; }

.text-color-white {
  color: #ffffff; }

.text-color-black {
  color: #000000; }

.text-color-light-grey {
  color: #4a4a4a; }

.text-color-light-pink {
  color: #fef0ef; }

.text-color-pink {
  color: #fabeb9; }

/* Global style guide for UI Lib */
/**
 * Browser support
 */
/**
 * Typography
 */
/**
 * Colors
 */
/**
 * Page -- UILib Specific Variables
 */
/**
 * Card -- UILib Specific Variables
 */
.icon-color-blue-0 {
  color: #99ccff; }

.icon-color-blue-1 {
  color: #6699ff; }

.icon-color-blue-2 {
  color: #3366ff; }

.icon-color-blue-3 {
  color: #0033ff; }

.icon-color-blue-4 {
  color: #0033cc; }

.icon-color-blue-5 {
  color: #003399; }

.icon-color-blue-6 {
  color: #003366; }

.icon-color-green-0 {
  color: #99ff99; }

.icon-color-green-1 {
  color: #66ff66; }

.icon-color-green-2 {
  color: #66ff33; }

.icon-color-green-3 {
  color: #33cb00; }

.icon-color-green-4 {
  color: #33cc00; }

.icon-color-green-5 {
  color: #339900; }

.icon-color-green-6 {
  color: #336600; }

.icon-color-orange-0 {
  color: #ffcc99; }

.icon-color-orange-1 {
  color: #ff9966; }

.icon-color-orange-2 {
  color: #ff9933; }

.icon-color-orange-3 {
  color: #ff6600; }

.icon-color-orange-4 {
  color: #cc662b; }

.icon-color-orange-5 {
  color: #993300; }

.icon-color-orange-6 {
  color: #663300; }

.icon-color-grey-0 {
  color: #fafafa; }

.icon-color-grey-1 {
  color: #eaeaea; }

.icon-color-grey-2 {
  color: #cccccc; }

.icon-color-grey-3 {
  color: #999999; }

.icon-color-grey-4 {
  color: #777777; }

.icon-color-grey-5 {
  color: #666666; }

.icon-color-grey-6 {
  color: #333333; }

.icon-color-white {
  color: #ffffff; }

.icon-color-black {
  color: #000000; }

.icon-color-light-grey {
  color: #4a4a4a; }

.icon-color-light-pink {
  color: #fef0ef; }

.icon-color-pink {
  color: #fabeb9; }

/* Global style guide for UI Lib */
/**
 * Browser support
 */
/**
 * Typography
 */
/**
 * Colors
 */
/**
 * Page -- UILib Specific Variables
 */
/**
 * Card -- UILib Specific Variables
 */
@media only screen and (min-width: 769px) {
  .message {
    position: relative;
    box-sizing: border-box;
    margin: 60px auto 40px auto;
    padding: 30px 40px;
    height: 100%;
    border-radius: 8px;
    font-weight: 500;
    display: flex;
    flex-direction: row; }
    .message .dismiss {
      position: absolute;
      top: 15px;
      right: 15px;
      font-size: 20px !important;
      cursor: pointer; }
    .message .message-icon {
      margin: auto 30px auto 0;
      font-size: 60px; }
    .message .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      width: 800px; }
      .message .content .header {
        font-size: 1.7em;
        line-height: 1.25;
        font-weight: 600; }
      .message .content .content {
        font-size: 1.2em;
        line-height: 1.63;
        font-weight: 500; }
    .message .action {
      display: flex;
      flex-direction: column;
      justify-content: center; }
  .message-background-color-blue-0 {
    background-color: #99ccff; }
  .message-background-color-blue-1 {
    background-color: #6699ff; }
  .message-background-color-blue-2 {
    background-color: #3366ff; }
  .message-background-color-blue-3 {
    background-color: #0033ff; }
  .message-background-color-blue-4 {
    background-color: #0033cc; }
  .message-background-color-blue-5 {
    background-color: #003399; }
  .message-background-color-blue-6 {
    background-color: #003366; }
  .message-background-color-green-0 {
    background-color: #99ff99; }
  .message-background-color-green-1 {
    background-color: #66ff66; }
  .message-background-color-green-2 {
    background-color: #66ff33; }
  .message-background-color-green-3 {
    background-color: #33cb00; }
  .message-background-color-green-4 {
    background-color: #33cc00; }
  .message-background-color-green-5 {
    background-color: #339900; }
  .message-background-color-green-6 {
    background-color: #336600; }
  .message-background-color-orange-0 {
    background-color: #ffcc99; }
  .message-background-color-orange-1 {
    background-color: #ff9966; }
  .message-background-color-orange-2 {
    background-color: #ff9933; }
  .message-background-color-orange-3 {
    background-color: #ff6600; }
  .message-background-color-orange-4 {
    background-color: #cc662b; }
  .message-background-color-orange-5 {
    background-color: #993300; }
  .message-background-color-orange-6 {
    background-color: #663300; }
  .message-background-color-grey-0 {
    background-color: #fafafa; }
  .message-background-color-grey-1 {
    background-color: #eaeaea; }
  .message-background-color-grey-2 {
    background-color: #cccccc; }
  .message-background-color-grey-3 {
    background-color: #999999; }
  .message-background-color-grey-4 {
    background-color: #777777; }
  .message-background-color-grey-5 {
    background-color: #666666; }
  .message-background-color-grey-6 {
    background-color: #333333; }
  .message-background-color-white {
    background-color: #ffffff; }
  .message-background-color-black {
    background-color: #000000; }
  .message-background-color-light-grey {
    background-color: #4a4a4a; }
  .message-background-color-light-pink {
    background-color: #fef0ef; }
  .message-background-color-pink {
    background-color: #fabeb9; }
  .message-border-color-blue-0 {
    border-color: #99ccff; }
  .message-border-color-blue-1 {
    border-color: #6699ff; }
  .message-border-color-blue-2 {
    border-color: #3366ff; }
  .message-border-color-blue-3 {
    border-color: #0033ff; }
  .message-border-color-blue-4 {
    border-color: #0033cc; }
  .message-border-color-blue-5 {
    border-color: #003399; }
  .message-border-color-blue-6 {
    border-color: #003366; }
  .message-border-color-green-0 {
    border-color: #99ff99; }
  .message-border-color-green-1 {
    border-color: #66ff66; }
  .message-border-color-green-2 {
    border-color: #66ff33; }
  .message-border-color-green-3 {
    border-color: #33cb00; }
  .message-border-color-green-4 {
    border-color: #33cc00; }
  .message-border-color-green-5 {
    border-color: #339900; }
  .message-border-color-green-6 {
    border-color: #336600; }
  .message-border-color-orange-0 {
    border-color: #ffcc99; }
  .message-border-color-orange-1 {
    border-color: #ff9966; }
  .message-border-color-orange-2 {
    border-color: #ff9933; }
  .message-border-color-orange-3 {
    border-color: #ff6600; }
  .message-border-color-orange-4 {
    border-color: #cc662b; }
  .message-border-color-orange-5 {
    border-color: #993300; }
  .message-border-color-orange-6 {
    border-color: #663300; }
  .message-border-color-grey-0 {
    border-color: #fafafa; }
  .message-border-color-grey-1 {
    border-color: #eaeaea; }
  .message-border-color-grey-2 {
    border-color: #cccccc; }
  .message-border-color-grey-3 {
    border-color: #999999; }
  .message-border-color-grey-4 {
    border-color: #777777; }
  .message-border-color-grey-5 {
    border-color: #666666; }
  .message-border-color-grey-6 {
    border-color: #333333; }
  .message-border-color-white {
    border-color: #ffffff; }
  .message-border-color-black {
    border-color: #000000; }
  .message-border-color-light-grey {
    border-color: #4a4a4a; }
  .message-border-color-light-pink {
    border-color: #fef0ef; }
  .message-border-color-pink {
    border-color: #fabeb9; } }

@media only screen and (max-width: 768px) {
  .message {
    position: relative;
    box-sizing: border-box;
    margin: 20px auto 20px auto;
    padding: 20px 10px;
    height: 100%;
    border-radius: 8px;
    font-weight: 500;
    display: flex;
    flex-direction: row; }
    .message .dismiss {
      display: none; }
    .message .message-icon {
      display: none; }
    .message .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      width: 80%;
      box-sizing: border-box;
      padding-right: 20px; }
      .message .content .header {
        font-size: 12px;
        line-height: 1.25;
        font-weight: 600; }
      .message .content .content {
        display: none; }
    .message .action {
      font-size: 12px;
      display: flex;
      flex-direction: column;
      justify-content: center; }
  .message-background-color-blue-0 {
    background-color: #99ccff; }
  .message-background-color-blue-1 {
    background-color: #6699ff; }
  .message-background-color-blue-2 {
    background-color: #3366ff; }
  .message-background-color-blue-3 {
    background-color: #0033ff; }
  .message-background-color-blue-4 {
    background-color: #0033cc; }
  .message-background-color-blue-5 {
    background-color: #003399; }
  .message-background-color-blue-6 {
    background-color: #003366; }
  .message-background-color-green-0 {
    background-color: #99ff99; }
  .message-background-color-green-1 {
    background-color: #66ff66; }
  .message-background-color-green-2 {
    background-color: #66ff33; }
  .message-background-color-green-3 {
    background-color: #33cb00; }
  .message-background-color-green-4 {
    background-color: #33cc00; }
  .message-background-color-green-5 {
    background-color: #339900; }
  .message-background-color-green-6 {
    background-color: #336600; }
  .message-background-color-orange-0 {
    background-color: #ffcc99; }
  .message-background-color-orange-1 {
    background-color: #ff9966; }
  .message-background-color-orange-2 {
    background-color: #ff9933; }
  .message-background-color-orange-3 {
    background-color: #ff6600; }
  .message-background-color-orange-4 {
    background-color: #cc662b; }
  .message-background-color-orange-5 {
    background-color: #993300; }
  .message-background-color-orange-6 {
    background-color: #663300; }
  .message-background-color-grey-0 {
    background-color: #fafafa; }
  .message-background-color-grey-1 {
    background-color: #eaeaea; }
  .message-background-color-grey-2 {
    background-color: #cccccc; }
  .message-background-color-grey-3 {
    background-color: #999999; }
  .message-background-color-grey-4 {
    background-color: #777777; }
  .message-background-color-grey-5 {
    background-color: #666666; }
  .message-background-color-grey-6 {
    background-color: #333333; }
  .message-background-color-white {
    background-color: #ffffff; }
  .message-background-color-black {
    background-color: #000000; }
  .message-background-color-light-grey {
    background-color: #4a4a4a; }
  .message-background-color-light-pink {
    background-color: #fef0ef; }
  .message-background-color-pink {
    background-color: #fabeb9; }
  .message-border-color-blue-0 {
    border-color: #99ccff; }
  .message-border-color-blue-1 {
    border-color: #6699ff; }
  .message-border-color-blue-2 {
    border-color: #3366ff; }
  .message-border-color-blue-3 {
    border-color: #0033ff; }
  .message-border-color-blue-4 {
    border-color: #0033cc; }
  .message-border-color-blue-5 {
    border-color: #003399; }
  .message-border-color-blue-6 {
    border-color: #003366; }
  .message-border-color-green-0 {
    border-color: #99ff99; }
  .message-border-color-green-1 {
    border-color: #66ff66; }
  .message-border-color-green-2 {
    border-color: #66ff33; }
  .message-border-color-green-3 {
    border-color: #33cb00; }
  .message-border-color-green-4 {
    border-color: #33cc00; }
  .message-border-color-green-5 {
    border-color: #339900; }
  .message-border-color-green-6 {
    border-color: #336600; }
  .message-border-color-orange-0 {
    border-color: #ffcc99; }
  .message-border-color-orange-1 {
    border-color: #ff9966; }
  .message-border-color-orange-2 {
    border-color: #ff9933; }
  .message-border-color-orange-3 {
    border-color: #ff6600; }
  .message-border-color-orange-4 {
    border-color: #cc662b; }
  .message-border-color-orange-5 {
    border-color: #993300; }
  .message-border-color-orange-6 {
    border-color: #663300; }
  .message-border-color-grey-0 {
    border-color: #fafafa; }
  .message-border-color-grey-1 {
    border-color: #eaeaea; }
  .message-border-color-grey-2 {
    border-color: #cccccc; }
  .message-border-color-grey-3 {
    border-color: #999999; }
  .message-border-color-grey-4 {
    border-color: #777777; }
  .message-border-color-grey-5 {
    border-color: #666666; }
  .message-border-color-grey-6 {
    border-color: #333333; }
  .message-border-color-white {
    border-color: #ffffff; }
  .message-border-color-black {
    border-color: #000000; }
  .message-border-color-light-grey {
    border-color: #4a4a4a; }
  .message-border-color-light-pink {
    border-color: #fef0ef; }
  .message-border-color-pink {
    border-color: #fabeb9; } }

/* Global style guide for UI Lib */
/**
 * Browser support
 */
/**
 * Typography
 */
/**
 * Colors
 */
/**
 * Page -- UILib Specific Variables
 */
/**
 * Card -- UILib Specific Variables
 */
@media only screen and (min-width: 769px) {
  .wallet {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
    .wallet .header-zone {
      width: 100%;
      text-align: left; }
      .wallet .header-zone .header {
        font-size: 2em;
        font-weight: 600;
        line-height: 1.25;
        color: inherit; }
      .wallet .header-zone .subheader {
        font-size: 14px;
        font-weight: 500;
        color: inherit; }
    .wallet .balance-zone {
      width: 100%;
      text-align: right;
      color: inherit; }
      .wallet .balance-zone .integer {
        font-size: 28px;
        font-weight: 700; }
      .wallet .balance-zone .float {
        font-size: 28px;
        font-weight: 700; }
      .wallet .balance-zone .currency {
        margin-left: 4px;
        font-size: 14px;
        font-weight: 700; }
      .wallet .balance-zone .create-now {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        color: inherit; }
        .wallet .balance-zone .create-now .text {
          font-size: 16px;
          font-weight: 600;
          margin-left: 8px;
          color: inherit; }
        .wallet .balance-zone .create-now .plus {
          font-size: 8px;
          color: inherit; }
  .wallet-small {
    box-sizing: border-box;
    max-width: 230px;
    border-radius: 8px; }
  .wallet-small-total {
    width: 100%;
    height: 135px;
    background-color: #ffffff;
    color: #333333;
    padding: 10px 15px; }
  .wallet-small-wallet {
    position: relative;
    cursor: pointer;
    width: 100%;
    height: 120px;
    background-color: transparent;
    color: #333333;
    padding: 10px 15px;
    border: 1px solid #333333; }
    .wallet-small-wallet .add {
      position: absolute;
      font-size: 12px;
      top: 10px;
      right: 10px; }
    .wallet-small-wallet .deposit {
      position: absolute;
      box-sizing: border-box;
      top: 10px;
      right: 10px;
      background-color: #ffffff;
      color: #0033ff;
      border-radius: 2px;
      font-size: 12px;
      font-weight: 600;
      padding: 3px 6px; }
  .wallet-small-wallet-hovered {
    background-color: #0033ff;
    border-color: #0033ff;
    color: #ffffff !important; }
  .wallet-disabled {
    color: #999999 !important;
    border-color: #999999 !important;
    cursor: pointer; }
  .currency-switch-zone {
    width: 100%;
    margin: 30px 0;
    font-size: 18px;
    font-weight: 500;
    color: #777777; }
  .currency-switch-align-left {
    text-align: left; }
  .currency-switch-align-right {
    text-align: right; }
  .currency-switch-align-center {
    text-align: center; }
  .currency-switch {
    display: inline-flex;
    border: 1px solid #777777;
    border-radius: 4px; }
    .currency-switch .item {
      cursor: pointer;
      padding: 10px; }
      .currency-switch .item:first-child {
        border-right: 1px solid #777777; }
    .currency-switch .active {
      background-color: #777777;
      color: #fafafa; } }

@media only screen and (max-width: 768px) {
  .wallet {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between; }
    .wallet .header-zone {
      width: 40vw;
      text-align: left; }
      .wallet .header-zone .header {
        font-size: 16px;
        font-weight: 600;
        line-height: 1.25;
        color: inherit; }
      .wallet .header-zone .subheader {
        font-size: 12px;
        font-weight: 500;
        color: inherit; }
    .wallet .balance-zone {
      width: 40vw;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      text-align: right;
      margin-top: 4px;
      margin-right: 10px;
      color: inherit; }
      .wallet .balance-zone .integer {
        font-size: 20px;
        font-weight: 700; }
      .wallet .balance-zone .float {
        font-size: 16px;
        font-weight: 700; }
      .wallet .balance-zone .currency {
        margin-left: 4px;
        font-size: 16px;
        font-weight: 700; }
      .wallet .balance-zone .create-now {
        width: 40vw;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        color: inherit; }
        .wallet .balance-zone .create-now .text {
          font-size: 16px;
          font-weight: 600;
          margin-left: 8px;
          color: inherit; }
        .wallet .balance-zone .create-now .plus {
          font-size: 8px;
          color: inherit; }
  .wallet-small {
    box-sizing: border-box;
    border-radius: 8px; }
  .wallet-small-total {
    width: 100%;
    background-color: #ffffff;
    color: #333333;
    padding: 10px 15px; }
  .wallet-small-wallet {
    position: relative;
    cursor: pointer;
    width: 100%;
    background-color: transparent;
    color: #333333;
    padding: 10px 15px;
    border: 1px solid #333333; }
    .wallet-small-wallet .add {
      position: absolute;
      font-size: 12px;
      top: 40%;
      right: 10px; }
    .wallet-small-wallet .deposit {
      display: none; }
  .wallet-small-wallet-hovered {
    background-color: #0033ff;
    border-color: #0033ff;
    color: #ffffff !important; }
  .wallet-disabled {
    color: #999999 !important;
    border-color: #999999 !important;
    cursor: pointer; }
  .currency-switch-zone {
    width: 100%;
    margin: 0px 0px 10px 0px;
    font-size: 12px;
    font-weight: 500;
    color: #777777; }
  .currency-switch-align-left {
    text-align: left; }
  .currency-switch-align-right {
    text-align: right; }
  .currency-switch-align-center {
    text-align: center; }
  .currency-switch {
    display: inline-flex;
    border: 1px solid #777777;
    border-radius: 4px; }
    .currency-switch .item {
      cursor: pointer;
      padding: 8px; }
      .currency-switch .item:first-child {
        border-right: 1px solid #777777; }
    .currency-switch .active {
      background-color: #777777;
      color: #fafafa; } }

/* Global style guide for UI Lib */
/**
 * Browser support
 */
/**
 * Typography
 */
/**
 * Colors
 */
/**
 * Page -- UILib Specific Variables
 */
/**
 * Card -- UILib Specific Variables
 */
@media only screen and (min-width: 769px) {
  .header-ui {
    width: 100%;
    font-size: 18px;
    font-weight: 500;
    text-align: center; }
  .header-ui-lined .content {
    position: relative;
    width: 100%; }
    .header-ui-lined .content .line {
      position: absolute;
      top: 50%;
      width: 100%;
      height: 1px;
      background-color: #4a4a4a;
      z-index: 1; }
    .header-ui-lined .content .text {
      position: absolute;
      left: 15%;
      width: 70%;
      background-color: #fafafa;
      position: relative;
      z-index: 10; }
  .header-ui-color-blue-0 {
    color: #99ccff; }
  .header-ui-color-blue-1 {
    color: #6699ff; }
  .header-ui-color-blue-2 {
    color: #3366ff; }
  .header-ui-color-blue-3 {
    color: #0033ff; }
  .header-ui-color-blue-4 {
    color: #0033cc; }
  .header-ui-color-blue-5 {
    color: #003399; }
  .header-ui-color-blue-6 {
    color: #003366; }
  .header-ui-color-green-0 {
    color: #99ff99; }
  .header-ui-color-green-1 {
    color: #66ff66; }
  .header-ui-color-green-2 {
    color: #66ff33; }
  .header-ui-color-green-3 {
    color: #33cb00; }
  .header-ui-color-green-4 {
    color: #33cc00; }
  .header-ui-color-green-5 {
    color: #339900; }
  .header-ui-color-green-6 {
    color: #336600; }
  .header-ui-color-orange-0 {
    color: #ffcc99; }
  .header-ui-color-orange-1 {
    color: #ff9966; }
  .header-ui-color-orange-2 {
    color: #ff9933; }
  .header-ui-color-orange-3 {
    color: #ff6600; }
  .header-ui-color-orange-4 {
    color: #cc662b; }
  .header-ui-color-orange-5 {
    color: #993300; }
  .header-ui-color-orange-6 {
    color: #663300; }
  .header-ui-color-grey-0 {
    color: #fafafa; }
  .header-ui-color-grey-1 {
    color: #eaeaea; }
  .header-ui-color-grey-2 {
    color: #cccccc; }
  .header-ui-color-grey-3 {
    color: #999999; }
  .header-ui-color-grey-4 {
    color: #777777; }
  .header-ui-color-grey-5 {
    color: #666666; }
  .header-ui-color-grey-6 {
    color: #333333; }
  .header-ui-color-white {
    color: #ffffff; }
  .header-ui-color-black {
    color: #000000; }
  .header-ui-color-light-grey {
    color: #4a4a4a; }
  .header-ui-color-light-pink {
    color: #fef0ef; }
  .header-ui-color-pink {
    color: #fabeb9; }
  .header-ui-background-color-blue-0 {
    background-color: #99ccff; }
  .header-ui-background-color-blue-1 {
    background-color: #6699ff; }
  .header-ui-background-color-blue-2 {
    background-color: #3366ff; }
  .header-ui-background-color-blue-3 {
    background-color: #0033ff; }
  .header-ui-background-color-blue-4 {
    background-color: #0033cc; }
  .header-ui-background-color-blue-5 {
    background-color: #003399; }
  .header-ui-background-color-blue-6 {
    background-color: #003366; }
  .header-ui-background-color-green-0 {
    background-color: #99ff99; }
  .header-ui-background-color-green-1 {
    background-color: #66ff66; }
  .header-ui-background-color-green-2 {
    background-color: #66ff33; }
  .header-ui-background-color-green-3 {
    background-color: #33cb00; }
  .header-ui-background-color-green-4 {
    background-color: #33cc00; }
  .header-ui-background-color-green-5 {
    background-color: #339900; }
  .header-ui-background-color-green-6 {
    background-color: #336600; }
  .header-ui-background-color-orange-0 {
    background-color: #ffcc99; }
  .header-ui-background-color-orange-1 {
    background-color: #ff9966; }
  .header-ui-background-color-orange-2 {
    background-color: #ff9933; }
  .header-ui-background-color-orange-3 {
    background-color: #ff6600; }
  .header-ui-background-color-orange-4 {
    background-color: #cc662b; }
  .header-ui-background-color-orange-5 {
    background-color: #993300; }
  .header-ui-background-color-orange-6 {
    background-color: #663300; }
  .header-ui-background-color-grey-0 {
    background-color: #fafafa; }
  .header-ui-background-color-grey-1 {
    background-color: #eaeaea; }
  .header-ui-background-color-grey-2 {
    background-color: #cccccc; }
  .header-ui-background-color-grey-3 {
    background-color: #999999; }
  .header-ui-background-color-grey-4 {
    background-color: #777777; }
  .header-ui-background-color-grey-5 {
    background-color: #666666; }
  .header-ui-background-color-grey-6 {
    background-color: #333333; }
  .header-ui-background-color-white {
    background-color: #ffffff; }
  .header-ui-background-color-black {
    background-color: #000000; }
  .header-ui-background-color-light-grey {
    background-color: #4a4a4a; }
  .header-ui-background-color-light-pink {
    background-color: #fef0ef; }
  .header-ui-background-color-pink {
    background-color: #fabeb9; } }

@media only screen and (max-width: 768px) {
  .header-ui {
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    text-align: center; }
  .header-ui-lined .content {
    position: relative;
    width: 100%; }
    .header-ui-lined .content .line {
      position: absolute;
      top: 50%;
      width: 100%;
      height: 1px;
      background-color: #4a4a4a;
      z-index: 1; }
    .header-ui-lined .content .text {
      position: absolute;
      text-align: left;
      left: 0%;
      width: 50%;
      background-color: #fafafa;
      position: relative;
      z-index: 10; }
  .header-ui-color-blue-0 {
    color: #99ccff; }
  .header-ui-color-blue-1 {
    color: #6699ff; }
  .header-ui-color-blue-2 {
    color: #3366ff; }
  .header-ui-color-blue-3 {
    color: #0033ff; }
  .header-ui-color-blue-4 {
    color: #0033cc; }
  .header-ui-color-blue-5 {
    color: #003399; }
  .header-ui-color-blue-6 {
    color: #003366; }
  .header-ui-color-green-0 {
    color: #99ff99; }
  .header-ui-color-green-1 {
    color: #66ff66; }
  .header-ui-color-green-2 {
    color: #66ff33; }
  .header-ui-color-green-3 {
    color: #33cb00; }
  .header-ui-color-green-4 {
    color: #33cc00; }
  .header-ui-color-green-5 {
    color: #339900; }
  .header-ui-color-green-6 {
    color: #336600; }
  .header-ui-color-orange-0 {
    color: #ffcc99; }
  .header-ui-color-orange-1 {
    color: #ff9966; }
  .header-ui-color-orange-2 {
    color: #ff9933; }
  .header-ui-color-orange-3 {
    color: #ff6600; }
  .header-ui-color-orange-4 {
    color: #cc662b; }
  .header-ui-color-orange-5 {
    color: #993300; }
  .header-ui-color-orange-6 {
    color: #663300; }
  .header-ui-color-grey-0 {
    color: #fafafa; }
  .header-ui-color-grey-1 {
    color: #eaeaea; }
  .header-ui-color-grey-2 {
    color: #cccccc; }
  .header-ui-color-grey-3 {
    color: #999999; }
  .header-ui-color-grey-4 {
    color: #777777; }
  .header-ui-color-grey-5 {
    color: #666666; }
  .header-ui-color-grey-6 {
    color: #333333; }
  .header-ui-color-white {
    color: #ffffff; }
  .header-ui-color-black {
    color: #000000; }
  .header-ui-color-light-grey {
    color: #4a4a4a; }
  .header-ui-color-light-pink {
    color: #fef0ef; }
  .header-ui-color-pink {
    color: #fabeb9; }
  .header-ui-background-color-blue-0 {
    background-color: #99ccff; }
  .header-ui-background-color-blue-1 {
    background-color: #6699ff; }
  .header-ui-background-color-blue-2 {
    background-color: #3366ff; }
  .header-ui-background-color-blue-3 {
    background-color: #0033ff; }
  .header-ui-background-color-blue-4 {
    background-color: #0033cc; }
  .header-ui-background-color-blue-5 {
    background-color: #003399; }
  .header-ui-background-color-blue-6 {
    background-color: #003366; }
  .header-ui-background-color-green-0 {
    background-color: #99ff99; }
  .header-ui-background-color-green-1 {
    background-color: #66ff66; }
  .header-ui-background-color-green-2 {
    background-color: #66ff33; }
  .header-ui-background-color-green-3 {
    background-color: #33cb00; }
  .header-ui-background-color-green-4 {
    background-color: #33cc00; }
  .header-ui-background-color-green-5 {
    background-color: #339900; }
  .header-ui-background-color-green-6 {
    background-color: #336600; }
  .header-ui-background-color-orange-0 {
    background-color: #ffcc99; }
  .header-ui-background-color-orange-1 {
    background-color: #ff9966; }
  .header-ui-background-color-orange-2 {
    background-color: #ff9933; }
  .header-ui-background-color-orange-3 {
    background-color: #ff6600; }
  .header-ui-background-color-orange-4 {
    background-color: #cc662b; }
  .header-ui-background-color-orange-5 {
    background-color: #993300; }
  .header-ui-background-color-orange-6 {
    background-color: #663300; }
  .header-ui-background-color-grey-0 {
    background-color: #fafafa; }
  .header-ui-background-color-grey-1 {
    background-color: #eaeaea; }
  .header-ui-background-color-grey-2 {
    background-color: #cccccc; }
  .header-ui-background-color-grey-3 {
    background-color: #999999; }
  .header-ui-background-color-grey-4 {
    background-color: #777777; }
  .header-ui-background-color-grey-5 {
    background-color: #666666; }
  .header-ui-background-color-grey-6 {
    background-color: #333333; }
  .header-ui-background-color-white {
    background-color: #ffffff; }
  .header-ui-background-color-black {
    background-color: #000000; }
  .header-ui-background-color-light-grey {
    background-color: #4a4a4a; }
  .header-ui-background-color-light-pink {
    background-color: #fef0ef; }
  .header-ui-background-color-pink {
    background-color: #fabeb9; } }

/* Global style guide for UI Lib */
/**
 * Browser support
 */
/**
 * Typography
 */
/**
 * Colors
 */
/**
 * Page -- UILib Specific Variables
 */
/**
 * Card -- UILib Specific Variables
 */
@media only screen and (min-width: 769px) {
  .investment-table-header {
    font-size: 24px;
    font-weight: 700;
    color: #4a4a4a;
    margin-bottom: 20px; }
  .general-table-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 200px;
    text-align: left; }
    .general-table-wrapper .table-loading {
      height: 200px;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center; }
  .invested-table-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 200px;
    text-align: left; }
    .invested-table-wrapper .table-loading {
      height: 200px;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center; }
    .invested-table-wrapper .table-error-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
      .invested-table-wrapper .table-error-wrapper .error-content {
        box-sizing: border-box;
        font-size: 24px;
        font-weight: 500;
        text-align: center;
        color: #333333;
        margin-top: 40px; }
    .invested-table-wrapper .table-header-zone {
      display: flex;
      flex-direction: row;
      margin-bottom: 10px;
      padding: 0 25px; }
      .invested-table-wrapper .table-header-zone .table-header {
        font-size: 12px;
        font-weight: 600;
        color: #bfc5d2;
        letter-spacing: 1.12px;
        width: 14%; }
        .invested-table-wrapper .table-header-zone .table-header:first-child {
          width: 30%; }
    .invested-table-wrapper .mobile-table-header-zone {
      display: none; }
    .invested-table-wrapper .table-item-zone {
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      height: 76px;
      padding: 20px 25px;
      margin-bottom: 10px;
      background-color: #ffffff;
      border: 1px solid rgba(46, 91, 255, 0.08);
      border-radius: 1px; }
      .invested-table-wrapper .table-item-zone .table-item {
        font-size: 16px;
        font-weight: 500;
        color: #4a4a4a;
        width: 14%;
        text-align: left;
        box-sizing: border-box;
        padding-right: 25px; }
        .invested-table-wrapper .table-item-zone .table-item:first-child {
          width: 30%;
          display: flex;
          flex-direction: row;
          align-items: center; }
          .invested-table-wrapper .table-item-zone .table-item:first-child .project-image {
            height: 36px;
            min-width: 36px;
            border-radius: 50%;
            background-color: rgba(46, 91, 255, 0.15);
            margin-right: 25px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center; }
            .invested-table-wrapper .table-item-zone .table-item:first-child .project-image .image-placeholder {
              font-size: 15px;
              color: #2e5bff; }
      .invested-table-wrapper .table-item-zone .earnings {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center; }
        .invested-table-wrapper .table-item-zone .earnings .indicator {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: #ff9933;
          margin-right: 10px; }
        .invested-table-wrapper .table-item-zone .earnings .active {
          background-color: #66ff66; }
      .invested-table-wrapper .table-item-zone .actions {
        text-align: right;
        font-size: 15px;
        color: #2e5bff; }
        .invested-table-wrapper .table-item-zone .actions a {
          text-decoration: none;
          color: inherit; }
          .invested-table-wrapper .table-item-zone .actions a .action {
            cursor: pointer; }
            .invested-table-wrapper .table-item-zone .actions a .action:hover {
              text-decoration: underline; }
    .invested-table-wrapper .mobile-table-item-zone {
      display: none; }
    .invested-table-wrapper .table-total-zone {
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      height: 50px;
      padding: 12px 25px;
      margin-bottom: 10px;
      background-color: rgba(255, 255, 255, 0.6);
      border: 1px solid rgba(46, 91, 255, 0.08);
      border-radius: 1px; }
      .invested-table-wrapper .table-total-zone .table-total {
        font-size: 18px;
        font-weight: 700;
        color: #4a4a4a;
        width: 14%;
        text-align: left; }
        .invested-table-wrapper .table-total-zone .table-total:first-child {
          width: 44%; }
      .invested-table-wrapper .table-total-zone .earnings {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center; }
        .invested-table-wrapper .table-total-zone .earnings .indicator {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          margin-right: 10px; }
      .invested-table-wrapper .table-total-zone .actions {
        text-align: center;
        font-size: 15px;
        color: #2e5bff;
        font-weight: 500; }
        .invested-table-wrapper .table-total-zone .actions a {
          text-decoration: none;
          color: inherit; }
          .invested-table-wrapper .table-total-zone .actions a .action {
            cursor: pointer; }
            .invested-table-wrapper .table-total-zone .actions a .action:hover {
              text-decoration: underline; }
  .investable-table-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 200px;
    text-align: left; }
    .investable-table-wrapper .table-error-wrapper {
      height: 200px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
      .investable-table-wrapper .table-error-wrapper .error-content {
        box-sizing: border-box;
        font-size: 24px;
        font-weight: 500;
        text-align: center;
        color: #333333;
        margin-top: 40px; }
    .investable-table-wrapper .table-header-zone {
      display: flex;
      flex-direction: row;
      margin-bottom: 10px;
      padding: 0 25px; }
      .investable-table-wrapper .table-header-zone .table-header {
        font-size: 12px;
        font-weight: 600;
        color: #bfc5d2;
        letter-spacing: 1.12px;
        width: 10%; }
        .investable-table-wrapper .table-header-zone .table-header:first-child {
          width: 35%; }
        .investable-table-wrapper .table-header-zone .table-header:nth-child(2) {
          width: 15%; }
        .investable-table-wrapper .table-header-zone .table-header:nth-child(3) {
          width: 20%; }
        .investable-table-wrapper .table-header-zone .table-header:last-child {
          width: 8%; }
    .investable-table-wrapper .mobile-table-header-zone {
      display: none; }
    .investable-table-wrapper .table-item-wrapper {
      position: relative; }
      .investable-table-wrapper .table-item-wrapper .table-item-audited {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        top: -10px;
        left: -15px;
        height: 25px;
        width: 90px;
        background-color: #33cb00;
        border-radius: 3px;
        font-size: 14px;
        font-weight: 600;
        color: white; }
      .investable-table-wrapper .table-item-wrapper .table-item-zone {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        align-items: center;
        min-height: 76px;
        padding: 20px 25px;
        margin-bottom: 20px;
        background-color: #ffffff;
        border: 1px solid rgba(46, 91, 255, 0.08);
        border-radius: 1px; }
        .investable-table-wrapper .table-item-wrapper .table-item-zone .table-item {
          font-size: 16px;
          font-weight: 500;
          color: #4a4a4a;
          width: 10%;
          box-sizing: border-box;
          text-align: left; }
          .investable-table-wrapper .table-item-wrapper .table-item-zone .table-item a {
            text-decoration: none;
            color: #000000; }
            .investable-table-wrapper .table-item-wrapper .table-item-zone .table-item a:hover {
              text-decoration: underline; }
          .investable-table-wrapper .table-item-wrapper .table-item-zone .table-item:first-child {
            width: 35%;
            padding-right: 20px; }
          .investable-table-wrapper .table-item-wrapper .table-item-zone .table-item:nth-child(2) {
            width: 15%; }
          .investable-table-wrapper .table-item-wrapper .table-item-zone .table-item:nth-child(3) {
            width: 20%; }
          .investable-table-wrapper .table-item-wrapper .table-item-zone .table-item:last-child {
            width: 8%; }
        .investable-table-wrapper .table-item-wrapper .table-item-zone .actions {
          text-align: right;
          font-size: 15px;
          color: #ff6600; }
          .investable-table-wrapper .table-item-wrapper .table-item-zone .actions a {
            color: inherit; }
            .investable-table-wrapper .table-item-wrapper .table-item-zone .actions a .action {
              cursor: pointer; }
              .investable-table-wrapper .table-item-wrapper .table-item-zone .actions a .action:hover {
                text-decoration: underline; }
    .investable-table-wrapper .mobile-table-item-wrapper {
      display: none; }
  .committed-table-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100px;
    text-align: left; }
    .committed-table-wrapper .table-header-zone {
      display: flex;
      flex-direction: row;
      margin-bottom: 10px;
      padding: 0 25px; }
      .committed-table-wrapper .table-header-zone .table-header {
        font-size: 12px;
        font-weight: 600;
        color: #bfc5d2;
        letter-spacing: 1.12px;
        width: 5%; }
        .committed-table-wrapper .table-header-zone .table-header:nth-child(1) {
          width: 28%; }
        .committed-table-wrapper .table-header-zone .table-header:nth-child(2) {
          width: 15%; }
        .committed-table-wrapper .table-header-zone .table-header:nth-child(3) {
          width: 17%; }
        .committed-table-wrapper .table-header-zone .table-header:nth-child(4) {
          width: 15%; }
        .committed-table-wrapper .table-header-zone .table-header:nth-child(5) {
          width: 10%; }
        .committed-table-wrapper .table-header-zone .table-header:nth-child(6) {
          width: 10%; }
    .committed-table-wrapper .mobile-table-header-zone {
      display: none; }
    .committed-table-wrapper .table-item-zone {
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      height: 76px;
      padding: 20px 25px;
      margin-bottom: 10px;
      background-color: #ffffff;
      border: 1px solid rgba(46, 91, 255, 0.08);
      border-radius: 1px; }
      .committed-table-wrapper .table-item-zone .table-item {
        font-size: 16px;
        font-weight: 500;
        color: #4a4a4a;
        width: 5%;
        text-align: left;
        box-sizing: border-box; }
        .committed-table-wrapper .table-item-zone .table-item:nth-child(1) {
          width: 28%; }
        .committed-table-wrapper .table-item-zone .table-item:nth-child(2) {
          width: 15%; }
        .committed-table-wrapper .table-item-zone .table-item:nth-child(3) {
          width: 17%; }
        .committed-table-wrapper .table-item-zone .table-item:nth-child(4) {
          width: 15%; }
        .committed-table-wrapper .table-item-zone .table-item:nth-child(5) {
          width: 10%; }
        .committed-table-wrapper .table-item-zone .table-item:nth-child(6) {
          width: 10%; }
      .committed-table-wrapper .table-item-zone a {
        text-decoration: none;
        color: #0033ff; }
        .committed-table-wrapper .table-item-zone a .action {
          cursor: pointer; }
          .committed-table-wrapper .table-item-zone a .action:hover {
            text-decoration: underline; }
    .committed-table-wrapper .mobile-table-item-zone {
      display: none; } }

@media only screen and (max-width: 768px) {
  .investment-table-header {
    display: none; }
  .general-table-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 150px;
    text-align: left; }
    .general-table-wrapper .table-loading {
      height: 150px;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center; }
  .committed-table-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100px;
    text-align: left; }
    .committed-table-wrapper .table-header-zone {
      display: none; }
    .committed-table-wrapper .mobile-table-header-zone {
      display: flex;
      flex-direction: row;
      margin-bottom: 5px;
      margin-top: 10px;
      box-sizing: border-box;
      padding-left: 10px;
      padding-right: 10px; }
      .committed-table-wrapper .mobile-table-header-zone .table-header {
        font-size: 12px;
        font-weight: 600;
        color: #bfc5d2; }
        .committed-table-wrapper .mobile-table-header-zone .table-header:nth-child(1) {
          width: 35%; }
        .committed-table-wrapper .mobile-table-header-zone .table-header:nth-child(2) {
          width: 20%; }
        .committed-table-wrapper .mobile-table-header-zone .table-header:nth-child(3) {
          width: 20%; }
        .committed-table-wrapper .mobile-table-header-zone .table-header:nth-child(4) {
          width: 15%; }
        .committed-table-wrapper .mobile-table-header-zone .table-header:nth-child(5) {
          width: 5%; }
    .committed-table-wrapper .table-item-zone {
      display: none; }
    .committed-table-wrapper .mobile-table-item-zone {
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      min-height: 76px;
      padding: 10px;
      background-color: #ffffff;
      border: 1px solid rgba(46, 91, 255, 0.08);
      border-radius: 1px;
      margin-bottom: 10px; }
      .committed-table-wrapper .mobile-table-item-zone .table-item {
        font-size: 14px;
        font-weight: 500;
        color: #4a4a4a;
        text-align: left;
        box-sizing: border-box; }
        .committed-table-wrapper .mobile-table-item-zone .table-item:nth-child(1) {
          width: 35%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start; }
          .committed-table-wrapper .mobile-table-item-zone .table-item:nth-child(1) .icon {
            font-size: 14px;
            margin-right: 3px; }
          .committed-table-wrapper .mobile-table-item-zone .table-item:nth-child(1) .title {
            font-weight: 600; }
          .committed-table-wrapper .mobile-table-item-zone .table-item:nth-child(1) .location {
            font-size: 10px; }
        .committed-table-wrapper .mobile-table-item-zone .table-item:nth-child(2) {
          width: 20%; }
        .committed-table-wrapper .mobile-table-item-zone .table-item:nth-child(3) {
          width: 20%; }
        .committed-table-wrapper .mobile-table-item-zone .table-item:nth-child(4) {
          width: 15%; }
        .committed-table-wrapper .mobile-table-item-zone .table-item:nth-child(5) {
          width: 5%; }
      .committed-table-wrapper .mobile-table-item-zone a {
        text-decoration: none;
        color: #0033ff; }
        .committed-table-wrapper .mobile-table-item-zone a .action {
          cursor: pointer; }
          .committed-table-wrapper .mobile-table-item-zone a .action:hover {
            text-decoration: underline; }
  .invested-table-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 150px;
    text-align: left; }
    .invested-table-wrapper .table-loading {
      height: 150px;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center; }
    .invested-table-wrapper .table-error-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
      .invested-table-wrapper .table-error-wrapper .error-content {
        box-sizing: border-box;
        font-size: 24px;
        font-weight: 500;
        text-align: center;
        color: #333333;
        margin-top: 40px; }
    .invested-table-wrapper .table-header-zone {
      display: none; }
    .invested-table-wrapper .mobile-table-header-zone {
      display: flex;
      flex-direction: row;
      margin-bottom: 5px;
      margin-top: 10px;
      box-sizing: border-box;
      padding-left: 10px;
      padding-right: 10px; }
      .invested-table-wrapper .mobile-table-header-zone .table-header {
        font-size: 12px;
        font-weight: 600;
        color: #bfc5d2; }
        .invested-table-wrapper .mobile-table-header-zone .table-header:nth-child(1) {
          width: 35%; }
        .invested-table-wrapper .mobile-table-header-zone .table-header:nth-child(2) {
          width: 20%; }
        .invested-table-wrapper .mobile-table-header-zone .table-header:nth-child(3) {
          width: 20%; }
        .invested-table-wrapper .mobile-table-header-zone .table-header:nth-child(4) {
          width: 15%; }
        .invested-table-wrapper .mobile-table-header-zone .table-header:nth-child(5) {
          width: 5%; }
    .invested-table-wrapper .table-item-zone {
      display: none; }
    .invested-table-wrapper .mobile-table-item-zone {
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      min-height: 76px;
      padding: 10px;
      background-color: #ffffff;
      border: 1px solid rgba(46, 91, 255, 0.08);
      border-radius: 1px;
      margin-bottom: 10px; }
      .invested-table-wrapper .mobile-table-item-zone .table-item {
        font-size: 14px;
        font-weight: 500;
        color: #4a4a4a;
        text-align: left;
        box-sizing: border-box; }
        .invested-table-wrapper .mobile-table-item-zone .table-item:nth-child(1) {
          width: 35%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start; }
          .invested-table-wrapper .mobile-table-item-zone .table-item:nth-child(1) .icon {
            font-size: 14px;
            margin-right: 3px; }
          .invested-table-wrapper .mobile-table-item-zone .table-item:nth-child(1) .title {
            font-weight: 600; }
          .invested-table-wrapper .mobile-table-item-zone .table-item:nth-child(1) .location {
            font-size: 10px; }
        .invested-table-wrapper .mobile-table-item-zone .table-item:nth-child(2) {
          width: 20%; }
        .invested-table-wrapper .mobile-table-item-zone .table-item:nth-child(3) {
          width: 20%; }
        .invested-table-wrapper .mobile-table-item-zone .table-item:nth-child(4) {
          width: 15%; }
        .invested-table-wrapper .mobile-table-item-zone .table-item:nth-child(5) {
          width: 5%; }
      .invested-table-wrapper .mobile-table-item-zone .earnings {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center; }
        .invested-table-wrapper .mobile-table-item-zone .earnings .indicator {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: #ff9933;
          margin-right: 2px; }
        .invested-table-wrapper .mobile-table-item-zone .earnings .active {
          background-color: #66ff66; }
      .invested-table-wrapper .mobile-table-item-zone .actions {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-end;
        text-align: right;
        font-size: 15px;
        color: #2e5bff; }
        .invested-table-wrapper .mobile-table-item-zone .actions:first-child {
          margin-bottom: 10px; }
        .invested-table-wrapper .mobile-table-item-zone .actions a {
          text-decoration: none;
          color: inherit; }
          .invested-table-wrapper .mobile-table-item-zone .actions a .action {
            cursor: pointer; }
            .invested-table-wrapper .mobile-table-item-zone .actions a .action:hover {
              text-decoration: underline; }
    .invested-table-wrapper .table-total-zone {
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      height: 50px;
      padding: 10px;
      margin-top: 10px;
      margin-bottom: 10px;
      background-color: rgba(255, 255, 255, 0.6);
      border: 1px solid rgba(46, 91, 255, 0.08);
      border-radius: 1px; }
      .invested-table-wrapper .table-total-zone .table-total {
        font-size: 18px;
        font-weight: 700;
        color: #4a4a4a;
        text-align: left; }
        .invested-table-wrapper .table-total-zone .table-total:nth-child(1) {
          width: 35%; }
        .invested-table-wrapper .table-total-zone .table-total:nth-child(2) {
          width: 25%; }
        .invested-table-wrapper .table-total-zone .table-total:nth-child(3) {
          display: none; }
        .invested-table-wrapper .table-total-zone .table-total:nth-child(4) {
          width: 20%; }
        .invested-table-wrapper .table-total-zone .table-total:nth-child(5) {
          width: 20%; }
      .invested-table-wrapper .table-total-zone .earnings {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: left; }
        .invested-table-wrapper .table-total-zone .earnings .indicator {
          width: 8px;
          height: 8px;
          border-radius: 50%; }
      .invested-table-wrapper .table-total-zone .actions {
        text-align: right;
        font-size: 15px;
        color: #2e5bff;
        font-weight: 500; }
        .invested-table-wrapper .table-total-zone .actions a {
          text-decoration: none;
          color: inherit; }
          .invested-table-wrapper .table-total-zone .actions a .action {
            cursor: pointer; }
            .invested-table-wrapper .table-total-zone .actions a .action:hover {
              text-decoration: underline; }
  .investable-table-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 200px;
    text-align: left; }
    .investable-table-wrapper .table-error-wrapper {
      height: 200px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
      .investable-table-wrapper .table-error-wrapper .error-content {
        box-sizing: border-box;
        font-size: 24px;
        font-weight: 500;
        text-align: center;
        color: #333333;
        margin-top: 40px; }
    .investable-table-wrapper .table-header-zone {
      display: none; }
    .investable-table-wrapper .mobile-table-header-zone {
      display: flex;
      flex-direction: row;
      margin-bottom: 5px;
      margin-top: 10px;
      box-sizing: border-box;
      padding-left: 10px;
      padding-right: 10px; }
      .investable-table-wrapper .mobile-table-header-zone .table-header {
        font-size: 12px;
        font-weight: 600;
        color: #bfc5d2; }
        .investable-table-wrapper .mobile-table-header-zone .table-header:nth-child(1) {
          width: 35%; }
        .investable-table-wrapper .mobile-table-header-zone .table-header:nth-child(2) {
          width: 20%; }
        .investable-table-wrapper .mobile-table-header-zone .table-header:nth-child(3) {
          width: 20%; }
        .investable-table-wrapper .mobile-table-header-zone .table-header:nth-child(4) {
          width: 15%; }
        .investable-table-wrapper .mobile-table-header-zone .table-header:nth-child(5) {
          width: 5%; }
    .investable-table-wrapper .table-item-wrapper {
      display: none; }
    .investable-table-wrapper .mobile-table-item-wrapper .table-item-zone {
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      min-height: 76px;
      padding: 10px;
      background-color: #ffffff;
      border: 1px solid rgba(46, 91, 255, 0.08);
      border-radius: 1px;
      margin-bottom: 10px; }
      .investable-table-wrapper .mobile-table-item-wrapper .table-item-zone .table-item {
        font-size: 14px;
        font-weight: 500;
        color: #4a4a4a;
        text-align: left;
        box-sizing: border-box; }
        .investable-table-wrapper .mobile-table-item-wrapper .table-item-zone .table-item:nth-child(1) {
          width: 35%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start; }
          .investable-table-wrapper .mobile-table-item-wrapper .table-item-zone .table-item:nth-child(1) .icon {
            font-size: 14px;
            margin-right: 3px; }
          .investable-table-wrapper .mobile-table-item-wrapper .table-item-zone .table-item:nth-child(1) .title {
            font-weight: 600; }
          .investable-table-wrapper .mobile-table-item-wrapper .table-item-zone .table-item:nth-child(1) .location {
            font-size: 10px; }
        .investable-table-wrapper .mobile-table-item-wrapper .table-item-zone .table-item:nth-child(2) {
          width: 20%; }
        .investable-table-wrapper .mobile-table-item-wrapper .table-item-zone .table-item:nth-child(3) {
          width: 20%; }
        .investable-table-wrapper .mobile-table-item-wrapper .table-item-zone .table-item:nth-child(4) {
          width: 15%; }
        .investable-table-wrapper .mobile-table-item-wrapper .table-item-zone .table-item:nth-child(5) {
          width: 5%; }
      .investable-table-wrapper .mobile-table-item-wrapper .table-item-zone .actions {
        text-align: right;
        font-size: 15px;
        color: #ff6600; }
        .investable-table-wrapper .mobile-table-item-wrapper .table-item-zone .actions a {
          color: inherit; }
          .investable-table-wrapper .mobile-table-item-wrapper .table-item-zone .actions a .action {
            cursor: pointer; }
            .investable-table-wrapper .mobile-table-item-wrapper .table-item-zone .actions a .action:hover {
              text-decoration: none; } }

/* Global style guide for UI Lib */
/**
 * Browser support
 */
/**
 * Typography
 */
/**
 * Colors
 */
/**
 * Page -- UILib Specific Variables
 */
/**
 * Card -- UILib Specific Variables
 */
.tag-ui {
  box-sizing: border-box;
  padding: 4px 10px;
  background-color: #eaeaea;
  font-size: 14px;
  font-weight: 500;
  color: #777777;
  border-radius: 3px; }

/* Global style guide for UI Lib */
/**
 * Browser support
 */
/**
 * Typography
 */
/**
 * Colors
 */
/**
 * Page -- UILib Specific Variables
 */
/**
 * Card -- UILib Specific Variables
 */
.slider {
  position: relative;
  width: 100%;
  height: 100%; }
  .slider .back-arrow {
    position: absolute;
    top: 50%;
    left: 10px;
    color: #ffffff;
    cursor: pointer;
    z-index: 100; }
  .slider .next-arrow {
    position: absolute;
    top: 50%;
    right: 10px;
    color: #ffffff;
    cursor: pointer;
    z-index: 100; }
  .slider .preloader {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; }
  .slider .image {
    position: relative;
    width: 100%;
    max-height: 400px;
    object-fit: cover;
    z-index: 10;
    opacity: 0.2;
    -webkit-transition: opacity ease-in 0.1s;
    transition: opacity ease-in 0.1s; }
  .slider .image-loaded {
    opacity: 1; }
  .slider .placeholder {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
    .slider .placeholder img {
      height: 30%; }

/* Global style guide for UI Lib */
/**
 * Browser support
 */
/**
 * Typography
 */
/**
 * Colors
 */
/**
 * Page -- UILib Specific Variables
 */
/**
 * Card -- UILib Specific Variables
 */
@media only screen and (min-width: 769px) {
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 1000; }
  .modal-main {
    position: fixed;
    box-sizing: border-box;
    background: white;
    width: 550px;
    height: 700px;
    top: 50%;
    left: 50%;
    border-radius: 5px;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    padding: 50px 50px 60px 50px; }
  .close-icon {
    position: absolute;
    cursor: pointer;
    top: 15px;
    right: 15px; }
  .display-block {
    display: block; }
  .display-none {
    display: none; } }

@media only screen and (max-width: 768px) {
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.6);
    z-index: 1000; }
  .modal-main {
    position: fixed;
    box-sizing: border-box;
    background: white;
    width: 80vw;
    min-height: 50vh;
    top: 50%;
    left: 50%;
    border-radius: 5px;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    padding: 30px 10px 30px 10px; }
  .close-icon {
    position: absolute;
    cursor: pointer;
    top: 10px;
    right: 10px; }
  .display-block {
    display: block; }
  .display-none {
    display: none; } }

/* Global style guide for UI Lib */
/**
 * Browser support
 */
/**
 * Typography
 */
/**
 * Colors
 */
/**
 * Page -- UILib Specific Variables
 */
/**
 * Card -- UILib Specific Variables
 */
.timeline {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  padding-left: 40px;
  padding-right: 40px; }
  .timeline .event-width-1 {
    width: calc(100% / 1); }
  .timeline .event-width-2 {
    width: calc(100% / 1); }
  .timeline .event-width-3 {
    width: calc(100% / 1); }
  .timeline .event-width-4 {
    width: calc(100% / 1); }
  .timeline .event-width-5 {
    width: calc(100% / 1); }
  .timeline .event-width-6 {
    width: calc(100% / 1); }
  .timeline .event-width-7 {
    width: calc(100% / 1); }
  .timeline .event-width-8 {
    width: calc(100% / 1); }
  .timeline .event-width-9 {
    width: calc(100% / 1); }
  .timeline .event-width-10 {
    width: calc(100% / 1); }
  .timeline .event-width-11 {
    width: calc(100% / 1); }
  .timeline .event-width-12 {
    width: calc(100% / 1); }
  .timeline .event-wrapper {
    box-sizing: border-box;
    position: relative;
    height: 100%;
    z-index: 1; }
    .timeline .event-wrapper .event-circle-past {
      position: absolute;
      top: calc(50% - 9px);
      left: calc(50% - 9px);
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background-color: #0033cc;
      z-index: 100; }
    .timeline .event-wrapper .event-circle-future {
      position: absolute;
      top: calc(50% - 9px);
      left: calc(50% - 9px);
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background-color: #cccccc;
      z-index: 100; }
    .timeline .event-wrapper .event-circle-current-shadow {
      position: absolute;
      top: calc(50% - 21px);
      left: calc(50% - 21px);
      width: 42px;
      height: 42px;
      border-radius: 50%;
      background-color: #6699ff;
      opacity: 0.4; }
    .timeline .event-wrapper .event-circle-sale {
      position: absolute;
      top: calc(50% - 9px);
      left: calc(50% - 9px);
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background-color: #ff6600;
      z-index: 100; }
    .timeline .event-wrapper .event-circle-sale-shadow {
      position: absolute;
      top: calc(50% - 21px);
      left: calc(50% - 21px);
      width: 42px;
      height: 42px;
      border-radius: 50%;
      background-color: #ff9966;
      opacity: 0.4; }
    .timeline .event-wrapper .event-line-past {
      position: absolute;
      top: calc(50% - 0.5px);
      width: 100%;
      height: 1px;
      background-color: #0033cc; }
    .timeline .event-wrapper .event-line-future {
      position: absolute;
      top: calc(50% - 0.5px);
      width: 100%;
      height: 1px;
      background-color: #cccccc; }
    .timeline .event-wrapper .event-text-top {
      position: absolute;
      top: calc(20%);
      width: 100%; }
    .timeline .event-wrapper .event-text-bottom {
      position: absolute;
      bottom: calc(20%);
      width: 100%; }
    .timeline .event-wrapper .event-text-header {
      font-size: 16px;
      line-break: 20px;
      font-weight: 600;
      text-align: left;
      margin-bottom: 5px; }
    .timeline .event-wrapper .event-text-header-past {
      color: #000000; }
    .timeline .event-wrapper .event-text-header-future {
      color: #777777; }
    .timeline .event-wrapper .event-text-header-current {
      color: #0033ff; }
    .timeline .event-wrapper .event-text-header-indicated {
      color: #ff6600; }
    .timeline .event-wrapper .event-text-content {
      font-size: 14px;
      line-break: 20px;
      font-weight: 500;
      text-align: left; }
    .timeline .event-wrapper .event-text-content-past {
      color: #000000; }
    .timeline .event-wrapper .event-text-content-future {
      color: #999999; }

/* Global style guide for UI Lib */
/**
 * Browser support
 */
/**
 * Typography
 */
/**
 * Colors
 */
/**
 * Page -- UILib Specific Variables
 */
/**
 * Card -- UILib Specific Variables
 */
@media only screen and (min-width: 769px) {
  .tab-wrapper .tab-menu {
    position: relative;
    height: 40px;
    top: -1px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start; }
    .tab-wrapper .tab-menu .tab-menu-item {
      box-sizing: border-box;
      padding: 10px 20px;
      font-size: 16px;
      font-weight: 600;
      line-height: 1.25;
      cursor: pointer;
      border-top: 1px solid #eaeaea;
      border-bottom: 1px solid #eaeaea;
      border-right: 1px solid #eaeaea; }
      .tab-wrapper .tab-menu .tab-menu-item:first-child {
        border-top-left-radius: 8px;
        border-left: 1px solid #eaeaea; }
      .tab-wrapper .tab-menu .tab-menu-item:last-child {
        border-top-right-radius: 8px; }
    .tab-wrapper .tab-menu .tab-menu-item-active {
      color: #ffffff;
      background-color: #0033ff;
      border-radius: inherit;
      border-color: #0033ff !important; }
    .tab-wrapper .tab-menu .tab-menu-item-passive {
      color: #000000;
      background-color: #ffffff;
      border-radius: inherit; }
  .tab-wrapper .tab-panel {
    border: 1px solid #eaeaea;
    border-radius: 8px;
    border-top-left-radius: 0px;
    box-sizing: border-box;
    padding: 40px 30px;
    width: 100%;
    min-height: 300px; }
    .tab-wrapper .tab-panel .tab-panel-item {
      font-size: 20px; }
    .tab-wrapper .tab-panel .tab-panel-item-active {
      display: block; }
    .tab-wrapper .tab-panel .tab-panel-item-passive {
      display: none; } }

@media only screen and (max-width: 768px) {
  .tab-wrapper .tab-menu {
    position: relative;
    min-height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    overflow-x: scroll; }
    .tab-wrapper .tab-menu .tab-menu-item {
      box-sizing: border-box;
      text-transform: uppercase;
      margin: 0 10px;
      padding: 15px 0px;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.25;
      cursor: pointer; }
      .tab-wrapper .tab-menu .tab-menu-item:first-child {
        padding-left: 0px; }
      .tab-wrapper .tab-menu .tab-menu-item::last-child {
        padding-right: 0px; }
    .tab-wrapper .tab-menu .tab-menu-item-active {
      color: #0033ff;
      background-color: #ffffff;
      border-radius: inherit;
      border-color: #0033ff !important; }
    .tab-wrapper .tab-menu .tab-menu-item-passive {
      color: #4a4a4a;
      background-color: #ffffff;
      border-radius: inherit; }
  .tab-wrapper .tab-panel {
    box-sizing: border-box;
    padding: 15px 0px 40px 10px;
    width: 100%;
    min-height: 300px; }
    .tab-wrapper .tab-panel .tab-panel-item {
      font-size: 20px; }
    .tab-wrapper .tab-panel .tab-panel-item-active {
      display: block; }
    .tab-wrapper .tab-panel .tab-panel-item-passive {
      display: none; } }

/* Global style guide for UI Lib */
/**
 * Browser support
 */
/**
 * Typography
 */
/**
 * Colors
 */
/**
 * Page -- UILib Specific Variables
 */
/**
 * Card -- UILib Specific Variables
 */
.vertical-bar {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: left; }
  .vertical-bar .bar-label {
    margin-top: 20px;
    text-align: center;
    font-size: 16px;
    font-weight: 500; }

.horizontal-bar {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start; }

/* Global style guide for UI Lib */
/**
 * Browser support
 */
/**
 * Typography
 */
/**
 * Colors
 */
/**
 * Page -- UILib Specific Variables
 */
/**
 * Card -- UILib Specific Variables
 */
@media only screen and (min-width: 769px) {
  .navbar-container {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; }
    .navbar-container .logo {
      height: 40px;
      margin: 20px 0 20px 125px; }
    .navbar-container .navbar-region {
      width: 775px;
      margin: 20px 125px 20px 0;
      text-align: right; }
      .navbar-container .navbar-region .margin-right {
        margin-right: 20px; }
    .navbar-container .mobile-navbar-region {
      display: none; } }

@media only screen and (max-width: 768px) {
  @-webkit-keyframes fade-in-right {
    from {
      opacity: 0.4;
      right: -60vw; }
    to {
      opacity: 1;
      right: 0; } }
  @keyframes fade-in-right {
    from {
      opacity: 0.4;
      right: -60vw; }
    to {
      opacity: 1;
      right: 0; } }
  .navbar-container {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; }
    .navbar-container .logo-wrapper .logo {
      height: 40px;
      margin: 0px 20px; }
    .navbar-container .navbar-region {
      display: none; }
    .navbar-container .mobile-navbar-region {
      margin: 20px 20px 20px 0;
      text-align: right; }
      .navbar-container .mobile-navbar-region .menu-button {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        width: 40px;
        cursor: pointer;
        border: 1px solid #eaeaea;
        border-radius: 4px; }
        .navbar-container .mobile-navbar-region .menu-button .icon {
          margin: 0 auto;
          font-size: 16px;
          line-height: 16px;
          width: 16px;
          color: #4a4a4a; }
      .navbar-container .mobile-navbar-region .entrance {
        -webkit-animation: fade-in-right ease 0.5s forwards;
                animation: fade-in-right ease 0.5s forwards; }
      .navbar-container .mobile-navbar-region .menu-zone {
        height: 100vh;
        width: 62vw;
        box-sizing: border-box;
        padding: 20px 20px;
        background-color: #ffffff;
        box-shadow: -2px 0px 5px 1px #aaaaaa;
        position: fixed;
        z-index: 100000;
        top: 0;
        right: -100vw;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center; }
        .navbar-container .mobile-navbar-region .menu-zone .full-width {
          width: 100%;
          margin-bottom: 20px; }
        .navbar-container .mobile-navbar-region .menu-zone .menu-button {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 40px;
          width: 40px;
          cursor: pointer;
          border: 1px solid #eaeaea;
          border-radius: 4px;
          float: right; }
          .navbar-container .mobile-navbar-region .menu-zone .menu-button .icon {
            margin: 0 auto;
            font-size: 16px;
            line-height: 16px;
            width: 16px;
            color: #4a4a4a; }
      .navbar-container .mobile-navbar-region .display-none {
        display: none; }
      .navbar-container .mobile-navbar-region .display {
        display: inherit; } }

/* Global style guide for UI Lib */
/**
 * Browser support
 */
/**
 * Typography
 */
/**
 * Colors
 */
/**
 * Page -- UILib Specific Variables
 */
/**
 * Card -- UILib Specific Variables
 */
@media only screen and (min-width: 769px) {
  .footer-wrapper {
    width: 100%;
    min-height: 500px;
    background-color: #0033ff; }
    .footer-wrapper .footer-zone {
      width: 1366px;
      height: 100%;
      margin: 0 auto;
      box-sizing: border-box;
      padding: 60px 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center; }
      .footer-wrapper .footer-zone .subheader {
        font-size: 18px;
        font-weight: 600;
        color: #9cf;
        margin-bottom: 10px; }
      .footer-wrapper .footer-zone .header {
        font-size: 36px;
        font-weight: 500;
        color: #fff;
        margin-bottom: 60px; }
      .footer-wrapper .footer-zone .input-zone {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start; }
        .footer-wrapper .footer-zone .input-zone .input {
          width: 300px;
          height: 40px;
          font-size: 18px;
          margin-right: 20px; }
        .footer-wrapper .footer-zone .input-zone .cta {
          height: 40px;
          width: 140px; }
      .footer-wrapper .footer-zone .footer-text {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 120px;
        margin-bottom: 60px; }
        .footer-wrapper .footer-zone .footer-text .footer-left-text {
          text-align: left;
          font-size: 18px;
          font-weight: 500;
          color: #ffffff; }
        .footer-wrapper .footer-zone .footer-text .footer-right-text {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: center; }
          .footer-wrapper .footer-zone .footer-text .footer-right-text .text {
            font-size: 18px;
            font-weight: 500;
            color: #ffffff; }
          .footer-wrapper .footer-zone .footer-text .footer-right-text a {
            text-decoration: none; }
            .footer-wrapper .footer-zone .footer-text .footer-right-text a:first-child {
              margin-right: 20px; }
  .mobile-footer-wrapper {
    display: none; } }

@media only screen and (max-width: 768px) {
  .footer-wrapper {
    display: none; }
  .mobile-footer-wrapper {
    width: 100%;
    min-height: 500px;
    background-color: #0033ff; }
    .mobile-footer-wrapper .footer-zone {
      width: 100%;
      height: 100%;
      margin: 0 auto;
      box-sizing: border-box;
      padding: 40px 20px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center; }
      .mobile-footer-wrapper .footer-zone .subheader {
        font-size: 18px;
        font-weight: 600;
        color: #9cf;
        margin-bottom: 10px; }
      .mobile-footer-wrapper .footer-zone .header {
        font-size: 28px;
        font-weight: 500;
        color: #fff;
        margin-bottom: 60px; }
      .mobile-footer-wrapper .footer-zone .input-zone {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center; }
        .mobile-footer-wrapper .footer-zone .input-zone .input {
          width: 300px;
          height: 40px;
          font-size: 18px; }
        .mobile-footer-wrapper .footer-zone .input-zone .cta {
          height: 40px;
          width: 300px; }
      .mobile-footer-wrapper .footer-zone .footer-text {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: 120px;
        margin-bottom: 60px; }
        .mobile-footer-wrapper .footer-zone .footer-text .footer-left-text {
          text-align: center;
          font-size: 18px;
          font-weight: 500;
          color: #ffffff; } }

/* Global style guide for UI Lib */
/**
 * Browser support
 */
/**
 * Typography
 */
/**
 * Colors
 */
/**
 * Page -- UILib Specific Variables
 */
/**
 * Card -- UILib Specific Variables
 */
@media only screen and (min-width: 769px) {
  .project-listing-border {
    width: 100vw;
    min-width: 1366px;
    height: 1px;
    background-color: #cccccc; }
  .project-listing-page-wrapper {
    box-sizing: border-box;
    width: 1366px;
    margin: 0 auto;
    padding-top: 60px;
    padding-bottom: 60px;
    margin-bottom: 50px; }
    .project-listing-page-wrapper .listing-header-zone .header {
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 1.5px;
      margin-bottom: 10px; }
    .project-listing-page-wrapper .listing-header-zone .subheader {
      font-size: 36px;
      font-weight: 500;
      color: #4a4a4a;
      margin-bottom: 60px; }
    .project-listing-page-wrapper .listing-header-zone .short-informations-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-bottom: 60px; }
      .project-listing-page-wrapper .listing-header-zone .short-informations-wrapper .short-information {
        box-sizing: border-box;
        padding-left: 30px;
        padding-right: 30px;
        border-right: 1px solid #cccccc; }
        .project-listing-page-wrapper .listing-header-zone .short-informations-wrapper .short-information:last-child {
          border-right: none; }
        .project-listing-page-wrapper .listing-header-zone .short-informations-wrapper .short-information .short-header {
          font-size: 16px;
          line-height: 20px;
          color: #999999;
          font-weight: 500;
          text-align: center;
          margin: 0; }
        .project-listing-page-wrapper .listing-header-zone .short-informations-wrapper .short-information .short-subheader {
          font-size: 16px;
          line-height: 20px;
          color: #000000;
          font-weight: 600;
          text-align: center;
          margin: 0; }
    .project-listing-page-wrapper .filter-section-zone {
      width: 1100px;
      margin: 0 auto;
      box-sizing: border-box;
      padding-bottom: 40px;
      border-bottom: 1px solid #cccccc; }
      .project-listing-page-wrapper .filter-section-zone .general-filter-area {
        box-sizing: border-box;
        width: 1100px;
        margin: 0 auto;
        padding: 0 50px 20px 50px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center; }
        .project-listing-page-wrapper .filter-section-zone .general-filter-area .general-filters {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center; }
          .project-listing-page-wrapper .filter-section-zone .general-filter-area .general-filters .filter-buttons {
            margin-right: 10px;
            padding-left: 12px;
            padding-right: 12px; }
            .project-listing-page-wrapper .filter-section-zone .general-filter-area .general-filters .filter-buttons:last-child {
              margin-right: 0; }
        .project-listing-page-wrapper .filter-section-zone .general-filter-area .more-filter-cta {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: center; }
      .project-listing-page-wrapper .filter-section-zone .more-filter-area {
        box-sizing: border-box;
        width: 1100px;
        margin: 0 auto;
        padding: 0 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center; }
        .project-listing-page-wrapper .filter-section-zone .more-filter-area .single-filter-zone {
          display: flex;
          flex-direction: column; }
          .project-listing-page-wrapper .filter-section-zone .more-filter-area .single-filter-zone .filter-header {
            width: 100%;
            font-size: 18px;
            line-height: 26px;
            color: #777777;
            text-align: left;
            margin-bottom: 10px; }
          .project-listing-page-wrapper .filter-section-zone .more-filter-area .single-filter-zone .filter-selectors {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center; }
            .project-listing-page-wrapper .filter-section-zone .more-filter-area .single-filter-zone .filter-selectors .filter-buttons {
              margin-right: 10px;
              padding-left: 12px;
              padding-right: 12px; }
              .project-listing-page-wrapper .filter-section-zone .more-filter-area .single-filter-zone .filter-selectors .filter-buttons:last-child {
                margin-right: 0; }
            .project-listing-page-wrapper .filter-section-zone .more-filter-area .single-filter-zone .filter-selectors .location-dropdown {
              width: 250px; }
              .project-listing-page-wrapper .filter-section-zone .more-filter-area .single-filter-zone .filter-selectors .location-dropdown span {
                font-size: 18px;
                line-height: 20px;
                color: #777777;
                width: 80%;
                text-align: left;
                padding-left: 20px; }
              .project-listing-page-wrapper .filter-section-zone .more-filter-area .single-filter-zone .filter-selectors .location-dropdown i {
                width: 20%;
                color: #777777; }
    .project-listing-page-wrapper .no-border {
      border-bottom: none !important; }
    .project-listing-page-wrapper .project-card-zone {
      width: 1100px;
      height: 260px;
      margin: 0 auto;
      box-sizing: border-box;
      padding: 30px 0px;
      border-bottom: 1px solid #cccccc;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center; }
      .project-listing-page-wrapper .project-card-zone .no-project-found {
        width: 100%;
        font-size: 24px;
        line-height: 32px;
        color: #666666;
        text-align: center; }
      .project-listing-page-wrapper .project-card-zone .project-image-wrapper {
        width: 25%;
        height: 100%;
        position: relative;
        margin-right: 35px; }
        .project-listing-page-wrapper .project-card-zone .project-image-wrapper .project-image {
          position: relative;
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          z-index: 10; }
        .project-listing-page-wrapper .project-card-zone .project-image-wrapper .project-tag-zone {
          position: absolute;
          top: 5px;
          left: -5px;
          z-index: 100; }
          .project-listing-page-wrapper .project-card-zone .project-image-wrapper .project-tag-zone .project-tag {
            box-sizing: border-box;
            padding: 5px 13px;
            color: #ffffff;
            margin-bottom: 2px;
            border-radius: 3px;
            font-size: 14px;
            font-weight: 500; }
            .project-listing-page-wrapper .project-card-zone .project-image-wrapper .project-tag-zone .project-tag-blue-0 {
              background-color: #99ccff; }
            .project-listing-page-wrapper .project-card-zone .project-image-wrapper .project-tag-zone .project-tag-blue-1 {
              background-color: #6699ff; }
            .project-listing-page-wrapper .project-card-zone .project-image-wrapper .project-tag-zone .project-tag-blue-2 {
              background-color: #3366ff; }
            .project-listing-page-wrapper .project-card-zone .project-image-wrapper .project-tag-zone .project-tag-blue-3 {
              background-color: #0033ff; }
            .project-listing-page-wrapper .project-card-zone .project-image-wrapper .project-tag-zone .project-tag-blue-4 {
              background-color: #0033cc; }
            .project-listing-page-wrapper .project-card-zone .project-image-wrapper .project-tag-zone .project-tag-blue-5 {
              background-color: #003399; }
            .project-listing-page-wrapper .project-card-zone .project-image-wrapper .project-tag-zone .project-tag-blue-6 {
              background-color: #003366; }
            .project-listing-page-wrapper .project-card-zone .project-image-wrapper .project-tag-zone .project-tag-green-0 {
              background-color: #99ff99; }
            .project-listing-page-wrapper .project-card-zone .project-image-wrapper .project-tag-zone .project-tag-green-1 {
              background-color: #66ff66; }
            .project-listing-page-wrapper .project-card-zone .project-image-wrapper .project-tag-zone .project-tag-green-2 {
              background-color: #66ff33; }
            .project-listing-page-wrapper .project-card-zone .project-image-wrapper .project-tag-zone .project-tag-green-3 {
              background-color: #33cb00; }
            .project-listing-page-wrapper .project-card-zone .project-image-wrapper .project-tag-zone .project-tag-green-4 {
              background-color: #33cc00; }
            .project-listing-page-wrapper .project-card-zone .project-image-wrapper .project-tag-zone .project-tag-green-5 {
              background-color: #339900; }
            .project-listing-page-wrapper .project-card-zone .project-image-wrapper .project-tag-zone .project-tag-green-6 {
              background-color: #336600; }
            .project-listing-page-wrapper .project-card-zone .project-image-wrapper .project-tag-zone .project-tag-orange-0 {
              background-color: #ffcc99; }
            .project-listing-page-wrapper .project-card-zone .project-image-wrapper .project-tag-zone .project-tag-orange-1 {
              background-color: #ff9966; }
            .project-listing-page-wrapper .project-card-zone .project-image-wrapper .project-tag-zone .project-tag-orange-2 {
              background-color: #ff9933; }
            .project-listing-page-wrapper .project-card-zone .project-image-wrapper .project-tag-zone .project-tag-orange-3 {
              background-color: #ff6600; }
            .project-listing-page-wrapper .project-card-zone .project-image-wrapper .project-tag-zone .project-tag-orange-4 {
              background-color: #cc662b; }
            .project-listing-page-wrapper .project-card-zone .project-image-wrapper .project-tag-zone .project-tag-orange-5 {
              background-color: #993300; }
            .project-listing-page-wrapper .project-card-zone .project-image-wrapper .project-tag-zone .project-tag-orange-6 {
              background-color: #663300; }
            .project-listing-page-wrapper .project-card-zone .project-image-wrapper .project-tag-zone .project-tag-grey-0 {
              background-color: #fafafa; }
            .project-listing-page-wrapper .project-card-zone .project-image-wrapper .project-tag-zone .project-tag-grey-1 {
              background-color: #eaeaea; }
            .project-listing-page-wrapper .project-card-zone .project-image-wrapper .project-tag-zone .project-tag-grey-2 {
              background-color: #cccccc; }
            .project-listing-page-wrapper .project-card-zone .project-image-wrapper .project-tag-zone .project-tag-grey-3 {
              background-color: #999999; }
            .project-listing-page-wrapper .project-card-zone .project-image-wrapper .project-tag-zone .project-tag-grey-4 {
              background-color: #777777; }
            .project-listing-page-wrapper .project-card-zone .project-image-wrapper .project-tag-zone .project-tag-grey-5 {
              background-color: #666666; }
            .project-listing-page-wrapper .project-card-zone .project-image-wrapper .project-tag-zone .project-tag-grey-6 {
              background-color: #333333; }
            .project-listing-page-wrapper .project-card-zone .project-image-wrapper .project-tag-zone .project-tag-white {
              background-color: #ffffff; }
            .project-listing-page-wrapper .project-card-zone .project-image-wrapper .project-tag-zone .project-tag-black {
              background-color: #000000; }
            .project-listing-page-wrapper .project-card-zone .project-image-wrapper .project-tag-zone .project-tag-light-grey {
              background-color: #4a4a4a; }
            .project-listing-page-wrapper .project-card-zone .project-image-wrapper .project-tag-zone .project-tag-light-pink {
              background-color: #fef0ef; }
            .project-listing-page-wrapper .project-card-zone .project-image-wrapper .project-tag-zone .project-tag-pink {
              background-color: #fabeb9; }
      .project-listing-page-wrapper .project-card-zone .project-information-wrapper {
        min-height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start; }
        .project-listing-page-wrapper .project-card-zone .project-information-wrapper .header-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start; }
          .project-listing-page-wrapper .project-card-zone .project-information-wrapper .header-wrapper a {
            text-decoration: none; }
            .project-listing-page-wrapper .project-card-zone .project-information-wrapper .header-wrapper a .header {
              font-size: 30px;
              font-weight: 700;
              line-height: 38px;
              color: #333333; }
              .project-listing-page-wrapper .project-card-zone .project-information-wrapper .header-wrapper a .header:hover {
                color: #ff6600;
                -webkit-transition: all ease-in-out 0.2s;
                transition: all ease-in-out 0.2s; }
          .project-listing-page-wrapper .project-card-zone .project-information-wrapper .header-wrapper .subheader {
            font-size: 20px;
            font-weight: 500;
            line-height: 20px;
            color: #4a4a4a; }
        .project-listing-page-wrapper .project-card-zone .project-information-wrapper .information-wrapper {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: flex-start; }
          .project-listing-page-wrapper .project-card-zone .project-information-wrapper .information-wrapper .information {
            margin-right: 20px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start; }
            .project-listing-page-wrapper .project-card-zone .project-information-wrapper .information-wrapper .information:last-child {
              margin-right: none; }
            .project-listing-page-wrapper .project-card-zone .project-information-wrapper .information-wrapper .information .header {
              font-size: 16px;
              line-height: 20px;
              font-weight: 500;
              color: #666666;
              text-align: left; }
            .project-listing-page-wrapper .project-card-zone .project-information-wrapper .information-wrapper .information .subheader {
              font-size: 18px;
              line-height: 20px;
              font-weight: 600;
              color: #000000;
              text-align: left; }
            .project-listing-page-wrapper .project-card-zone .project-information-wrapper .information-wrapper .information .return {
              color: #ff6600; }
      .project-listing-page-wrapper .project-card-zone .project-action-wrapper {
        min-height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        margin-left: auto; }
        .project-listing-page-wrapper .project-card-zone .project-action-wrapper .header-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-end; }
          .project-listing-page-wrapper .project-card-zone .project-action-wrapper .header-wrapper .header {
            font-size: 20px;
            font-weight: 500;
            line-height: 26px;
            color: #4a4a4a; }
          .project-listing-page-wrapper .project-card-zone .project-action-wrapper .header-wrapper .contribution {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            font-size: 36px; }
            .project-listing-page-wrapper .project-card-zone .project-action-wrapper .header-wrapper .contribution .icon {
              margin-right: 8px; }
            .project-listing-page-wrapper .project-card-zone .project-action-wrapper .header-wrapper .contribution .amount {
              font-weight: 600;
              line-height: 45px;
              color: #000000; }
          .project-listing-page-wrapper .project-card-zone .project-action-wrapper .header-wrapper .statistics {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center; }
            .project-listing-page-wrapper .project-card-zone .project-action-wrapper .header-wrapper .statistics .information-zone {
              margin-right: 30px;
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: flex-start; }
              .project-listing-page-wrapper .project-card-zone .project-action-wrapper .header-wrapper .statistics .information-zone:last-child {
                margin-right: 0;
                align-items: flex-end; }
              .project-listing-page-wrapper .project-card-zone .project-action-wrapper .header-wrapper .statistics .information-zone .header {
                font-size: 14px;
                line-height: 20px;
                color: #333333;
                font-weight: 500; }
              .project-listing-page-wrapper .project-card-zone .project-action-wrapper .header-wrapper .statistics .information-zone .subheader {
                font-size: 14px;
                line-height: 20px;
                color: #000000;
                font-weight: 500; }
          .project-listing-page-wrapper .project-card-zone .project-action-wrapper .header-wrapper .start-date-header {
            font-size: 16px;
            line-height: 26px;
            font-weight: 500;
            color: #4a4a4a; }
          .project-listing-page-wrapper .project-card-zone .project-action-wrapper .header-wrapper .start-date-subheader {
            font-size: 20px;
            font-weight: 500;
            color: #000000; }
        .project-listing-page-wrapper .project-card-zone .project-action-wrapper .action-wrapper {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: center; }
          .project-listing-page-wrapper .project-card-zone .project-action-wrapper .action-wrapper .remaining-days {
            box-sizing: border-box;
            width: 40%;
            font-size: 18px;
            line-height: 20px;
            font-weight: 500;
            color: #333333;
            text-align: right;
            padding-right: 13px; }
    .project-listing-page-wrapper .mobile-project-card-zone {
      display: none; }
    .project-listing-page-wrapper .load-more-zone {
      width: 1100px;
      margin: 60px auto;
      display: flex;
      flex-direction: row;
      justify-content: center; }
      .project-listing-page-wrapper .load-more-zone .load-more-button {
        height: 50px; } }

@media only screen and (max-width: 768px) {
  .project-listing-border {
    width: 100vw;
    height: 1px;
    background-color: #cccccc; }
  .project-listing-page-wrapper {
    box-sizing: border-box;
    width: 100vw;
    margin: 0 auto;
    padding: 20px;
    padding-top: 0px;
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center; }
    .project-listing-page-wrapper .listing-header-zone {
      display: none; }
    .project-listing-page-wrapper .filter-section-zone {
      display: none; }
    .project-listing-page-wrapper .no-border {
      border-bottom: none !important; }
    .project-listing-page-wrapper .project-card-zone {
      display: none; }
    .project-listing-page-wrapper .mobile-project-card-zone {
      width: 100%;
      margin: 0 auto;
      box-sizing: border-box;
      padding-bottom: 30px;
      padding-top: 30px;
      border-bottom: 1px solid #cccccc;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start; }
      .project-listing-page-wrapper .mobile-project-card-zone:last-child {
        border-bottom: none; }
      .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start; }
        .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .header-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          margin-bottom: 15px; }
          .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .header-wrapper a {
            text-decoration: none; }
            .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .header-wrapper a .header {
              font-size: 24px;
              font-weight: 700;
              line-height: 30px;
              color: #333333; }
              .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .header-wrapper a .header:hover {
                color: #ff6600;
                -webkit-transition: all ease-in-out 0.2s;
                transition: all ease-in-out 0.2s; }
          .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .header-wrapper .subheader {
            font-size: 16px;
            font-weight: 500;
            line-height: 20px;
            color: #4a4a4a; }
        .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .information-wrapper {
          display: flex;
          flex-direction: row;
          width: calc(100vw - 40px);
          justify-content: space-between;
          align-items: flex-start;
          margin-bottom: 15px; }
          .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .information-wrapper .information {
            padding-right: 10px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start; }
            .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .information-wrapper .information:last-child {
              padding-right: none; }
            .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .information-wrapper .information .header {
              font-size: 14px;
              line-height: 20px;
              font-weight: 500;
              color: #666666;
              text-align: left; }
            .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .information-wrapper .information .subheader {
              font-size: 14px;
              line-height: 20px;
              font-weight: 600;
              color: #000000;
              text-align: left; }
            .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .information-wrapper .information .return {
              color: #ff6600; }
          .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .information-wrapper .first-column {
            width: 30% !important; }
          .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .information-wrapper .center-column {
            width: 50% !important; }
          .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .information-wrapper .last-column {
            width: 20% !important; }
        .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .project-image-wrapper {
          width: 100%;
          min-height: 200px;
          position: relative;
          margin-bottom: 15px; }
          .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .project-image-wrapper .project-image {
            position: relative;
            width: 100%;
            min-height: 200px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            z-index: 10; }
          .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .project-image-wrapper .project-tag-zone {
            position: absolute;
            top: 5px;
            left: -5px;
            z-index: 100; }
            .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .project-image-wrapper .project-tag-zone .project-tag {
              box-sizing: border-box;
              padding: 5px 13px;
              color: #ffffff;
              margin-bottom: 2px;
              border-radius: 3px;
              font-size: 14px;
              font-weight: 500; }
              .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .project-image-wrapper .project-tag-zone .project-tag-blue-0 {
                background-color: #99ccff; }
              .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .project-image-wrapper .project-tag-zone .project-tag-blue-1 {
                background-color: #6699ff; }
              .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .project-image-wrapper .project-tag-zone .project-tag-blue-2 {
                background-color: #3366ff; }
              .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .project-image-wrapper .project-tag-zone .project-tag-blue-3 {
                background-color: #0033ff; }
              .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .project-image-wrapper .project-tag-zone .project-tag-blue-4 {
                background-color: #0033cc; }
              .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .project-image-wrapper .project-tag-zone .project-tag-blue-5 {
                background-color: #003399; }
              .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .project-image-wrapper .project-tag-zone .project-tag-blue-6 {
                background-color: #003366; }
              .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .project-image-wrapper .project-tag-zone .project-tag-green-0 {
                background-color: #99ff99; }
              .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .project-image-wrapper .project-tag-zone .project-tag-green-1 {
                background-color: #66ff66; }
              .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .project-image-wrapper .project-tag-zone .project-tag-green-2 {
                background-color: #66ff33; }
              .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .project-image-wrapper .project-tag-zone .project-tag-green-3 {
                background-color: #33cb00; }
              .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .project-image-wrapper .project-tag-zone .project-tag-green-4 {
                background-color: #33cc00; }
              .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .project-image-wrapper .project-tag-zone .project-tag-green-5 {
                background-color: #339900; }
              .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .project-image-wrapper .project-tag-zone .project-tag-green-6 {
                background-color: #336600; }
              .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .project-image-wrapper .project-tag-zone .project-tag-orange-0 {
                background-color: #ffcc99; }
              .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .project-image-wrapper .project-tag-zone .project-tag-orange-1 {
                background-color: #ff9966; }
              .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .project-image-wrapper .project-tag-zone .project-tag-orange-2 {
                background-color: #ff9933; }
              .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .project-image-wrapper .project-tag-zone .project-tag-orange-3 {
                background-color: #ff6600; }
              .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .project-image-wrapper .project-tag-zone .project-tag-orange-4 {
                background-color: #cc662b; }
              .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .project-image-wrapper .project-tag-zone .project-tag-orange-5 {
                background-color: #993300; }
              .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .project-image-wrapper .project-tag-zone .project-tag-orange-6 {
                background-color: #663300; }
              .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .project-image-wrapper .project-tag-zone .project-tag-grey-0 {
                background-color: #fafafa; }
              .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .project-image-wrapper .project-tag-zone .project-tag-grey-1 {
                background-color: #eaeaea; }
              .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .project-image-wrapper .project-tag-zone .project-tag-grey-2 {
                background-color: #cccccc; }
              .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .project-image-wrapper .project-tag-zone .project-tag-grey-3 {
                background-color: #999999; }
              .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .project-image-wrapper .project-tag-zone .project-tag-grey-4 {
                background-color: #777777; }
              .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .project-image-wrapper .project-tag-zone .project-tag-grey-5 {
                background-color: #666666; }
              .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .project-image-wrapper .project-tag-zone .project-tag-grey-6 {
                background-color: #333333; }
              .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .project-image-wrapper .project-tag-zone .project-tag-white {
                background-color: #ffffff; }
              .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .project-image-wrapper .project-tag-zone .project-tag-black {
                background-color: #000000; }
              .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .project-image-wrapper .project-tag-zone .project-tag-light-grey {
                background-color: #4a4a4a; }
              .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .project-image-wrapper .project-tag-zone .project-tag-light-pink {
                background-color: #fef0ef; }
              .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .project-image-wrapper .project-tag-zone .project-tag-pink {
                background-color: #fabeb9; }
        .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .project-action-wrapper {
          display: flex;
          flex-direction: row;
          width: calc(100vw - 40px);
          justify-content: space-between;
          align-items: flex-start; }
          .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .project-action-wrapper .information {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start; }
            .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .project-action-wrapper .information .header {
              font-size: 14px;
              line-height: 20px;
              font-weight: 500;
              color: #666666;
              text-align: left; }
            .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .project-action-wrapper .information .subheader {
              font-size: 14px;
              line-height: 20px;
              font-weight: 600;
              color: #000000;
              text-align: left; }
            .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .project-action-wrapper .information .return {
              color: #ff6600;
              font-size: 18px; }
            .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .project-action-wrapper .information .amount {
              font-size: 18px;
              color: #000000;
              font-weight: 600; }
    .project-listing-page-wrapper .load-more-zone {
      width: 100%;
      margin: 30px auto;
      display: flex;
      flex-direction: row;
      justify-content: center; }
      .project-listing-page-wrapper .load-more-zone .load-more-button {
        height: 50px; } }

/* Global style guide for UI Lib */
/**
 * Browser support
 */
/**
 * Typography
 */
/**
 * Colors
 */
/**
 * Page -- UILib Specific Variables
 */
/**
 * Card -- UILib Specific Variables
 */
@media only screen and (min-width: 769px) {
  .project-detail-border {
    width: 100vw;
    min-width: 1366px;
    height: 1px;
    background-color: #cccccc; }
  .hanging-nav-bar-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    height: 80px;
    width: 100%;
    background-color: #ffffff;
    border-bottom: 1px solid #eaeaea;
    z-index: 10000; }
    .hanging-nav-bar-wrapper .nav-bar-container {
      box-sizing: border-box;
      width: 1366px;
      height: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center; }
      .hanging-nav-bar-wrapper .nav-bar-container .nav-bar-header {
        font-size: 24px;
        font-weight: 700;
        color: #4a4a4a;
        cursor: pointer; }
      .hanging-nav-bar-wrapper .nav-bar-container .nav-bar-right-pane {
        box-sizing: border-box;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center; }
        .hanging-nav-bar-wrapper .nav-bar-container .nav-bar-right-pane .section-wrapper {
          height: 100%; }
          .hanging-nav-bar-wrapper .nav-bar-container .nav-bar-right-pane .section-wrapper ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
            height: 100%;
            display: flex;
            flex-direction: row;
            align-items: center; }
            .hanging-nav-bar-wrapper .nav-bar-container .nav-bar-right-pane .section-wrapper ul li {
              box-sizing: border-box;
              display: block;
              cursor: pointer;
              padding: 10px 15px;
              font-size: 16px;
              line-height: 26px;
              font-weight: 600;
              color: #4a4a4a; }
            .hanging-nav-bar-wrapper .nav-bar-container .nav-bar-right-pane .section-wrapper ul .active {
              color: #0033ff; }
        .hanging-nav-bar-wrapper .nav-bar-container .nav-bar-right-pane .statistics-wrapper {
          box-sizing: border-box;
          height: 100%;
          padding: 14px 30px;
          display: flex;
          flex-direction: row;
          align-items: center; }
          .hanging-nav-bar-wrapper .nav-bar-container .nav-bar-right-pane .statistics-wrapper .statistics-item {
            box-sizing: border-box;
            padding: 0 20px;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center; }
            .hanging-nav-bar-wrapper .nav-bar-container .nav-bar-right-pane .statistics-wrapper .statistics-item:first-child {
              border-right: 1px solid #eaeaea; }
            .hanging-nav-bar-wrapper .nav-bar-container .nav-bar-right-pane .statistics-wrapper .statistics-item .header {
              font-size: 20px;
              line-height: 20px;
              margin: 0;
              font-weight: 700; }
            .hanging-nav-bar-wrapper .nav-bar-container .nav-bar-right-pane .statistics-wrapper .statistics-item .subheader {
              font-size: 14px;
              line-height: 14px;
              margin: 0;
              font-weight: 500; }
        .hanging-nav-bar-wrapper .nav-bar-container .nav-bar-right-pane .buy-action {
          cursor: pointer;
          top: -0.5px;
          height: calc(100% + 1px);
          width: 200px;
          background-color: #33cc00;
          color: white;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          font-size: 16px;
          line-height: 26px;
          font-weight: 600; }
  .project-detail-page-wrapper {
    box-sizing: border-box;
    width: 1366px;
    margin: 0 auto;
    padding-top: 60px;
    padding-bottom: 60px; }
    .project-detail-page-wrapper .buy-shares {
      display: none; }
    .project-detail-page-wrapper .header-zone {
      width: 1180px;
      margin: 0 auto 80px auto;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center; }
      .project-detail-page-wrapper .header-zone .left-pane .tag-zone {
        display: flex;
        flex-direction: row;
        margin-bottom: 10px; }
        .project-detail-page-wrapper .header-zone .left-pane .tag-zone .tag {
          margin-right: 10px; }
      .project-detail-page-wrapper .header-zone .left-pane .plant-header {
        font-size: 44px;
        font-weight: 700; }
      .project-detail-page-wrapper .header-zone .left-pane .city {
        display: none; }
      .project-detail-page-wrapper .header-zone .left-pane .plant-owner {
        font-size: 16px;
        font-weight: 600; }
      .project-detail-page-wrapper .header-zone .left-pane .project-count {
        font-size: 16px;
        font-weight: 500; }
      .project-detail-page-wrapper .header-zone .right-pane .project-statistics {
        border: 1px solid #eaeaea;
        border-radius: 8px;
        padding: 15px 0;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        align-items: center; }
        .project-detail-page-wrapper .header-zone .right-pane .project-statistics .viewer-zone {
          padding: 0 30px;
          border-right: 1px solid #eaeaea; }
          .project-detail-page-wrapper .header-zone .right-pane .project-statistics .viewer-zone .header {
            font-size: 24px;
            line-height: 1;
            font-weight: 700; }
          .project-detail-page-wrapper .header-zone .right-pane .project-statistics .viewer-zone .subheader {
            font-size: 16px;
            font-weight: 500; }
        .project-detail-page-wrapper .header-zone .right-pane .project-statistics .backer-zone {
          padding: 0 30px; }
          .project-detail-page-wrapper .header-zone .right-pane .project-statistics .backer-zone .header {
            font-size: 24px;
            line-height: 1;
            font-weight: 700; }
          .project-detail-page-wrapper .header-zone .right-pane .project-statistics .backer-zone .subheader {
            font-size: 16px;
            font-weight: 500; }
    .project-detail-page-wrapper .hero-zone {
      position: relative;
      width: 1180px;
      margin: 0 auto 60px auto; }
      .project-detail-page-wrapper .hero-zone .highlight {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        box-sizing: border-box;
        position: absolute;
        top: -40px;
        left: 0;
        height: 60px;
        background-color: #0033ff;
        border-radius: 8px;
        z-index: 1; }
        .project-detail-page-wrapper .hero-zone .highlight .icon {
          font-size: 12px;
          margin: 15px 10px auto 15px; }
        .project-detail-page-wrapper .hero-zone .highlight .text {
          font-size: 16px;
          margin: 10px 50px auto 0px; }
      .project-detail-page-wrapper .hero-zone .hero-wrapper {
        box-sizing: border-box;
        position: relative;
        z-index: 10;
        width: 100%;
        height: 400px;
        background-color: #ffffff;
        border: 1px solid #eaeaea;
        border-radius: 8px;
        display: flex;
        flex-direction: row;
        align-items: flex-start; }
        .project-detail-page-wrapper .hero-zone .hero-wrapper .general-information {
          width: 15%;
          height: 100%;
          padding-right: 15px;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          align-items: flex-start; }
          .project-detail-page-wrapper .hero-zone .hero-wrapper .general-information .information {
            box-sizing: border-box;
            width: 100%;
            padding-left: 20px;
            padding-bottom: 15px;
            border-bottom: 1px solid #eaeaea; }
            .project-detail-page-wrapper .hero-zone .hero-wrapper .general-information .information:last-child {
              border-bottom: none; }
            .project-detail-page-wrapper .hero-zone .hero-wrapper .general-information .information .header {
              font-size: 14px;
              font-weight: 500;
              color: #999999; }
            .project-detail-page-wrapper .hero-zone .hero-wrapper .general-information .information .subheader {
              font-size: 16px;
              font-weight: 600;
              color: #000000; }
        .project-detail-page-wrapper .hero-zone .hero-wrapper .project-images {
          position: relative;
          width: 50%;
          height: 100%; }
          .project-detail-page-wrapper .hero-zone .hero-wrapper .project-images .image {
            position: relative;
            top: -0.5px;
            width: 100%;
            height: calc(100% + 1px);
            object-fit: cover; }
        .project-detail-page-wrapper .hero-zone .hero-wrapper .project-information {
          width: 35%;
          height: 100%;
          padding-left: 30px;
          padding-right: 30px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start; }
          .project-detail-page-wrapper .hero-zone .hero-wrapper .project-information .information-zone {
            width: 100%;
            display: flex;
            flex-direction: column; }
            .project-detail-page-wrapper .hero-zone .hero-wrapper .project-information .information-zone .information-line {
              width: 100%;
              border-bottom: 1px solid #eaeaea;
              padding: 20px 0 20px 0; }
              .project-detail-page-wrapper .hero-zone .hero-wrapper .project-information .information-zone .information-line:last-child {
                border-bottom: none; }
              .project-detail-page-wrapper .hero-zone .hero-wrapper .project-information .information-zone .information-line .header {
                font-size: 16px;
                font-weight: 500;
                color: #999999; }
              .project-detail-page-wrapper .hero-zone .hero-wrapper .project-information .information-zone .information-line .subheader {
                font-size: 24px;
                font-weight: 600;
                color: #000000; }
              .project-detail-page-wrapper .hero-zone .hero-wrapper .project-information .information-zone .information-line .extended-subheader {
                font-size: 30px; }
            .project-detail-page-wrapper .hero-zone .hero-wrapper .project-information .information-zone .flex-information-line {
              display: flex;
              flex-direction: row;
              align-items: flex-start; }
              .project-detail-page-wrapper .hero-zone .hero-wrapper .project-information .information-zone .flex-information-line .information {
                width: 60%; }
                .project-detail-page-wrapper .hero-zone .hero-wrapper .project-information .information-zone .flex-information-line .information:last-child {
                  width: 40%; }
          .project-detail-page-wrapper .hero-zone .hero-wrapper .project-information .cta-zone {
            width: 100%;
            display: flex;
            flex-direction: column;
            margin-bottom: 25px; }
            .project-detail-page-wrapper .hero-zone .hero-wrapper .project-information .cta-zone .button {
              height: 40px;
              font-size: 16px;
              font-weight: 600;
              line-height: 20px; }
            .project-detail-page-wrapper .hero-zone .hero-wrapper .project-information .cta-zone .info {
              font-size: 14px;
              line-height: 1.45;
              margin-top: 5px; }
    .project-detail-page-wrapper .buy-shares-zone {
      width: 1180px;
      height: 170px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      color: #ffffff; }
      .project-detail-page-wrapper .buy-shares-zone .action-zone {
        box-sizing: border-box;
        width: 100%;
        height: 130px;
        background-color: #0033ff;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        padding: 40px 60px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center; }
        .project-detail-page-wrapper .buy-shares-zone .action-zone .roi-zone {
          display: flex;
          width: 85%;
          flex-direction: row;
          align-items: center; }
          .project-detail-page-wrapper .buy-shares-zone .action-zone .roi-zone .input-zone {
            position: relative; }
            .project-detail-page-wrapper .buy-shares-zone .action-zone .roi-zone .input-zone input {
              box-sizing: border-box;
              outline: none;
              padding: 10px;
              width: 300px;
              min-width: 300px;
              height: 50px;
              border: none;
              border-radius: 4px;
              font-size: 24px;
              font-weight: 500;
              margin-right: 40px; }
              .project-detail-page-wrapper .buy-shares-zone .action-zone .roi-zone .input-zone input::-webkit-input-placeholder {
                color: #d2d2d2; }
              .project-detail-page-wrapper .buy-shares-zone .action-zone .roi-zone .input-zone input::-moz-placeholder {
                color: #d2d2d2; }
              .project-detail-page-wrapper .buy-shares-zone .action-zone .roi-zone .input-zone input::-ms-input-placeholder {
                color: #d2d2d2; }
              .project-detail-page-wrapper .buy-shares-zone .action-zone .roi-zone .input-zone input::placeholder {
                color: #d2d2d2; }
            .project-detail-page-wrapper .buy-shares-zone .action-zone .roi-zone .input-zone input:placeholder-shown + label {
              display: none; }
            .project-detail-page-wrapper .buy-shares-zone .action-zone .roi-zone .input-zone input:placeholder-shown {
              padding-left: 10px; }
            .project-detail-page-wrapper .buy-shares-zone .action-zone .roi-zone .input-zone input:not(placeholder-shown) {
              padding-left: 30px; }
            .project-detail-page-wrapper .buy-shares-zone .action-zone .roi-zone .input-zone input:not(placeholder-shown) + label {
              position: absolute;
              top: 12px;
              left: 10px;
              font-size: 24px;
              color: #000000;
              font-weight: 500;
              z-index: 100; }
            .project-detail-page-wrapper .buy-shares-zone .action-zone .roi-zone .input-zone input[type='number']::-webkit-outer-spin-button,
            .project-detail-page-wrapper .buy-shares-zone .action-zone .roi-zone .input-zone input[type='number']::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0; }
            .project-detail-page-wrapper .buy-shares-zone .action-zone .roi-zone .input-zone input[type='number'] {
              -moz-appearance: textfield; }
          .project-detail-page-wrapper .buy-shares-zone .action-zone .roi-zone .calculation-zone {
            display: flex;
            flex-direction: column;
            align-items: flex-start; }
            .project-detail-page-wrapper .buy-shares-zone .action-zone .roi-zone .calculation-zone .header {
              font-size: 16px;
              font-weight: 500; }
            .project-detail-page-wrapper .buy-shares-zone .action-zone .roi-zone .calculation-zone .content {
              font-size: 24px;
              font-weight: 500; }
      .project-detail-page-wrapper .buy-shares-zone .information-zone {
        box-sizing: border-box;
        width: 100%;
        height: 40px;
        background-color: #3366ff;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        padding-top: 9px; }
        .project-detail-page-wrapper .buy-shares-zone .information-zone .text {
          font-size: 16px;
          font-weight: 500; }
    .project-detail-page-wrapper .project-overview-zone {
      width: 1180px;
      margin: 0 auto;
      margin-top: 100px; }
      .project-detail-page-wrapper .project-overview-zone .header {
        text-align: left;
        font-size: 30px;
        font-weight: 600;
        margin-bottom: 40px; }
      .project-detail-page-wrapper .project-overview-zone .project-overview-card-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start; }
        .project-detail-page-wrapper .project-overview-zone .project-overview-card-wrapper .overview-card {
          box-sizing: border-box;
          position: relative;
          width: 342px;
          height: 215px;
          border: 1px solid #eaeaea;
          border-radius: 8px; }
          .project-detail-page-wrapper .project-overview-zone .project-overview-card-wrapper .overview-card .header-zone {
            width: 100%;
            box-sizing: border-box;
            height: 60px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            padding-left: 30px;
            margin: 0; }
            .project-detail-page-wrapper .project-overview-zone .project-overview-card-wrapper .overview-card .header-zone .icon {
              font-size: 16px;
              margin-right: 10px; }
            .project-detail-page-wrapper .project-overview-zone .project-overview-card-wrapper .overview-card .header-zone .card-header {
              font-size: 24px;
              font-weight: 600; }
          .project-detail-page-wrapper .project-overview-zone .project-overview-card-wrapper .overview-card .separator {
            position: absolute;
            top: 60px;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: #eaeaea; }
          .project-detail-page-wrapper .project-overview-zone .project-overview-card-wrapper .overview-card .content-zone {
            box-sizing: border-box;
            padding: 30px 30px 20px 30px;
            height: calc(100% - 60px);
            display: flex;
            flex-direction: column;
            align-items: flex-start; }
            .project-detail-page-wrapper .project-overview-zone .project-overview-card-wrapper .overview-card .content-zone .subheader-zone {
              display: flex;
              flex-direction: row;
              width: 100%;
              margin-bottom: 10px; }
              .project-detail-page-wrapper .project-overview-zone .project-overview-card-wrapper .overview-card .content-zone .subheader-zone .flex-content {
                width: 60%; }
              .project-detail-page-wrapper .project-overview-zone .project-overview-card-wrapper .overview-card .content-zone .subheader-zone .content {
                text-align: left;
                font-size: 16px;
                font-weight: 500; }
              .project-detail-page-wrapper .project-overview-zone .project-overview-card-wrapper .overview-card .content-zone .subheader-zone .content-bold {
                font-weight: 700; }
            .project-detail-page-wrapper .project-overview-zone .project-overview-card-wrapper .overview-card .content-zone .short-description {
              font-size: 14px;
              line-height: 1.2;
              font-weight: 500;
              text-align: left;
              color: #999999;
              margin-bottom: 20px; }
            .project-detail-page-wrapper .project-overview-zone .project-overview-card-wrapper .overview-card .content-zone .details-cta {
              font-size: 14px;
              line-height: 1.43;
              font-weight: 600;
              text-align: left;
              color: #4a4a4a;
              cursor: pointer; }
    .project-detail-page-wrapper .project-information-zone {
      width: 1180px;
      margin: 0 auto;
      margin-top: 100px; }
      .project-detail-page-wrapper .project-information-zone .header {
        text-align: left;
        font-size: 30px;
        font-weight: 600;
        margin-bottom: 40px; }
      .project-detail-page-wrapper .project-information-zone .general-project-information-zone {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin-bottom: 45px; }
        .project-detail-page-wrapper .project-information-zone .general-project-information-zone .information {
          border-right: 1px solid #eaeaea;
          padding-right: 30px;
          padding-left: 30px;
          padding-top: 5px;
          padding-bottom: 5px; }
          .project-detail-page-wrapper .project-information-zone .general-project-information-zone .information:first-child {
            padding-left: 0; }
          .project-detail-page-wrapper .project-information-zone .general-project-information-zone .information:last-child {
            padding-right: 0;
            border-right: none; }
          .project-detail-page-wrapper .project-information-zone .general-project-information-zone .information .header {
            font-size: 16px;
            line-height: 1.25;
            font-weight: 500;
            color: #999999;
            margin: 0; }
          .project-detail-page-wrapper .project-information-zone .general-project-information-zone .information .subheader {
            font-size: 16px;
            line-height: 1.25;
            font-weight: 600;
            color: #000000; }
      .project-detail-page-wrapper .project-information-zone .map-project-information-zone {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        height: 250px;
        width: 100%; }
        .project-detail-page-wrapper .project-information-zone .map-project-information-zone .map {
          min-width: 338px;
          height: 100%; }
          .project-detail-page-wrapper .project-information-zone .map-project-information-zone .map:first-child {
            margin-right: 30px; }
          .project-detail-page-wrapper .project-information-zone .map-project-information-zone .map:nth-child(2) {
            margin-right: 40px; }
        .project-detail-page-wrapper .project-information-zone .map-project-information-zone .map-placeholder {
          min-width: 338px;
          height: 100%;
          background-color: #eaeaea;
          cursor: pointer; }
          .project-detail-page-wrapper .project-information-zone .map-project-information-zone .map-placeholder:first-child {
            margin-right: 30px; }
          .project-detail-page-wrapper .project-information-zone .map-project-information-zone .map-placeholder:nth-child(2) {
            margin-right: 40px; }
        .project-detail-page-wrapper .project-information-zone .map-project-information-zone .land-information-wrapper {
          width: 300px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: space-around; }
          .project-detail-page-wrapper .project-information-zone .map-project-information-zone .land-information-wrapper .information-line {
            box-sizing: border-box;
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            border-bottom: 1px solid #eaeaea;
            padding-right: 20px;
            padding-bottom: 20px; }
            .project-detail-page-wrapper .project-information-zone .map-project-information-zone .land-information-wrapper .information-line:last-child {
              border-bottom: none; }
            .project-detail-page-wrapper .project-information-zone .map-project-information-zone .land-information-wrapper .information-line .information-full {
              flex: 1 1; }
              .project-detail-page-wrapper .project-information-zone .map-project-information-zone .land-information-wrapper .information-line .information-full .header {
                font-size: 16px;
                line-height: 1.25;
                font-weight: 500;
                color: #999999;
                margin: 0; }
              .project-detail-page-wrapper .project-information-zone .map-project-information-zone .land-information-wrapper .information-line .information-full .subheader {
                font-size: 16px;
                line-height: 1.25;
                font-weight: 600;
                color: #000000; }
            .project-detail-page-wrapper .project-information-zone .map-project-information-zone .land-information-wrapper .information-line .information {
              flex: 0.5 1; }
              .project-detail-page-wrapper .project-information-zone .map-project-information-zone .land-information-wrapper .information-line .information .header {
                font-size: 16px;
                line-height: 1.25;
                font-weight: 500;
                color: #999999;
                margin: 0; }
              .project-detail-page-wrapper .project-information-zone .map-project-information-zone .land-information-wrapper .information-line .information .subheader {
                font-size: 16px;
                line-height: 1.25;
                font-weight: 600;
                color: #000000; }
    .project-detail-page-wrapper .mobile-project-information-zone {
      display: none; }
    .project-detail-page-wrapper .roadmap-zone {
      position: relative;
      width: 1180px;
      margin: 145px auto 0 auto; }
      .project-detail-page-wrapper .roadmap-zone .highlight {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        box-sizing: border-box;
        position: absolute;
        top: -40px;
        left: 0;
        height: 60px;
        background-color: #ff6600;
        border-radius: 8px;
        z-index: 1; }
        .project-detail-page-wrapper .roadmap-zone .highlight .icon {
          font-size: 12px;
          margin: 15px 10px auto 15px; }
        .project-detail-page-wrapper .roadmap-zone .highlight .text {
          font-size: 16px;
          margin: 10px 50px auto 0px; }
      .project-detail-page-wrapper .roadmap-zone .roadmap-div {
        position: relative;
        z-index: 10;
        height: 400px;
        width: 100%;
        border-radius: 8px;
        background-color: #fafafa; }
    .project-detail-page-wrapper .financial-details-zone {
      width: 1180px;
      margin: 0 auto;
      margin-top: 100px; }
      .project-detail-page-wrapper .financial-details-zone .header {
        text-align: left;
        font-size: 30px;
        font-weight: 600;
        margin-bottom: 30px; }
      .project-detail-page-wrapper .financial-details-zone .divided-tab-panel {
        display: flex;
        flex-direction: row; }
      .project-detail-page-wrapper .financial-details-zone .data-column {
        display: flex;
        flex-direction: row;
        width: 33%; }
        .project-detail-page-wrapper .financial-details-zone .data-column .tab-content-vertical {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start; }
          .project-detail-page-wrapper .financial-details-zone .data-column .tab-content-vertical .general-tab-header {
            font-size: 20px;
            font-weight: 700;
            text-align: left;
            margin-bottom: 20px; }
          .project-detail-page-wrapper .financial-details-zone .data-column .tab-content-vertical .tab-content {
            display: flex;
            flex-direction: row; }
            .project-detail-page-wrapper .financial-details-zone .data-column .tab-content-vertical .tab-content .header-column {
              width: 50%;
              display: flex;
              flex-direction: column;
              align-items: flex-start; }
              .project-detail-page-wrapper .financial-details-zone .data-column .tab-content-vertical .tab-content .header-column .header {
                font-size: 16px;
                line-height: 2;
                color: #999999;
                margin: 0;
                font-weight: 500; }
            .project-detail-page-wrapper .financial-details-zone .data-column .tab-content-vertical .tab-content .value-column {
              width: 50%;
              display: flex;
              flex-direction: column;
              align-items: flex-start; }
              .project-detail-page-wrapper .financial-details-zone .data-column .tab-content-vertical .tab-content .value-column .value {
                font-size: 16px;
                line-height: 2;
                color: #000000;
                margin: 0;
                font-weight: 600; }
      .project-detail-page-wrapper .financial-details-zone .costs-zone {
        margin: 40px 0 0 0;
        border: 1px solid #eaeaea;
        border-radius: 8px;
        box-sizing: border-box;
        width: 100%;
        display: flex;
        flex-direction: row; }
        .project-detail-page-wrapper .financial-details-zone .costs-zone .graph-area {
          width: 50%;
          border-right: 1px solid #eaeaea;
          box-sizing: border-box;
          padding: 20px;
          font-size: 24px; }
        .project-detail-page-wrapper .financial-details-zone .costs-zone .you-should-know-area {
          width: 50%;
          box-sizing: border-box;
          padding: 30px 40px; }
          .project-detail-page-wrapper .financial-details-zone .costs-zone .you-should-know-area .header {
            font-size: 24px;
            font-weight: 700;
            margin: 0;
            margin-bottom: 20px; }
          .project-detail-page-wrapper .financial-details-zone .costs-zone .you-should-know-area .content {
            font-size: 14px;
            line-height: 20px;
            font-weight: 500;
            text-align: left;
            margin-bottom: 50px;
            padding-right: 80px; }
          .project-detail-page-wrapper .financial-details-zone .costs-zone .you-should-know-area .description {
            font-size: 14px;
            line-height: 20px;
            font-weight: 500;
            text-align: left;
            color: #999999; }
    .project-detail-page-wrapper .mobile-financial-details-zone {
      display: none; }
    .project-detail-page-wrapper .technology-details-zone {
      width: 1180px;
      margin: 0 auto;
      margin-top: 100px; }
      .project-detail-page-wrapper .technology-details-zone .header {
        text-align: left;
        font-size: 30px;
        font-weight: 600;
        margin-bottom: 30px; }
      .project-detail-page-wrapper .technology-details-zone .technology-tabs {
        padding: 0; }
        .project-detail-page-wrapper .technology-details-zone .technology-tabs .technology-tab-content-wrapper {
          display: flex;
          flex-direction: row;
          width: 100%; }
          .project-detail-page-wrapper .technology-details-zone .technology-tabs .technology-tab-content-wrapper .data-column {
            display: flex;
            flex-direction: row;
            width: 50%;
            padding: 40px 30px; }
            .project-detail-page-wrapper .technology-details-zone .technology-tabs .technology-tab-content-wrapper .data-column .tab-content-vertical {
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: flex-start; }
              .project-detail-page-wrapper .technology-details-zone .technology-tabs .technology-tab-content-wrapper .data-column .tab-content-vertical .general-tab-header {
                font-size: 20px;
                font-weight: 700;
                text-align: left;
                margin-bottom: 20px; }
              .project-detail-page-wrapper .technology-details-zone .technology-tabs .technology-tab-content-wrapper .data-column .tab-content-vertical .tab-content {
                display: flex;
                flex-direction: row; }
                .project-detail-page-wrapper .technology-details-zone .technology-tabs .technology-tab-content-wrapper .data-column .tab-content-vertical .tab-content .header-column {
                  width: 50%;
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start; }
                  .project-detail-page-wrapper .technology-details-zone .technology-tabs .technology-tab-content-wrapper .data-column .tab-content-vertical .tab-content .header-column .header {
                    font-size: 16px;
                    line-height: 2;
                    color: #999999;
                    margin: 0;
                    font-weight: 500; }
                .project-detail-page-wrapper .technology-details-zone .technology-tabs .technology-tab-content-wrapper .data-column .tab-content-vertical .tab-content .value-column {
                  width: 50%;
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start; }
                  .project-detail-page-wrapper .technology-details-zone .technology-tabs .technology-tab-content-wrapper .data-column .tab-content-vertical .tab-content .value-column .value {
                    font-size: 16px;
                    line-height: 2;
                    color: #000000;
                    margin: 0;
                    font-weight: 600; }
          .project-detail-page-wrapper .technology-details-zone .technology-tabs .technology-tab-content-wrapper .description-column {
            width: 50%;
            box-sizing: border-box;
            background-color: #0033ff;
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
            padding: 30px 40px; }
            .project-detail-page-wrapper .technology-details-zone .technology-tabs .technology-tab-content-wrapper .description-column .header {
              color: #ffffff;
              font-size: 24px;
              font-weight: 700;
              margin: 0;
              margin-bottom: 20px; }
            .project-detail-page-wrapper .technology-details-zone .technology-tabs .technology-tab-content-wrapper .description-column .content {
              color: #ffffff;
              font-size: 14px;
              line-height: 20px;
              font-weight: 500;
              text-align: left;
              margin-bottom: 50px;
              padding-right: 80px; }
        .project-detail-page-wrapper .technology-details-zone .technology-tabs .technology-tab-content-wrapper-with-3 {
          display: flex;
          flex-direction: row;
          width: 100%; }
          .project-detail-page-wrapper .technology-details-zone .technology-tabs .technology-tab-content-wrapper-with-3 .data-column {
            display: flex;
            flex-direction: row;
            width: 35%;
            padding: 40px 30px; }
            .project-detail-page-wrapper .technology-details-zone .technology-tabs .technology-tab-content-wrapper-with-3 .data-column:first-child {
              width: 30%; }
            .project-detail-page-wrapper .technology-details-zone .technology-tabs .technology-tab-content-wrapper-with-3 .data-column .tab-content-vertical {
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: flex-start; }
              .project-detail-page-wrapper .technology-details-zone .technology-tabs .technology-tab-content-wrapper-with-3 .data-column .tab-content-vertical .general-tab-header {
                font-size: 20px;
                font-weight: 700;
                text-align: left;
                margin-bottom: 20px; }
              .project-detail-page-wrapper .technology-details-zone .technology-tabs .technology-tab-content-wrapper-with-3 .data-column .tab-content-vertical .tab-content {
                display: flex;
                flex-direction: row; }
                .project-detail-page-wrapper .technology-details-zone .technology-tabs .technology-tab-content-wrapper-with-3 .data-column .tab-content-vertical .tab-content .header-column {
                  width: 50%;
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start; }
                  .project-detail-page-wrapper .technology-details-zone .technology-tabs .technology-tab-content-wrapper-with-3 .data-column .tab-content-vertical .tab-content .header-column .header {
                    font-size: 16px;
                    line-height: 2;
                    color: #999999;
                    margin: 0;
                    font-weight: 500; }
                .project-detail-page-wrapper .technology-details-zone .technology-tabs .technology-tab-content-wrapper-with-3 .data-column .tab-content-vertical .tab-content .value-column {
                  width: 50%;
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start; }
                  .project-detail-page-wrapper .technology-details-zone .technology-tabs .technology-tab-content-wrapper-with-3 .data-column .tab-content-vertical .tab-content .value-column .value {
                    font-size: 16px;
                    line-height: 2;
                    color: #000000;
                    margin: 0;
                    font-weight: 600; }
    .project-detail-page-wrapper .mobile-technology-details-zone {
      display: none; }
    .project-detail-page-wrapper .market-details-zone {
      width: 1180px;
      margin: 0 auto;
      margin-top: 100px;
      margin-bottom: 60px; }
      .project-detail-page-wrapper .market-details-zone .header {
        text-align: left;
        font-size: 30px;
        font-weight: 600;
        margin-bottom: 30px; }
      .project-detail-page-wrapper .market-details-zone .market-tabs {
        padding: 0; }
        .project-detail-page-wrapper .market-details-zone .market-tabs .market-tab-content-wrapper {
          display: flex;
          flex-direction: row;
          width: 100%; }
          .project-detail-page-wrapper .market-details-zone .market-tabs .market-tab-content-wrapper .you-should-know-column {
            width: 50%;
            box-sizing: border-box;
            border-bottom-left-radius: 8px;
            padding: 30px 40px; }
            .project-detail-page-wrapper .market-details-zone .market-tabs .market-tab-content-wrapper .you-should-know-column .header {
              font-size: 24px;
              font-weight: 700;
              margin: 0;
              margin-bottom: 20px; }
            .project-detail-page-wrapper .market-details-zone .market-tabs .market-tab-content-wrapper .you-should-know-column .content {
              font-size: 14px;
              line-height: 20px;
              font-weight: 500;
              text-align: left;
              margin-bottom: 50px;
              padding-right: 80px; }
          .project-detail-page-wrapper .market-details-zone .market-tabs .market-tab-content-wrapper .market-index-chart-area {
            width: 50%;
            min-height: 280px;
            box-sizing: border-box;
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
            padding: 30px 40px;
            border-left: 1px solid #eaeaea; }
            .project-detail-page-wrapper .market-details-zone .market-tabs .market-tab-content-wrapper .market-index-chart-area .market-index-chart-title {
              font-size: 24px;
              font-weight: 500;
              color: black;
              text-align: left; }
            .project-detail-page-wrapper .market-details-zone .market-tabs .market-tab-content-wrapper .market-index-chart-area .market-index-chart-wrapper {
              height: 100%;
              box-sizing: border-box;
              padding: 20px 0;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              align-items: flex-start; }
          .project-detail-page-wrapper .market-details-zone .market-tabs .market-tab-content-wrapper .chart-area {
            width: 50%;
            min-height: 280px;
            box-sizing: border-box;
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
            padding: 30px 40px;
            border-left: 1px solid #eaeaea; }
          .project-detail-page-wrapper .market-details-zone .market-tabs .market-tab-content-wrapper .investment-chart-area {
            width: 50%;
            min-height: 280px;
            box-sizing: border-box;
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
            padding: 30px 40px;
            border-left: 1px solid #eaeaea;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: flex-end; }
          .project-detail-page-wrapper .market-details-zone .market-tabs .market-tab-content-wrapper .data-column {
            display: flex;
            flex-direction: row;
            width: 50%;
            box-sizing: border-box;
            padding: 40px 30px; }
            .project-detail-page-wrapper .market-details-zone .market-tabs .market-tab-content-wrapper .data-column .tab-content-vertical {
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: flex-start; }
              .project-detail-page-wrapper .market-details-zone .market-tabs .market-tab-content-wrapper .data-column .tab-content-vertical .general-tab-header {
                font-size: 20px;
                font-weight: 700;
                text-align: left;
                margin-bottom: 20px; }
              .project-detail-page-wrapper .market-details-zone .market-tabs .market-tab-content-wrapper .data-column .tab-content-vertical .tab-content {
                display: flex;
                flex-direction: row; }
                .project-detail-page-wrapper .market-details-zone .market-tabs .market-tab-content-wrapper .data-column .tab-content-vertical .tab-content .header-column {
                  width: 50%;
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start; }
                  .project-detail-page-wrapper .market-details-zone .market-tabs .market-tab-content-wrapper .data-column .tab-content-vertical .tab-content .header-column .header {
                    font-size: 16px;
                    line-height: 2;
                    color: #999999;
                    margin: 0;
                    font-weight: 500; }
                .project-detail-page-wrapper .market-details-zone .market-tabs .market-tab-content-wrapper .data-column .tab-content-vertical .tab-content .value-column {
                  width: 50%;
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start; }
                  .project-detail-page-wrapper .market-details-zone .market-tabs .market-tab-content-wrapper .data-column .tab-content-vertical .tab-content .value-column .value {
                    font-size: 16px;
                    line-height: 2;
                    color: #000000;
                    margin: 0;
                    font-weight: 600; }
    .project-detail-page-wrapper .mobile-market-details-zone {
      display: none; }
    .project-detail-page-wrapper .file-zone {
      width: 1180px;
      margin: 0 auto;
      border: 1px solid #eaeaea;
      border-radius: 8px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;
      box-sizing: border-box;
      padding: 4px 12px;
      margin-top: 40px; }
      .project-detail-page-wrapper .file-zone .file-item {
        width: 25%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        box-sizing: border-box;
        padding: 20px; }
        .project-detail-page-wrapper .file-zone .file-item .icon {
          font-size: 50px; }
        .project-detail-page-wrapper .file-zone .file-item .text-area {
          box-sizing: border-box;
          padding: 0 20px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start; }
          .project-detail-page-wrapper .file-zone .file-item .text-area .header {
            font-size: 16px;
            line-height: 20px;
            font-weight: 600;
            margin: 0; }
          .project-detail-page-wrapper .file-zone .file-item .text-area .download {
            font-size: 16px;
            line-height: 20px;
            font-weight: 500;
            margin: 0;
            text-align: left;
            color: #0033ff;
            cursor: pointer; }
            .project-detail-page-wrapper .file-zone .file-item .text-area .download:hover {
              text-decoration: underline; }
  .chart-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end; }
    .chart-container .chart-wrapper {
      width: 50%;
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between; }
      .chart-container .chart-wrapper .single-chart {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center; }
        .chart-container .chart-wrapper .single-chart .header {
          width: 100%;
          text-align: center;
          font-size: 24px;
          font-weight: 700;
          margin: 0; }
        .chart-container .chart-wrapper .single-chart .subheader {
          width: 100%;
          text-align: center;
          font-size: 12px;
          line-height: 16x;
          font-weight: 500; }
    .chart-container .content-wrapper {
      box-sizing: border-box;
      padding-left: 50px;
      padding-right: 20px;
      width: 50%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start; }
      .chart-container .content-wrapper .content-difference {
        font-size: 60px;
        line-height: 40px;
        font-weight: 600;
        margin: 0; }
      .chart-container .content-wrapper .content-difference-header {
        font-size: 16px;
        line-height: 26px;
        font-weight: 500; }
      .chart-container .content-wrapper .separator {
        height: 1px;
        width: 100%;
        background-color: #979797;
        margin-top: 20px;
        margin-bottom: 20px; }
      .chart-container .content-wrapper .content-description {
        font-size: 14px;
        line-height: 20px;
        text-align: left;
        font-weight: 500; } }

@media only screen and (max-width: 768px) {
  .project-detail-border {
    width: 100vw;
    height: 1px;
    background-color: #cccccc; }
  .hanging-nav-bar-wrapper {
    display: none; }
  .project-detail-page-wrapper {
    box-sizing: border-box;
    width: 100vw;
    margin: 0 auto;
    padding-top: 20px; }
    .project-detail-page-wrapper .buy-shares {
      z-index: 10000;
      position: fixed;
      left: 0;
      bottom: 0;
      width: 100vw;
      height: 50px;
      color: #ffffff;
      background-color: #33cc00;
      font-size: 16px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
      .project-detail-page-wrapper .buy-shares .text {
        font-size: 20px;
        font-weight: 600; }
    .project-detail-page-wrapper .header-zone {
      width: 94vw;
      margin: 0 auto 20px auto;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center; }
      .project-detail-page-wrapper .header-zone .left-pane {
        flex: 1 1; }
        .project-detail-page-wrapper .header-zone .left-pane .tag-zone {
          display: flex;
          flex-direction: row;
          margin-bottom: 10px; }
          .project-detail-page-wrapper .header-zone .left-pane .tag-zone .tag {
            margin-right: 10px; }
        .project-detail-page-wrapper .header-zone .left-pane .plant-header {
          font-size: 32px;
          font-weight: 700; }
        .project-detail-page-wrapper .header-zone .left-pane .plant-owner {
          font-size: 16px;
          color: #4a4a4a;
          font-weight: 500; }
        .project-detail-page-wrapper .header-zone .left-pane .project-count {
          display: none; }
      .project-detail-page-wrapper .header-zone .right-pane {
        display: none; }
    .project-detail-page-wrapper .hero-zone {
      position: relative;
      width: 94vw;
      margin: 0 auto 20px auto; }
      .project-detail-page-wrapper .hero-zone .highlight {
        display: none; }
      .project-detail-page-wrapper .hero-zone .hero-wrapper {
        box-sizing: border-box;
        position: relative;
        z-index: 10;
        width: 100%;
        background-color: #ffffff;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start; }
        .project-detail-page-wrapper .hero-zone .hero-wrapper .general-information {
          width: 100%;
          margin-bottom: 20px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-start; }
          .project-detail-page-wrapper .hero-zone .hero-wrapper .general-information .not-visible {
            display: none; }
          .project-detail-page-wrapper .hero-zone .hero-wrapper .general-information .information {
            box-sizing: border-box; }
            .project-detail-page-wrapper .hero-zone .hero-wrapper .general-information .information .header {
              font-size: 14px;
              font-weight: 500;
              color: #999999; }
            .project-detail-page-wrapper .hero-zone .hero-wrapper .general-information .information .subheader {
              font-size: 16px;
              font-weight: 600;
              color: #000000; }
        .project-detail-page-wrapper .hero-zone .hero-wrapper .project-images {
          position: relative;
          width: 96%;
          margin: 0 auto;
          height: 100%;
          margin-bottom: 15px; }
          .project-detail-page-wrapper .hero-zone .hero-wrapper .project-images .image {
            position: relative;
            top: -0.5px;
            width: 100%;
            height: calc(100% + 1px);
            object-fit: cover; }
        .project-detail-page-wrapper .hero-zone .hero-wrapper .project-information {
          width: 96%;
          margin: 0 auto;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start; }
          .project-detail-page-wrapper .hero-zone .hero-wrapper .project-information .information-zone {
            width: 100%;
            display: flex;
            flex-direction: column; }
            .project-detail-page-wrapper .hero-zone .hero-wrapper .project-information .information-zone .information-line {
              width: 100%;
              border-bottom: 1px solid #eaeaea;
              padding: 20px 0 20px 0; }
              .project-detail-page-wrapper .hero-zone .hero-wrapper .project-information .information-zone .information-line:last-child {
                border-bottom: none; }
              .project-detail-page-wrapper .hero-zone .hero-wrapper .project-information .information-zone .information-line .header {
                font-size: 16px;
                font-weight: 500;
                color: #999999; }
              .project-detail-page-wrapper .hero-zone .hero-wrapper .project-information .information-zone .information-line .subheader {
                font-size: 24px;
                font-weight: 600;
                color: #000000; }
              .project-detail-page-wrapper .hero-zone .hero-wrapper .project-information .information-zone .information-line .extended-subheader {
                font-size: 30px; }
            .project-detail-page-wrapper .hero-zone .hero-wrapper .project-information .information-zone .flex-information-line {
              display: flex;
              flex-direction: row;
              align-items: flex-start; }
              .project-detail-page-wrapper .hero-zone .hero-wrapper .project-information .information-zone .flex-information-line .information {
                width: 60%; }
                .project-detail-page-wrapper .hero-zone .hero-wrapper .project-information .information-zone .flex-information-line .information:last-child {
                  width: 40%; }
          .project-detail-page-wrapper .hero-zone .hero-wrapper .project-information .cta-zone {
            display: none; }
    .project-detail-page-wrapper .buy-shares-zone {
      display: none; }
    .project-detail-page-wrapper .project-overview-zone {
      display: none; }
    .project-detail-page-wrapper .project-information-zone {
      display: none; }
    .project-detail-page-wrapper .mobile-project-information-zone {
      width: 90vw;
      margin: 0 auto 20px auto; }
      .project-detail-page-wrapper .mobile-project-information-zone .header {
        text-align: left;
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 15px; }
      .project-detail-page-wrapper .mobile-project-information-zone .map-project-information-zone {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
        margin-bottom: 15px; }
        .project-detail-page-wrapper .mobile-project-information-zone .map-project-information-zone .map {
          width: 45%;
          height: auto;
          cursor: pointer; }
        .project-detail-page-wrapper .mobile-project-information-zone .map-project-information-zone .map-placeholder {
          height: 100px;
          width: 45%;
          background-color: #eaeaea;
          cursor: pointer; }
      .project-detail-page-wrapper .mobile-project-information-zone .mobile-information-wrapper {
        display: flex;
        flex-direction: row;
        align-items: flex-start; }
        .project-detail-page-wrapper .mobile-project-information-zone .mobile-information-wrapper .zone {
          flex: 0.5 1; }
          .project-detail-page-wrapper .mobile-project-information-zone .mobile-information-wrapper .zone .information {
            margin-bottom: 15px; }
            .project-detail-page-wrapper .mobile-project-information-zone .mobile-information-wrapper .zone .information .header {
              font-size: 16px;
              line-height: 1.25;
              font-weight: 500;
              color: #999999;
              margin: 0; }
            .project-detail-page-wrapper .mobile-project-information-zone .mobile-information-wrapper .zone .information .subheader {
              font-size: 16px;
              line-height: 1.25;
              font-weight: 600;
              color: #000000; }
    .project-detail-page-wrapper .roadmap-zone {
      position: relative;
      width: 100vw;
      margin: 20px auto 20px auto;
      overflow-x: scroll; }
      .project-detail-page-wrapper .roadmap-zone .highlight {
        display: none; }
      .project-detail-page-wrapper .roadmap-zone .roadmap-div {
        position: relative;
        z-index: 10;
        height: 400px;
        width: 1180px;
        background-color: #fafafa; }
    .project-detail-page-wrapper .financial-details-zone {
      display: none; }
    .project-detail-page-wrapper .mobile-financial-details-zone {
      width: 94vw;
      margin: 40px auto 0 auto; }
      .project-detail-page-wrapper .mobile-financial-details-zone .header {
        text-align: left;
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 20px; }
      .project-detail-page-wrapper .mobile-financial-details-zone .data-column {
        width: 100%; }
        .project-detail-page-wrapper .mobile-financial-details-zone .data-column .data-row {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: flex-start; }
          .project-detail-page-wrapper .mobile-financial-details-zone .data-column .data-row .header {
            width: 50%;
            font-size: 14px;
            color: #999999;
            font-weight: 500;
            margin-bottom: 10px; }
          .project-detail-page-wrapper .mobile-financial-details-zone .data-column .data-row .value {
            width: 50%;
            font-size: 14px;
            color: #000000;
            font-weight: 500;
            text-align: left;
            margin-bottom: 10px; }
      .project-detail-page-wrapper .mobile-financial-details-zone .costs-zone {
        display: none; }
    .project-detail-page-wrapper .technology-details-zone {
      display: none; }
    .project-detail-page-wrapper .mobile-technology-details-zone {
      width: 94vw;
      margin: 40px auto 0 auto; }
      .project-detail-page-wrapper .mobile-technology-details-zone .header {
        text-align: left;
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 20px; }
      .project-detail-page-wrapper .mobile-technology-details-zone .data-column {
        width: 100%; }
        .project-detail-page-wrapper .mobile-technology-details-zone .data-column .data-row {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: flex-start; }
          .project-detail-page-wrapper .mobile-technology-details-zone .data-column .data-row .header {
            width: 60%;
            font-size: 14px;
            color: #999999;
            font-weight: 500;
            margin-bottom: 10px; }
          .project-detail-page-wrapper .mobile-technology-details-zone .data-column .data-row .value {
            width: 40%;
            font-size: 14px;
            color: #000000;
            font-weight: 500;
            text-align: left;
            margin-bottom: 10px; }
      .project-detail-page-wrapper .mobile-technology-details-zone .button {
        display: none; }
    .project-detail-page-wrapper .market-details-zone {
      display: none; }
    .project-detail-page-wrapper .mobile-market-details-zone {
      width: 94vw;
      margin: 0 auto 0 auto; }
      .project-detail-page-wrapper .mobile-market-details-zone .header {
        text-align: left;
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 20px; }
      .project-detail-page-wrapper .mobile-market-details-zone .market-index-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start; }
        .project-detail-page-wrapper .mobile-market-details-zone .market-index-wrapper .market-index-chart-title {
          font-size: 16px;
          font-weight: 500;
          color: black;
          text-align: left; }
        .project-detail-page-wrapper .mobile-market-details-zone .market-index-wrapper .market-index-chart-wrapper {
          width: 100%;
          height: 100%;
          min-height: 150px;
          box-sizing: border-box;
          padding: 20px 0;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start; }
      .project-detail-page-wrapper .mobile-market-details-zone .chart-area {
        width: 100%;
        min-height: 200px;
        box-sizing: border-box;
        padding: 20px 10px 10px 10px; }
      .project-detail-page-wrapper .mobile-market-details-zone .investment-chart-area {
        width: 94vw;
        min-height: 280px;
        box-sizing: border-box;
        padding: 20px 10px 10px 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: flex-end; }
      .project-detail-page-wrapper .mobile-market-details-zone .data-column {
        width: 100%; }
        .project-detail-page-wrapper .mobile-market-details-zone .data-column .data-row {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: flex-start; }
          .project-detail-page-wrapper .mobile-market-details-zone .data-column .data-row .header {
            width: 50%;
            font-size: 14px;
            color: #999999;
            font-weight: 500;
            margin-bottom: 10px; }
          .project-detail-page-wrapper .mobile-market-details-zone .data-column .data-row .value {
            width: 50%;
            font-size: 14px;
            color: #000000;
            font-weight: 500;
            text-align: left;
            margin-bottom: 10px; }
      .project-detail-page-wrapper .mobile-market-details-zone .button {
        display: none; }
    .project-detail-page-wrapper .file-zone {
      width: 94vw;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;
      box-sizing: border-box;
      padding: 4px 12px; }
      .project-detail-page-wrapper .file-zone .file-item {
        width: 50%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        box-sizing: border-box;
        padding: 5px; }
        .project-detail-page-wrapper .file-zone .file-item .icon {
          font-size: 50px; }
        .project-detail-page-wrapper .file-zone .file-item .text-area {
          box-sizing: border-box;
          padding: 0 5px 0 10px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start; }
          .project-detail-page-wrapper .file-zone .file-item .text-area .header {
            font-size: 14px;
            line-height: 20px;
            font-weight: 600;
            margin: 0; }
          .project-detail-page-wrapper .file-zone .file-item .text-area .download {
            font-size: 14px;
            line-height: 20px;
            font-weight: 500;
            margin: 0;
            text-align: left;
            color: #0033ff;
            cursor: pointer; }
            .project-detail-page-wrapper .file-zone .file-item .text-area .download:hover {
              text-decoration: underline; }
  .chart-container {
    display: none; } }

/* Global style guide for UI Lib */
/**
 * Browser support
 */
/**
 * Typography
 */
/**
 * Colors
 */
/**
 * Page -- UILib Specific Variables
 */
/**
 * Card -- UILib Specific Variables
 */
@media only screen and (min-width: 769px) {
  .activation-message {
    max-width: 1130px; }
  .dashboard-content-wrapper {
    max-width: 1130px;
    margin: 0 auto 100px auto;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between; }
    .dashboard-content-wrapper .investment-pane {
      width: 850px; }
      .dashboard-content-wrapper .investment-pane .invested-projects-zone {
        margin: 60px 0; }
      .dashboard-content-wrapper .investment-pane .investable-projects-zone {
        margin: 60px 0; }
      .dashboard-content-wrapper .investment-pane .dashboard-line-chart {
        box-sizing: border-box;
        width: 100%;
        background-color: #fff;
        border-radius: 8px; }
    .dashboard-content-wrapper .mobile-investment-pane {
      display: none; }
    .dashboard-content-wrapper .wallet-pane {
      width: 230px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start; }
      .dashboard-content-wrapper .wallet-pane .action-buttons {
        display: flex;
        flex-direction: row;
        margin-top: 20px;
        justify-content: space-around; }
        .dashboard-content-wrapper .wallet-pane .action-buttons .button {
          width: 100px;
          padding: 7px 7px;
          font-weight: 600; }
      .dashboard-content-wrapper .wallet-pane .header-section {
        margin: 20px 0 20px 0; }
      .dashboard-content-wrapper .wallet-pane .wallet-margin {
        margin-bottom: 20px; }
      .dashboard-content-wrapper .wallet-pane a {
        text-decoration: none;
        color: #4a4a4a; }
        .dashboard-content-wrapper .wallet-pane a span .management-action {
          cursor: pointer; }
          .dashboard-content-wrapper .wallet-pane a span .management-action:hover {
            text-decoration: underline; }
  .dashboard-content-wrapper-margin-fix {
    margin-top: 60px;
    margin-bottom: 60px; }
  .wallet-deposit-loader {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
  .wallet-deposit-modal-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between; }
    .wallet-deposit-modal-wrapper .header {
      font-size: 36px;
      font-weight: 700; }
    .wallet-deposit-modal-wrapper .wallet-created {
      margin: 0 auto;
      margin-top: 10px;
      margin-bottom: 10px;
      font-weight: 500;
      background-color: #6699ff;
      border: 1px solid #0033ff;
      border-radius: 4px;
      padding: 10px 40px;
      box-sizing: border-box; }
      .wallet-deposit-modal-wrapper .wallet-created .text {
        font-size: 16px;
        color: #ffffff; }
    .wallet-deposit-modal-wrapper .wallet-address {
      display: flex;
      width: calc(100% - 60px);
      margin: 0 auto;
      flex-direction: column; }
      .wallet-deposit-modal-wrapper .wallet-address .header {
        width: 100%;
        text-align: left;
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 5px; }
      .wallet-deposit-modal-wrapper .wallet-address .wallet-address-zone {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #cccccc;
        color: #4a4a4a;
        border-radius: 4px;
        padding: 13px;
        background-color: #ffffff;
        cursor: pointer;
        z-index: 10; }
        .wallet-deposit-modal-wrapper .wallet-address .wallet-address-zone .address {
          font-size: 16px;
          color: #4a4a4a;
          font-weight: 500; }
        .wallet-deposit-modal-wrapper .wallet-address .wallet-address-zone .icon {
          font-size: 18px; }
        .wallet-deposit-modal-wrapper .wallet-address .wallet-address-zone .copied-zone {
          position: absolute;
          box-sizing: border-box;
          top: -30px;
          right: -1px;
          font-size: 12px;
          background-color: #ffffff;
          border: 1px solid #cccccc;
          border-radius: 3px;
          padding: 5px 10px 5px 10px; }
      .wallet-deposit-modal-wrapper .wallet-address .regenerate {
        width: 100%;
        text-align: right;
        font-size: 14px;
        font-weight: 500;
        color: #4a4a4a;
        margin-top: 5px;
        cursor: pointer; }
        .wallet-deposit-modal-wrapper .wallet-address .regenerate:hover {
          text-decoration: underline; }
      .wallet-deposit-modal-wrapper .wallet-address .error {
        margin-top: 20px; }
    .wallet-deposit-modal-wrapper .wallet-qr-code {
      height: 180px;
      width: 180px;
      margin: 0 auto; }
      .wallet-deposit-modal-wrapper .wallet-qr-code .qr-code {
        height: 100%;
        width: 100%; }
    .wallet-deposit-modal-wrapper .actions {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 300px; }
      .wallet-deposit-modal-wrapper .actions .button {
        width: 140px; }
  .disabled-wallet-modal-wrapper {
    height: 90%;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start; }
    .disabled-wallet-modal-wrapper .header {
      margin-top: 50px;
      margin-bottom: 60px;
      font-size: 36px !important;
      font-weight: 700 !important; }
    .disabled-wallet-modal-wrapper .icon {
      font-size: 140px;
      margin-bottom: 60px; }
    .disabled-wallet-modal-wrapper .text {
      margin-bottom: 80px;
      font-size: 18px;
      font-weight: 500; }
    .disabled-wallet-modal-wrapper .action {
      width: 200px;
      height: 50px;
      margin-top: auto; } }

@media only screen and (max-width: 768px) {
  .activation-message {
    max-width: 90vw; }
  .dashboard-content-wrapper {
    max-width: 90vw;
    margin: 0 auto 0 auto;
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    justify-content: space-between; }
    .dashboard-content-wrapper .investment-pane {
      display: none; }
    .dashboard-content-wrapper .mobile-investment-pane {
      width: 100%; }
      .dashboard-content-wrapper .mobile-investment-pane .invested-projects-zone {
        margin: 10px 0 0 0; }
      .dashboard-content-wrapper .mobile-investment-pane .investable-projects-zone {
        margin: 10px 0 0 0; }
    .dashboard-content-wrapper .wallet-pane {
      width: 90vw;
      display: flex;
      flex-direction: column;
      justify-content: flex-start; }
      .dashboard-content-wrapper .wallet-pane .action-buttons {
        display: flex;
        flex-direction: row;
        margin-top: 20px;
        justify-content: space-around;
        width: 90vw; }
        .dashboard-content-wrapper .wallet-pane .action-buttons .button {
          width: 40vw;
          padding: 7px 7px;
          font-weight: 600; }
      .dashboard-content-wrapper .wallet-pane .header-section {
        margin: 20px 0 20px 0; }
      .dashboard-content-wrapper .wallet-pane .wallet-margin {
        margin-bottom: 20px; }
      .dashboard-content-wrapper .wallet-pane a {
        text-decoration: none;
        color: #4a4a4a; }
        .dashboard-content-wrapper .wallet-pane a span .management-action {
          display: none; }
  .dashboard-content-wrapper-margin-fix {
    margin-top: 60px;
    margin-bottom: 60px; }
  .wallet-deposit-loader {
    min-height: 50vh;
    width: 80vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
  .wallet-deposit-modal-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between; }
    .wallet-deposit-modal-wrapper .header {
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 10px; }
    .wallet-deposit-modal-wrapper .wallet-created {
      margin: 0 auto;
      margin-top: 10px;
      margin-bottom: 10px;
      font-weight: 500;
      background-color: #6699ff;
      border: 1px solid #0033ff;
      border-radius: 4px;
      padding: 10px 20px;
      box-sizing: border-box; }
      .wallet-deposit-modal-wrapper .wallet-created .text {
        font-size: 12px;
        color: #ffffff; }
    .wallet-deposit-modal-wrapper .content {
      width: 90%;
      font-size: 12px;
      font-weight: 500;
      margin-bottom: 20px; }
    .wallet-deposit-modal-wrapper .wallet-address {
      display: flex;
      width: 80%;
      margin: 0 auto;
      flex-direction: column; }
      .wallet-deposit-modal-wrapper .wallet-address .header {
        width: 100%;
        text-align: left;
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 5px; }
      .wallet-deposit-modal-wrapper .wallet-address .wallet-address-zone {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #cccccc;
        color: #4a4a4a;
        border-radius: 4px;
        box-sizing: border-box;
        padding: 13px;
        margin-bottom: 20px;
        background-color: #ffffff;
        z-index: 10; }
        .wallet-deposit-modal-wrapper .wallet-address .wallet-address-zone .address {
          font-size: 14px;
          font-weight: 500;
          overflow-x: scroll; }
        .wallet-deposit-modal-wrapper .wallet-address .wallet-address-zone .icon {
          display: none; }
        .wallet-deposit-modal-wrapper .wallet-address .wallet-address-zone .copied-zone {
          position: absolute;
          box-sizing: border-box;
          top: -30px;
          right: -1px;
          font-size: 12px;
          background-color: #ffffff;
          border: 1px solid #cccccc;
          border-radius: 3px;
          padding: 5px 10px 5px 10px; }
      .wallet-deposit-modal-wrapper .wallet-address .regenerate {
        width: 100%;
        text-align: right;
        font-size: 14px;
        font-weight: 500;
        color: #4a4a4a;
        cursor: pointer; }
        .wallet-deposit-modal-wrapper .wallet-address .regenerate:hover {
          text-decoration: underline; }
      .wallet-deposit-modal-wrapper .wallet-address .error {
        margin-top: 20px; }
    .wallet-deposit-modal-wrapper .wallet-qr-code {
      height: 60%;
      width: 60%;
      margin: 20px auto; }
      .wallet-deposit-modal-wrapper .wallet-qr-code .qr-code {
        height: 100%;
        width: 100%; }
    .wallet-deposit-modal-wrapper .actions {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 90%; }
      .wallet-deposit-modal-wrapper .actions .button {
        width: 32vw;
        font-size: 11px; }
  .disabled-wallet-modal-wrapper {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start; }
    .disabled-wallet-modal-wrapper .header {
      margin-top: 30px !important;
      margin-bottom: 30px !important;
      font-size: 32px !important;
      font-weight: 700 !important; }
    .disabled-wallet-modal-wrapper .icon {
      font-size: 80px;
      margin-bottom: 30px; }
    .disabled-wallet-modal-wrapper .text {
      width: 80%;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 60px;
      font-size: 16px;
      font-weight: 500; }
    .disabled-wallet-modal-wrapper .action {
      width: 200px;
      height: 50px;
      margin-top: auto; } }

/* Global style guide for UI Lib */
/**
 * Browser support
 */
/**
 * Typography
 */
/**
 * Colors
 */
/**
 * Page -- UILib Specific Variables
 */
/**
 * Card -- UILib Specific Variables
 */
@media only screen and (min-width: 769px) {
  .commitment-page-wrapper {
    box-sizing: border-box;
    width: 1130px;
    margin: 0 auto;
    padding-top: 40px; }
    .commitment-page-wrapper .loading-wrapper {
      width: 100%;
      height: calc(100vh - 140px);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
    .commitment-page-wrapper .tags-wrapper {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 10px; }
      .commitment-page-wrapper .tags-wrapper .tag {
        font-size: 14px;
        line-height: 18px;
        font-weight: 600;
        text-align: center;
        background-color: #eaeaea;
        color: #777777;
        padding: 4px 10px;
        border-radius: 3px;
        margin-right: 10px; }
    .commitment-page-wrapper .header-zone-wrapper {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      margin-bottom: 60px; }
      .commitment-page-wrapper .header-zone-wrapper .header-zone {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start; }
        .commitment-page-wrapper .header-zone-wrapper .header-zone a {
          text-decoration: none; }
        .commitment-page-wrapper .header-zone-wrapper .header-zone .header {
          font-size: 44px;
          color: #000000;
          font-weight: 700; }
          .commitment-page-wrapper .header-zone-wrapper .header-zone .header:hover {
            text-decoration: underline; }
        .commitment-page-wrapper .header-zone-wrapper .header-zone .subheader {
          font-size: 16px;
          line-height: 24px;
          color: #000000;
          font-weight: 600; }
      .commitment-page-wrapper .header-zone-wrapper .statistics-zone .project-statistics {
        border-radius: 3px;
        padding: 15px 0;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: #ffffff; }
        .commitment-page-wrapper .header-zone-wrapper .statistics-zone .project-statistics .viewer-zone {
          padding: 0 30px;
          border-right: 1px solid #eaeaea; }
          .commitment-page-wrapper .header-zone-wrapper .statistics-zone .project-statistics .viewer-zone .header {
            font-size: 24px;
            line-height: 1;
            font-weight: 700; }
          .commitment-page-wrapper .header-zone-wrapper .statistics-zone .project-statistics .viewer-zone .subheader {
            font-size: 16px;
            font-weight: 500; }
        .commitment-page-wrapper .header-zone-wrapper .statistics-zone .project-statistics .backer-zone {
          padding: 0 30px; }
          .commitment-page-wrapper .header-zone-wrapper .statistics-zone .project-statistics .backer-zone .header {
            font-size: 24px;
            line-height: 1;
            font-weight: 700; }
          .commitment-page-wrapper .header-zone-wrapper .statistics-zone .project-statistics .backer-zone .subheader {
            font-size: 16px;
            font-weight: 500; }
    .commitment-page-wrapper .require-login-zone-wrapper {
      width: 100%;
      padding-bottom: 100px; }
      .commitment-page-wrapper .require-login-zone-wrapper .require-login {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center; }
        .commitment-page-wrapper .require-login-zone-wrapper .require-login .header {
          font-size: 36px;
          color: #4a4a4a;
          font-weight: 700; }
        .commitment-page-wrapper .require-login-zone-wrapper .require-login .subheader {
          font-size: 18px;
          line-height: 26px;
          color: #4a4a4a;
          font-weight: 500;
          margin: 0 45px; }
        .commitment-page-wrapper .require-login-zone-wrapper .require-login .actions {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          align-items: center; }
          .commitment-page-wrapper .require-login-zone-wrapper .require-login .actions .action-button {
            height: 60px;
            width: 175px; }
    .commitment-page-wrapper .commitment-main-wrapper {
      max-width: 1130px;
      margin: 0 auto 0 auto;
      padding-bottom: 100px;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between; }
      .commitment-page-wrapper .commitment-main-wrapper .wallet-pane {
        width: 230px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start; }
        .commitment-page-wrapper .commitment-main-wrapper .wallet-pane .action-buttons {
          display: flex;
          flex-direction: row;
          margin-top: 20px;
          justify-content: space-around; }
          .commitment-page-wrapper .commitment-main-wrapper .wallet-pane .action-buttons .button {
            width: 100px;
            padding: 7px 7px;
            font-weight: 600; }
        .commitment-page-wrapper .commitment-main-wrapper .wallet-pane .header-section {
          margin: 20px 0 20px 0; }
        .commitment-page-wrapper .commitment-main-wrapper .wallet-pane .wallet-margin {
          margin-bottom: 20px; }
        .commitment-page-wrapper .commitment-main-wrapper .wallet-pane .management-action {
          cursor: pointer; }
          .commitment-page-wrapper .commitment-main-wrapper .wallet-pane .management-action:hover {
            text-decoration: underline; }
      .commitment-page-wrapper .commitment-main-wrapper .commitment-pane {
        width: 800px;
        display: flex;
        flex-direction: column; }
        .commitment-page-wrapper .commitment-main-wrapper .commitment-pane .commitment-selection-zone {
          width: 100%;
          height: 360px;
          background-color: #ffffff;
          border-radius: 5px;
          box-sizing: border-box;
          padding: 50px 50px 80px 50px;
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-bottom: 60px; }
          .commitment-page-wrapper .commitment-main-wrapper .commitment-pane .commitment-selection-zone .header {
            font-size: 20px;
            font-weight: 700;
            color: #4a4a4a;
            margin: 0; }
          .commitment-page-wrapper .commitment-main-wrapper .commitment-pane .commitment-selection-zone .subheader {
            font-size: 16px;
            line-height: 24px;
            font-weight: 500;
            color: #4a4a4a;
            margin-bottom: 50px; }
          .commitment-page-wrapper .commitment-main-wrapper .commitment-pane .commitment-selection-zone .commitment-pricing-table {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-end; }
            .commitment-page-wrapper .commitment-main-wrapper .commitment-pane .commitment-selection-zone .commitment-pricing-table .commitment-pricing-active-item {
              background-color: #ff6600 !important;
              border-color: #ff6600 !important;
              color: #ffffff !important; }
            .commitment-page-wrapper .commitment-main-wrapper .commitment-pane .commitment-selection-zone .commitment-pricing-table .commitment-pricing-item {
              height: 100px;
              width: 100px;
              border: 1px solid #777777;
              border-radius: 4px;
              color: #777777;
              position: relative;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              cursor: pointer; }
              .commitment-page-wrapper .commitment-main-wrapper .commitment-pane .commitment-selection-zone .commitment-pricing-table .commitment-pricing-item .pricing-header {
                font-size: 20px;
                line-height: 20px;
                font-weight: 700;
                color: inherit;
                margin: 0; }
              .commitment-page-wrapper .commitment-main-wrapper .commitment-pane .commitment-selection-zone .commitment-pricing-table .commitment-pricing-item .pricing-subheader {
                font-size: 20px;
                line-height: 20px;
                font-weight: 500;
                color: inherit; }
              .commitment-page-wrapper .commitment-main-wrapper .commitment-pane .commitment-selection-zone .commitment-pricing-table .commitment-pricing-item .preferred {
                position: absolute;
                top: -30px;
                height: 25px;
                width: 100%;
                border: 1px solid #ff6600;
                border-radius: 3px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                font-size: 14px;
                line-height: 18px;
                color: #ff6600;
                font-weight: 600; }
        .commitment-page-wrapper .commitment-main-wrapper .commitment-pane .commitment-calculation-zone {
          width: 100%;
          background-color: #ffffff;
          border-radius: 5px;
          box-sizing: border-box;
          padding: 30px 50px;
          margin-bottom: 50px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center; }
          .commitment-page-wrapper .commitment-main-wrapper .commitment-pane .commitment-calculation-zone div .header {
            font-size: 20px;
            line-height: 20px;
            color: #4a4a4a;
            font-weight: 500; }
          .commitment-page-wrapper .commitment-main-wrapper .commitment-pane .commitment-calculation-zone div .subheader {
            font-size: 36px;
            line-height: 45px;
            color: #4a4a4a;
            font-weight: 700; }
          .commitment-page-wrapper .commitment-main-wrapper .commitment-pane .commitment-calculation-zone div .decide-later {
            font-size: 22px; }
          .commitment-page-wrapper .commitment-main-wrapper .commitment-pane .commitment-calculation-zone div .irr {
            font-size: 28px; }
          .commitment-page-wrapper .commitment-main-wrapper .commitment-pane .commitment-calculation-zone div .calculation {
            color: #ff6600; }
        .commitment-page-wrapper .commitment-main-wrapper .commitment-pane .submission-zone {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center; }
          .commitment-page-wrapper .commitment-main-wrapper .commitment-pane .submission-zone .left-pane {
            font-size: 14px;
            line-height: 24px;
            color: #4a4a4a;
            font-weight: 500;
            text-align: left; }
          .commitment-page-wrapper .commitment-main-wrapper .commitment-pane .submission-zone .action-button {
            width: 300px;
            height: 60px;
            font-size: 24px;
            font-weight: 600;
            margin-left: 75px; }
      .commitment-page-wrapper .commitment-main-wrapper .commitment-success-pane {
        width: 800px;
        display: flex;
        flex-direction: column; }
        .commitment-page-wrapper .commitment-main-wrapper .commitment-success-pane .commitment-information-zone {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 120px;
          margin-top: 40px; }
          .commitment-page-wrapper .commitment-main-wrapper .commitment-success-pane .commitment-information-zone .information-right-zone {
            margin-left: 30px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start; }
            .commitment-page-wrapper .commitment-main-wrapper .commitment-success-pane .commitment-information-zone .information-right-zone .header {
              font-size: 30px;
              line-height: 38px;
              font-weight: 700;
              color: #4a4a4a; }
            .commitment-page-wrapper .commitment-main-wrapper .commitment-success-pane .commitment-information-zone .information-right-zone .subheader {
              font-size: 18px;
              line-height: 26px;
              font-weight: 500;
              color: #4a4a4a; }
        .commitment-page-wrapper .commitment-main-wrapper .commitment-success-pane .commitment-completion-zone {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          background-color: #ffffff;
          border-radius: 5px;
          box-sizing: border-box;
          padding: 35px 40px 35px 50px; }
          .commitment-page-wrapper .commitment-main-wrapper .commitment-success-pane .commitment-completion-zone div .header {
            font-size: 20px;
            line-height: 20px;
            color: #4a4a4a;
            font-weight: 500; }
          .commitment-page-wrapper .commitment-main-wrapper .commitment-success-pane .commitment-completion-zone div .subheader {
            font-size: 36px;
            line-height: 36px;
            color: #4a4a4a;
            font-weight: 700; }
          .commitment-page-wrapper .commitment-main-wrapper .commitment-success-pane .commitment-completion-zone .action {
            width: 240px;
            height: 60px; }
    .commitment-page-wrapper .commitment-failure-wrapper {
      align-items: center;
      padding-left: 70px;
      padding-right: 70px; }
      .commitment-page-wrapper .commitment-failure-wrapper .description {
        padding-left: 30px;
        padding-right: 30px; }
      .commitment-page-wrapper .commitment-failure-wrapper .action {
        width: 200px;
        height: 60px; }
  .commitment-page-padding {
    padding-bottom: 80px; } }

@media only screen and (max-width: 768px) {
  .commitment-page-wrapper {
    box-sizing: border-box;
    width: 90vw;
    margin: 0 auto;
    padding-top: 30px; }
    .commitment-page-wrapper .loading-wrapper {
      width: 100%;
      height: calc(100vh - 100px);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
    .commitment-page-wrapper .tags-wrapper {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      flex-wrap: wrap;
      align-items: center;
      margin-bottom: 10px; }
      .commitment-page-wrapper .tags-wrapper .tag {
        font-size: 14px;
        line-height: 18px;
        font-weight: 600;
        text-align: center;
        background-color: #eaeaea;
        color: #777777;
        padding: 4px 10px;
        border-radius: 3px;
        margin-right: 10px; }
    .commitment-page-wrapper .header-zone-wrapper {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: 20px; }
      .commitment-page-wrapper .header-zone-wrapper .header-zone {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start; }
        .commitment-page-wrapper .header-zone-wrapper .header-zone a {
          text-decoration: none; }
        .commitment-page-wrapper .header-zone-wrapper .header-zone .header {
          font-size: 30px;
          color: #000000;
          font-weight: 700;
          text-align: left; }
          .commitment-page-wrapper .header-zone-wrapper .header-zone .header:hover {
            text-decoration: underline; }
        .commitment-page-wrapper .header-zone-wrapper .header-zone .subheader {
          font-size: 16px;
          line-height: 24px;
          color: #4a4a4a;
          font-weight: 500; }
      .commitment-page-wrapper .header-zone-wrapper .statistics-zone {
        display: none; }
    .commitment-page-wrapper .require-login-zone-wrapper {
      width: 100%;
      padding-bottom: 20px; }
      .commitment-page-wrapper .require-login-zone-wrapper .require-login {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center; }
        .commitment-page-wrapper .require-login-zone-wrapper .require-login .header {
          font-size: 20px;
          color: #4a4a4a;
          font-weight: 700; }
        .commitment-page-wrapper .require-login-zone-wrapper .require-login .subheader {
          font-size: 16px;
          line-height: 26px;
          color: #4a4a4a;
          font-weight: 500;
          margin: 0 20px; }
        .commitment-page-wrapper .require-login-zone-wrapper .require-login .actions {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          align-items: center; }
          .commitment-page-wrapper .require-login-zone-wrapper .require-login .actions .action-button {
            height: 40px;
            width: 120px; }
    .commitment-page-wrapper .commitment-main-wrapper {
      max-width: 94vw;
      width: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between; }
      .commitment-page-wrapper .commitment-main-wrapper .wallet-pane {
        display: none; }
      .commitment-page-wrapper .commitment-main-wrapper .commitment-pane {
        width: 100%;
        display: flex;
        flex-direction: column; }
        .commitment-page-wrapper .commitment-main-wrapper .commitment-pane .commitment-selection-zone {
          width: 100%;
          background-color: #ffffff;
          border-radius: 5px;
          box-sizing: border-box;
          padding: 20px 20px 30px 20px;
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-bottom: 30px; }
          .commitment-page-wrapper .commitment-main-wrapper .commitment-pane .commitment-selection-zone .header {
            font-size: 24px;
            font-weight: 700;
            color: #4a4a4a;
            margin: 0; }
          .commitment-page-wrapper .commitment-main-wrapper .commitment-pane .commitment-selection-zone .subheader {
            font-size: 14px;
            line-height: 24px;
            font-weight: 500;
            color: #4a4a4a;
            margin-bottom: 30px; }
          .commitment-page-wrapper .commitment-main-wrapper .commitment-pane .commitment-selection-zone .commitment-pricing-table {
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: flex-end; }
            .commitment-page-wrapper .commitment-main-wrapper .commitment-pane .commitment-selection-zone .commitment-pricing-table .commitment-pricing-active-item {
              background-color: #ff6600 !important;
              border-color: #ff6600 !important;
              color: #ffffff !important; }
            .commitment-page-wrapper .commitment-main-wrapper .commitment-pane .commitment-selection-zone .commitment-pricing-table .commitment-pricing-item {
              height: 80px;
              width: 30%;
              margin-bottom: 50px;
              border: 1px solid #777777;
              border-radius: 4px;
              color: #777777;
              position: relative;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              cursor: pointer; }
              .commitment-page-wrapper .commitment-main-wrapper .commitment-pane .commitment-selection-zone .commitment-pricing-table .commitment-pricing-item .pricing-header {
                font-size: 16px;
                line-height: 20px;
                font-weight: 700;
                color: inherit;
                margin: 0; }
              .commitment-page-wrapper .commitment-main-wrapper .commitment-pane .commitment-selection-zone .commitment-pricing-table .commitment-pricing-item .pricing-subheader {
                font-size: 16px;
                line-height: 20px;
                font-weight: 500;
                color: inherit; }
              .commitment-page-wrapper .commitment-main-wrapper .commitment-pane .commitment-selection-zone .commitment-pricing-table .commitment-pricing-item .preferred {
                position: absolute;
                top: -30px;
                height: 25px;
                width: 100%;
                border: 1px solid #ff6600;
                border-radius: 3px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                font-size: 14px;
                line-height: 18px;
                color: #ff6600;
                font-weight: 600; }
        .commitment-page-wrapper .commitment-main-wrapper .commitment-pane .commitment-calculation-zone {
          width: 100%;
          background-color: #ffffff;
          border-radius: 5px;
          box-sizing: border-box;
          padding: 20px;
          margin-bottom: 30px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center; }
          .commitment-page-wrapper .commitment-main-wrapper .commitment-pane .commitment-calculation-zone div .header {
            font-size: 16px;
            line-height: 20px;
            color: #4a4a4a;
            font-weight: 500; }
          .commitment-page-wrapper .commitment-main-wrapper .commitment-pane .commitment-calculation-zone div .subheader {
            font-size: 24px;
            line-height: 45px;
            color: #4a4a4a;
            font-weight: 700; }
          .commitment-page-wrapper .commitment-main-wrapper .commitment-pane .commitment-calculation-zone div .decide-later {
            font-size: 18px; }
          .commitment-page-wrapper .commitment-main-wrapper .commitment-pane .commitment-calculation-zone div .irr {
            font-size: 24px; }
          .commitment-page-wrapper .commitment-main-wrapper .commitment-pane .commitment-calculation-zone div .calculation {
            color: #ff6600; }
        .commitment-page-wrapper .commitment-main-wrapper .commitment-pane .submission-zone {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center; }
          .commitment-page-wrapper .commitment-main-wrapper .commitment-pane .submission-zone .left-pane {
            font-size: 14px;
            line-height: 24px;
            color: #4a4a4a;
            font-weight: 500;
            text-align: left;
            margin-bottom: 20px; }
          .commitment-page-wrapper .commitment-main-wrapper .commitment-pane .submission-zone .action-button {
            width: 100%;
            height: 60px;
            font-size: 24px;
            font-weight: 600; }
      .commitment-page-wrapper .commitment-main-wrapper .commitment-success-pane {
        width: 100%;
        display: flex;
        flex-direction: column; }
        .commitment-page-wrapper .commitment-main-wrapper .commitment-success-pane .commitment-information-zone {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 60px;
          margin-top: 40px; }
          .commitment-page-wrapper .commitment-main-wrapper .commitment-success-pane .commitment-information-zone .information-right-zone {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start; }
            .commitment-page-wrapper .commitment-main-wrapper .commitment-success-pane .commitment-information-zone .information-right-zone .header {
              text-align: center;
              margin-top: 20px;
              margin-bottom: 20px;
              font-size: 24px;
              line-height: 30px;
              font-weight: 700;
              color: #4a4a4a; }
            .commitment-page-wrapper .commitment-main-wrapper .commitment-success-pane .commitment-information-zone .information-right-zone .description {
              text-align: center;
              font-size: 16px;
              line-height: 22px;
              font-weight: 500;
              color: #4a4a4a; }
        .commitment-page-wrapper .commitment-main-wrapper .commitment-success-pane .commitment-completion-zone {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          background-color: #ffffff;
          border-radius: 5px;
          box-sizing: border-box;
          padding: 20px; }
          .commitment-page-wrapper .commitment-main-wrapper .commitment-success-pane .commitment-completion-zone div .header {
            font-size: 16px;
            line-height: 20px;
            color: #4a4a4a;
            font-weight: 500; }
          .commitment-page-wrapper .commitment-main-wrapper .commitment-success-pane .commitment-completion-zone div .subheader {
            margin-bottom: 20px;
            font-size: 24px;
            line-height: 36px;
            color: #4a4a4a;
            font-weight: 700; }
          .commitment-page-wrapper .commitment-main-wrapper .commitment-success-pane .commitment-completion-zone .action {
            width: 100%;
            height: 60px; }
    .commitment-page-wrapper .commitment-failure-wrapper {
      align-items: center; }
      .commitment-page-wrapper .commitment-failure-wrapper .icon {
        margin-top: 30px; }
      .commitment-page-wrapper .commitment-failure-wrapper .description {
        padding-left: 30px;
        padding-right: 30px;
        margin-bottom: 30px;
        margin-top: 30px; }
      .commitment-page-wrapper .commitment-failure-wrapper .action {
        width: 100%;
        height: 60px; }
  .commitment-page-padding {
    padding-bottom: 40px; } }

/* Global style guide for UI Lib */
/**
 * Browser support
 */
/**
 * Typography
 */
/**
 * Colors
 */
/**
 * Page -- UILib Specific Variables
 */
/**
 * Card -- UILib Specific Variables
 */
@media only screen and (min-width: 769px) {
  .investment-page-wrapper {
    box-sizing: border-box;
    width: 1130px;
    margin: 0 auto;
    padding-top: 40px; }
    .investment-page-wrapper .loading-wrapper {
      width: 100%;
      height: calc(100vh - 140px);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
    .investment-page-wrapper .tags-wrapper {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 10px; }
      .investment-page-wrapper .tags-wrapper .tag {
        font-size: 14px;
        line-height: 18px;
        font-weight: 600;
        text-align: center;
        background-color: #eaeaea;
        color: #777777;
        padding: 4px 10px;
        border-radius: 3px;
        margin-right: 10px; }
    .investment-page-wrapper .header-zone-wrapper {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      margin-bottom: 60px; }
      .investment-page-wrapper .header-zone-wrapper .header-zone {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start; }
        .investment-page-wrapper .header-zone-wrapper .header-zone a {
          text-decoration: none; }
        .investment-page-wrapper .header-zone-wrapper .header-zone .header {
          font-size: 44px;
          color: #000000;
          font-weight: 700; }
          .investment-page-wrapper .header-zone-wrapper .header-zone .header:hover {
            text-decoration: underline; }
        .investment-page-wrapper .header-zone-wrapper .header-zone .subheader {
          font-size: 16px;
          line-height: 24px;
          color: #000000;
          font-weight: 600; }
      .investment-page-wrapper .header-zone-wrapper .statistics-zone .project-statistics {
        border-radius: 3px;
        padding: 15px 0;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: #ffffff; }
        .investment-page-wrapper .header-zone-wrapper .statistics-zone .project-statistics .viewer-zone {
          padding: 0 30px;
          border-right: 1px solid #eaeaea; }
          .investment-page-wrapper .header-zone-wrapper .statistics-zone .project-statistics .viewer-zone .header {
            font-size: 24px;
            line-height: 1;
            font-weight: 700; }
          .investment-page-wrapper .header-zone-wrapper .statistics-zone .project-statistics .viewer-zone .subheader {
            font-size: 16px;
            font-weight: 500; }
        .investment-page-wrapper .header-zone-wrapper .statistics-zone .project-statistics .backer-zone {
          padding: 0 30px; }
          .investment-page-wrapper .header-zone-wrapper .statistics-zone .project-statistics .backer-zone .header {
            font-size: 24px;
            line-height: 1;
            font-weight: 700; }
          .investment-page-wrapper .header-zone-wrapper .statistics-zone .project-statistics .backer-zone .subheader {
            font-size: 16px;
            font-weight: 500; }
    .investment-page-wrapper .require-login-zone-wrapper {
      width: 100%;
      padding-bottom: 100px; }
      .investment-page-wrapper .require-login-zone-wrapper .require-login {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center; }
        .investment-page-wrapper .require-login-zone-wrapper .require-login .header {
          font-size: 36px;
          color: #4a4a4a;
          font-weight: 700; }
        .investment-page-wrapper .require-login-zone-wrapper .require-login .subheader {
          font-size: 18px;
          line-height: 26px;
          color: #4a4a4a;
          font-weight: 500;
          margin: 0 45px; }
        .investment-page-wrapper .require-login-zone-wrapper .require-login .actions {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          align-items: center; }
          .investment-page-wrapper .require-login-zone-wrapper .require-login .actions .action-button {
            height: 60px;
            width: 175px; }
    .investment-page-wrapper .start-step-loading-wrapper {
      width: 100%;
      height: calc(100vh - 400px);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
    .investment-page-wrapper .investment-page-amount-wrapper {
      max-width: 1130px;
      margin: 0 auto 0 auto;
      display: flex;
      padding-bottom: 100px;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between; }
      .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper {
        width: 800px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start; }
        .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-general-information-zone {
          width: 100%;
          height: 105px;
          border-radius: 5px;
          background-color: #ffffff;
          box-sizing: border-box;
          padding: 20px 50px;
          margin-bottom: 80px;
          display: flex;
          flex-direction: row;
          justify-content: flex-start; }
          .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-general-information-zone .information {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            margin-right: 30px; }
            .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-general-information-zone .information:last-child {
              margin-left: auto;
              align-items: flex-end;
              margin-right: 0px; }
            .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-general-information-zone .information .subheader {
              font-size: 16px;
              line-height: 26px;
              font-weight: 500; }
            .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-general-information-zone .information .header {
              font-size: 30px;
              font-weight: 600; }
        .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-amount-selection-panel {
          position: relative;
          width: 100%;
          margin-bottom: 20px; }
          .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-amount-selection-panel .timer-zone {
            box-sizing: border-box;
            position: absolute;
            top: -40px;
            left: 0;
            height: 60px;
            width: 280px;
            background-color: #0033ff;
            border-radius: 8px;
            z-index: 1;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 13px; }
            .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-amount-selection-panel .timer-zone .timer {
              width: 40px;
              font-size: 18px !important;
              font-weight: 700 !important;
              margin-right: 15px; }
            .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-amount-selection-panel .timer-zone .text {
              font-size: 18px;
              font-weight: 500; }
          .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-amount-selection-panel .investment-pane {
            position: relative;
            z-index: 10;
            width: 100%; }
            .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-amount-selection-panel .investment-pane .investment-selection-zone {
              width: 100%;
              height: 430px;
              background-color: #ffffff;
              border-radius: 5px;
              display: flex;
              flex-direction: column;
              align-items: center;
              box-sizing: border-box;
              padding: 80px 50px; }
              .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-amount-selection-panel .investment-pane .investment-selection-zone .investment-pricing-table {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-end;
                margin-bottom: 60px; }
                .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-amount-selection-panel .investment-pane .investment-selection-zone .investment-pricing-table .investment-pricing-active-item {
                  background-color: #ff6600 !important;
                  border-color: #ff6600 !important;
                  color: #ffffff !important; }
                .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-amount-selection-panel .investment-pane .investment-selection-zone .investment-pricing-table .investment-pricing-item {
                  height: 100px;
                  width: 100px;
                  border: 1px solid #777777;
                  border-radius: 4px;
                  color: #777777;
                  position: relative;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  cursor: pointer; }
                  .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-amount-selection-panel .investment-pane .investment-selection-zone .investment-pricing-table .investment-pricing-item .pricing-header {
                    font-size: 20px;
                    line-height: 20px;
                    font-weight: 700;
                    color: inherit;
                    margin: 0; }
                  .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-amount-selection-panel .investment-pane .investment-selection-zone .investment-pricing-table .investment-pricing-item .pricing-subheader {
                    font-size: 20px;
                    line-height: 20px;
                    font-weight: 500;
                    color: inherit; }
                  .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-amount-selection-panel .investment-pane .investment-selection-zone .investment-pricing-table .investment-pricing-item .preferred {
                    position: absolute;
                    top: -30px;
                    height: 25px;
                    width: 100%;
                    border: 1px solid #ff6600;
                    border-radius: 3px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    font-size: 14px;
                    line-height: 18px;
                    color: #ff6600;
                    font-weight: 600; }
              .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-amount-selection-panel .investment-pane .investment-selection-zone .investment-pricing-calculation-area {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center; }
                .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-amount-selection-panel .investment-pane .investment-selection-zone .investment-pricing-calculation-area .header {
                  font-size: 16px;
                  font-weight: 500;
                  margin-bottom: 10px; }
                .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-amount-selection-panel .investment-pane .investment-selection-zone .investment-pricing-calculation-area .form-zone {
                  display: flex;
                  flex-direction: row;
                  justify-content: flex-start;
                  align-items: center;
                  margin-bottom: 10px; }
                  .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-amount-selection-panel .investment-pane .investment-selection-zone .investment-pricing-calculation-area .form-zone .input {
                    width: 200px;
                    height: 50px; }
                    .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-amount-selection-panel .investment-pane .investment-selection-zone .investment-pricing-calculation-area .form-zone .input:first-child {
                      margin-right: 20px; }
                .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-amount-selection-panel .investment-pane .investment-selection-zone .investment-pricing-calculation-area .subheader-zone {
                  cursor: pointer; }
                  .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-amount-selection-panel .investment-pane .investment-selection-zone .investment-pricing-calculation-area .subheader-zone .subheader {
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: 300;
                    text-decoration: underline; }
        .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-total-calculation-panel {
          width: 100%;
          height: 105px;
          border-radius: 5px;
          background-color: #ffffff;
          box-sizing: border-box;
          padding: 20px 50px;
          margin-bottom: 80px;
          display: flex;
          flex-direction: row;
          justify-content: flex-start; }
          .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-total-calculation-panel .information {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            margin-right: 30px; }
            .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-total-calculation-panel .information:last-child {
              margin-left: auto;
              align-items: flex-end;
              margin-right: 0px; }
            .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-total-calculation-panel .information .subheader {
              font-size: 20px;
              font-weight: 500; }
            .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-total-calculation-panel .information .header {
              font-size: 36px;
              font-weight: 700; }
        .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-action-zone {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: flex-end; }
          .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-action-zone .action {
            width: 240px;
            height: 60px;
            font-size: 24px;
            font-weight: 600; }
      .investment-page-wrapper .investment-page-amount-wrapper .wallet-pane {
        width: 230px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start; }
        .investment-page-wrapper .investment-page-amount-wrapper .wallet-pane .action-buttons {
          display: flex;
          flex-direction: row;
          margin-top: 20px;
          justify-content: space-around; }
          .investment-page-wrapper .investment-page-amount-wrapper .wallet-pane .action-buttons .button {
            width: 100px;
            padding: 7px 7px;
            font-weight: 600; }
        .investment-page-wrapper .investment-page-amount-wrapper .wallet-pane .header-section {
          margin: 20px 0 20px 0; }
        .investment-page-wrapper .investment-page-amount-wrapper .wallet-pane .wallet-margin {
          margin-bottom: 20px; }
        .investment-page-wrapper .investment-page-amount-wrapper .wallet-pane .management-action {
          cursor: pointer; }
          .investment-page-wrapper .investment-page-amount-wrapper .wallet-pane .management-action:hover {
            text-decoration: underline; }
    .investment-page-wrapper .investment-page-confirm-wrapper {
      max-width: 1130px;
      margin: 0 auto 0 auto;
      display: flex;
      padding-bottom: 100px;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between; }
      .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper {
        width: 800px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start; }
        .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-general-information-zone {
          width: 100%;
          height: 105px;
          border-radius: 5px;
          background-color: #ffffff;
          box-sizing: border-box;
          padding: 20px 50px;
          margin-bottom: 80px;
          display: flex;
          flex-direction: row;
          justify-content: flex-start; }
          .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-general-information-zone .information {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            margin-right: 30px; }
            .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-general-information-zone .information:last-child {
              margin-left: auto;
              align-items: flex-end;
              margin-right: 0px; }
            .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-general-information-zone .information .subheader {
              font-size: 16px;
              line-height: 26px;
              font-weight: 500; }
            .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-general-information-zone .information .header {
              font-size: 30px;
              font-weight: 600; }
        .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-confirm-selection-panel {
          position: relative;
          width: 100%;
          margin-bottom: 20px; }
          .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-confirm-selection-panel .timer-zone {
            box-sizing: border-box;
            position: absolute;
            top: -40px;
            left: 0;
            height: 60px;
            width: 280px;
            background-color: #0033ff;
            border-radius: 8px;
            z-index: 1;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 13px; }
            .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-confirm-selection-panel .timer-zone .timer {
              width: 40px;
              font-size: 18px !important;
              font-weight: 700 !important;
              margin-right: 15px; }
            .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-confirm-selection-panel .timer-zone .text {
              font-size: 18px;
              font-weight: 500; }
          .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-confirm-selection-panel .confirm-pane {
            position: relative;
            z-index: 10;
            width: 100%; }
            .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-confirm-selection-panel .confirm-pane .confirm-information-zone {
              width: 100%;
              height: 310px;
              background-color: #ffffff;
              border-radius: 5px;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              box-sizing: border-box;
              padding: 30px 40px; }
              .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-confirm-selection-panel .confirm-pane .confirm-information-zone .information-line {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: flex-start; }
                .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-confirm-selection-panel .confirm-pane .confirm-information-zone .information-line:first-child {
                  margin-bottom: 40px; }
                .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-confirm-selection-panel .confirm-pane .confirm-information-zone .information-line:nth-child(2) {
                  margin-bottom: 20px; }
                .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-confirm-selection-panel .confirm-pane .confirm-information-zone .information-line .main-information {
                  display: flex;
                  flex-direction: column;
                  justify-content: flex-start;
                  align-items: flex-start;
                  margin-right: 40px; }
                  .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-confirm-selection-panel .confirm-pane .confirm-information-zone .information-line .main-information .subheader {
                    font-size: 20px;
                    font-weight: 500; }
                  .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-confirm-selection-panel .confirm-pane .confirm-information-zone .information-line .main-information .header {
                    font-size: 36px;
                    font-weight: 700; }
                .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-confirm-selection-panel .confirm-pane .confirm-information-zone .information-line .supplementary-information {
                  display: flex;
                  flex-direction: column;
                  justify-content: flex-start;
                  align-items: flex-start;
                  margin-right: 40px; }
                  .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-confirm-selection-panel .confirm-pane .confirm-information-zone .information-line .supplementary-information .subheader {
                    font-size: 16px;
                    line-height: 20px;
                    font-weight: 500; }
                  .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-confirm-selection-panel .confirm-pane .confirm-information-zone .information-line .supplementary-information .header {
                    font-size: 20px;
                    font-weight: 600; }
        .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-confirmation-total-panel {
          width: 100%;
          height: 105px;
          border-radius: 5px;
          background-color: #ffffff;
          box-sizing: border-box;
          padding: 20px 50px;
          margin-bottom: 40px;
          display: flex;
          flex-direction: row;
          justify-content: flex-end; }
          .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-confirmation-total-panel .information {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end; }
            .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-confirmation-total-panel .information .subheader {
              font-size: 20px;
              font-weight: 500; }
            .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-confirmation-total-panel .information .header {
              font-size: 36px;
              font-weight: 700; }
        .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-action-zone {
          width: 100%; }
          .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-action-zone form {
            width: 100%; }
            .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-action-zone form .form-container {
              width: 100%;
              display: flex;
              flex-direction: row;
              justify-content: flex-end;
              align-items: flex-end; }
              .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-action-zone form .form-container .aggrements {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                margin-right: 50px;
                box-sizing: border-box;
                padding-bottom: 5px; }
                .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-action-zone form .form-container .aggrements .checkbox {
                  margin-bottom: 10px !important; }
                  .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-action-zone form .form-container .aggrements .checkbox:last-child {
                    margin-bottom: 0px !important; }
              .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-action-zone form .form-container .action {
                width: 240px;
                height: 60px;
                font-size: 24px;
                font-weight: 600; }
      .investment-page-wrapper .investment-page-confirm-wrapper .wallet-pane {
        width: 230px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start; }
        .investment-page-wrapper .investment-page-confirm-wrapper .wallet-pane .action-buttons {
          display: flex;
          flex-direction: row;
          margin-top: 20px;
          justify-content: space-around; }
          .investment-page-wrapper .investment-page-confirm-wrapper .wallet-pane .action-buttons .button {
            width: 100px;
            padding: 7px 7px;
            font-weight: 600; }
        .investment-page-wrapper .investment-page-confirm-wrapper .wallet-pane .header-section {
          margin: 20px 0 20px 0; }
        .investment-page-wrapper .investment-page-confirm-wrapper .wallet-pane .wallet-margin {
          margin-bottom: 20px; }
        .investment-page-wrapper .investment-page-confirm-wrapper .wallet-pane .management-action {
          cursor: pointer; }
          .investment-page-wrapper .investment-page-confirm-wrapper .wallet-pane .management-action:hover {
            text-decoration: underline; }
    .investment-page-wrapper .investment-page-result-wrapper {
      max-width: 1130px;
      margin: 0 auto 0 auto;
      display: flex;
      padding-bottom: 100px;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between; }
      .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper {
        width: 800px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start; }
        .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-result-success-header-zone {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 60px; }
          .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-result-success-header-zone .icon {
            font-size: 100px;
            margin-left: 40px;
            margin-right: 40px; }
          .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-result-success-header-zone .text-area {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start; }
            .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-result-success-header-zone .text-area .header {
              font-size: 30px;
              color: #4a4a4a;
              font-weight: 700; }
            .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-result-success-header-zone .text-area .subheader {
              text-align: left;
              font-size: 18px;
              line-height: 26px;
              color: #4a4a4a;
              font-weight: 500; }
        .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-result-selection-panel {
          position: relative;
          width: 100%;
          margin-bottom: 20px; }
          .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-result-selection-panel .result-pane {
            position: relative;
            z-index: 10;
            width: 100%; }
            .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-result-selection-panel .result-pane .result-information-zone {
              width: 100%;
              height: 310px;
              background-color: #ffffff;
              border-radius: 5px;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              box-sizing: border-box;
              padding: 30px 40px; }
              .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-result-selection-panel .result-pane .result-information-zone .information-line {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: flex-start; }
                .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-result-selection-panel .result-pane .result-information-zone .information-line:first-child {
                  margin-bottom: 40px; }
                .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-result-selection-panel .result-pane .result-information-zone .information-line:nth-child(2) {
                  margin-bottom: 20px; }
                .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-result-selection-panel .result-pane .result-information-zone .information-line .main-information {
                  display: flex;
                  flex-direction: column;
                  justify-content: flex-start;
                  align-items: flex-start;
                  margin-right: 40px; }
                  .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-result-selection-panel .result-pane .result-information-zone .information-line .main-information .subheader {
                    font-size: 20px;
                    font-weight: 500; }
                  .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-result-selection-panel .result-pane .result-information-zone .information-line .main-information .header {
                    font-size: 36px;
                    font-weight: 700; }
                .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-result-selection-panel .result-pane .result-information-zone .information-line .pull-left-information {
                  margin-left: auto;
                  align-items: flex-end; }
                .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-result-selection-panel .result-pane .result-information-zone .information-line .supplementary-information {
                  display: flex;
                  flex-direction: column;
                  justify-content: flex-start;
                  align-items: flex-start;
                  margin-right: 40px; }
                  .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-result-selection-panel .result-pane .result-information-zone .information-line .supplementary-information .subheader {
                    font-size: 16px;
                    line-height: 20px;
                    font-weight: 500; }
                  .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-result-selection-panel .result-pane .result-information-zone .information-line .supplementary-information .header {
                    font-size: 20px;
                    font-weight: 600; }
        .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-confirmation-total-panel {
          width: 100%;
          height: 105px;
          border-radius: 5px;
          background-color: #ffffff;
          box-sizing: border-box;
          padding: 20px 50px;
          margin-bottom: 40px;
          display: flex;
          flex-direction: row;
          justify-content: flex-end; }
          .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-confirmation-total-panel .information {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end; }
            .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-confirmation-total-panel .information .subheader {
              font-size: 20px;
              font-weight: 500; }
            .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-confirmation-total-panel .information .header {
              font-size: 36px;
              font-weight: 700; }
        .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-general-information-zone {
          width: 100%;
          height: 105px;
          border-radius: 5px;
          background-color: #ffffff;
          box-sizing: border-box;
          padding: 20px 50px;
          margin-bottom: 80px;
          display: flex;
          flex-direction: row;
          justify-content: flex-start; }
          .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-general-information-zone .information {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            margin-right: 30px; }
            .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-general-information-zone .information:last-child {
              margin-left: auto;
              align-items: flex-end;
              margin-right: 0px; }
            .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-general-information-zone .information .subheader {
              font-size: 16px;
              line-height: 26px;
              font-weight: 500; }
            .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-general-information-zone .information .header {
              font-size: 30px;
              font-weight: 600; }
        .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-action-zone {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: flex-end; }
          .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-action-zone .action {
            width: 240px;
            height: 60px;
            font-size: 24px;
            font-weight: 600; }
      .investment-page-wrapper .investment-page-result-wrapper .wallet-pane {
        width: 230px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start; }
        .investment-page-wrapper .investment-page-result-wrapper .wallet-pane .action-buttons {
          display: flex;
          flex-direction: row;
          margin-top: 20px;
          justify-content: space-around; }
          .investment-page-wrapper .investment-page-result-wrapper .wallet-pane .action-buttons .button {
            width: 100px;
            padding: 7px 7px;
            font-weight: 600; }
        .investment-page-wrapper .investment-page-result-wrapper .wallet-pane .header-section {
          margin: 20px 0 20px 0; }
        .investment-page-wrapper .investment-page-result-wrapper .wallet-pane .wallet-margin {
          margin-bottom: 20px; }
        .investment-page-wrapper .investment-page-result-wrapper .wallet-pane .management-action {
          cursor: pointer; }
          .investment-page-wrapper .investment-page-result-wrapper .wallet-pane .management-action:hover {
            text-decoration: underline; }
    .investment-page-wrapper .investment-insufficient-funds-wrapper {
      max-width: 1130px;
      margin: 0 auto 0 auto;
      display: flex;
      padding-bottom: 100px;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between; }
      .investment-page-wrapper .investment-insufficient-funds-wrapper .investment-insufficient-funds-left-pane-wrapper {
        width: 800px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start; }
        .investment-page-wrapper .investment-insufficient-funds-wrapper .investment-insufficient-funds-left-pane-wrapper .investment-general-information-zone {
          width: 100%;
          height: 105px;
          border-radius: 5px;
          background-color: #ffffff;
          box-sizing: border-box;
          padding: 20px 50px;
          margin-bottom: 80px;
          display: flex;
          flex-direction: row;
          justify-content: flex-start; }
          .investment-page-wrapper .investment-insufficient-funds-wrapper .investment-insufficient-funds-left-pane-wrapper .investment-general-information-zone .information {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            margin-right: 30px; }
            .investment-page-wrapper .investment-insufficient-funds-wrapper .investment-insufficient-funds-left-pane-wrapper .investment-general-information-zone .information:last-child {
              margin-left: auto;
              align-items: flex-end;
              margin-right: 0px; }
            .investment-page-wrapper .investment-insufficient-funds-wrapper .investment-insufficient-funds-left-pane-wrapper .investment-general-information-zone .information .subheader {
              font-size: 16px;
              line-height: 26px;
              font-weight: 500; }
            .investment-page-wrapper .investment-insufficient-funds-wrapper .investment-insufficient-funds-left-pane-wrapper .investment-general-information-zone .information .header {
              font-size: 30px;
              font-weight: 600; }
        .investment-page-wrapper .investment-insufficient-funds-wrapper .investment-insufficient-funds-left-pane-wrapper .investment-deposit-selection-panel {
          position: relative;
          width: 100%;
          margin-bottom: 20px; }
          .investment-page-wrapper .investment-insufficient-funds-wrapper .investment-insufficient-funds-left-pane-wrapper .investment-deposit-selection-panel .insufficient-funds-pane {
            position: relative;
            z-index: 10;
            width: 100%; }
            .investment-page-wrapper .investment-insufficient-funds-wrapper .investment-insufficient-funds-left-pane-wrapper .investment-deposit-selection-panel .insufficient-funds-pane .investment-selection-zone {
              width: 100%;
              height: 470px;
              background-color: #ffffff;
              border-radius: 5px;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              box-sizing: border-box;
              padding: 40px 30px; }
              .investment-page-wrapper .investment-insufficient-funds-wrapper .investment-insufficient-funds-left-pane-wrapper .investment-deposit-selection-panel .insufficient-funds-pane .investment-selection-zone .header {
                font-size: 30px;
                font-weight: 700; }
              .investment-page-wrapper .investment-insufficient-funds-wrapper .investment-insufficient-funds-left-pane-wrapper .investment-deposit-selection-panel .insufficient-funds-pane .investment-selection-zone .subheader {
                text-align: left;
                font-size: 18px;
                line-height: 26px;
                font-weight: 500;
                margin-bottom: 60px; }
              .investment-page-wrapper .investment-insufficient-funds-wrapper .investment-insufficient-funds-left-pane-wrapper .investment-deposit-selection-panel .insufficient-funds-pane .investment-selection-zone .deposit-area {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                width: 100%; }
                .investment-page-wrapper .investment-insufficient-funds-wrapper .investment-insufficient-funds-left-pane-wrapper .investment-deposit-selection-panel .insufficient-funds-pane .investment-selection-zone .deposit-area .button-zone {
                  width: 200px;
                  height: 200px;
                  border: 1px solid #eaeaea;
                  border-radius: 8px;
                  cursor: pointer;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center; }
                  .investment-page-wrapper .investment-insufficient-funds-wrapper .investment-insufficient-funds-left-pane-wrapper .investment-deposit-selection-panel .insufficient-funds-pane .investment-selection-zone .deposit-area .button-zone:first-child {
                    margin-right: 25px;
                    cursor: default; }
                  .investment-page-wrapper .investment-insufficient-funds-wrapper .investment-insufficient-funds-left-pane-wrapper .investment-deposit-selection-panel .insufficient-funds-pane .investment-selection-zone .deposit-area .button-zone .button-icon {
                    font-size: 65px;
                    margin-bottom: 20px; }
                  .investment-page-wrapper .investment-insufficient-funds-wrapper .investment-insufficient-funds-left-pane-wrapper .investment-deposit-selection-panel .insufficient-funds-pane .investment-selection-zone .deposit-area .button-zone .button-text {
                    font-size: 20px;
                    font-weight: 600; }
        .investment-page-wrapper .investment-insufficient-funds-wrapper .investment-insufficient-funds-left-pane-wrapper .investment-total-calculation-panel {
          width: 100%;
          height: 105px;
          border-radius: 5px;
          background-color: #ffffff;
          box-sizing: border-box;
          padding: 20px 50px;
          margin-bottom: 80px;
          display: flex;
          flex-direction: row;
          justify-content: flex-start; }
          .investment-page-wrapper .investment-insufficient-funds-wrapper .investment-insufficient-funds-left-pane-wrapper .investment-total-calculation-panel .information {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            margin-right: 30px; }
            .investment-page-wrapper .investment-insufficient-funds-wrapper .investment-insufficient-funds-left-pane-wrapper .investment-total-calculation-panel .information:last-child {
              margin-left: auto;
              align-items: flex-end;
              margin-right: 0px; }
            .investment-page-wrapper .investment-insufficient-funds-wrapper .investment-insufficient-funds-left-pane-wrapper .investment-total-calculation-panel .information .subheader {
              font-size: 20px;
              font-weight: 500; }
            .investment-page-wrapper .investment-insufficient-funds-wrapper .investment-insufficient-funds-left-pane-wrapper .investment-total-calculation-panel .information .header {
              font-size: 36px;
              font-weight: 700; }
        .investment-page-wrapper .investment-insufficient-funds-wrapper .investment-insufficient-funds-left-pane-wrapper .investment-action-zone {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: flex-end; }
          .investment-page-wrapper .investment-insufficient-funds-wrapper .investment-insufficient-funds-left-pane-wrapper .investment-action-zone .action {
            width: 240px;
            height: 60px;
            font-size: 24px;
            font-weight: 600; }
      .investment-page-wrapper .investment-insufficient-funds-wrapper .wallet-pane {
        width: 230px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start; }
        .investment-page-wrapper .investment-insufficient-funds-wrapper .wallet-pane .action-buttons {
          display: flex;
          flex-direction: row;
          margin-top: 20px;
          justify-content: space-around; }
          .investment-page-wrapper .investment-insufficient-funds-wrapper .wallet-pane .action-buttons .button {
            width: 100px;
            padding: 7px 7px;
            font-weight: 600; }
        .investment-page-wrapper .investment-insufficient-funds-wrapper .wallet-pane .header-section {
          margin: 20px 0 20px 0; }
        .investment-page-wrapper .investment-insufficient-funds-wrapper .wallet-pane .wallet-margin {
          margin-bottom: 20px; }
        .investment-page-wrapper .investment-insufficient-funds-wrapper .wallet-pane .management-action {
          cursor: pointer; }
          .investment-page-wrapper .investment-insufficient-funds-wrapper .wallet-pane .management-action:hover {
            text-decoration: underline; } }

@media only screen and (max-width: 768px) {
  .investment-page-wrapper {
    box-sizing: border-box;
    width: 90vw;
    margin: 0 auto;
    padding-top: 30px; }
    .investment-page-wrapper .loading-wrapper {
      width: 100%;
      height: calc(100vh - 100px);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
    .investment-page-wrapper .tags-wrapper {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      flex-wrap: wrap;
      align-items: center;
      margin-bottom: 10px; }
      .investment-page-wrapper .tags-wrapper .tag {
        font-size: 14px;
        line-height: 18px;
        font-weight: 600;
        text-align: center;
        background-color: #eaeaea;
        color: #777777;
        padding: 4px 10px;
        border-radius: 3px;
        margin-right: 10px; }
    .investment-page-wrapper .header-zone-wrapper {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: 20px; }
      .investment-page-wrapper .header-zone-wrapper .header-zone {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start; }
        .investment-page-wrapper .header-zone-wrapper .header-zone a {
          text-decoration: none; }
        .investment-page-wrapper .header-zone-wrapper .header-zone .header {
          font-size: 30px;
          color: #000000;
          font-weight: 700;
          text-align: left; }
          .investment-page-wrapper .header-zone-wrapper .header-zone .header:hover {
            text-decoration: underline; }
        .investment-page-wrapper .header-zone-wrapper .header-zone .subheader {
          font-size: 16px;
          line-height: 24px;
          color: #4a4a4a;
          font-weight: 500; }
      .investment-page-wrapper .header-zone-wrapper .statistics-zone {
        display: none; }
    .investment-page-wrapper .require-login-zone-wrapper {
      width: 100%;
      padding-bottom: 20px; }
      .investment-page-wrapper .require-login-zone-wrapper .require-login {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center; }
        .investment-page-wrapper .require-login-zone-wrapper .require-login .header {
          font-size: 20px;
          color: #4a4a4a;
          font-weight: 700; }
        .investment-page-wrapper .require-login-zone-wrapper .require-login .subheader {
          font-size: 16px;
          line-height: 26px;
          color: #4a4a4a;
          font-weight: 500;
          margin: 0 20px; }
        .investment-page-wrapper .require-login-zone-wrapper .require-login .actions {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          align-items: center; }
          .investment-page-wrapper .require-login-zone-wrapper .require-login .actions .action-button {
            height: 40px;
            width: 120px; }
    .investment-page-wrapper .start-step-loading-wrapper {
      width: 100%;
      height: calc(100vh - 400px);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
    .investment-page-wrapper .investment-page-amount-wrapper {
      max-width: 94vw;
      width: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between; }
      .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start; }
        .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-general-information-zone {
          width: 100%;
          border-radius: 5px;
          background-color: transparent;
          box-sizing: border-box;
          margin-bottom: 80px;
          display: flex;
          flex-direction: row;
          justify-content: space-between; }
          .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-general-information-zone .information {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start; }
            .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-general-information-zone .information .subheader {
              font-size: 14px;
              line-height: 22px;
              font-weight: 500; }
            .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-general-information-zone .information .header {
              font-size: 16px;
              font-weight: 600; }
        .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-amount-selection-panel {
          position: relative;
          width: 100%;
          max-width: 94vw;
          margin-bottom: 20px; }
          .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-amount-selection-panel .timer-zone {
            box-sizing: border-box;
            position: absolute;
            top: -30px;
            left: 0;
            height: 40px;
            width: 240px;
            background-color: #0033ff;
            border-radius: 8px;
            z-index: 1;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding-top: 8px;
            padding-left: 10px; }
            .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-amount-selection-panel .timer-zone .timer {
              width: 40px;
              font-size: 14px !important;
              font-weight: 700 !important;
              margin-right: 15px; }
            .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-amount-selection-panel .timer-zone .text {
              font-size: 14px;
              font-weight: 500; }
          .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-amount-selection-panel .investment-pane {
            position: relative;
            z-index: 10;
            width: 100%; }
            .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-amount-selection-panel .investment-pane .investment-selection-zone {
              width: 100%;
              min-height: 400px;
              background-color: #ffffff;
              border-radius: 5px;
              display: flex;
              flex-direction: column;
              align-items: center;
              box-sizing: border-box;
              padding: 20px;
              padding-top: 70px; }
              .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-amount-selection-panel .investment-pane .investment-selection-zone .investment-pricing-table {
                width: 100%;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-between;
                align-items: flex-end;
                margin-bottom: 20px; }
                .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-amount-selection-panel .investment-pane .investment-selection-zone .investment-pricing-table .investment-pricing-active-item {
                  background-color: #ff6600 !important;
                  border-color: #ff6600 !important;
                  color: #ffffff !important; }
                .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-amount-selection-panel .investment-pane .investment-selection-zone .investment-pricing-table .investment-pricing-item {
                  height: 80px;
                  width: 30%;
                  margin-bottom: 50px;
                  border: 1px solid #777777;
                  border-radius: 4px;
                  color: #777777;
                  position: relative;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  cursor: pointer; }
                  .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-amount-selection-panel .investment-pane .investment-selection-zone .investment-pricing-table .investment-pricing-item .pricing-header {
                    font-size: 16px;
                    line-height: 20px;
                    font-weight: 700;
                    color: inherit;
                    margin: 0; }
                  .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-amount-selection-panel .investment-pane .investment-selection-zone .investment-pricing-table .investment-pricing-item .pricing-subheader {
                    font-size: 16px;
                    line-height: 20px;
                    font-weight: 500;
                    color: inherit; }
                  .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-amount-selection-panel .investment-pane .investment-selection-zone .investment-pricing-table .investment-pricing-item .preferred {
                    position: absolute;
                    top: -30px;
                    height: 25px;
                    width: 100%;
                    border: 1px solid #ff6600;
                    border-radius: 3px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    font-size: 14px;
                    line-height: 18px;
                    color: #ff6600;
                    font-weight: 600; }
              .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-amount-selection-panel .investment-pane .investment-selection-zone .investment-pricing-calculation-area {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center; }
                .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-amount-selection-panel .investment-pane .investment-selection-zone .investment-pricing-calculation-area .header {
                  font-size: 14px;
                  font-weight: 500;
                  margin-bottom: 10px; }
                .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-amount-selection-panel .investment-pane .investment-selection-zone .investment-pricing-calculation-area .form-zone {
                  width: 100%;
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;
                  margin-bottom: 20px; }
                  .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-amount-selection-panel .investment-pane .investment-selection-zone .investment-pricing-calculation-area .form-zone .input {
                    width: 35vw;
                    margin: 0 auto;
                    height: 50px; }
                .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-amount-selection-panel .investment-pane .investment-selection-zone .investment-pricing-calculation-area .subheader-zone {
                  cursor: pointer; }
                  .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-amount-selection-panel .investment-pane .investment-selection-zone .investment-pricing-calculation-area .subheader-zone .subheader {
                    font-size: 16px;
                    line-height: 34px;
                    font-weight: 300;
                    text-decoration: underline; }
        .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-total-calculation-panel {
          width: 100%;
          height: 105px;
          border-radius: 5px;
          background-color: #ffffff;
          box-sizing: border-box;
          padding: 20px 10px;
          margin-bottom: 40px;
          display: flex;
          flex-direction: row;
          justify-content: space-between; }
          .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-total-calculation-panel .information {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center; }
            .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-total-calculation-panel .information .subheader {
              font-size: 14px;
              line-height: 22px;
              font-weight: 500;
              margin-bottom: 10px; }
            .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-total-calculation-panel .information .header {
              font-size: 16px;
              font-weight: 600; }
        .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-action-zone {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: flex-end; }
          .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-action-zone .action {
            width: 94vw;
            height: 50px;
            font-size: 22px;
            font-weight: 600; }
      .investment-page-wrapper .investment-page-amount-wrapper .wallet-pane {
        display: none; }
    .investment-page-wrapper .investment-page-confirm-wrapper {
      max-width: 94vw;
      width: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between; }
      .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start; }
        .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-general-information-zone {
          width: 100%;
          border-radius: 5px;
          background-color: transparent;
          box-sizing: border-box;
          margin-bottom: 80px;
          display: flex;
          flex-direction: row;
          justify-content: space-between; }
          .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-general-information-zone .information {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start; }
            .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-general-information-zone .information .subheader {
              font-size: 14px;
              line-height: 22px;
              font-weight: 500; }
            .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-general-information-zone .information .header {
              font-size: 16px;
              font-weight: 600; }
        .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-confirm-selection-panel {
          position: relative;
          width: 100%;
          margin-bottom: 40px; }
          .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-confirm-selection-panel .timer-zone {
            box-sizing: border-box;
            position: absolute;
            top: -30px;
            left: 0;
            height: 40px;
            width: 240px;
            background-color: #0033ff;
            border-radius: 8px;
            z-index: 1;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding-top: 8px;
            padding-left: 10px; }
            .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-confirm-selection-panel .timer-zone .timer {
              width: 40px;
              font-size: 14px !important;
              font-weight: 700 !important;
              margin-right: 15px; }
            .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-confirm-selection-panel .timer-zone .text {
              font-size: 14px;
              font-weight: 500; }
          .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-confirm-selection-panel .confirm-pane {
            position: relative;
            z-index: 10;
            width: 100%; }
            .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-confirm-selection-panel .confirm-pane .confirm-information-zone {
              width: 100%;
              background-color: #ffffff;
              border-radius: 5px;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              box-sizing: border-box;
              padding: 20px; }
              .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-confirm-selection-panel .confirm-pane .confirm-information-zone .information-line {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-start; }
                .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-confirm-selection-panel .confirm-pane .confirm-information-zone .information-line .main-information {
                  width: 30%;
                  display: flex;
                  flex-direction: column;
                  justify-content: flex-start;
                  align-items: flex-start;
                  margin-bottom: 20px; }
                  .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-confirm-selection-panel .confirm-pane .confirm-information-zone .information-line .main-information .subheader {
                    text-align: left;
                    font-size: 14px;
                    font-weight: 500; }
                  .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-confirm-selection-panel .confirm-pane .confirm-information-zone .information-line .main-information .header {
                    text-align: left;
                    font-size: 16px;
                    font-weight: 700; }
                .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-confirm-selection-panel .confirm-pane .confirm-information-zone .information-line .supplementary-information {
                  display: flex;
                  flex-direction: column;
                  justify-content: flex-start;
                  align-items: flex-start;
                  margin-bottom: 20px; }
                  .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-confirm-selection-panel .confirm-pane .confirm-information-zone .information-line .supplementary-information .subheader {
                    font-size: 14px;
                    line-height: 20px;
                    font-weight: 500; }
                  .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-confirm-selection-panel .confirm-pane .confirm-information-zone .information-line .supplementary-information .header {
                    font-size: 16px;
                    font-weight: 600; }
        .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-confirmation-total-panel {
          display: none; }
        .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-action-zone {
          width: 100%; }
          .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-action-zone form {
            width: 100%; }
            .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-action-zone form .form-container {
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: center; }
              .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-action-zone form .form-container .aggrements {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                box-sizing: border-box;
                padding-bottom: 5px; }
                .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-action-zone form .form-container .aggrements .checkbox {
                  margin-bottom: 10px !important; }
                  .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-action-zone form .form-container .aggrements .checkbox:last-child {
                    margin-bottom: 0px !important; }
              .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-action-zone form .form-container .action {
                margin-top: 20px;
                width: 94vw;
                height: 50px;
                font-size: 22px;
                font-weight: 600; }
      .investment-page-wrapper .investment-page-confirm-wrapper .wallet-pane {
        display: none; }
    .investment-page-wrapper .investment-page-result-wrapper {
      max-width: 94vw;
      width: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between; }
      .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start; }
        .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-result-success-header-zone {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 40px; }
          .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-result-success-header-zone .icon {
            font-size: 30px;
            margin-right: 15px; }
          .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-result-success-header-zone .text-area {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start; }
            .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-result-success-header-zone .text-area .header {
              text-align: left;
              font-size: 16px;
              color: #4a4a4a;
              font-weight: 700;
              margin-bottom: 10px; }
            .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-result-success-header-zone .text-area .subheader {
              text-align: left;
              font-size: 14px;
              line-height: 18px;
              color: #4a4a4a;
              font-weight: 500; }
        .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-result-selection-panel {
          position: relative;
          width: 100%;
          margin-bottom: 20px; }
          .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-result-selection-panel .result-pane {
            position: relative;
            z-index: 10;
            width: 100%; }
            .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-result-selection-panel .result-pane .result-information-zone {
              width: 100%;
              background-color: #ffffff;
              border-radius: 5px;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              box-sizing: border-box;
              padding: 20px; }
              .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-result-selection-panel .result-pane .result-information-zone .information-line {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-start; }
                .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-result-selection-panel .result-pane .result-information-zone .information-line .main-information {
                  width: 30%;
                  display: flex;
                  flex-direction: column;
                  justify-content: flex-start;
                  align-items: flex-start;
                  margin-bottom: 20px; }
                  .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-result-selection-panel .result-pane .result-information-zone .information-line .main-information .subheader {
                    text-align: left;
                    font-size: 14px;
                    font-weight: 500; }
                  .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-result-selection-panel .result-pane .result-information-zone .information-line .main-information .header {
                    text-align: left;
                    font-size: 16px;
                    font-weight: 700; }
                .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-result-selection-panel .result-pane .result-information-zone .information-line .supplementary-information {
                  display: flex;
                  flex-direction: column;
                  justify-content: flex-start;
                  align-items: flex-start;
                  margin-bottom: 20px; }
                  .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-result-selection-panel .result-pane .result-information-zone .information-line .supplementary-information .subheader {
                    font-size: 14px;
                    line-height: 20px;
                    font-weight: 500; }
                  .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-result-selection-panel .result-pane .result-information-zone .information-line .supplementary-information .header {
                    font-size: 16px;
                    font-weight: 600; }
        .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-confirmation-total-panel {
          width: 100%;
          height: 105px;
          border-radius: 5px;
          background-color: #ffffff;
          box-sizing: border-box;
          padding: 20px;
          margin-bottom: 40px;
          display: flex;
          flex-direction: row;
          justify-content: flex-end; }
          .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-confirmation-total-panel .information {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end; }
            .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-confirmation-total-panel .information .subheader {
              font-size: 14px;
              font-weight: 500; }
            .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-confirmation-total-panel .information .header {
              font-size: 16px;
              font-weight: 700; }
        .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-general-information-zone {
          width: 100%;
          border-radius: 5px;
          background-color: #ffffff;
          box-sizing: border-box;
          padding: 20px;
          margin-bottom: 40px;
          display: flex;
          flex-direction: row;
          justify-content: flex-start; }
          .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-general-information-zone .information {
            display: flex;
            flex-direction: column;
            justify-content: space-between; }
            .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-general-information-zone .information .subheader {
              font-size: 14px;
              line-height: 22px;
              font-weight: 500;
              margin-bottom: 10px; }
            .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-general-information-zone .information .header {
              font-size: 16px;
              font-weight: 600; }
        .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-action-zone {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: flex-end; }
          .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-action-zone .action {
            width: 94vw;
            height: 50px;
            font-size: 22px;
            font-weight: 600; }
      .investment-page-wrapper .investment-page-result-wrapper .wallet-pane {
        display: none; }
    .investment-page-wrapper .investment-insufficient-funds-wrapper {
      max-width: 94vw;
      width: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between; }
      .investment-page-wrapper .investment-insufficient-funds-wrapper .investment-insufficient-funds-left-pane-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start; }
        .investment-page-wrapper .investment-insufficient-funds-wrapper .investment-insufficient-funds-left-pane-wrapper .investment-general-information-zone {
          width: 100%;
          border-radius: 5px;
          background-color: #ffffff;
          box-sizing: border-box;
          padding: 20px;
          margin-bottom: 40px;
          display: flex;
          flex-direction: row;
          justify-content: space-between; }
          .investment-page-wrapper .investment-insufficient-funds-wrapper .investment-insufficient-funds-left-pane-wrapper .investment-general-information-zone .information {
            display: flex;
            flex-direction: column;
            justify-content: space-between; }
            .investment-page-wrapper .investment-insufficient-funds-wrapper .investment-insufficient-funds-left-pane-wrapper .investment-general-information-zone .information .subheader {
              font-size: 14px;
              line-height: 22px;
              font-weight: 500; }
            .investment-page-wrapper .investment-insufficient-funds-wrapper .investment-insufficient-funds-left-pane-wrapper .investment-general-information-zone .information .header {
              font-size: 16px;
              font-weight: 600; }
        .investment-page-wrapper .investment-insufficient-funds-wrapper .investment-insufficient-funds-left-pane-wrapper .investment-deposit-selection-panel {
          position: relative;
          width: 100%;
          margin-bottom: 20px; }
          .investment-page-wrapper .investment-insufficient-funds-wrapper .investment-insufficient-funds-left-pane-wrapper .investment-deposit-selection-panel .insufficient-funds-pane {
            position: relative;
            z-index: 10;
            width: 100%; }
            .investment-page-wrapper .investment-insufficient-funds-wrapper .investment-insufficient-funds-left-pane-wrapper .investment-deposit-selection-panel .insufficient-funds-pane .investment-selection-zone {
              width: 100%;
              background-color: transparent;
              border-radius: 5px;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              box-sizing: border-box;
              padding: 20px; }
              .investment-page-wrapper .investment-insufficient-funds-wrapper .investment-insufficient-funds-left-pane-wrapper .investment-deposit-selection-panel .insufficient-funds-pane .investment-selection-zone .header {
                font-size: 16px;
                font-weight: 700; }
              .investment-page-wrapper .investment-insufficient-funds-wrapper .investment-insufficient-funds-left-pane-wrapper .investment-deposit-selection-panel .insufficient-funds-pane .investment-selection-zone .subheader {
                text-align: left;
                font-size: 14px;
                line-height: 18px;
                font-weight: 500;
                margin-bottom: 30px; }
              .investment-page-wrapper .investment-insufficient-funds-wrapper .investment-insufficient-funds-left-pane-wrapper .investment-deposit-selection-panel .insufficient-funds-pane .investment-selection-zone .deposit-area {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 100%; }
                .investment-page-wrapper .investment-insufficient-funds-wrapper .investment-insufficient-funds-left-pane-wrapper .investment-deposit-selection-panel .insufficient-funds-pane .investment-selection-zone .deposit-area .button-zone {
                  width: 45%;
                  height: 120px;
                  border: 1px solid #eaeaea;
                  border-radius: 8px;
                  cursor: pointer;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center; }
                  .investment-page-wrapper .investment-insufficient-funds-wrapper .investment-insufficient-funds-left-pane-wrapper .investment-deposit-selection-panel .insufficient-funds-pane .investment-selection-zone .deposit-area .button-zone .button-icon {
                    font-size: 40px;
                    margin-bottom: 20px; }
                  .investment-page-wrapper .investment-insufficient-funds-wrapper .investment-insufficient-funds-left-pane-wrapper .investment-deposit-selection-panel .insufficient-funds-pane .investment-selection-zone .deposit-area .button-zone .button-text {
                    font-size: 16px;
                    font-weight: 600;
                    color: #4a4a4a; }
        .investment-page-wrapper .investment-insufficient-funds-wrapper .investment-insufficient-funds-left-pane-wrapper .investment-total-calculation-panel {
          width: 100%;
          border-radius: 5px;
          background-color: #ffffff;
          box-sizing: border-box;
          padding: 20px;
          margin-bottom: 40px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between; }
          .investment-page-wrapper .investment-insufficient-funds-wrapper .investment-insufficient-funds-left-pane-wrapper .investment-total-calculation-panel .information {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center; }
            .investment-page-wrapper .investment-insufficient-funds-wrapper .investment-insufficient-funds-left-pane-wrapper .investment-total-calculation-panel .information .subheader {
              font-size: 14px;
              font-weight: 500; }
            .investment-page-wrapper .investment-insufficient-funds-wrapper .investment-insufficient-funds-left-pane-wrapper .investment-total-calculation-panel .information .header {
              font-size: 16px;
              font-weight: 700; }
        .investment-page-wrapper .investment-insufficient-funds-wrapper .investment-insufficient-funds-left-pane-wrapper .investment-action-zone {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: flex-end; }
          .investment-page-wrapper .investment-insufficient-funds-wrapper .investment-insufficient-funds-left-pane-wrapper .investment-action-zone .action {
            width: 94vw;
            height: 50px;
            font-size: 22px;
            font-weight: 600; }
      .investment-page-wrapper .investment-insufficient-funds-wrapper .wallet-pane {
        display: none; } }

/* Global style guide for UI Lib */
/**
 * Browser support
 */
/**
 * Typography
 */
/**
 * Colors
 */
/**
 * Page -- UILib Specific Variables
 */
/**
 * Card -- UILib Specific Variables
 */
@media only screen and (min-width: 769px) {
  .login-form-zone {
    justify-content: flex-start;
    min-height: 575px; }
    .login-form-zone .header {
      margin-bottom: 20px; }
    .login-form-zone .description {
      margin-bottom: 60px; }
    .login-form-zone .bottom-section {
      margin-top: auto;
      font-size: 14px; }
    .login-form-zone .submit-container-with-error {
      margin-bottom: 40px; }
    .login-form-zone .login-submit-container {
      text-align: right; }
  .register-form-zone {
    justify-content: flex-start; }
    .register-form-zone .description {
      width: 350px;
      margin: 20px auto 50px auto;
      font-size: 18px;
      line-height: 26px;
      font-weight: 500;
      color: #4a4a4a; }
    .register-form-zone .bottom-section {
      margin-top: auto;
      font-size: 14px; }
  .custom-form-zone {
    justify-content: flex-start; }
    .custom-form-zone .header {
      width: 240px;
      margin: 0 auto;
      margin-bottom: 40px; }
    .custom-form-zone .send-verification-header {
      width: 360px;
      margin: 0 auto;
      margin-bottom: 40px; }
    .custom-form-zone .verify-code-otp-header {
      width: 420px;
      margin: 0 auto;
      margin-bottom: 40px; }
    .custom-form-zone .verify-user-info-header {
      width: 420px;
      margin: 0 auto;
      margin-bottom: 40px; }
    .custom-form-zone .execute-header {
      width: 430px;
      margin: 0 auto;
      margin-bottom: 40px; }
    .custom-form-zone .reset-password-start-description {
      width: 340px;
      margin: 0 auto;
      margin-bottom: 40px; }
    .custom-form-zone .verify-code-otp-description {
      width: 400px;
      margin: 0 auto;
      margin-bottom: 56px; }
    .custom-form-zone .verify-user-info-description {
      width: 335px;
      margin: 0 auto;
      margin-bottom: 40px; }
    .custom-form-zone .execute-description {
      width: 335px;
      margin: 0 auto;
      margin-bottom: 66px; }
    .custom-form-zone .timer {
      margin-bottom: 20px; }
    .custom-form-zone .verify-code-otp-action-margin {
      margin-top: 36px; }
    .custom-form-zone .verify-user-information-action {
      margin-top: 55px; }
    .custom-form-zone .bottom-section {
      margin-top: auto;
      font-size: 14px; }
  .custom-form-container {
    width: 330px;
    margin: 0 auto; }
    .custom-form-container .descriptive-text {
      margin-bottom: 20px; }
  .form-container {
    width: 300px; }
    .form-container .reset-password {
      text-align: right;
      margin-top: -5px;
      margin-bottom: 10px; }
    .form-container .error-container {
      min-height: 35px;
      padding-bottom: 10px;
      margin-bottom: 10px; }
    .form-container .recaptcha-container {
      padding-bottom: 10px; }
    .form-container .submit-container {
      text-align: right;
      margin-top: 20px; }
    .form-container .execute-error-container {
      min-height: 35px;
      margin-top: 50px; }
    .form-container .execute-submit-container {
      text-align: right;
      margin-top: 80px; }
    .form-container .execute-submit-container-with-error {
      text-align: right;
      margin-top: 20px; }
    .form-container .submit-container-with-error {
      text-align: right; }
  .authentication-result-container {
    width: 300px;
    margin: 0 auto; }
  .authentication-result-content-container {
    height: 260px;
    text-align: center; }
    .authentication-result-content-container .icon {
      width: 130px;
      height: 130px;
      margin: 0 auto; }
    .authentication-result-content-container .content-text {
      margin: 50px auto 0 auto; }
  .authentication-result-footer-container {
    margin: 0 auto; }
  .authentication-card-centered {
    justify-content: center;
    align-items: center; }
  .send-verification-container {
    height: 430px; }
    .send-verification-container .descriptive-text {
      width: 330px;
      margin: 0 auto;
      margin-bottom: 60px; }
    .send-verification-container .option-selection {
      width: 425px;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      justify-content: space-between; }
      .send-verification-container .option-selection .option-button {
        width: 200px;
        height: 167px;
        box-sizing: border-box;
        border: 1px solid #eaeaea;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center; }
        .send-verification-container .option-selection .option-button .icon {
          margin-bottom: 20px; }
  .verify-code-otp-container .descriptive-text {
    width: 330px;
    margin: 0 auto;
    margin-bottom: 20px; }
  .verify-code-otp-container .digits-container {
    text-align: center; }
    .verify-code-otp-container .digits-container .tries-text {
      text-align: right;
      margin-right: 15px;
      margin-bottom: 20px; }
  .verify-code-otp-container .generic-container {
    width: 330px;
    margin: 0 auto;
    margin-bottom: 20px; }
  .verify-code-otp-container .actions-container {
    display: flex;
    flex-direction: 'row';
    justify-content: space-between; }
  .verify-code-otp-container .right-align {
    text-align: right; } }

@media only screen and (max-width: 768px) {
  .login-form-zone {
    justify-content: flex-start;
    min-height: 575px; }
    .login-form-zone .header {
      margin-bottom: 20px;
      font-size: 24px; }
    .login-form-zone .description {
      margin-bottom: 60px;
      font-size: 16px; }
    .login-form-zone .bottom-section {
      margin-top: auto;
      font-size: 14px; }
    .login-form-zone .submit-container-with-error {
      margin-bottom: 40px; }
    .login-form-zone .login-submit-container {
      text-align: right; }
  .register-form-zone {
    justify-content: flex-start;
    min-height: 575px; }
    .register-form-zone .header {
      margin-bottom: 20px;
      font-size: 24px; }
    .register-form-zone .description {
      width: 80vw;
      margin: 20px auto 50px auto;
      font-size: 16px;
      line-height: 26px;
      font-weight: 500;
      color: #4a4a4a; }
    .register-form-zone .bottom-section {
      margin-top: 20px;
      font-size: 14px; }
  .generic-authentication-header {
    font-size: 24px;
    margin-bottom: 20px; }
  .custom-form-zone {
    justify-content: flex-start;
    min-height: 575px;
    width: 90vw; }
    .custom-form-zone .header {
      font-size: 24px;
      margin: 0 auto;
      margin-bottom: 40px; }
    .custom-form-zone .send-verification-header {
      font-size: 24px;
      margin: 0 auto;
      margin-bottom: 40px; }
    .custom-form-zone .verify-code-otp-header {
      font-size: 24px;
      width: 90%;
      margin: 0 auto;
      margin-bottom: 40px; }
    .custom-form-zone .verify-user-info-header {
      font-size: 24px;
      margin: 0 auto;
      margin-bottom: 40px; }
    .custom-form-zone .execute-header {
      font-size: 24px;
      margin: 0 auto;
      margin-bottom: 40px; }
    .custom-form-zone .reset-password-start-description {
      font-size: 16px;
      margin: 0 auto;
      margin-bottom: 40px; }
    .custom-form-zone .verify-code-otp-description {
      font-size: 16px;
      margin: 0 auto;
      margin-bottom: 56px; }
    .custom-form-zone .verify-user-info-description {
      font-size: 16px;
      margin: 0 auto;
      margin-bottom: 40px; }
    .custom-form-zone .execute-description {
      font-size: 16px;
      margin: 0 auto;
      margin-bottom: 66px; }
    .custom-form-zone .timer {
      font-size: 24px;
      margin-bottom: 20px; }
    .custom-form-zone .verify-code-otp-action-margin {
      margin-top: 36px; }
    .custom-form-zone .verify-user-information-action {
      margin-top: 55px; }
    .custom-form-zone .bottom-section {
      margin-top: 20px;
      font-size: 14px; }
  .custom-form-container {
    width: 80vw;
    margin: 0 auto; }
    .custom-form-container .descriptive-text {
      font-size: 16px;
      margin-bottom: 20px; }
  .form-container {
    width: 80vw;
    margin: 0 auto; }
    .form-container .reset-password {
      text-align: right;
      margin-top: 0px;
      margin-bottom: 10px; }
    .form-container .error-container {
      min-height: 35px;
      padding-bottom: 10px;
      margin-bottom: 10px; }
    .form-container .recaptcha-container {
      padding-bottom: 10px; }
    .form-container .submit-container {
      text-align: right;
      margin-top: 20px; }
    .form-container .execute-error-container {
      min-height: 35px;
      margin-top: 50px; }
    .form-container .execute-submit-container {
      text-align: right;
      margin-top: 80px; }
    .form-container .execute-submit-container-with-error {
      text-align: right;
      margin-top: 20px; }
    .form-container .submit-container-with-error {
      text-align: right; }
  .authentication-result-container {
    width: 80vw;
    margin: 0 auto; }
    .authentication-result-container .result-header {
      font-size: 24px;
      margin-bottom: 40px; }
  .authentication-result-content-container {
    min-height: 300px;
    text-align: center; }
    .authentication-result-content-container .result-header {
      font-size: 24px; }
    .authentication-result-content-container .icon {
      width: 120px;
      height: 120px;
      margin: 0 auto; }
    .authentication-result-content-container .content-text {
      font-size: 12px;
      margin: 50px auto 0 auto; }
  .authentication-result-footer-container {
    margin: 0 auto; }
  .authentication-card-centered {
    justify-content: center;
    align-items: center; }
  .send-verification-container {
    height: 430px; }
    .send-verification-container .descriptive-text {
      font-size: 16px;
      margin: 0 auto;
      margin-bottom: 60px; }
    .send-verification-container .option-selection {
      width: 80vw;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      justify-content: space-between; }
      .send-verification-container .option-selection .option-button {
        width: 35vw;
        height: 165px;
        box-sizing: border-box;
        border: 1px solid #eaeaea;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center; }
        .send-verification-container .option-selection .option-button .icon {
          margin-bottom: 20px; }
  .verify-code-otp-container {
    min-height: 200px; }
    .verify-code-otp-container .descriptive-text {
      width: 80vw;
      margin: 0 auto;
      margin-bottom: 20px;
      font-size: 16px; }
    .verify-code-otp-container .timer-text {
      width: 80vw;
      margin: 0 auto;
      margin-bottom: 20px;
      font-size: 24px; }
    .verify-code-otp-container .digits-container {
      text-align: center; }
      .verify-code-otp-container .digits-container .tries-text {
        text-align: right;
        margin-right: 15px;
        margin-bottom: 20px; }
    .verify-code-otp-container .generic-container {
      width: 80vw;
      margin: 0 auto;
      margin-bottom: 20px; }
    .verify-code-otp-container .actions-container {
      display: flex;
      flex-direction: 'row';
      justify-content: space-between; }
    .verify-code-otp-container .right-align {
      text-align: right; }
  .padded-string {
    padding-top: 40px; } }

/* Global style guide for UI Lib */
/**
 * Browser support
 */
/**
 * Typography
 */
/**
 * Colors
 */
/**
 * Page -- UILib Specific Variables
 */
/**
 * Card -- UILib Specific Variables
 */
@media only screen and (min-width: 769px) {
  .withdraw-create-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 1130px;
    box-sizing: border-box;
    margin: 0 auto;
    margin-top: 60px;
    padding-bottom: 60px; }
    .withdraw-create-wrapper .withdraw-header {
      color: #4a4a4a;
      font-weight: 700;
      font-size: 36px;
      margin-bottom: 10px; }
    .withdraw-create-wrapper .withdraw-text {
      color: #4a4a4a;
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      margin-bottom: 40px; }
    .withdraw-create-wrapper .withdraw-zone {
      background-color: #ffffff;
      width: 100%;
      min-height: 630px;
      border-radius: 8px;
      box-sizing: border-box;
      padding: 45px 60px 60px 60px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start; }
      .withdraw-create-wrapper .withdraw-zone .wallet-selection-zone {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
        margin-bottom: 60px; }
        .withdraw-create-wrapper .withdraw-zone .wallet-selection-zone .wallet-selection .header {
          color: #4a4a4a;
          font-size: 24px;
          font-weight: 600; }
        .withdraw-create-wrapper .withdraw-zone .wallet-selection-zone .wallet-selection .subheader {
          color: #4a4a4a;
          font-size: 16px;
          line-height: 26px;
          font-weight: 500;
          margin-bottom: 15px; }
        .withdraw-create-wrapper .withdraw-zone .wallet-selection-zone .wallet-selection .wallet-selector {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: flex-start; }
          .withdraw-create-wrapper .withdraw-zone .wallet-selection-zone .wallet-selection .wallet-selector .wallet-selector-item {
            width: 100px;
            height: 70px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-size: 18px;
            font-weight: 600;
            color: #4a4a4a;
            border: 1px solid #999999;
            border-radius: 8px;
            margin-right: 10px;
            cursor: pointer; }
          .withdraw-create-wrapper .withdraw-zone .wallet-selection-zone .wallet-selection .wallet-selector .selected-wallet {
            background-color: #0033ff;
            border-color: #0033ff;
            color: #ffffff; }
        .withdraw-create-wrapper .withdraw-zone .wallet-selection-zone .wallet-details .wallet {
          box-sizing: border-box;
          min-width: 230px;
          border-radius: 8px;
          height: 120px;
          background-color: transparent;
          color: #333333;
          padding: 10px 15px;
          border: 1px solid #333333;
          display: flex;
          flex-direction: column;
          justify-content: space-between; }
          .withdraw-create-wrapper .withdraw-zone .wallet-selection-zone .wallet-details .wallet .header-zone {
            width: 100%;
            text-align: left; }
            .withdraw-create-wrapper .withdraw-zone .wallet-selection-zone .wallet-details .wallet .header-zone .header {
              font-size: 2em;
              font-weight: 600;
              line-height: 1.25;
              color: inherit; }
            .withdraw-create-wrapper .withdraw-zone .wallet-selection-zone .wallet-details .wallet .header-zone .subheader {
              font-size: 14px;
              font-weight: 500;
              color: inherit; }
          .withdraw-create-wrapper .withdraw-zone .wallet-selection-zone .wallet-details .wallet .balance-zone {
            width: 100%;
            text-align: right;
            color: inherit; }
            .withdraw-create-wrapper .withdraw-zone .wallet-selection-zone .wallet-details .wallet .balance-zone .integer {
              font-size: 28px;
              font-weight: 700; }
            .withdraw-create-wrapper .withdraw-zone .wallet-selection-zone .wallet-details .wallet .balance-zone .float {
              font-size: 28px;
              font-weight: 700; }
            .withdraw-create-wrapper .withdraw-zone .wallet-selection-zone .wallet-details .wallet .balance-zone .currency {
              margin-left: 4px;
              font-size: 14px;
              font-weight: 700; }
      .withdraw-create-wrapper .withdraw-zone .withdraw-form-zone {
        width: 385px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center; }
        .withdraw-create-wrapper .withdraw-zone .withdraw-form-zone form {
          width: 100%; }
          .withdraw-create-wrapper .withdraw-zone .withdraw-form-zone form .form-container {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start; }
            .withdraw-create-wrapper .withdraw-zone .withdraw-form-zone form .form-container .wallet-address {
              width: 100%;
              margin-bottom: 20px; }
              .withdraw-create-wrapper .withdraw-zone .withdraw-form-zone form .form-container .wallet-address .input-text {
                width: 100%;
                margin-left: 15px;
                font-size: 20px;
                font-weight: 700;
                color: #4a4a4a;
                margin-bottom: 5px; }
              .withdraw-create-wrapper .withdraw-zone .withdraw-form-zone form .form-container .wallet-address .input {
                width: 100%;
                height: 50px;
                border-radius: 4px;
                border: 1px solid #d9d9d9;
                outline: none;
                box-sizing: border-box;
                padding: 13px 20px 13px 20px;
                font-size: 18px;
                line-height: 24px; }
              .withdraw-create-wrapper .withdraw-zone .withdraw-form-zone form .form-container .wallet-address .error-input {
                border: 1px solid #ff6600; }
            .withdraw-create-wrapper .withdraw-zone .withdraw-form-zone form .form-container .amount {
              width: 100%;
              margin-bottom: 60px; }
              .withdraw-create-wrapper .withdraw-zone .withdraw-form-zone form .form-container .amount .input-text {
                width: 100%;
                margin-left: 15px;
                font-size: 20px;
                font-weight: 700;
                color: #4a4a4a;
                margin-bottom: 5px; }
              .withdraw-create-wrapper .withdraw-zone .withdraw-form-zone form .form-container .amount .input {
                width: 100%;
                height: 50px;
                border-radius: 4px;
                border: 1px solid #d9d9d9;
                outline: none;
                box-sizing: border-box;
                padding: 13px 20px 13px 20px;
                font-size: 18px;
                line-height: 24px; }
              .withdraw-create-wrapper .withdraw-zone .withdraw-form-zone form .form-container .amount .error-input {
                border: 1px solid #ff6600; }
            .withdraw-create-wrapper .withdraw-zone .withdraw-form-zone form .form-container .error {
              width: 100%;
              margin-bottom: 20px; }
            .withdraw-create-wrapper .withdraw-zone .withdraw-form-zone form .form-container .toc {
              margin: 0 auto;
              margin-bottom: 10px; }
            .withdraw-create-wrapper .withdraw-zone .withdraw-form-zone form .form-container .action {
              margin: 0 auto;
              width: 240px;
              height: 60px;
              font-size: 24px;
              font-weight: 600; }
  .withdraw-confirm-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 1130px;
    box-sizing: border-box;
    margin: 0 auto;
    margin-top: 60px;
    padding-bottom: 60px; }
    .withdraw-confirm-wrapper .withdraw-header {
      color: #4a4a4a;
      font-weight: 700;
      font-size: 36px;
      margin-bottom: 10px; }
    .withdraw-confirm-wrapper .withdraw-text {
      color: #4a4a4a;
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      margin-bottom: 40px; }
    .withdraw-confirm-wrapper .confirm-card {
      margin-top: 0;
      display: flex;
      justify-content: flex-start;
      align-items: center; }
      .withdraw-confirm-wrapper .confirm-card .header {
        color: #4a4a4a;
        font-weight: 700;
        font-size: 36px;
        margin-bottom: 20px; }
      .withdraw-confirm-wrapper .confirm-card .subheader {
        color: #4a4a4a;
        font-weight: 500;
        font-size: 18px;
        line-height: 26px;
        margin-bottom: 60px; }
      .withdraw-confirm-wrapper .confirm-card .time {
        color: #4a4a4a;
        font-weight: 700;
        font-size: 36px;
        margin-bottom: 20px; }
      .withdraw-confirm-wrapper .confirm-card .digits-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-bottom: 94px; }
        .withdraw-confirm-wrapper .confirm-card .digits-container .tries-text {
          width: 100%;
          text-align: right; }
      .withdraw-confirm-wrapper .confirm-card .error {
        width: 100%;
        margin-top: -30px;
        margin-bottom: 20px; }
      .withdraw-confirm-wrapper .confirm-card .action-zone {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: flex-end; }
        .withdraw-confirm-wrapper .confirm-card .action-zone .action-button {
          width: 150px;
          height: 50px;
          font-size: 18px;
          font-weight: 600; }
  .withdraw-failure-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 800px;
    box-sizing: border-box;
    margin: 0 auto;
    margin-top: 80px;
    padding-bottom: 60px; }
    .withdraw-failure-wrapper .failure-header-zone {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      height: 100px;
      margin-bottom: 60px; }
      .withdraw-failure-wrapper .failure-header-zone .icon {
        font-size: 100px; }
      .withdraw-failure-wrapper .failure-header-zone .failure-header-content {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-left: 40px; }
        .withdraw-failure-wrapper .failure-header-zone .failure-header-content .failure-header {
          color: #4a4a4a;
          font-weight: 700;
          font-size: 30px; }
        .withdraw-failure-wrapper .failure-header-zone .failure-header-content .failure-text {
          color: #4a4a4a;
          font-weight: 500;
          font-size: 18px;
          line-height: 26px; }
    .withdraw-failure-wrapper .failure-general-zone {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
      height: 360px;
      box-sizing: border-box;
      padding: 40px;
      margin-bottom: 60px;
      background-color: #ffffff;
      border-radius: 5px; }
      .withdraw-failure-wrapper .failure-general-zone .failure-large-header {
        color: #4a4a4a;
        font-size: 20px;
        font-weight: 500; }
      .withdraw-failure-wrapper .failure-general-zone .failure-large-text {
        color: #4a4a4a;
        font-size: 36px;
        font-weight: 700; }
      .withdraw-failure-wrapper .failure-general-zone .failure-header {
        color: #999999;
        font-size: 16px;
        line-height: 26px;
        font-weight: 500; }
      .withdraw-failure-wrapper .failure-general-zone .failure-text {
        color: #4a4a4a;
        font-size: 20px;
        font-weight: 600; }
    .withdraw-failure-wrapper .failure-action-zone {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start; }
      .withdraw-failure-wrapper .failure-action-zone .button {
        width: 240px;
        height: 60px;
        font-size: 24px;
        font-weight: 600; }
  .withdraw-success-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 800px;
    box-sizing: border-box;
    margin: 0 auto;
    margin-top: 80px;
    padding-bottom: 60px; }
    .withdraw-success-wrapper .success-header-zone {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      height: 100px;
      margin-bottom: 60px; }
      .withdraw-success-wrapper .success-header-zone .icon {
        font-size: 100px; }
      .withdraw-success-wrapper .success-header-zone .success-header-content {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-left: 40px; }
        .withdraw-success-wrapper .success-header-zone .success-header-content .success-header {
          color: #4a4a4a;
          font-weight: 700;
          font-size: 30px; }
        .withdraw-success-wrapper .success-header-zone .success-header-content .success-text {
          color: #4a4a4a;
          font-weight: 500;
          font-size: 18px;
          line-height: 26px; }
    .withdraw-success-wrapper .success-general-zone {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
      height: 360px;
      box-sizing: border-box;
      padding: 40px;
      margin-bottom: 60px;
      background-color: #ffffff;
      border-radius: 5px; }
      .withdraw-success-wrapper .success-general-zone .success-large-header {
        color: #4a4a4a;
        font-size: 20px;
        font-weight: 500; }
      .withdraw-success-wrapper .success-general-zone .success-large-text {
        color: #4a4a4a;
        font-size: 36px;
        font-weight: 700; }
      .withdraw-success-wrapper .success-general-zone .success-header {
        color: #999999;
        font-size: 16px;
        line-height: 26px;
        font-weight: 500; }
      .withdraw-success-wrapper .success-general-zone .success-text {
        color: #4a4a4a;
        font-size: 20px;
        font-weight: 600; }
    .withdraw-success-wrapper .success-action-zone {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-start; }
      .withdraw-success-wrapper .success-action-zone .button {
        width: 240px;
        height: 60px;
        font-size: 24px;
        font-weight: 600; }
  .confirmation-modal {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%; }
    .confirmation-modal .confirmation-modal-header {
      font-size: 32px;
      font-weight: 700; }
    .confirmation-modal .confirmation-modal-subheader {
      font-size: 22px;
      font-weight: 500;
      line-height: 30px;
      margin-top: 40px; }
    .confirmation-modal .confirmation-modal-icon {
      font-size: 100px;
      color: #ff6600;
      text-align: center;
      margin-top: 80px;
      margin-bottom: 100px; }
    .confirmation-modal .confirmation-actions {
      margin-top: auto;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between; }
      .confirmation-modal .confirmation-actions .button {
        width: 46%;
        height: 50px; } }

@media only screen and (max-width: 768px) {
  .withdraw-create-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: calc(100%-40px);
    box-sizing: border-box;
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding-bottom: 60px; }
    .withdraw-create-wrapper .withdraw-header {
      color: #4a4a4a;
      font-weight: 700;
      font-size: 24px;
      margin-bottom: 10px; }
    .withdraw-create-wrapper .withdraw-text {
      color: #4a4a4a;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 30px; }
    .withdraw-create-wrapper .withdraw-zone {
      background-color: transparent;
      width: 100%;
      min-height: 600px;
      border-radius: 8px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: flex-start; }
      .withdraw-create-wrapper .withdraw-zone .wallet-selection-zone {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
        margin-bottom: 40px; }
        .withdraw-create-wrapper .withdraw-zone .wallet-selection-zone .wallet-selection .header {
          color: #4a4a4a;
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 10px; }
        .withdraw-create-wrapper .withdraw-zone .wallet-selection-zone .wallet-selection .subheader {
          display: none; }
        .withdraw-create-wrapper .withdraw-zone .wallet-selection-zone .wallet-selection .wallet-selector {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: flex-start; }
          .withdraw-create-wrapper .withdraw-zone .wallet-selection-zone .wallet-selection .wallet-selector .wallet-selector-item {
            width: 100px;
            height: 50px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-size: 16px;
            font-weight: 600;
            color: #4a4a4a;
            border: 1px solid #999999;
            border-radius: 8px;
            margin-right: 10px;
            cursor: pointer;
            margin-bottom: 40px; }
          .withdraw-create-wrapper .withdraw-zone .wallet-selection-zone .wallet-selection .wallet-selector .selected-wallet {
            background-color: #0033ff;
            border-color: #0033ff;
            color: #ffffff; }
        .withdraw-create-wrapper .withdraw-zone .wallet-selection-zone .wallet-details {
          width: 100%; }
          .withdraw-create-wrapper .withdraw-zone .wallet-selection-zone .wallet-details .wallet {
            box-sizing: border-box;
            width: 100%;
            height: 60px;
            background-color: #ffffff;
            border-radius: 4px;
            color: #333333;
            padding: 10px 15px;
            display: flex;
            flex-direction: row;
            justify-content: space-between; }
            .withdraw-create-wrapper .withdraw-zone .wallet-selection-zone .wallet-details .wallet .header-zone {
              width: 40%;
              text-align: left; }
              .withdraw-create-wrapper .withdraw-zone .wallet-selection-zone .wallet-details .wallet .header-zone .header {
                font-size: 16px;
                font-weight: 600;
                line-height: 1.25;
                color: inherit; }
              .withdraw-create-wrapper .withdraw-zone .wallet-selection-zone .wallet-details .wallet .header-zone .subheader {
                font-size: 10px;
                font-weight: 500;
                color: inherit; }
            .withdraw-create-wrapper .withdraw-zone .wallet-selection-zone .wallet-details .wallet .balance-zone {
              width: 60%;
              text-align: right;
              color: inherit; }
              .withdraw-create-wrapper .withdraw-zone .wallet-selection-zone .wallet-details .wallet .balance-zone .integer {
                font-size: 28px;
                font-weight: 700; }
              .withdraw-create-wrapper .withdraw-zone .wallet-selection-zone .wallet-details .wallet .balance-zone .float {
                font-size: 28px;
                font-weight: 700; }
              .withdraw-create-wrapper .withdraw-zone .wallet-selection-zone .wallet-details .wallet .balance-zone .currency {
                margin-left: 4px;
                font-size: 14px;
                font-weight: 700; }
      .withdraw-create-wrapper .withdraw-zone .withdraw-form-zone {
        width: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center; }
        .withdraw-create-wrapper .withdraw-zone .withdraw-form-zone form {
          width: 100%; }
          .withdraw-create-wrapper .withdraw-zone .withdraw-form-zone form .form-container {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start; }
            .withdraw-create-wrapper .withdraw-zone .withdraw-form-zone form .form-container .wallet-address {
              width: 100%;
              margin-bottom: 20px; }
              .withdraw-create-wrapper .withdraw-zone .withdraw-form-zone form .form-container .wallet-address .input-text {
                width: 100%;
                font-size: 16px;
                font-weight: 500;
                color: #4a4a4a;
                margin-bottom: 5px; }
              .withdraw-create-wrapper .withdraw-zone .withdraw-form-zone form .form-container .wallet-address .input {
                width: 100%;
                height: 50px;
                border-radius: 4px;
                border: 1px solid #d9d9d9;
                outline: none;
                box-sizing: border-box;
                padding: 13px 20px 13px 20px;
                font-size: 18px;
                line-height: 24px; }
              .withdraw-create-wrapper .withdraw-zone .withdraw-form-zone form .form-container .wallet-address .error-input {
                border: 1px solid #ff6600; }
            .withdraw-create-wrapper .withdraw-zone .withdraw-form-zone form .form-container .amount {
              width: 100%;
              margin-bottom: 60px; }
              .withdraw-create-wrapper .withdraw-zone .withdraw-form-zone form .form-container .amount .input-text {
                width: 100%;
                font-size: 16px;
                font-weight: 500;
                color: #4a4a4a;
                margin-bottom: 5px; }
              .withdraw-create-wrapper .withdraw-zone .withdraw-form-zone form .form-container .amount .input {
                width: 100%;
                height: 50px;
                border-radius: 4px;
                border: 1px solid #d9d9d9;
                outline: none;
                box-sizing: border-box;
                padding: 13px 20px 13px 20px;
                font-size: 18px;
                line-height: 24px; }
              .withdraw-create-wrapper .withdraw-zone .withdraw-form-zone form .form-container .amount .error-input {
                border: 1px solid #ff6600; }
            .withdraw-create-wrapper .withdraw-zone .withdraw-form-zone form .form-container .error {
              width: 100%;
              margin-bottom: 20px; }
            .withdraw-create-wrapper .withdraw-zone .withdraw-form-zone form .form-container .toc {
              margin: 0 auto;
              margin-bottom: 10px; }
            .withdraw-create-wrapper .withdraw-zone .withdraw-form-zone form .form-container .action {
              margin: 0 auto;
              width: 240px;
              height: 60px;
              font-size: 24px;
              font-weight: 600; }
  .withdraw-confirm-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: calc(100% - 40px);
    box-sizing: border-box;
    margin: 0 auto;
    margin-top: 30px;
    padding-bottom: 60px; }
    .withdraw-confirm-wrapper .withdraw-header {
      color: #4a4a4a;
      font-weight: 700;
      font-size: 24px;
      margin-bottom: 10px; }
    .withdraw-confirm-wrapper .withdraw-text {
      color: #4a4a4a;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 40px; }
    .withdraw-confirm-wrapper .confirm-card {
      margin-top: 0;
      display: flex;
      justify-content: flex-start;
      align-items: center; }
      .withdraw-confirm-wrapper .confirm-card .header {
        color: #4a4a4a;
        font-weight: 700;
        font-size: 24px;
        margin-bottom: 20px; }
      .withdraw-confirm-wrapper .confirm-card .subheader {
        color: #4a4a4a;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 40px; }
      .withdraw-confirm-wrapper .confirm-card .time {
        color: #4a4a4a;
        font-weight: 700;
        font-size: 24px;
        margin-bottom: 20px; }
      .withdraw-confirm-wrapper .confirm-card .digits-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-bottom: 60px; }
        .withdraw-confirm-wrapper .confirm-card .digits-container .tries-text {
          width: 100%;
          text-align: right; }
      .withdraw-confirm-wrapper .confirm-card .error {
        width: 100%;
        margin-top: -30px;
        margin-bottom: 20px; }
      .withdraw-confirm-wrapper .confirm-card .action-zone {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-end; }
        .withdraw-confirm-wrapper .confirm-card .action-zone .action-button {
          width: 150px;
          height: 50px;
          font-size: 18px;
          font-weight: 600; }
  .withdraw-success-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: calc(100% - 40px);
    box-sizing: border-box;
    margin: 0 auto;
    margin-top: 30px;
    padding-bottom: 60px; }
    .withdraw-success-wrapper .success-header-zone {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 60px; }
      .withdraw-success-wrapper .success-header-zone .icon {
        font-size: 100px;
        margin-bottom: 20px; }
      .withdraw-success-wrapper .success-header-zone .success-header-content {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start; }
        .withdraw-success-wrapper .success-header-zone .success-header-content .success-header {
          color: #4a4a4a;
          font-weight: 700;
          font-size: 24px;
          margin-bottom: 10px; }
        .withdraw-success-wrapper .success-header-zone .success-header-content .success-text {
          color: #4a4a4a;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px; }
    .withdraw-success-wrapper .success-general-zone {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
      box-sizing: border-box;
      padding: 20px;
      margin-bottom: 30px;
      background-color: #ffffff;
      border-radius: 5px; }
      .withdraw-success-wrapper .success-general-zone .success-large-header {
        color: #4a4a4a;
        font-size: 16px;
        font-weight: 500; }
      .withdraw-success-wrapper .success-general-zone .success-large-text {
        color: #4a4a4a;
        font-size: 24px;
        font-weight: 700; }
      .withdraw-success-wrapper .success-general-zone .success-header {
        color: #999999;
        font-size: 16px;
        line-height: 20px;
        font-weight: 500; }
      .withdraw-success-wrapper .success-general-zone .success-text {
        color: #4a4a4a;
        font-size: 20px;
        font-weight: 600; }
    .withdraw-success-wrapper .success-action-zone {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-start; }
      .withdraw-success-wrapper .success-action-zone .button {
        width: 240px;
        height: 60px;
        font-size: 24px;
        font-weight: 600; }
  .withdraw-failure-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: calc(100% - 40px);
    box-sizing: border-box;
    margin: 0 auto;
    margin-top: 30px;
    padding-bottom: 60px; }
    .withdraw-failure-wrapper .failure-header-zone {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 60px; }
      .withdraw-failure-wrapper .failure-header-zone .icon {
        font-size: 100px;
        margin-bottom: 20px; }
      .withdraw-failure-wrapper .failure-header-zone .failure-header-content {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start; }
        .withdraw-failure-wrapper .failure-header-zone .failure-header-content .failure-header {
          color: #4a4a4a;
          font-weight: 700;
          font-size: 24px;
          margin-bottom: 10px; }
        .withdraw-failure-wrapper .failure-header-zone .failure-header-content .failure-text {
          color: #4a4a4a;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px; }
    .withdraw-failure-wrapper .failure-general-zone {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
      box-sizing: border-box;
      padding: 20px;
      margin-bottom: 30px;
      background-color: #ffffff;
      border-radius: 5px; }
      .withdraw-failure-wrapper .failure-general-zone .failure-large-header {
        color: #4a4a4a;
        font-size: 16px;
        font-weight: 500; }
      .withdraw-failure-wrapper .failure-general-zone .failure-large-text {
        color: #4a4a4a;
        font-size: 24px;
        font-weight: 700; }
      .withdraw-failure-wrapper .failure-general-zone .failure-header {
        color: #999999;
        font-size: 16px;
        line-height: 20px;
        font-weight: 500; }
      .withdraw-failure-wrapper .failure-general-zone .failure-text {
        color: #4a4a4a;
        font-size: 20px;
        font-weight: 600; }
    .withdraw-failure-wrapper .failure-action-zone {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
      .withdraw-failure-wrapper .failure-action-zone .button {
        width: 240px;
        height: 60px;
        font-size: 24px;
        font-weight: 600; }
        .withdraw-failure-wrapper .failure-action-zone .button:first-child {
          margin-bottom: 10px; }
  .confirmation-modal {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%; }
    .confirmation-modal .confirmation-modal-header {
      font-size: 24px;
      font-weight: 700; }
    .confirmation-modal .confirmation-modal-subheader {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      margin-top: 20px; }
    .confirmation-modal .confirmation-modal-icon {
      font-size: 60px;
      color: #ff6600;
      text-align: center;
      margin-top: 40px;
      margin-bottom: 40px; }
    .confirmation-modal .confirmation-actions {
      margin-top: 30px;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between; }
      .confirmation-modal .confirmation-actions .button {
        width: 46%;
        height: 50px; } }

/* Global style guide for UI Lib */
/**
 * Browser support
 */
/**
 * Typography
 */
/**
 * Colors
 */
/**
 * Page -- UILib Specific Variables
 */
/**
 * Card -- UILib Specific Variables
 */
@media only screen and (min-width: 769px) {
  .exchange-trade-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 1130px;
    box-sizing: border-box;
    margin: 0 auto;
    margin-top: 60px;
    padding-bottom: 60px; }
    .exchange-trade-wrapper .exchange-header {
      color: #4a4a4a;
      font-weight: 700;
      font-size: 36px;
      margin-bottom: 10px; }
    .exchange-trade-wrapper .exchange-subheader {
      color: #4a4a4a;
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      margin-bottom: 40px; }
    .exchange-trade-wrapper .exchange-rates-server-error {
      width: 100%;
      margin-bottom: 20px; }
    .exchange-trade-wrapper .trade-zone {
      background-color: #ffffff;
      width: 100%;
      min-height: 630px;
      border-radius: 8px;
      box-sizing: border-box;
      padding: 45px 60px 60px 60px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start; }
      .exchange-trade-wrapper .trade-zone .currency-selection-wrapper {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 60px; }
        .exchange-trade-wrapper .trade-zone .currency-selection-wrapper .currency-selection-zone {
          width: 50%;
          box-sizing: border-box;
          min-height: 140px;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start; }
          .exchange-trade-wrapper .trade-zone .currency-selection-wrapper .currency-selection-zone:first-child {
            border-right: 1px solid #4a4a4a;
            padding-right: 70px; }
          .exchange-trade-wrapper .trade-zone .currency-selection-wrapper .currency-selection-zone:last-child {
            padding-left: 70px; }
          .exchange-trade-wrapper .trade-zone .currency-selection-wrapper .currency-selection-zone .header {
            font-size: 24px;
            font-weight: 600;
            color: #4a4a4a; }
          .exchange-trade-wrapper .trade-zone .currency-selection-wrapper .currency-selection-zone .subheader {
            font-size: 16px;
            font-weight: 500;
            color: #4a4a4a;
            margin-bottom: 15px; }
          .exchange-trade-wrapper .trade-zone .currency-selection-wrapper .currency-selection-zone .currency-selection-row {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start; }
            .exchange-trade-wrapper .trade-zone .currency-selection-wrapper .currency-selection-zone .currency-selection-row .currency-button {
              width: 100px;
              height: 70px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              box-sizing: border-box;
              border: 1px solid #4a4a4a;
              font-size: 18px;
              font-weight: 600;
              color: #4a4a4a;
              background-color: #ffffff;
              border-radius: 8px;
              cursor: pointer; }
            .exchange-trade-wrapper .trade-zone .currency-selection-wrapper .currency-selection-zone .currency-selection-row .selected {
              background-color: #0033ff;
              color: #ffffff;
              border: none; }
            .exchange-trade-wrapper .trade-zone .currency-selection-wrapper .currency-selection-zone .currency-selection-row .disabled {
              background-color: #eaeaea;
              color: #999999;
              border: none; }
      .exchange-trade-wrapper .trade-zone .amount-inputs-wrapper {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-end;
        margin-bottom: 60px;
        box-sizing: border-box; }
        .exchange-trade-wrapper .trade-zone .amount-inputs-wrapper .from-input-zone {
          width: 230px;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: flex-start; }
          .exchange-trade-wrapper .trade-zone .amount-inputs-wrapper .from-input-zone .input-header {
            font-size: 20px;
            font-weight: 500;
            color: #4a4a4a;
            margin-bottom: 10px; }
          .exchange-trade-wrapper .trade-zone .amount-inputs-wrapper .from-input-zone .input {
            width: 230px;
            height: 50px;
            font-size: 18px;
            box-sizing: border-box;
            border-radius: 4px;
            border: 1px solid #cccccc;
            padding-left: 15px;
            padding-right: 15px;
            outline: none;
            text-align: left; }
            .exchange-trade-wrapper .trade-zone .amount-inputs-wrapper .from-input-zone .input:focus {
              border-color: #3366ff; }
        .exchange-trade-wrapper .trade-zone .amount-inputs-wrapper .to-input-zone {
          width: 230px;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: flex-start; }
          .exchange-trade-wrapper .trade-zone .amount-inputs-wrapper .to-input-zone .input-header {
            font-size: 20px;
            font-weight: 500;
            color: #4a4a4a;
            margin-bottom: 10px; }
          .exchange-trade-wrapper .trade-zone .amount-inputs-wrapper .to-input-zone .input {
            width: 230px;
            height: 50px;
            font-size: 18px;
            box-sizing: border-box;
            border-radius: 4px;
            border: 1px solid #cccccc;
            padding-left: 15px;
            padding-right: 15px;
            outline: none;
            text-align: left; }
            .exchange-trade-wrapper .trade-zone .amount-inputs-wrapper .to-input-zone .input:focus {
              border-color: #3366ff; }
        .exchange-trade-wrapper .trade-zone .amount-inputs-wrapper .icon {
          margin: auto 27px 10px 27px;
          font-size: 30px;
          cursor: pointer; }
        .exchange-trade-wrapper .trade-zone .amount-inputs-wrapper .safe-order {
          height: 50px;
          width: 60px;
          box-sizing: border-box;
          padding: 10px;
          text-align: center;
          border: 1px solid #cccccc;
          border-radius: 3px;
          margin-left: 10px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          cursor: pointer; }
          .exchange-trade-wrapper .trade-zone .amount-inputs-wrapper .safe-order .text {
            color: #999999;
            font-size: 15px;
            font-weight: 600; }
          .exchange-trade-wrapper .trade-zone .amount-inputs-wrapper .safe-order .selected {
            color: #ffffff; }
        .exchange-trade-wrapper .trade-zone .amount-inputs-wrapper .safe-order-selected {
          background-color: #0033ff;
          border: none; }
      .exchange-trade-wrapper .trade-zone .currency-exchange-rate-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; }
        .exchange-trade-wrapper .trade-zone .currency-exchange-rate-wrapper .header {
          font-size: 16px;
          line-height: 26px;
          font-weight: 700;
          color: #4a4a4a; }
        .exchange-trade-wrapper .trade-zone .currency-exchange-rate-wrapper .content {
          font-size: 16px;
          line-height: 26px;
          font-weight: 500;
          color: #4a4a4a; }
      .exchange-trade-wrapper .trade-zone .safe-order-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 60px;
        width: 650px;
        margin-left: auto;
        margin-right: auto; }
        .exchange-trade-wrapper .trade-zone .safe-order-wrapper .safe-order-zone {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          margin-bottom: 4px;
          cursor: pointer; }
          .exchange-trade-wrapper .trade-zone .safe-order-wrapper .safe-order-zone .icon {
            font-size: 20px; }
          .exchange-trade-wrapper .trade-zone .safe-order-wrapper .safe-order-zone .text {
            margin-left: 8px;
            font-size: 20px;
            color: #4a4a4a;
            font-weight: 500; }
        .exchange-trade-wrapper .trade-zone .safe-order-wrapper .text {
          font-size: 16px;
          color: #999999;
          font-weight: 500;
          line-height: 26px; }
      .exchange-trade-wrapper .trade-zone .submit-wrapper {
        width: 600px;
        margin: 0 auto; }
        .exchange-trade-wrapper .trade-zone .submit-wrapper form {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center; }
          .exchange-trade-wrapper .trade-zone .submit-wrapper form .error-container {
            margin-bottom: 30px;
            font-size: 14px; }
          .exchange-trade-wrapper .trade-zone .submit-wrapper form .toc-zone {
            width: 240px;
            margin-bottom: 10px; }
          .exchange-trade-wrapper .trade-zone .submit-wrapper form .action {
            width: 240px;
            height: 50px; }
      .exchange-trade-wrapper .trade-zone .information-zone {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin-top: 10px; }
        .exchange-trade-wrapper .trade-zone .information-zone .divider {
          width: 150px;
          height: 1px;
          background-color: #999999;
          margin-top: 30px;
          margin-bottom: 30px; }
        .exchange-trade-wrapper .trade-zone .information-zone .text {
          font-size: 16px;
          color: #4a4a4a;
          font-weight: 500;
          line-height: 26px; }
  .exchange-success-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 1130px;
    box-sizing: border-box;
    margin: 0 auto;
    margin-top: 60px;
    padding-bottom: 60px; }
    .exchange-success-wrapper .success-header-zone {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      height: 100px;
      margin-bottom: 60px; }
      .exchange-success-wrapper .success-header-zone .icon {
        font-size: 100px; }
      .exchange-success-wrapper .success-header-zone .success-header-content {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-left: 40px; }
        .exchange-success-wrapper .success-header-zone .success-header-content .success-header {
          color: #4a4a4a;
          font-weight: 700;
          font-size: 30px; }
        .exchange-success-wrapper .success-header-zone .success-header-content .success-text {
          color: #4a4a4a;
          font-weight: 500;
          font-size: 18px;
          line-height: 26px; }
    .exchange-success-wrapper .success-action-zone {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-start; }
      .exchange-success-wrapper .success-action-zone .button {
        width: 240px;
        height: 60px;
        font-size: 24px;
        font-weight: 600; }
  .exchange-failure-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 1130px;
    box-sizing: border-box;
    margin: 0 auto;
    margin-top: 60px;
    padding-bottom: 60px; }
    .exchange-failure-wrapper .failure-header-zone {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      height: 100px;
      margin-bottom: 60px; }
      .exchange-failure-wrapper .failure-header-zone .icon {
        font-size: 100px; }
      .exchange-failure-wrapper .failure-header-zone .failure-header-content {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-left: 40px; }
        .exchange-failure-wrapper .failure-header-zone .failure-header-content .failure-header {
          color: #4a4a4a;
          font-weight: 700;
          font-size: 30px; }
        .exchange-failure-wrapper .failure-header-zone .failure-header-content .failure-text {
          color: #4a4a4a;
          font-weight: 500;
          font-size: 18px;
          line-height: 26px; }
    .exchange-failure-wrapper .failure-action-zone {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start; }
      .exchange-failure-wrapper .failure-action-zone .button {
        width: 240px;
        height: 60px;
        font-size: 24px;
        font-weight: 600; }
  .confirmation-modal {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%; }
    .confirmation-modal .confirmation-modal-header {
      font-size: 32px;
      font-weight: 700; }
    .confirmation-modal .confirmation-modal-subheader {
      font-size: 22px;
      font-weight: 500;
      line-height: 30px;
      margin-top: 40px; }
    .confirmation-modal .confirmation-modal-icon {
      font-size: 100px;
      color: #ff6600;
      text-align: center;
      margin-top: 80px;
      margin-bottom: 100px; }
    .confirmation-modal .confirmation-actions {
      margin-top: auto;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between; }
      .confirmation-modal .confirmation-actions .button {
        width: 46%;
        height: 50px; } }

@media only screen and (max-width: 768px) {
  .exchange-trade-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: calc(100% - 40px);
    box-sizing: border-box;
    margin: 0 auto;
    margin-top: 20px;
    padding-bottom: 20px; }
    .exchange-trade-wrapper .exchange-header {
      color: #4a4a4a;
      font-weight: 700;
      font-size: 24px;
      margin-bottom: 5px; }
    .exchange-trade-wrapper .exchange-subheader {
      color: #4a4a4a;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 30px; }
    .exchange-trade-wrapper .trade-zone {
      background-color: transparent;
      width: 100%;
      min-height: 630px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start; }
      .exchange-trade-wrapper .trade-zone .currency-selection-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 20px; }
        .exchange-trade-wrapper .trade-zone .currency-selection-wrapper .currency-selection-zone {
          width: 100%;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start; }
          .exchange-trade-wrapper .trade-zone .currency-selection-wrapper .currency-selection-zone:first-child {
            margin-bottom: 20px; }
          .exchange-trade-wrapper .trade-zone .currency-selection-wrapper .currency-selection-zone .header {
            font-size: 16px;
            font-weight: 600;
            color: #4a4a4a;
            margin-bottom: 8px; }
          .exchange-trade-wrapper .trade-zone .currency-selection-wrapper .currency-selection-zone .subheader {
            display: none; }
          .exchange-trade-wrapper .trade-zone .currency-selection-wrapper .currency-selection-zone .currency-selection-row {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start; }
            .exchange-trade-wrapper .trade-zone .currency-selection-wrapper .currency-selection-zone .currency-selection-row .currency-button {
              width: 100px;
              height: 50px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              box-sizing: border-box;
              border: 1px solid #4a4a4a;
              font-size: 18px;
              font-weight: 600;
              color: #4a4a4a;
              background-color: #ffffff;
              border-radius: 8px;
              cursor: pointer; }
              .exchange-trade-wrapper .trade-zone .currency-selection-wrapper .currency-selection-zone .currency-selection-row .currency-button:last-child {
                display: none; }
            .exchange-trade-wrapper .trade-zone .currency-selection-wrapper .currency-selection-zone .currency-selection-row .selected {
              background-color: #0033ff;
              color: #ffffff;
              border: none; }
            .exchange-trade-wrapper .trade-zone .currency-selection-wrapper .currency-selection-zone .currency-selection-row .disabled {
              background-color: #eaeaea;
              color: #999999;
              border: none; }
      .exchange-trade-wrapper .trade-zone .amount-inputs-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        margin-bottom: 30px;
        box-sizing: border-box; }
        .exchange-trade-wrapper .trade-zone .amount-inputs-wrapper .from-input-zone {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: flex-start; }
          .exchange-trade-wrapper .trade-zone .amount-inputs-wrapper .from-input-zone .input-header {
            font-size: 16px;
            font-weight: 500;
            color: #4a4a4a;
            margin-bottom: 10px; }
          .exchange-trade-wrapper .trade-zone .amount-inputs-wrapper .from-input-zone .input {
            width: 100%;
            height: 50px;
            font-size: 18px;
            box-sizing: border-box;
            border-radius: 4px;
            border: 1px solid #cccccc;
            padding-left: 15px;
            padding-right: 15px;
            outline: none;
            text-align: left; }
            .exchange-trade-wrapper .trade-zone .amount-inputs-wrapper .from-input-zone .input:focus {
              border-color: #3366ff; }
        .exchange-trade-wrapper .trade-zone .amount-inputs-wrapper .to-input-zone {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: flex-start;
          margin-top: 20px; }
          .exchange-trade-wrapper .trade-zone .amount-inputs-wrapper .to-input-zone .input-header {
            font-size: 16px;
            font-weight: 500;
            color: #4a4a4a;
            margin-bottom: 10px; }
          .exchange-trade-wrapper .trade-zone .amount-inputs-wrapper .to-input-zone .input {
            width: 100%;
            height: 50px;
            font-size: 18px;
            box-sizing: border-box;
            border-radius: 4px;
            border: 1px solid #cccccc;
            padding-left: 15px;
            padding-right: 15px;
            outline: none;
            text-align: left; }
            .exchange-trade-wrapper .trade-zone .amount-inputs-wrapper .to-input-zone .input:focus {
              border-color: #3366ff; }
        .exchange-trade-wrapper .trade-zone .amount-inputs-wrapper .icon {
          display: none; }
        .exchange-trade-wrapper .trade-zone .amount-inputs-wrapper .safe-order {
          display: none; }
        .exchange-trade-wrapper .trade-zone .amount-inputs-wrapper .safe-order-selected {
          display: none; }
      .exchange-trade-wrapper .trade-zone .safe-order-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 50px;
        width: 100%;
        margin-left: auto;
        margin-right: auto; }
        .exchange-trade-wrapper .trade-zone .safe-order-wrapper .safe-order-zone {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          margin-bottom: 4px;
          cursor: pointer; }
          .exchange-trade-wrapper .trade-zone .safe-order-wrapper .safe-order-zone .icon {
            font-size: 20px; }
          .exchange-trade-wrapper .trade-zone .safe-order-wrapper .safe-order-zone .text {
            margin-left: 8px;
            font-size: 16px;
            color: #4a4a4a;
            font-weight: 500; }
        .exchange-trade-wrapper .trade-zone .safe-order-wrapper .text {
          font-size: 12px;
          color: #999999;
          font-weight: 500;
          line-height: 16px; }
      .exchange-trade-wrapper .trade-zone .submit-wrapper {
        width: 240px;
        margin: 0 auto; }
        .exchange-trade-wrapper .trade-zone .submit-wrapper form {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center; }
          .exchange-trade-wrapper .trade-zone .submit-wrapper form .error-container {
            margin-bottom: 30px;
            font-size: 14px; }
          .exchange-trade-wrapper .trade-zone .submit-wrapper form .toc-zone {
            width: 100%;
            margin-bottom: 10px; }
          .exchange-trade-wrapper .trade-zone .submit-wrapper form .action {
            width: 100%;
            height: 50px; }
      .exchange-trade-wrapper .trade-zone .information-zone {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin-top: 10px; }
        .exchange-trade-wrapper .trade-zone .information-zone .divider {
          width: 150px;
          height: 1px;
          background-color: #999999;
          margin-top: 30px;
          margin-bottom: 30px; }
        .exchange-trade-wrapper .trade-zone .information-zone .text {
          font-size: 14px;
          color: #4a4a4a;
          font-weight: 500;
          line-height: 20px; }
  .exchange-success-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    box-sizing: border-box;
    margin: 0 auto;
    margin-top: 30px;
    padding-bottom: 60px; }
    .exchange-success-wrapper .success-header-zone {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 60px; }
      .exchange-success-wrapper .success-header-zone .icon {
        font-size: 100px;
        margin-bottom: 20px; }
      .exchange-success-wrapper .success-header-zone .success-header-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        margin-left: 20px;
        margin-right: 20px; }
        .exchange-success-wrapper .success-header-zone .success-header-content .success-header {
          text-align: center;
          color: #4a4a4a;
          font-weight: 700;
          font-size: 24px;
          margin-bottom: 10px; }
        .exchange-success-wrapper .success-header-zone .success-header-content .success-text {
          color: #4a4a4a;
          font-weight: 500;
          font-size: 16px;
          line-height: 26px;
          margin: 0 auto; }
    .exchange-success-wrapper .success-action-zone {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-start; }
      .exchange-success-wrapper .success-action-zone .button {
        width: 240px;
        height: 60px;
        font-size: 24px;
        font-weight: 600; }
  .exchange-failure-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    box-sizing: border-box;
    margin: 0 auto;
    margin-top: 30px;
    padding-bottom: 60px; }
    .exchange-failure-wrapper .failure-header-zone {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 60px; }
      .exchange-failure-wrapper .failure-header-zone .icon {
        font-size: 100px;
        margin-bottom: 20px; }
      .exchange-failure-wrapper .failure-header-zone .failure-header-content {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-left: 20px;
        margin-right: 20px; }
        .exchange-failure-wrapper .failure-header-zone .failure-header-content .failure-header {
          color: #4a4a4a;
          font-weight: 700;
          font-size: 24px;
          margin-bottom: 10px;
          text-align: center; }
        .exchange-failure-wrapper .failure-header-zone .failure-header-content .failure-text {
          color: #4a4a4a;
          font-weight: 500;
          font-size: 16px;
          line-height: 26px;
          margin: 0 auto; }
    .exchange-failure-wrapper .failure-action-zone {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      box-sizing: border-box;
      padding-left: 20px;
      padding-right: 20px; }
      .exchange-failure-wrapper .failure-action-zone .button {
        width: 100%;
        height: 60px;
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 20px; }
      .exchange-failure-wrapper .failure-action-zone a {
        width: 100%; }
        .exchange-failure-wrapper .failure-action-zone a .button {
          width: 100%;
          height: 60px;
          font-size: 14px;
          font-weight: 600; }
  .confirmation-modal {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%; }
    .confirmation-modal .confirmation-modal-header {
      font-size: 24px;
      font-weight: 700; }
    .confirmation-modal .confirmation-modal-subheader {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      margin-top: 20px; }
    .confirmation-modal .confirmation-modal-icon {
      font-size: 60px;
      color: #ff6600;
      text-align: center;
      margin-top: 40px;
      margin-bottom: 40px; }
    .confirmation-modal .confirmation-actions {
      margin-top: 30px;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between; }
      .confirmation-modal .confirmation-actions .button {
        width: 46%;
        height: 50px; } }

/* Global style guide for UI Lib */
/**
 * Browser support
 */
/**
 * Typography
 */
/**
 * Colors
 */
/**
 * Page -- UILib Specific Variables
 */
/**
 * Card -- UILib Specific Variables
 */
.logout-card {
  min-height: 600px;
  justify-content: flex-start;
  align-items: center;
  padding-top: 80px; }
  .logout-card .icon {
    margin-bottom: 60px; }
  .logout-card .text {
    font-size: 36px;
    color: #4a4a4a;
    font-weight: 700;
    width: 290px;
    margin: 0 auto; }
  .logout-card .action {
    margin-top: 80px;
    height: 60px;
    width: 175px; }

/* Global style guide for UI Lib */
/**
 * Browser support
 */
/**
 * Typography
 */
/**
 * Colors
 */
/**
 * Page -- UILib Specific Variables
 */
/**
 * Card -- UILib Specific Variables
 */
@media only screen and (min-width: 769px) {
  .start-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
    .start-card .icon {
      margin-bottom: 40px; }
  .add-card {
    justify-content: flex-start; }
    .add-card .header {
      width: 300px;
      margin: 0 auto;
      margin-bottom: 40px; }
    .add-card .content {
      height: 400px;
      width: 375px;
      margin: 0 auto; }
      .add-card .content .descriptive-text {
        margin-bottom: 40px; }
      .add-card .content form {
        width: 100%; }
      .add-card .content .form-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: center; }
        .add-card .content .form-wrapper .intl-tel-input {
          height: 50px;
          width: 300px;
          outline: none; }
        .add-card .content .form-wrapper .form-control {
          height: 50px;
          width: 300px;
          font-size: 16px;
          border-radius: 4px;
          outline: none;
          border: 1px solid #cccccc; }
      .add-card .content .error-container {
        width: 100%;
        margin-top: 25px;
        margin-bottom: 45px; }
      .add-card .content .submit-container {
        text-align: center; }
      .add-card .content .margin-top-75 {
        margin-top: 75px; }
    .add-card .bottom-section {
      margin-top: auto;
      font-size: 14px; }
  .confirm-card {
    justify-content: flex-start; }
    .confirm-card .header {
      margin-bottom: 40px; }
    .confirm-card .otp-container {
      height: 430px; }
      .confirm-card .otp-container .descriptive-text {
        width: 330px;
        margin: 0 auto; }
      .confirm-card .otp-container .phone-number {
        width: 330px;
        margin: 0 auto;
        margin-bottom: 30px;
        font-weight: 900; }
      .confirm-card .otp-container .time {
        margin-bottom: 20px; }
      .confirm-card .otp-container .digits-container {
        text-align: center; }
        .confirm-card .otp-container .digits-container .tries-text {
          text-align: right;
          margin-right: 15px;
          margin-bottom: 20px; }
      .confirm-card .otp-container .generic-container {
        width: 330px;
        margin: 0 auto;
        margin-bottom: 20px; }
      .confirm-card .otp-container .right-align {
        margin-top: 60px;
        text-align: right; }
    .confirm-card .bottom-section {
      margin-top: auto;
      font-size: 14px; } }

@media only screen and (max-width: 768px) {
  .start-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
    .start-card .icon {
      margin-bottom: 40px; }
  .add-card {
    justify-content: flex-start; }
    .add-card .header {
      font-size: 24px;
      margin: 0 auto;
      margin-bottom: 40px; }
    .add-card .content {
      height: 400px;
      width: 80vw;
      font-size: 12px;
      margin: 0 auto; }
      .add-card .content .descriptive-text {
        margin-bottom: 40px; }
      .add-card .content .form-wrapper {
        display: flex;
        flex-direction: row;
        margin-bottom: 75px; }
      .add-card .content .submit-container {
        text-align: right; }
    .add-card .bottom-section {
      margin-top: auto;
      font-size: 14px; }
  .confirm-card {
    justify-content: flex-start; }
    .confirm-card .header {
      font-size: 24px;
      margin-bottom: 40px; }
    .confirm-card .otp-container {
      height: 430px; }
      .confirm-card .otp-container .descriptive-text {
        font-size: 16px;
        margin: 0 auto; }
      .confirm-card .otp-container .phone-number {
        font-size: 16px;
        margin: 0 auto;
        margin-bottom: 30px;
        font-weight: 900; }
      .confirm-card .otp-container .time {
        font-size: 24px;
        margin-bottom: 20px; }
      .confirm-card .otp-container .digits-container {
        text-align: center; }
        .confirm-card .otp-container .digits-container .tries-text {
          text-align: right;
          margin-right: 15px;
          margin-bottom: 20px; }
      .confirm-card .otp-container .generic-container {
        width: 330px;
        margin: 0 auto;
        margin-bottom: 20px; }
      .confirm-card .otp-container .right-align {
        margin-top: 60px;
        text-align: right; }
    .confirm-card .bottom-section {
      margin-top: auto;
      font-size: 14px; } }

/* Global style guide for UI Lib */
/**
 * Browser support
 */
/**
 * Typography
 */
/**
 * Colors
 */
/**
 * Page -- UILib Specific Variables
 */
/**
 * Card -- UILib Specific Variables
 */
@media only screen and (min-width: 769px) {
  .kyc-page-wrapper {
    box-sizing: border-box;
    width: 800px;
    margin: 0 auto;
    padding-top: 80px;
    padding-bottom: 80px; }
    .kyc-page-wrapper .step-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center; }
      .kyc-page-wrapper .step-wrapper .step-icon {
        font-size: 40px; }
      .kyc-page-wrapper .step-wrapper .results-icon {
        font-size: 100px; }
      .kyc-page-wrapper .step-wrapper .dots-icon {
        font-size: 20px; }
    .kyc-page-wrapper .kyc-continue-wrapper {
      width: 100%;
      height: 420px;
      background-color: #ffffff;
      border-radius: 8px;
      box-sizing: border-box;
      padding: 50px 80px; }
      .kyc-page-wrapper .kyc-continue-wrapper .section-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center; }
        .kyc-page-wrapper .kyc-continue-wrapper .section-wrapper .header {
          font-size: 20px;
          font-weight: 700;
          color: #4a4a4a;
          margin-top: 60px; }
        .kyc-page-wrapper .kyc-continue-wrapper .section-wrapper .content {
          font-size: 18px;
          line-height: 26px;
          font-weight: 500;
          color: #4a4a4a;
          margin-top: 10px;
          margin-bottom: 40px; }
        .kyc-page-wrapper .kyc-continue-wrapper .section-wrapper .action {
          width: 225px;
          height: 50px; }
    .kyc-page-wrapper .kyc-start-wrapper {
      width: 100%;
      height: 420px;
      background-color: #ffffff;
      border-radius: 8px;
      box-sizing: border-box;
      padding: 50px 80px; }
      .kyc-page-wrapper .kyc-start-wrapper .start-loader {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; }
    .kyc-page-wrapper .kyc-personal-wrapper {
      width: 100%;
      background-color: #ffffff;
      border-radius: 8px;
      box-sizing: border-box;
      padding: 50px 80px; }
      .kyc-page-wrapper .kyc-personal-wrapper .section-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center; }
        .kyc-page-wrapper .kyc-personal-wrapper .section-wrapper .header {
          font-size: 20px;
          font-weight: 700;
          color: #4a4a4a;
          margin-top: 60px; }
        .kyc-page-wrapper .kyc-personal-wrapper .section-wrapper .content {
          font-size: 18px;
          line-height: 26px;
          font-weight: 500;
          color: #4a4a4a;
          margin-top: 10px;
          margin-bottom: 40px; }
        .kyc-page-wrapper .kyc-personal-wrapper .section-wrapper .personal-form-wrapper {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center; }
          .kyc-page-wrapper .kyc-personal-wrapper .section-wrapper .personal-form-wrapper form {
            width: 100%; }
            .kyc-page-wrapper .kyc-personal-wrapper .section-wrapper .personal-form-wrapper form .form-zone {
              width: 100%;
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: flex-start;
              margin-bottom: 20px; }
              .kyc-page-wrapper .kyc-personal-wrapper .section-wrapper .personal-form-wrapper form .form-zone .input {
                width: 300px;
                margin-right: 25px;
                margin-bottom: 20px; }
              .kyc-page-wrapper .kyc-personal-wrapper .section-wrapper .personal-form-wrapper form .form-zone .custom-datepicker {
                box-sizing: border-box;
                height: 50px;
                font-size: 1.5em;
                border-radius: 4px;
                border: 1px solid #cccccc;
                color: #4a4a4a;
                padding-left: 15px;
                padding-right: 15px;
                outline: none;
                text-align: left; }
                .kyc-page-wrapper .kyc-personal-wrapper .section-wrapper .personal-form-wrapper form .form-zone .custom-datepicker:focus {
                  border-color: #3366ff; }
            .kyc-page-wrapper .kyc-personal-wrapper .section-wrapper .personal-form-wrapper form .error-zone {
              width: 100%;
              margin-bottom: 30px;
              font-size: 16px; }
            .kyc-page-wrapper .kyc-personal-wrapper .section-wrapper .personal-form-wrapper form .action-zone {
              width: 100%;
              display: flex;
              flex-direction: row;
              justify-content: flex-end;
              align-items: flex-end; }
              .kyc-page-wrapper .kyc-personal-wrapper .section-wrapper .personal-form-wrapper form .action-zone .button {
                width: 120px; }
    .kyc-page-wrapper .kyc-document-wrapper {
      width: 100%;
      background-color: #ffffff;
      border-radius: 8px;
      box-sizing: border-box;
      padding: 50px 80px; }
      .kyc-page-wrapper .kyc-document-wrapper .section-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center; }
        .kyc-page-wrapper .kyc-document-wrapper .section-wrapper .header {
          font-size: 20px;
          font-weight: 700;
          color: #4a4a4a;
          margin-top: 60px; }
        .kyc-page-wrapper .kyc-document-wrapper .section-wrapper .content {
          font-size: 18px;
          line-height: 26px;
          font-weight: 500;
          color: #4a4a4a;
          margin-top: 10px;
          margin-bottom: 40px; }
        .kyc-page-wrapper .kyc-document-wrapper .section-wrapper .button-section {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 20px; }
          .kyc-page-wrapper .kyc-document-wrapper .section-wrapper .button-section .button {
            width: 150px;
            height: 40px; }
        .kyc-page-wrapper .kyc-document-wrapper .section-wrapper .upload-section {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-start;
          margin-bottom: 50px; }
          .kyc-page-wrapper .kyc-document-wrapper .section-wrapper .upload-section .section {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            width: 275px; }
            .kyc-page-wrapper .kyc-document-wrapper .section-wrapper .upload-section .section .header {
              font-size: 18px;
              line-height: 26px;
              font-weight: 500;
              color: #4a4a4a;
              margin-bottom: 15px; }
            .kyc-page-wrapper .kyc-document-wrapper .section-wrapper .upload-section .section .dropzone {
              cursor: pointer;
              width: 320px;
              height: 190px;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              border-radius: 8px;
              background-color: #eaeaea;
              border: 3px dashed #666666; }
              .kyc-page-wrapper .kyc-document-wrapper .section-wrapper .upload-section .section .dropzone .image {
                width: 100%;
                height: 100%;
                object-fit: contain; }
              .kyc-page-wrapper .kyc-document-wrapper .section-wrapper .upload-section .section .dropzone .icon {
                font-size: 45px;
                color: #666666;
                margin-bottom: 20px; }
              .kyc-page-wrapper .kyc-document-wrapper .section-wrapper .upload-section .section .dropzone .text {
                width: 110px;
                text-align: center;
                font-size: 18px;
                font-weight: 500; }
        .kyc-page-wrapper .kyc-document-wrapper .section-wrapper .error-zone {
          width: 100%;
          margin-bottom: 30px;
          font-size: 16px; }
        .kyc-page-wrapper .kyc-document-wrapper .section-wrapper .action-zone {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-start; }
          .kyc-page-wrapper .kyc-document-wrapper .section-wrapper .action-zone .back {
            width: 120px;
            height: 40px; }
          .kyc-page-wrapper .kyc-document-wrapper .section-wrapper .action-zone .continue {
            width: 120px;
            height: 40px; }
    .kyc-page-wrapper .kyc-profile-wrapper {
      width: 100%;
      background-color: #ffffff;
      border-radius: 8px;
      box-sizing: border-box;
      padding: 50px 80px; }
      .kyc-page-wrapper .kyc-profile-wrapper .section-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center; }
        .kyc-page-wrapper .kyc-profile-wrapper .section-wrapper .header {
          font-size: 20px;
          font-weight: 700;
          color: #4a4a4a;
          margin-top: 60px; }
        .kyc-page-wrapper .kyc-profile-wrapper .section-wrapper .content {
          font-size: 18px;
          line-height: 26px;
          font-weight: 500;
          color: #4a4a4a;
          margin-top: 10px;
          margin-bottom: 20px; }
        .kyc-page-wrapper .kyc-profile-wrapper .section-wrapper .selfie-section {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: flex-start;
          margin-bottom: 50px; }
          .kyc-page-wrapper .kyc-profile-wrapper .section-wrapper .selfie-section .section {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            width: 275px; }
            .kyc-page-wrapper .kyc-profile-wrapper .section-wrapper .selfie-section .section .header {
              font-size: 18px;
              line-height: 26px;
              font-weight: 500;
              color: #4a4a4a;
              margin-bottom: 15px; }
            .kyc-page-wrapper .kyc-profile-wrapper .section-wrapper .selfie-section .section .selfie-zone {
              cursor: pointer;
              width: 320px;
              height: 240px;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              border-radius: 8px;
              background-color: #eaeaea;
              border: 3px dashed #666666;
              margin-bottom: 30px; }
              .kyc-page-wrapper .kyc-profile-wrapper .section-wrapper .selfie-section .section .selfie-zone .image {
                width: 100%;
                height: 100%;
                object-fit: contain; }
              .kyc-page-wrapper .kyc-profile-wrapper .section-wrapper .selfie-section .section .selfie-zone .webcam {
                width: 320px;
                height: auto;
                border-radius: 8px; }
            .kyc-page-wrapper .kyc-profile-wrapper .section-wrapper .selfie-section .section .capture {
              font-size: 40px;
              cursor: pointer; }
          .kyc-page-wrapper .kyc-profile-wrapper .section-wrapper .selfie-section .mobile-section {
            display: none; }
        .kyc-page-wrapper .kyc-profile-wrapper .section-wrapper .error-zone {
          width: 100%;
          margin-bottom: 30px;
          font-size: 16px; }
        .kyc-page-wrapper .kyc-profile-wrapper .section-wrapper .action-zone {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-start; }
          .kyc-page-wrapper .kyc-profile-wrapper .section-wrapper .action-zone .back {
            width: 120px;
            height: 40px; }
          .kyc-page-wrapper .kyc-profile-wrapper .section-wrapper .action-zone .continue {
            width: 120px;
            height: 40px; }
          .kyc-page-wrapper .kyc-profile-wrapper .section-wrapper .action-zone .mobile-continue {
            display: none; }
    .kyc-page-wrapper .kyc-address-wrapper {
      width: 100%;
      background-color: #ffffff;
      border-radius: 8px;
      box-sizing: border-box;
      padding: 50px 80px; }
      .kyc-page-wrapper .kyc-address-wrapper .section-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center; }
        .kyc-page-wrapper .kyc-address-wrapper .section-wrapper .header {
          font-size: 20px;
          font-weight: 700;
          color: #4a4a4a;
          margin-top: 60px; }
        .kyc-page-wrapper .kyc-address-wrapper .section-wrapper .content {
          font-size: 18px;
          line-height: 26px;
          font-weight: 500;
          color: #4a4a4a;
          margin-top: 10px;
          margin-bottom: 40px; }
        .kyc-page-wrapper .kyc-address-wrapper .section-wrapper .address-form-wrapper {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center; }
          .kyc-page-wrapper .kyc-address-wrapper .section-wrapper .address-form-wrapper .form-row {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 20px; }
            .kyc-page-wrapper .kyc-address-wrapper .section-wrapper .address-form-wrapper .form-row .input {
              width: 650px; }
            .kyc-page-wrapper .kyc-address-wrapper .section-wrapper .address-form-wrapper .form-row .left-pane {
              flex: 0.5 1; }
              .kyc-page-wrapper .kyc-address-wrapper .section-wrapper .address-form-wrapper .form-row .left-pane .input {
                width: 300px;
                margin-right: 25px; }
            .kyc-page-wrapper .kyc-address-wrapper .section-wrapper .address-form-wrapper .form-row .right-pane {
              flex: 0.5 1;
              padding-left: 25px;
              box-sizing: border-box; }
              .kyc-page-wrapper .kyc-address-wrapper .section-wrapper .address-form-wrapper .form-row .right-pane .input {
                width: 300px; }
            .kyc-page-wrapper .kyc-address-wrapper .section-wrapper .address-form-wrapper .form-row .subcontent {
              width: 650px;
              text-align: center;
              font-size: 18px;
              line-height: 26px;
              color: #4a4a4a;
              font-weight: 500; }
            .kyc-page-wrapper .kyc-address-wrapper .section-wrapper .address-form-wrapper .form-row .upload-section {
              width: 100%;
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: flex-start;
              margin-bottom: 50px; }
              .kyc-page-wrapper .kyc-address-wrapper .section-wrapper .address-form-wrapper .form-row .upload-section .section {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                width: 275px; }
                .kyc-page-wrapper .kyc-address-wrapper .section-wrapper .address-form-wrapper .form-row .upload-section .section .header {
                  font-size: 18px;
                  line-height: 26px;
                  font-weight: 500;
                  color: #4a4a4a;
                  margin-bottom: 15px; }
                .kyc-page-wrapper .kyc-address-wrapper .section-wrapper .address-form-wrapper .form-row .upload-section .section .dropzone {
                  cursor: pointer;
                  width: 320px;
                  height: 190px;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                  border-radius: 8px;
                  background-color: #eaeaea;
                  border: 3px dashed #666666; }
                  .kyc-page-wrapper .kyc-address-wrapper .section-wrapper .address-form-wrapper .form-row .upload-section .section .dropzone .image {
                    width: 100%;
                    height: 100%;
                    object-fit: contain; }
                  .kyc-page-wrapper .kyc-address-wrapper .section-wrapper .address-form-wrapper .form-row .upload-section .section .dropzone .icon {
                    font-size: 45px;
                    color: #666666;
                    margin-bottom: 20px; }
                  .kyc-page-wrapper .kyc-address-wrapper .section-wrapper .address-form-wrapper .form-row .upload-section .section .dropzone .text {
                    width: 110px;
                    text-align: center;
                    font-size: 18px;
                    font-weight: 500; }
        .kyc-page-wrapper .kyc-address-wrapper .section-wrapper .error-zone {
          width: 100%;
          margin-bottom: 30px;
          font-size: 16px; }
        .kyc-page-wrapper .kyc-address-wrapper .section-wrapper .action-zone {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-start; }
          .kyc-page-wrapper .kyc-address-wrapper .section-wrapper .action-zone .back {
            width: 120px;
            height: 40px; }
          .kyc-page-wrapper .kyc-address-wrapper .section-wrapper .action-zone .continue {
            width: 120px;
            height: 40px; }
    .kyc-page-wrapper .kyc-submit-wrapper {
      width: 100%;
      background-color: #ffffff;
      border-radius: 8px;
      box-sizing: border-box;
      padding: 50px 80px; }
      .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center; }
        .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .header {
          font-size: 20px;
          font-weight: 700;
          color: #4a4a4a;
          margin-top: 60px; }
        .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .content {
          font-size: 18px;
          line-height: 26px;
          font-weight: 500;
          color: #4a4a4a;
          margin-top: 10px;
          margin-bottom: 50px; }
        .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .submit-section-wrapper {
          display: flex;
          flex-direction: column;
          width: 100%;
          justify-content: flex-start;
          align-items: flex-start; }
          .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .submit-section-wrapper .personal-row {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            width: 100%; }
            .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .submit-section-wrapper .personal-row .item {
              width: 20%;
              margin-bottom: 40px; }
              .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .submit-section-wrapper .personal-row .item .item-header {
                font-size: 14px;
                line-height: 20px;
                color: #999999; }
              .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .submit-section-wrapper .personal-row .item .item-text {
                font-size: 16px;
                line-height: 20px;
                color: #000000;
                font-weight: 600; }
          .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .submit-section-wrapper .address-row {
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: flex-start;
            align-items: flex-start; }
            .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .submit-section-wrapper .address-row .item {
              width: 20%;
              margin-bottom: 40px; }
              .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .submit-section-wrapper .address-row .item .item-header {
                font-size: 14px;
                line-height: 20px;
                color: #999999; }
              .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .submit-section-wrapper .address-row .item .item-text {
                font-size: 16px;
                line-height: 20px;
                color: #000000;
                font-weight: 600; }
            .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .submit-section-wrapper .address-row .address-item {
              width: 60%;
              box-sizing: border-box;
              padding-right: 10px; }
          .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .submit-section-wrapper .document-row {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            width: 100%; }
            .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .submit-section-wrapper .document-row .item {
              width: 25%;
              margin-bottom: 80px; }
              .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .submit-section-wrapper .document-row .item .item-header {
                font-size: 14px;
                line-height: 20px;
                color: #999999; }
              .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .submit-section-wrapper .document-row .item .document-zone {
                margin-top: 10px;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: flex-start; }
                .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .submit-section-wrapper .document-row .item .document-zone .image {
                  border-radius: 2px;
                  width: 125px;
                  overflow: hidden;
                  height: auto; }
                  .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .submit-section-wrapper .document-row .item .document-zone .image:first-child {
                    margin-right: 20px; }
            .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .submit-section-wrapper .document-row .general-documents-item {
              width: 50%; }
          .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .submit-section-wrapper .mobile-document-row {
            display: none; }
        .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .action-zone {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-start; }
          .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .action-zone .back {
            width: 120px;
            height: 40px; }
          .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .action-zone .continue {
            width: 120px;
            height: 40px; }
    .kyc-page-wrapper .kyc-success-wrapper {
      width: 100%;
      background-color: #ffffff;
      border-radius: 8px;
      box-sizing: border-box;
      padding: 50px 80px; }
      .kyc-page-wrapper .kyc-success-wrapper .section-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center; }
        .kyc-page-wrapper .kyc-success-wrapper .section-wrapper .header {
          font-size: 20px;
          font-weight: 700;
          color: #4a4a4a;
          margin-top: 60px; }
        .kyc-page-wrapper .kyc-success-wrapper .section-wrapper .content {
          font-size: 18px;
          line-height: 26px;
          font-weight: 500;
          color: #4a4a4a;
          margin-top: 10px;
          margin-bottom: 50px; }
        .kyc-page-wrapper .kyc-success-wrapper .section-wrapper .action-zone {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center; }
          .kyc-page-wrapper .kyc-success-wrapper .section-wrapper .action-zone .return {
            width: 120px;
            height: 40px; } }

@media only screen and (max-width: 768px) {
  .kyc-page-wrapper {
    box-sizing: border-box;
    width: 100vw;
    min-height: 100vh;
    margin: 0 auto;
    border-top: 1px solid #999999;
    padding: 30px;
    background-color: #ffffff; }
    .kyc-page-wrapper .step-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center; }
      .kyc-page-wrapper .step-wrapper .step-icon {
        font-size: 24px; }
      .kyc-page-wrapper .step-wrapper .results-icon {
        font-size: 40px; }
      .kyc-page-wrapper .step-wrapper .dots-icon {
        font-size: 10px; }
    .kyc-page-wrapper .kyc-continue-wrapper {
      width: 100%;
      min-height: 420px;
      background-color: #ffffff; }
      .kyc-page-wrapper .kyc-continue-wrapper .section-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center; }
        .kyc-page-wrapper .kyc-continue-wrapper .section-wrapper .header {
          font-size: 20px;
          font-weight: 700;
          color: #4a4a4a;
          margin-top: 45px; }
        .kyc-page-wrapper .kyc-continue-wrapper .section-wrapper .content {
          font-size: 16px;
          line-height: 26px;
          font-weight: 500;
          color: #4a4a4a;
          margin-top: 10px;
          margin-bottom: 60px; }
        .kyc-page-wrapper .kyc-continue-wrapper .section-wrapper .action {
          width: 80%;
          height: 50px; }
    .kyc-page-wrapper .kyc-start-wrapper {
      width: 100%;
      min-height: 420px;
      background-color: #ffffff; }
      .kyc-page-wrapper .kyc-start-wrapper .start-loader {
        width: 100%;
        min-height: 420px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; }
    .kyc-page-wrapper .kyc-personal-wrapper {
      width: 100%;
      min-height: 420px;
      background-color: #ffffff; }
      .kyc-page-wrapper .kyc-personal-wrapper .section-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center; }
        .kyc-page-wrapper .kyc-personal-wrapper .section-wrapper .header {
          font-size: 20px;
          font-weight: 700;
          color: #4a4a4a;
          margin-top: 45px; }
        .kyc-page-wrapper .kyc-personal-wrapper .section-wrapper .content {
          font-size: 16px;
          line-height: 26px;
          font-weight: 500;
          color: #4a4a4a;
          margin-top: 10px;
          margin-bottom: 30px; }
        .kyc-page-wrapper .kyc-personal-wrapper .section-wrapper .personal-form-wrapper {
          width: 300px !important;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center; }
          .kyc-page-wrapper .kyc-personal-wrapper .section-wrapper .personal-form-wrapper form {
            width: 100%; }
            .kyc-page-wrapper .kyc-personal-wrapper .section-wrapper .personal-form-wrapper form .form-zone {
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: flex-start; }
              .kyc-page-wrapper .kyc-personal-wrapper .section-wrapper .personal-form-wrapper form .form-zone .input {
                width: 300px; }
              .kyc-page-wrapper .kyc-personal-wrapper .section-wrapper .personal-form-wrapper form .form-zone .custom-datepicker {
                box-sizing: border-box;
                height: 50px;
                font-size: 1.5em;
                border-radius: 4px;
                border: 1px solid #cccccc;
                color: #4a4a4a;
                padding-left: 15px;
                padding-right: 15px;
                outline: none;
                text-align: left;
                margin-bottom: 10px; }
                .kyc-page-wrapper .kyc-personal-wrapper .section-wrapper .personal-form-wrapper form .form-zone .custom-datepicker:focus {
                  border-color: #3366ff; }
            .kyc-page-wrapper .kyc-personal-wrapper .section-wrapper .personal-form-wrapper form .error-zone {
              width: 100%;
              margin-bottom: 20px;
              font-size: 14px; }
            .kyc-page-wrapper .kyc-personal-wrapper .section-wrapper .personal-form-wrapper form .action-zone {
              width: 100%;
              display: flex;
              flex-direction: row;
              justify-content: flex-end;
              align-items: flex-end; }
    .kyc-page-wrapper .kyc-document-wrapper {
      width: 100%;
      min-height: 420px;
      background-color: #ffffff; }
      .kyc-page-wrapper .kyc-document-wrapper .section-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center; }
        .kyc-page-wrapper .kyc-document-wrapper .section-wrapper .header {
          font-size: 20px;
          font-weight: 700;
          color: #4a4a4a;
          margin-top: 30px; }
        .kyc-page-wrapper .kyc-document-wrapper .section-wrapper .content {
          font-size: 16px;
          line-height: 26px;
          font-weight: 500;
          color: #4a4a4a;
          margin-top: 10px;
          margin-bottom: 30px; }
        .kyc-page-wrapper .kyc-document-wrapper .section-wrapper .button-section {
          width: 100%;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: center; }
          .kyc-page-wrapper .kyc-document-wrapper .section-wrapper .button-section .button {
            width: 48%;
            height: 40px;
            margin-bottom: 20px; }
        .kyc-page-wrapper .kyc-document-wrapper .section-wrapper .upload-section {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-start;
          margin-bottom: 40px; }
          .kyc-page-wrapper .kyc-document-wrapper .section-wrapper .upload-section .section {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            width: 48%; }
            .kyc-page-wrapper .kyc-document-wrapper .section-wrapper .upload-section .section .header {
              font-size: 18px;
              line-height: 26px;
              font-weight: 500;
              color: #4a4a4a;
              margin-bottom: 15px; }
            .kyc-page-wrapper .kyc-document-wrapper .section-wrapper .upload-section .section .dropzone {
              cursor: pointer;
              width: 100%;
              height: 100px;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              border-radius: 8px;
              background-color: #eaeaea;
              border: 2px dashed #666666; }
              .kyc-page-wrapper .kyc-document-wrapper .section-wrapper .upload-section .section .dropzone .image {
                width: 100%;
                height: 100%;
                object-fit: contain; }
              .kyc-page-wrapper .kyc-document-wrapper .section-wrapper .upload-section .section .dropzone .icon {
                font-size: 24px;
                color: #666666;
                margin-bottom: 10px; }
              .kyc-page-wrapper .kyc-document-wrapper .section-wrapper .upload-section .section .dropzone .text {
                width: 80%;
                text-align: center;
                font-size: 14px;
                font-weight: 500; }
        .kyc-page-wrapper .kyc-document-wrapper .section-wrapper .error-zone {
          width: 100%;
          margin-bottom: 20px;
          font-size: 14px; }
        .kyc-page-wrapper .kyc-document-wrapper .section-wrapper .action-zone {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-start; }
          .kyc-page-wrapper .kyc-document-wrapper .section-wrapper .action-zone .back {
            width: 120px;
            height: 40px; }
          .kyc-page-wrapper .kyc-document-wrapper .section-wrapper .action-zone .continue {
            width: 120px;
            height: 40px; }
    .kyc-page-wrapper .kyc-profile-wrapper {
      width: 100%;
      min-height: 420px;
      background-color: #ffffff; }
      .kyc-page-wrapper .kyc-profile-wrapper .section-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center; }
        .kyc-page-wrapper .kyc-profile-wrapper .section-wrapper .header {
          font-size: 20px;
          font-weight: 700;
          color: #4a4a4a;
          margin-top: 30px; }
        .kyc-page-wrapper .kyc-profile-wrapper .section-wrapper .content {
          font-size: 16px;
          line-height: 26px;
          font-weight: 500;
          color: #4a4a4a;
          margin-top: 10px;
          margin-bottom: 30px; }
        .kyc-page-wrapper .kyc-profile-wrapper .section-wrapper .selfie-section {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: flex-start;
          margin-bottom: 50px; }
          .kyc-page-wrapper .kyc-profile-wrapper .section-wrapper .selfie-section .section {
            display: none; }
          .kyc-page-wrapper .kyc-profile-wrapper .section-wrapper .selfie-section .mobile-section {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            width: 275px; }
            .kyc-page-wrapper .kyc-profile-wrapper .section-wrapper .selfie-section .mobile-section .header {
              font-size: 18px;
              line-height: 26px;
              font-weight: 500;
              color: #4a4a4a;
              margin-bottom: 15px; }
            .kyc-page-wrapper .kyc-profile-wrapper .section-wrapper .selfie-section .mobile-section .dropzone {
              cursor: pointer;
              width: 50%;
              height: 100px;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              border-radius: 8px;
              background-color: #eaeaea;
              border: 2px dashed #666666; }
              .kyc-page-wrapper .kyc-profile-wrapper .section-wrapper .selfie-section .mobile-section .dropzone .image {
                width: 100%;
                height: 100%;
                object-fit: contain; }
              .kyc-page-wrapper .kyc-profile-wrapper .section-wrapper .selfie-section .mobile-section .dropzone .icon {
                font-size: 24px;
                color: #666666;
                margin-bottom: 10px; }
              .kyc-page-wrapper .kyc-profile-wrapper .section-wrapper .selfie-section .mobile-section .dropzone .text {
                width: 80%;
                text-align: center;
                font-size: 14px;
                font-weight: 500; }
        .kyc-page-wrapper .kyc-profile-wrapper .section-wrapper .error-zone {
          width: 100%;
          margin-bottom: 20px;
          font-size: 14px; }
        .kyc-page-wrapper .kyc-profile-wrapper .section-wrapper .action-zone {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-start; }
          .kyc-page-wrapper .kyc-profile-wrapper .section-wrapper .action-zone .back {
            width: 120px;
            height: 40px; }
          .kyc-page-wrapper .kyc-profile-wrapper .section-wrapper .action-zone .continue {
            display: none; }
          .kyc-page-wrapper .kyc-profile-wrapper .section-wrapper .action-zone .mobile-continue {
            width: 120px;
            height: 40px; }
    .kyc-page-wrapper .kyc-address-wrapper {
      width: 100%;
      min-height: 420px;
      background-color: #ffffff; }
      .kyc-page-wrapper .kyc-address-wrapper .section-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center; }
        .kyc-page-wrapper .kyc-address-wrapper .section-wrapper .header {
          font-size: 20px;
          font-weight: 700;
          color: #4a4a4a;
          margin-top: 30px; }
        .kyc-page-wrapper .kyc-address-wrapper .section-wrapper .content {
          font-size: 16px;
          line-height: 26px;
          font-weight: 500;
          color: #4a4a4a;
          margin-top: 10px;
          margin-bottom: 30px; }
        .kyc-page-wrapper .kyc-address-wrapper .section-wrapper .address-form-wrapper {
          width: 300px !important;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center; }
          .kyc-page-wrapper .kyc-address-wrapper .section-wrapper .address-form-wrapper .form-row {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center; }
            .kyc-page-wrapper .kyc-address-wrapper .section-wrapper .address-form-wrapper .form-row .input {
              width: 300px; }
            .kyc-page-wrapper .kyc-address-wrapper .section-wrapper .address-form-wrapper .form-row .left-pane {
              flex: 1 1; }
              .kyc-page-wrapper .kyc-address-wrapper .section-wrapper .address-form-wrapper .form-row .left-pane .input {
                width: 300px; }
            .kyc-page-wrapper .kyc-address-wrapper .section-wrapper .address-form-wrapper .form-row .right-pane {
              flex: 1 1; }
              .kyc-page-wrapper .kyc-address-wrapper .section-wrapper .address-form-wrapper .form-row .right-pane .input {
                width: 300px; }
            .kyc-page-wrapper .kyc-address-wrapper .section-wrapper .address-form-wrapper .form-row .subcontent {
              width: 300px;
              text-align: center;
              font-size: 14px;
              line-height: 26px;
              color: #4a4a4a;
              font-weight: 500; }
            .kyc-page-wrapper .kyc-address-wrapper .section-wrapper .address-form-wrapper .form-row .upload-section {
              width: 100%;
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: flex-start;
              margin-bottom: 40px; }
              .kyc-page-wrapper .kyc-address-wrapper .section-wrapper .address-form-wrapper .form-row .upload-section .section {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                width: 70%; }
                .kyc-page-wrapper .kyc-address-wrapper .section-wrapper .address-form-wrapper .form-row .upload-section .section .header {
                  font-size: 18px;
                  line-height: 26px;
                  font-weight: 500;
                  color: #4a4a4a;
                  margin-bottom: 15px; }
                .kyc-page-wrapper .kyc-address-wrapper .section-wrapper .address-form-wrapper .form-row .upload-section .section .dropzone {
                  cursor: pointer;
                  width: 100%;
                  height: 150px;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                  border-radius: 8px;
                  margin-top: 10px;
                  background-color: #eaeaea;
                  border: 2px dashed #666666; }
                  .kyc-page-wrapper .kyc-address-wrapper .section-wrapper .address-form-wrapper .form-row .upload-section .section .dropzone .image {
                    width: 100%;
                    height: 100%;
                    object-fit: contain; }
                  .kyc-page-wrapper .kyc-address-wrapper .section-wrapper .address-form-wrapper .form-row .upload-section .section .dropzone .icon {
                    font-size: 24px;
                    color: #666666;
                    margin-bottom: 10px; }
                  .kyc-page-wrapper .kyc-address-wrapper .section-wrapper .address-form-wrapper .form-row .upload-section .section .dropzone .text {
                    width: 80%;
                    text-align: center;
                    font-size: 14px;
                    font-weight: 500; }
        .kyc-page-wrapper .kyc-address-wrapper .section-wrapper .error-zone {
          width: 100%;
          margin-bottom: 20px;
          font-size: 14px; }
        .kyc-page-wrapper .kyc-address-wrapper .section-wrapper .action-zone {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-start; }
          .kyc-page-wrapper .kyc-address-wrapper .section-wrapper .action-zone .back {
            width: 120px;
            height: 40px; }
          .kyc-page-wrapper .kyc-address-wrapper .section-wrapper .action-zone .continue {
            width: 120px;
            height: 40px; }
    .kyc-page-wrapper .kyc-submit-wrapper {
      width: 100%;
      min-height: 420px;
      background-color: #ffffff; }
      .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center; }
        .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .header {
          font-size: 20px;
          font-weight: 700;
          color: #4a4a4a;
          margin-top: 30px; }
        .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .content {
          font-size: 16px;
          line-height: 26px;
          font-weight: 500;
          color: #4a4a4a;
          margin-top: 10px;
          margin-bottom: 30px; }
        .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .submit-section-wrapper {
          display: flex;
          flex-direction: column;
          width: 100%;
          justify-content: flex-start;
          align-items: flex-start; }
          .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .submit-section-wrapper .personal-row {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: flex-start;
            width: 100%; }
            .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .submit-section-wrapper .personal-row .item {
              width: 33%;
              margin-bottom: 20px; }
              .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .submit-section-wrapper .personal-row .item .item-header {
                font-size: 14px;
                line-height: 20px;
                color: #999999; }
              .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .submit-section-wrapper .personal-row .item .item-text {
                font-size: 16px;
                line-height: 20px;
                color: #000000;
                font-weight: 600; }
          .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .submit-section-wrapper .address-row {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            width: 100%;
            justify-content: flex-start;
            align-items: flex-start; }
            .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .submit-section-wrapper .address-row .item {
              width: 50%;
              margin-bottom: 20px; }
              .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .submit-section-wrapper .address-row .item .item-header {
                font-size: 14px;
                line-height: 20px;
                color: #999999; }
              .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .submit-section-wrapper .address-row .item .item-text {
                font-size: 16px;
                line-height: 20px;
                color: #000000;
                font-weight: 600; }
            .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .submit-section-wrapper .address-row .address-item {
              width: 100%;
              box-sizing: border-box;
              padding-right: 10px; }
          .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .submit-section-wrapper .document-row {
            display: none; }
          .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .submit-section-wrapper .mobile-document-row {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            width: 100%; }
            .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .submit-section-wrapper .mobile-document-row .item {
              width: 100%;
              margin-bottom: 20px; }
              .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .submit-section-wrapper .mobile-document-row .item .item-header {
                font-size: 14px;
                line-height: 20px;
                color: #999999; }
              .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .submit-section-wrapper .mobile-document-row .item .document-zone {
                margin-top: 10px;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: flex-start; }
                .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .submit-section-wrapper .mobile-document-row .item .document-zone a {
                  text-decoration: none; }
                  .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .submit-section-wrapper .mobile-document-row .item .document-zone a .document-text {
                    font-size: 16px;
                    color: #0033ff;
                    font-weight: 600; }
                    .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .submit-section-wrapper .mobile-document-row .item .document-zone a .document-text:first-child {
                      margin-right: 30px; }
                .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .submit-section-wrapper .mobile-document-row .item .document-zone .image {
                  border-radius: 2px;
                  width: 125px;
                  overflow: hidden;
                  height: auto; }
                  .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .submit-section-wrapper .mobile-document-row .item .document-zone .image:first-child {
                    margin-right: 20px; }
            .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .submit-section-wrapper .mobile-document-row .general-documents-item {
              width: 100%; }
        .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .action-zone {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-start; }
          .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .action-zone .back {
            width: 120px;
            height: 40px; }
          .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .action-zone .continue {
            width: 120px;
            height: 40px; }
    .kyc-page-wrapper .kyc-success-wrapper {
      width: 100%;
      min-height: 420px;
      background-color: #ffffff; }
      .kyc-page-wrapper .kyc-success-wrapper .section-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center; }
        .kyc-page-wrapper .kyc-success-wrapper .section-wrapper .header {
          font-size: 20px;
          font-weight: 700;
          color: #4a4a4a;
          margin-top: 45px; }
        .kyc-page-wrapper .kyc-success-wrapper .section-wrapper .content {
          font-size: 16px;
          line-height: 26px;
          font-weight: 500;
          color: #4a4a4a;
          margin-top: 10px;
          margin-bottom: 30px; }
        .kyc-page-wrapper .kyc-success-wrapper .section-wrapper .action-zone {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center; }
          .kyc-page-wrapper .kyc-success-wrapper .section-wrapper .action-zone .return {
            width: 120px;
            height: 40px; } }

/* Global style guide for UI Lib */
/**
 * Browser support
 */
/**
 * Typography
 */
/**
 * Colors
 */
/**
 * Page -- UILib Specific Variables
 */
/**
 * Card -- UILib Specific Variables
 */
.select-search-box {
  width: 300px;
  height: 50px;
  position: relative;
  margin-bottom: 30px;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid #cccccc;
  outline: none;
  text-align: left; }
  .select-search-box:focus {
    border-color: #3366ff; }

.select-search-box--multiple {
  border-radius: 4px; }

.select-search-box::after {
  display: none; }

.select-search-box--multiple::after,
.select-search-box--focus::after {
  display: none; }

.select-search-box__icon--disabled::after {
  content: none !important; }

.select-search-box--input::after {
  display: none !important; }

.select-search-box__out {
  display: none; }

.select-search-box__search {
  display: block;
  width: 100%;
  height: 50px;
  border: none;
  background: none;
  outline: none;
  font-size: 16px;
  padding: 0 15px;
  color: #4a4a4a;
  -webkit-appearance: none;
  box-sizing: border-box;
  position: relative;
  z-index: 2;
  cursor: pointer;
  line-height: 50px; }

input.select-search-box__search {
  line-height: 1; }

.select-search-box--multiple .select-search-box__search {
  box-shadow: none; }

.select-search-box--input .select-search-box__search {
  cursor: text; }

.select-search-box__search:focus {
  cursor: text; }

.select-search-box__search--placeholder {
  font-weight: normal; }

.select-search-box input::-webkit-input-placeholder {
  color: #999999;
  font-weight: normal; }

.select-search-box input::-moz-placeholder {
  color: #999999;
  font-weight: normal; }

.select-search-box input:-moz-placeholder {
  color: #999999;
  font-weight: normal; }

.select-search-box input:-ms-input-placeholder {
  color: #999999;
  font-weight: normal; }

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
  -webkit-appearance: none; }

.select-search-box__select {
  display: none;
  position: absolute;
  width: 100%;
  top: 60px;
  left: 0;
  right: 0;
  background: #fff;
  border-radius: 4px;
  overflow: auto;
  box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
  z-index: 100;
  min-height: 200px; }

.select-search-box--multiple .select-search-box__select {
  display: block;
  position: static;
  border-top: 1px solid #eee;
  border-radius: 0;
  box-shadow: none; }

.select-search-box__select--display {
  display: block; }

.select-search-box__options {
  -webkit-padding-start: 0px;
          padding-inline-start: 0px;
  -webkit-margin-before: 0px;
          margin-block-start: 0px;
  -webkit-margin-after: 0px;
          margin-block-end: 0px; }

.select-search-box__option {
  font-size: 16px;
  font-weight: 400;
  color: #616b74;
  box-sizing: border-box;
  padding: 15px 20px;
  border-top: 1px solid #eaeaea;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.select-search-box__option:first-child {
  border-top: none; }

.select-search-box__option--hover,
.select-search-box__option:hover {
  background: #fafafa; }

.select-search-box__option--selected {
  background: #0033ff;
  color: #fff;
  border-top-color: #0033ff; }

.select-search-box__option--selected:hover,
.select-search-box__option--selected.select-search-box__option--hover {
  background: #0033ff;
  color: #fff;
  border-top-color: #0033ff; }

.select-search-box__group {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #eee;
  position: relative; }

.select-search-box__group-header {
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background: white;
  padding: 0 10px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 12px; }

/* Global style guide for UI Lib */
/**
 * Browser support
 */
/**
 * Typography
 */
/**
 * Colors
 */
/**
 * Page -- UILib Specific Variables
 */
/**
 * Card -- UILib Specific Variables
 */
@media only screen and (min-width: 769px) {
  .not-found-zone {
    height: calc(100vh - 83px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    .not-found-zone .text {
      color: #666666;
      font-size: 24px;
      font-weight: 500;
      margin-bottom: 30px; }
    .not-found-zone .action-zone {
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 100%; }
      .not-found-zone .action-zone .action {
        width: 175px; }
        .not-found-zone .action-zone .action:first-child {
          margin-right: 50px; } }

@media only screen and (max-width: 768px) {
  .not-found-zone {
    width: 90vw;
    margin: 0 auto;
    min-height: calc(100vh - 200px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    .not-found-zone .text {
      color: #666666;
      font-size: 24px;
      font-weight: 500;
      margin-bottom: 30px; }
    .not-found-zone .action-zone {
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 100%; }
      .not-found-zone .action-zone .action {
        width: 175px; }
        .not-found-zone .action-zone .action:first-child {
          margin-right: 50px; } }

/* Global style guide for UI Lib */
/**
 * Browser support
 */
/**
 * Typography
 */
/**
 * Colors
 */
/**
 * Page -- UILib Specific Variables
 */
/**
 * Card -- UILib Specific Variables
 */
@media only screen and (min-width: 769px) {
  .work-in-progress-zone {
    height: calc(100vh - 83px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    .work-in-progress-zone .text {
      color: #666666;
      font-size: 24px;
      font-weight: 500;
      margin-bottom: 30px; }
    .work-in-progress-zone .action-zone {
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 100%; }
      .work-in-progress-zone .action-zone .action {
        width: 175px; }
        .work-in-progress-zone .action-zone .action:first-child {
          margin-right: 50px; } }

@media only screen and (max-width: 768px) {
  .work-in-progress-zone {
    width: 90vw;
    margin: 0 auto;
    min-height: calc(100vh - 200px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    .work-in-progress-zone .text {
      color: #666666;
      font-size: 24px;
      font-weight: 500;
      margin-bottom: 30px; }
    .work-in-progress-zone .action-zone {
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 100%; }
      .work-in-progress-zone .action-zone .action {
        width: 175px; }
        .work-in-progress-zone .action-zone .action:first-child {
          margin-right: 50px; } }

/* Global style guide for UI Lib */
/**
 * Browser support
 */
/**
 * Typography
 */
/**
 * Colors
 */
/**
 * Page -- UILib Specific Variables
 */
/**
 * Card -- UILib Specific Variables
 */
html,
body {
  min-height: 100vh;
  min-width: 100vw;
  margin: 0;
  padding: 0;
  font-family: "Rajdhani", sans-serif;
  font-size: 12px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

h1 {
  font-size: 3em;
  line-height: 1.25;
  font-weight: 700;
  margin: 0; }

h2 {
  font-size: 2.5em;
  line-height: 1.25;
  font-weight: 700;
  margin: 0; }

h3 {
  font-size: 2em;
  line-height: 1.25;
  font-weight: 700;
  margin: 0; }

h4 {
  font-size: 1.67em;
  line-height: 1.25;
  font-weight: 700;
  margin: 0; }

p {
  font-size: 1.167em;
  line-height: 1.25;
  font-weight: 500;
  margin: 0; }

.text-quote {
  font-size: 2em;
  line-height: 1.44;
  font-weight: 500;
  margin: 0; }

.text-cta {
  font-size: 1.5em;
  line-height: 1.25;
  font-weight: 600;
  margin: 0; }

.text-intro {
  font-size: 1.5em;
  line-height: 1.44;
  font-weight: 500;
  margin: 0; }

.text-small {
  font-size: 0.83em;
  line-height: 1.5;
  font-weight: 500;
  margin: 0; }

