/* Global style guide for UI Lib */
/**
 * Browser support
 */
/**
 * Typography
 */
@import url("https://fonts.googleapis.com/css?family=Rajdhani:300,400,500,600,700&subset=latin-ext");
/**
 * Colors
 */
/**
 * Page -- UILib Specific Variables
 */
/**
 * Card -- UILib Specific Variables
 */
@media only screen and (min-width: 769px) {
  .exchange-trade-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 1130px;
    box-sizing: border-box;
    margin: 0 auto;
    margin-top: 60px;
    padding-bottom: 60px; }
    .exchange-trade-wrapper .exchange-header {
      color: #4a4a4a;
      font-weight: 700;
      font-size: 36px;
      margin-bottom: 10px; }
    .exchange-trade-wrapper .exchange-subheader {
      color: #4a4a4a;
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      margin-bottom: 40px; }
    .exchange-trade-wrapper .exchange-rates-server-error {
      width: 100%;
      margin-bottom: 20px; }
    .exchange-trade-wrapper .trade-zone {
      background-color: #ffffff;
      width: 100%;
      min-height: 630px;
      border-radius: 8px;
      box-sizing: border-box;
      padding: 45px 60px 60px 60px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start; }
      .exchange-trade-wrapper .trade-zone .currency-selection-wrapper {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 60px; }
        .exchange-trade-wrapper .trade-zone .currency-selection-wrapper .currency-selection-zone {
          width: 50%;
          box-sizing: border-box;
          min-height: 140px;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start; }
          .exchange-trade-wrapper .trade-zone .currency-selection-wrapper .currency-selection-zone:first-child {
            border-right: 1px solid #4a4a4a;
            padding-right: 70px; }
          .exchange-trade-wrapper .trade-zone .currency-selection-wrapper .currency-selection-zone:last-child {
            padding-left: 70px; }
          .exchange-trade-wrapper .trade-zone .currency-selection-wrapper .currency-selection-zone .header {
            font-size: 24px;
            font-weight: 600;
            color: #4a4a4a; }
          .exchange-trade-wrapper .trade-zone .currency-selection-wrapper .currency-selection-zone .subheader {
            font-size: 16px;
            font-weight: 500;
            color: #4a4a4a;
            margin-bottom: 15px; }
          .exchange-trade-wrapper .trade-zone .currency-selection-wrapper .currency-selection-zone .currency-selection-row {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start; }
            .exchange-trade-wrapper .trade-zone .currency-selection-wrapper .currency-selection-zone .currency-selection-row .currency-button {
              width: 100px;
              height: 70px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              box-sizing: border-box;
              border: 1px solid #4a4a4a;
              font-size: 18px;
              font-weight: 600;
              color: #4a4a4a;
              background-color: #ffffff;
              border-radius: 8px;
              cursor: pointer; }
            .exchange-trade-wrapper .trade-zone .currency-selection-wrapper .currency-selection-zone .currency-selection-row .selected {
              background-color: #0033ff;
              color: #ffffff;
              border: none; }
            .exchange-trade-wrapper .trade-zone .currency-selection-wrapper .currency-selection-zone .currency-selection-row .disabled {
              background-color: #eaeaea;
              color: #999999;
              border: none; }
      .exchange-trade-wrapper .trade-zone .amount-inputs-wrapper {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-end;
        margin-bottom: 60px;
        box-sizing: border-box; }
        .exchange-trade-wrapper .trade-zone .amount-inputs-wrapper .from-input-zone {
          width: 230px;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: flex-start; }
          .exchange-trade-wrapper .trade-zone .amount-inputs-wrapper .from-input-zone .input-header {
            font-size: 20px;
            font-weight: 500;
            color: #4a4a4a;
            margin-bottom: 10px; }
          .exchange-trade-wrapper .trade-zone .amount-inputs-wrapper .from-input-zone .input {
            width: 230px;
            height: 50px;
            font-size: 18px;
            box-sizing: border-box;
            border-radius: 4px;
            border: 1px solid #cccccc;
            padding-left: 15px;
            padding-right: 15px;
            outline: none;
            text-align: left; }
            .exchange-trade-wrapper .trade-zone .amount-inputs-wrapper .from-input-zone .input:focus {
              border-color: #3366ff; }
        .exchange-trade-wrapper .trade-zone .amount-inputs-wrapper .to-input-zone {
          width: 230px;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: flex-start; }
          .exchange-trade-wrapper .trade-zone .amount-inputs-wrapper .to-input-zone .input-header {
            font-size: 20px;
            font-weight: 500;
            color: #4a4a4a;
            margin-bottom: 10px; }
          .exchange-trade-wrapper .trade-zone .amount-inputs-wrapper .to-input-zone .input {
            width: 230px;
            height: 50px;
            font-size: 18px;
            box-sizing: border-box;
            border-radius: 4px;
            border: 1px solid #cccccc;
            padding-left: 15px;
            padding-right: 15px;
            outline: none;
            text-align: left; }
            .exchange-trade-wrapper .trade-zone .amount-inputs-wrapper .to-input-zone .input:focus {
              border-color: #3366ff; }
        .exchange-trade-wrapper .trade-zone .amount-inputs-wrapper .icon {
          margin: auto 27px 10px 27px;
          font-size: 30px;
          cursor: pointer; }
        .exchange-trade-wrapper .trade-zone .amount-inputs-wrapper .safe-order {
          height: 50px;
          width: 60px;
          box-sizing: border-box;
          padding: 10px;
          text-align: center;
          border: 1px solid #cccccc;
          border-radius: 3px;
          margin-left: 10px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          cursor: pointer; }
          .exchange-trade-wrapper .trade-zone .amount-inputs-wrapper .safe-order .text {
            color: #999999;
            font-size: 15px;
            font-weight: 600; }
          .exchange-trade-wrapper .trade-zone .amount-inputs-wrapper .safe-order .selected {
            color: #ffffff; }
        .exchange-trade-wrapper .trade-zone .amount-inputs-wrapper .safe-order-selected {
          background-color: #0033ff;
          border: none; }
      .exchange-trade-wrapper .trade-zone .currency-exchange-rate-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; }
        .exchange-trade-wrapper .trade-zone .currency-exchange-rate-wrapper .header {
          font-size: 16px;
          line-height: 26px;
          font-weight: 700;
          color: #4a4a4a; }
        .exchange-trade-wrapper .trade-zone .currency-exchange-rate-wrapper .content {
          font-size: 16px;
          line-height: 26px;
          font-weight: 500;
          color: #4a4a4a; }
      .exchange-trade-wrapper .trade-zone .safe-order-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 60px;
        width: 650px;
        margin-left: auto;
        margin-right: auto; }
        .exchange-trade-wrapper .trade-zone .safe-order-wrapper .safe-order-zone {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          margin-bottom: 4px;
          cursor: pointer; }
          .exchange-trade-wrapper .trade-zone .safe-order-wrapper .safe-order-zone .icon {
            font-size: 20px; }
          .exchange-trade-wrapper .trade-zone .safe-order-wrapper .safe-order-zone .text {
            margin-left: 8px;
            font-size: 20px;
            color: #4a4a4a;
            font-weight: 500; }
        .exchange-trade-wrapper .trade-zone .safe-order-wrapper .text {
          font-size: 16px;
          color: #999999;
          font-weight: 500;
          line-height: 26px; }
      .exchange-trade-wrapper .trade-zone .submit-wrapper {
        width: 600px;
        margin: 0 auto; }
        .exchange-trade-wrapper .trade-zone .submit-wrapper form {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center; }
          .exchange-trade-wrapper .trade-zone .submit-wrapper form .error-container {
            margin-bottom: 30px;
            font-size: 14px; }
          .exchange-trade-wrapper .trade-zone .submit-wrapper form .toc-zone {
            width: 240px;
            margin-bottom: 10px; }
          .exchange-trade-wrapper .trade-zone .submit-wrapper form .action {
            width: 240px;
            height: 50px; }
      .exchange-trade-wrapper .trade-zone .information-zone {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin-top: 10px; }
        .exchange-trade-wrapper .trade-zone .information-zone .divider {
          width: 150px;
          height: 1px;
          background-color: #999999;
          margin-top: 30px;
          margin-bottom: 30px; }
        .exchange-trade-wrapper .trade-zone .information-zone .text {
          font-size: 16px;
          color: #4a4a4a;
          font-weight: 500;
          line-height: 26px; }
  .exchange-success-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 1130px;
    box-sizing: border-box;
    margin: 0 auto;
    margin-top: 60px;
    padding-bottom: 60px; }
    .exchange-success-wrapper .success-header-zone {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      height: 100px;
      margin-bottom: 60px; }
      .exchange-success-wrapper .success-header-zone .icon {
        font-size: 100px; }
      .exchange-success-wrapper .success-header-zone .success-header-content {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-left: 40px; }
        .exchange-success-wrapper .success-header-zone .success-header-content .success-header {
          color: #4a4a4a;
          font-weight: 700;
          font-size: 30px; }
        .exchange-success-wrapper .success-header-zone .success-header-content .success-text {
          color: #4a4a4a;
          font-weight: 500;
          font-size: 18px;
          line-height: 26px; }
    .exchange-success-wrapper .success-action-zone {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-start; }
      .exchange-success-wrapper .success-action-zone .button {
        width: 240px;
        height: 60px;
        font-size: 24px;
        font-weight: 600; }
  .exchange-failure-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 1130px;
    box-sizing: border-box;
    margin: 0 auto;
    margin-top: 60px;
    padding-bottom: 60px; }
    .exchange-failure-wrapper .failure-header-zone {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      height: 100px;
      margin-bottom: 60px; }
      .exchange-failure-wrapper .failure-header-zone .icon {
        font-size: 100px; }
      .exchange-failure-wrapper .failure-header-zone .failure-header-content {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-left: 40px; }
        .exchange-failure-wrapper .failure-header-zone .failure-header-content .failure-header {
          color: #4a4a4a;
          font-weight: 700;
          font-size: 30px; }
        .exchange-failure-wrapper .failure-header-zone .failure-header-content .failure-text {
          color: #4a4a4a;
          font-weight: 500;
          font-size: 18px;
          line-height: 26px; }
    .exchange-failure-wrapper .failure-action-zone {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start; }
      .exchange-failure-wrapper .failure-action-zone .button {
        width: 240px;
        height: 60px;
        font-size: 24px;
        font-weight: 600; }
  .confirmation-modal {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%; }
    .confirmation-modal .confirmation-modal-header {
      font-size: 32px;
      font-weight: 700; }
    .confirmation-modal .confirmation-modal-subheader {
      font-size: 22px;
      font-weight: 500;
      line-height: 30px;
      margin-top: 40px; }
    .confirmation-modal .confirmation-modal-icon {
      font-size: 100px;
      color: #ff6600;
      text-align: center;
      margin-top: 80px;
      margin-bottom: 100px; }
    .confirmation-modal .confirmation-actions {
      margin-top: auto;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between; }
      .confirmation-modal .confirmation-actions .button {
        width: 46%;
        height: 50px; } }

@media only screen and (max-width: 768px) {
  .exchange-trade-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: calc(100% - 40px);
    box-sizing: border-box;
    margin: 0 auto;
    margin-top: 20px;
    padding-bottom: 20px; }
    .exchange-trade-wrapper .exchange-header {
      color: #4a4a4a;
      font-weight: 700;
      font-size: 24px;
      margin-bottom: 5px; }
    .exchange-trade-wrapper .exchange-subheader {
      color: #4a4a4a;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 30px; }
    .exchange-trade-wrapper .trade-zone {
      background-color: transparent;
      width: 100%;
      min-height: 630px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start; }
      .exchange-trade-wrapper .trade-zone .currency-selection-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 20px; }
        .exchange-trade-wrapper .trade-zone .currency-selection-wrapper .currency-selection-zone {
          width: 100%;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start; }
          .exchange-trade-wrapper .trade-zone .currency-selection-wrapper .currency-selection-zone:first-child {
            margin-bottom: 20px; }
          .exchange-trade-wrapper .trade-zone .currency-selection-wrapper .currency-selection-zone .header {
            font-size: 16px;
            font-weight: 600;
            color: #4a4a4a;
            margin-bottom: 8px; }
          .exchange-trade-wrapper .trade-zone .currency-selection-wrapper .currency-selection-zone .subheader {
            display: none; }
          .exchange-trade-wrapper .trade-zone .currency-selection-wrapper .currency-selection-zone .currency-selection-row {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start; }
            .exchange-trade-wrapper .trade-zone .currency-selection-wrapper .currency-selection-zone .currency-selection-row .currency-button {
              width: 100px;
              height: 50px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              box-sizing: border-box;
              border: 1px solid #4a4a4a;
              font-size: 18px;
              font-weight: 600;
              color: #4a4a4a;
              background-color: #ffffff;
              border-radius: 8px;
              cursor: pointer; }
              .exchange-trade-wrapper .trade-zone .currency-selection-wrapper .currency-selection-zone .currency-selection-row .currency-button:last-child {
                display: none; }
            .exchange-trade-wrapper .trade-zone .currency-selection-wrapper .currency-selection-zone .currency-selection-row .selected {
              background-color: #0033ff;
              color: #ffffff;
              border: none; }
            .exchange-trade-wrapper .trade-zone .currency-selection-wrapper .currency-selection-zone .currency-selection-row .disabled {
              background-color: #eaeaea;
              color: #999999;
              border: none; }
      .exchange-trade-wrapper .trade-zone .amount-inputs-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        margin-bottom: 30px;
        box-sizing: border-box; }
        .exchange-trade-wrapper .trade-zone .amount-inputs-wrapper .from-input-zone {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: flex-start; }
          .exchange-trade-wrapper .trade-zone .amount-inputs-wrapper .from-input-zone .input-header {
            font-size: 16px;
            font-weight: 500;
            color: #4a4a4a;
            margin-bottom: 10px; }
          .exchange-trade-wrapper .trade-zone .amount-inputs-wrapper .from-input-zone .input {
            width: 100%;
            height: 50px;
            font-size: 18px;
            box-sizing: border-box;
            border-radius: 4px;
            border: 1px solid #cccccc;
            padding-left: 15px;
            padding-right: 15px;
            outline: none;
            text-align: left; }
            .exchange-trade-wrapper .trade-zone .amount-inputs-wrapper .from-input-zone .input:focus {
              border-color: #3366ff; }
        .exchange-trade-wrapper .trade-zone .amount-inputs-wrapper .to-input-zone {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: flex-start;
          margin-top: 20px; }
          .exchange-trade-wrapper .trade-zone .amount-inputs-wrapper .to-input-zone .input-header {
            font-size: 16px;
            font-weight: 500;
            color: #4a4a4a;
            margin-bottom: 10px; }
          .exchange-trade-wrapper .trade-zone .amount-inputs-wrapper .to-input-zone .input {
            width: 100%;
            height: 50px;
            font-size: 18px;
            box-sizing: border-box;
            border-radius: 4px;
            border: 1px solid #cccccc;
            padding-left: 15px;
            padding-right: 15px;
            outline: none;
            text-align: left; }
            .exchange-trade-wrapper .trade-zone .amount-inputs-wrapper .to-input-zone .input:focus {
              border-color: #3366ff; }
        .exchange-trade-wrapper .trade-zone .amount-inputs-wrapper .icon {
          display: none; }
        .exchange-trade-wrapper .trade-zone .amount-inputs-wrapper .safe-order {
          display: none; }
        .exchange-trade-wrapper .trade-zone .amount-inputs-wrapper .safe-order-selected {
          display: none; }
      .exchange-trade-wrapper .trade-zone .safe-order-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 50px;
        width: 100%;
        margin-left: auto;
        margin-right: auto; }
        .exchange-trade-wrapper .trade-zone .safe-order-wrapper .safe-order-zone {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          margin-bottom: 4px;
          cursor: pointer; }
          .exchange-trade-wrapper .trade-zone .safe-order-wrapper .safe-order-zone .icon {
            font-size: 20px; }
          .exchange-trade-wrapper .trade-zone .safe-order-wrapper .safe-order-zone .text {
            margin-left: 8px;
            font-size: 16px;
            color: #4a4a4a;
            font-weight: 500; }
        .exchange-trade-wrapper .trade-zone .safe-order-wrapper .text {
          font-size: 12px;
          color: #999999;
          font-weight: 500;
          line-height: 16px; }
      .exchange-trade-wrapper .trade-zone .submit-wrapper {
        width: 240px;
        margin: 0 auto; }
        .exchange-trade-wrapper .trade-zone .submit-wrapper form {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center; }
          .exchange-trade-wrapper .trade-zone .submit-wrapper form .error-container {
            margin-bottom: 30px;
            font-size: 14px; }
          .exchange-trade-wrapper .trade-zone .submit-wrapper form .toc-zone {
            width: 100%;
            margin-bottom: 10px; }
          .exchange-trade-wrapper .trade-zone .submit-wrapper form .action {
            width: 100%;
            height: 50px; }
      .exchange-trade-wrapper .trade-zone .information-zone {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin-top: 10px; }
        .exchange-trade-wrapper .trade-zone .information-zone .divider {
          width: 150px;
          height: 1px;
          background-color: #999999;
          margin-top: 30px;
          margin-bottom: 30px; }
        .exchange-trade-wrapper .trade-zone .information-zone .text {
          font-size: 14px;
          color: #4a4a4a;
          font-weight: 500;
          line-height: 20px; }
  .exchange-success-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    box-sizing: border-box;
    margin: 0 auto;
    margin-top: 30px;
    padding-bottom: 60px; }
    .exchange-success-wrapper .success-header-zone {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 60px; }
      .exchange-success-wrapper .success-header-zone .icon {
        font-size: 100px;
        margin-bottom: 20px; }
      .exchange-success-wrapper .success-header-zone .success-header-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        margin-left: 20px;
        margin-right: 20px; }
        .exchange-success-wrapper .success-header-zone .success-header-content .success-header {
          text-align: center;
          color: #4a4a4a;
          font-weight: 700;
          font-size: 24px;
          margin-bottom: 10px; }
        .exchange-success-wrapper .success-header-zone .success-header-content .success-text {
          color: #4a4a4a;
          font-weight: 500;
          font-size: 16px;
          line-height: 26px;
          margin: 0 auto; }
    .exchange-success-wrapper .success-action-zone {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-start; }
      .exchange-success-wrapper .success-action-zone .button {
        width: 240px;
        height: 60px;
        font-size: 24px;
        font-weight: 600; }
  .exchange-failure-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    box-sizing: border-box;
    margin: 0 auto;
    margin-top: 30px;
    padding-bottom: 60px; }
    .exchange-failure-wrapper .failure-header-zone {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 60px; }
      .exchange-failure-wrapper .failure-header-zone .icon {
        font-size: 100px;
        margin-bottom: 20px; }
      .exchange-failure-wrapper .failure-header-zone .failure-header-content {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-left: 20px;
        margin-right: 20px; }
        .exchange-failure-wrapper .failure-header-zone .failure-header-content .failure-header {
          color: #4a4a4a;
          font-weight: 700;
          font-size: 24px;
          margin-bottom: 10px;
          text-align: center; }
        .exchange-failure-wrapper .failure-header-zone .failure-header-content .failure-text {
          color: #4a4a4a;
          font-weight: 500;
          font-size: 16px;
          line-height: 26px;
          margin: 0 auto; }
    .exchange-failure-wrapper .failure-action-zone {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      box-sizing: border-box;
      padding-left: 20px;
      padding-right: 20px; }
      .exchange-failure-wrapper .failure-action-zone .button {
        width: 100%;
        height: 60px;
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 20px; }
      .exchange-failure-wrapper .failure-action-zone a {
        width: 100%; }
        .exchange-failure-wrapper .failure-action-zone a .button {
          width: 100%;
          height: 60px;
          font-size: 14px;
          font-weight: 600; }
  .confirmation-modal {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%; }
    .confirmation-modal .confirmation-modal-header {
      font-size: 24px;
      font-weight: 700; }
    .confirmation-modal .confirmation-modal-subheader {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      margin-top: 20px; }
    .confirmation-modal .confirmation-modal-icon {
      font-size: 60px;
      color: #ff6600;
      text-align: center;
      margin-top: 40px;
      margin-bottom: 40px; }
    .confirmation-modal .confirmation-actions {
      margin-top: 30px;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between; }
      .confirmation-modal .confirmation-actions .button {
        width: 46%;
        height: 50px; } }
