/* Global style guide for UI Lib */
/**
 * Browser support
 */
/**
 * Typography
 */
@import url("https://fonts.googleapis.com/css?family=Rajdhani:300,400,500,600,700&subset=latin-ext");
/**
 * Colors
 */
/**
 * Page -- UILib Specific Variables
 */
/**
 * Card -- UILib Specific Variables
 */
@media only screen and (min-width: 769px) {
  .navbar-container {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; }
    .navbar-container .logo {
      height: 40px;
      margin: 20px 0 20px 125px; }
    .navbar-container .navbar-region {
      width: 775px;
      margin: 20px 125px 20px 0;
      text-align: right; }
      .navbar-container .navbar-region .margin-right {
        margin-right: 20px; }
    .navbar-container .mobile-navbar-region {
      display: none; } }

@media only screen and (max-width: 768px) {
  @keyframes fade-in-right {
    from {
      opacity: 0.4;
      right: -60vw; }
    to {
      opacity: 1;
      right: 0; } }
  .navbar-container {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; }
    .navbar-container .logo-wrapper .logo {
      height: 40px;
      margin: 0px 20px; }
    .navbar-container .navbar-region {
      display: none; }
    .navbar-container .mobile-navbar-region {
      margin: 20px 20px 20px 0;
      text-align: right; }
      .navbar-container .mobile-navbar-region .menu-button {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        width: 40px;
        cursor: pointer;
        border: 1px solid #eaeaea;
        border-radius: 4px; }
        .navbar-container .mobile-navbar-region .menu-button .icon {
          margin: 0 auto;
          font-size: 16px;
          line-height: 16px;
          width: 16px;
          color: #4a4a4a; }
      .navbar-container .mobile-navbar-region .entrance {
        animation: fade-in-right ease 0.5s forwards; }
      .navbar-container .mobile-navbar-region .menu-zone {
        height: 100vh;
        width: 62vw;
        box-sizing: border-box;
        padding: 20px 20px;
        background-color: #ffffff;
        box-shadow: -2px 0px 5px 1px #aaaaaa;
        position: fixed;
        z-index: 100000;
        top: 0;
        right: -100vw;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center; }
        .navbar-container .mobile-navbar-region .menu-zone .full-width {
          width: 100%;
          margin-bottom: 20px; }
        .navbar-container .mobile-navbar-region .menu-zone .menu-button {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 40px;
          width: 40px;
          cursor: pointer;
          border: 1px solid #eaeaea;
          border-radius: 4px;
          float: right; }
          .navbar-container .mobile-navbar-region .menu-zone .menu-button .icon {
            margin: 0 auto;
            font-size: 16px;
            line-height: 16px;
            width: 16px;
            color: #4a4a4a; }
      .navbar-container .mobile-navbar-region .display-none {
        display: none; }
      .navbar-container .mobile-navbar-region .display {
        display: inherit; } }
