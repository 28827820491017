/* Global style guide for UI Lib */
/**
 * Browser support
 */
/**
 * Typography
 */
@import url("https://fonts.googleapis.com/css?family=Rajdhani:300,400,500,600,700&subset=latin-ext");
/**
 * Colors
 */
/**
 * Page -- UILib Specific Variables
 */
/**
 * Card -- UILib Specific Variables
 */
@media only screen and (min-width: 769px) {
  .footer-wrapper {
    width: 100%;
    min-height: 500px;
    background-color: #0033ff; }
    .footer-wrapper .footer-zone {
      width: 1366px;
      height: 100%;
      margin: 0 auto;
      box-sizing: border-box;
      padding: 60px 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center; }
      .footer-wrapper .footer-zone .subheader {
        font-size: 18px;
        font-weight: 600;
        color: #9cf;
        margin-bottom: 10px; }
      .footer-wrapper .footer-zone .header {
        font-size: 36px;
        font-weight: 500;
        color: #fff;
        margin-bottom: 60px; }
      .footer-wrapper .footer-zone .input-zone {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start; }
        .footer-wrapper .footer-zone .input-zone .input {
          width: 300px;
          height: 40px;
          font-size: 18px;
          margin-right: 20px; }
        .footer-wrapper .footer-zone .input-zone .cta {
          height: 40px;
          width: 140px; }
      .footer-wrapper .footer-zone .footer-text {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 120px;
        margin-bottom: 60px; }
        .footer-wrapper .footer-zone .footer-text .footer-left-text {
          text-align: left;
          font-size: 18px;
          font-weight: 500;
          color: #ffffff; }
        .footer-wrapper .footer-zone .footer-text .footer-right-text {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: center; }
          .footer-wrapper .footer-zone .footer-text .footer-right-text .text {
            font-size: 18px;
            font-weight: 500;
            color: #ffffff; }
          .footer-wrapper .footer-zone .footer-text .footer-right-text a {
            text-decoration: none; }
            .footer-wrapper .footer-zone .footer-text .footer-right-text a:first-child {
              margin-right: 20px; }
  .mobile-footer-wrapper {
    display: none; } }

@media only screen and (max-width: 768px) {
  .footer-wrapper {
    display: none; }
  .mobile-footer-wrapper {
    width: 100%;
    min-height: 500px;
    background-color: #0033ff; }
    .mobile-footer-wrapper .footer-zone {
      width: 100%;
      height: 100%;
      margin: 0 auto;
      box-sizing: border-box;
      padding: 40px 20px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center; }
      .mobile-footer-wrapper .footer-zone .subheader {
        font-size: 18px;
        font-weight: 600;
        color: #9cf;
        margin-bottom: 10px; }
      .mobile-footer-wrapper .footer-zone .header {
        font-size: 28px;
        font-weight: 500;
        color: #fff;
        margin-bottom: 60px; }
      .mobile-footer-wrapper .footer-zone .input-zone {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center; }
        .mobile-footer-wrapper .footer-zone .input-zone .input {
          width: 300px;
          height: 40px;
          font-size: 18px; }
        .mobile-footer-wrapper .footer-zone .input-zone .cta {
          height: 40px;
          width: 300px; }
      .mobile-footer-wrapper .footer-zone .footer-text {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: 120px;
        margin-bottom: 60px; }
        .mobile-footer-wrapper .footer-zone .footer-text .footer-left-text {
          text-align: center;
          font-size: 18px;
          font-weight: 500;
          color: #ffffff; } }
