/* Global style guide for UI Lib */
/**
 * Browser support
 */
/**
 * Typography
 */
@import url("https://fonts.googleapis.com/css?family=Rajdhani:300,400,500,600,700&subset=latin-ext");
/**
 * Colors
 */
/**
 * Page -- UILib Specific Variables
 */
/**
 * Card -- UILib Specific Variables
 */
@media only screen and (min-width: 769px) {
  .kyc-page-wrapper {
    box-sizing: border-box;
    width: 800px;
    margin: 0 auto;
    padding-top: 80px;
    padding-bottom: 80px; }
    .kyc-page-wrapper .step-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center; }
      .kyc-page-wrapper .step-wrapper .step-icon {
        font-size: 40px; }
      .kyc-page-wrapper .step-wrapper .results-icon {
        font-size: 100px; }
      .kyc-page-wrapper .step-wrapper .dots-icon {
        font-size: 20px; }
    .kyc-page-wrapper .kyc-continue-wrapper {
      width: 100%;
      height: 420px;
      background-color: #ffffff;
      border-radius: 8px;
      box-sizing: border-box;
      padding: 50px 80px; }
      .kyc-page-wrapper .kyc-continue-wrapper .section-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center; }
        .kyc-page-wrapper .kyc-continue-wrapper .section-wrapper .header {
          font-size: 20px;
          font-weight: 700;
          color: #4a4a4a;
          margin-top: 60px; }
        .kyc-page-wrapper .kyc-continue-wrapper .section-wrapper .content {
          font-size: 18px;
          line-height: 26px;
          font-weight: 500;
          color: #4a4a4a;
          margin-top: 10px;
          margin-bottom: 40px; }
        .kyc-page-wrapper .kyc-continue-wrapper .section-wrapper .action {
          width: 225px;
          height: 50px; }
    .kyc-page-wrapper .kyc-start-wrapper {
      width: 100%;
      height: 420px;
      background-color: #ffffff;
      border-radius: 8px;
      box-sizing: border-box;
      padding: 50px 80px; }
      .kyc-page-wrapper .kyc-start-wrapper .start-loader {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; }
    .kyc-page-wrapper .kyc-personal-wrapper {
      width: 100%;
      background-color: #ffffff;
      border-radius: 8px;
      box-sizing: border-box;
      padding: 50px 80px; }
      .kyc-page-wrapper .kyc-personal-wrapper .section-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center; }
        .kyc-page-wrapper .kyc-personal-wrapper .section-wrapper .header {
          font-size: 20px;
          font-weight: 700;
          color: #4a4a4a;
          margin-top: 60px; }
        .kyc-page-wrapper .kyc-personal-wrapper .section-wrapper .content {
          font-size: 18px;
          line-height: 26px;
          font-weight: 500;
          color: #4a4a4a;
          margin-top: 10px;
          margin-bottom: 40px; }
        .kyc-page-wrapper .kyc-personal-wrapper .section-wrapper .personal-form-wrapper {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center; }
          .kyc-page-wrapper .kyc-personal-wrapper .section-wrapper .personal-form-wrapper form {
            width: 100%; }
            .kyc-page-wrapper .kyc-personal-wrapper .section-wrapper .personal-form-wrapper form .form-zone {
              width: 100%;
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: flex-start;
              margin-bottom: 20px; }
              .kyc-page-wrapper .kyc-personal-wrapper .section-wrapper .personal-form-wrapper form .form-zone .input {
                width: 300px;
                margin-right: 25px;
                margin-bottom: 20px; }
              .kyc-page-wrapper .kyc-personal-wrapper .section-wrapper .personal-form-wrapper form .form-zone .custom-datepicker {
                box-sizing: border-box;
                height: 50px;
                font-size: 1.5em;
                border-radius: 4px;
                border: 1px solid #cccccc;
                color: #4a4a4a;
                padding-left: 15px;
                padding-right: 15px;
                outline: none;
                text-align: left; }
                .kyc-page-wrapper .kyc-personal-wrapper .section-wrapper .personal-form-wrapper form .form-zone .custom-datepicker:focus {
                  border-color: #3366ff; }
            .kyc-page-wrapper .kyc-personal-wrapper .section-wrapper .personal-form-wrapper form .error-zone {
              width: 100%;
              margin-bottom: 30px;
              font-size: 16px; }
            .kyc-page-wrapper .kyc-personal-wrapper .section-wrapper .personal-form-wrapper form .action-zone {
              width: 100%;
              display: flex;
              flex-direction: row;
              justify-content: flex-end;
              align-items: flex-end; }
              .kyc-page-wrapper .kyc-personal-wrapper .section-wrapper .personal-form-wrapper form .action-zone .button {
                width: 120px; }
    .kyc-page-wrapper .kyc-document-wrapper {
      width: 100%;
      background-color: #ffffff;
      border-radius: 8px;
      box-sizing: border-box;
      padding: 50px 80px; }
      .kyc-page-wrapper .kyc-document-wrapper .section-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center; }
        .kyc-page-wrapper .kyc-document-wrapper .section-wrapper .header {
          font-size: 20px;
          font-weight: 700;
          color: #4a4a4a;
          margin-top: 60px; }
        .kyc-page-wrapper .kyc-document-wrapper .section-wrapper .content {
          font-size: 18px;
          line-height: 26px;
          font-weight: 500;
          color: #4a4a4a;
          margin-top: 10px;
          margin-bottom: 40px; }
        .kyc-page-wrapper .kyc-document-wrapper .section-wrapper .button-section {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 20px; }
          .kyc-page-wrapper .kyc-document-wrapper .section-wrapper .button-section .button {
            width: 150px;
            height: 40px; }
        .kyc-page-wrapper .kyc-document-wrapper .section-wrapper .upload-section {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-start;
          margin-bottom: 50px; }
          .kyc-page-wrapper .kyc-document-wrapper .section-wrapper .upload-section .section {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            width: 275px; }
            .kyc-page-wrapper .kyc-document-wrapper .section-wrapper .upload-section .section .header {
              font-size: 18px;
              line-height: 26px;
              font-weight: 500;
              color: #4a4a4a;
              margin-bottom: 15px; }
            .kyc-page-wrapper .kyc-document-wrapper .section-wrapper .upload-section .section .dropzone {
              cursor: pointer;
              width: 320px;
              height: 190px;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              border-radius: 8px;
              background-color: #eaeaea;
              border: 3px dashed #666666; }
              .kyc-page-wrapper .kyc-document-wrapper .section-wrapper .upload-section .section .dropzone .image {
                width: 100%;
                height: 100%;
                object-fit: contain; }
              .kyc-page-wrapper .kyc-document-wrapper .section-wrapper .upload-section .section .dropzone .icon {
                font-size: 45px;
                color: #666666;
                margin-bottom: 20px; }
              .kyc-page-wrapper .kyc-document-wrapper .section-wrapper .upload-section .section .dropzone .text {
                width: 110px;
                text-align: center;
                font-size: 18px;
                font-weight: 500; }
        .kyc-page-wrapper .kyc-document-wrapper .section-wrapper .error-zone {
          width: 100%;
          margin-bottom: 30px;
          font-size: 16px; }
        .kyc-page-wrapper .kyc-document-wrapper .section-wrapper .action-zone {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-start; }
          .kyc-page-wrapper .kyc-document-wrapper .section-wrapper .action-zone .back {
            width: 120px;
            height: 40px; }
          .kyc-page-wrapper .kyc-document-wrapper .section-wrapper .action-zone .continue {
            width: 120px;
            height: 40px; }
    .kyc-page-wrapper .kyc-profile-wrapper {
      width: 100%;
      background-color: #ffffff;
      border-radius: 8px;
      box-sizing: border-box;
      padding: 50px 80px; }
      .kyc-page-wrapper .kyc-profile-wrapper .section-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center; }
        .kyc-page-wrapper .kyc-profile-wrapper .section-wrapper .header {
          font-size: 20px;
          font-weight: 700;
          color: #4a4a4a;
          margin-top: 60px; }
        .kyc-page-wrapper .kyc-profile-wrapper .section-wrapper .content {
          font-size: 18px;
          line-height: 26px;
          font-weight: 500;
          color: #4a4a4a;
          margin-top: 10px;
          margin-bottom: 20px; }
        .kyc-page-wrapper .kyc-profile-wrapper .section-wrapper .selfie-section {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: flex-start;
          margin-bottom: 50px; }
          .kyc-page-wrapper .kyc-profile-wrapper .section-wrapper .selfie-section .section {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            width: 275px; }
            .kyc-page-wrapper .kyc-profile-wrapper .section-wrapper .selfie-section .section .header {
              font-size: 18px;
              line-height: 26px;
              font-weight: 500;
              color: #4a4a4a;
              margin-bottom: 15px; }
            .kyc-page-wrapper .kyc-profile-wrapper .section-wrapper .selfie-section .section .selfie-zone {
              cursor: pointer;
              width: 320px;
              height: 240px;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              border-radius: 8px;
              background-color: #eaeaea;
              border: 3px dashed #666666;
              margin-bottom: 30px; }
              .kyc-page-wrapper .kyc-profile-wrapper .section-wrapper .selfie-section .section .selfie-zone .image {
                width: 100%;
                height: 100%;
                object-fit: contain; }
              .kyc-page-wrapper .kyc-profile-wrapper .section-wrapper .selfie-section .section .selfie-zone .webcam {
                width: 320px;
                height: auto;
                border-radius: 8px; }
            .kyc-page-wrapper .kyc-profile-wrapper .section-wrapper .selfie-section .section .capture {
              font-size: 40px;
              cursor: pointer; }
          .kyc-page-wrapper .kyc-profile-wrapper .section-wrapper .selfie-section .mobile-section {
            display: none; }
        .kyc-page-wrapper .kyc-profile-wrapper .section-wrapper .error-zone {
          width: 100%;
          margin-bottom: 30px;
          font-size: 16px; }
        .kyc-page-wrapper .kyc-profile-wrapper .section-wrapper .action-zone {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-start; }
          .kyc-page-wrapper .kyc-profile-wrapper .section-wrapper .action-zone .back {
            width: 120px;
            height: 40px; }
          .kyc-page-wrapper .kyc-profile-wrapper .section-wrapper .action-zone .continue {
            width: 120px;
            height: 40px; }
          .kyc-page-wrapper .kyc-profile-wrapper .section-wrapper .action-zone .mobile-continue {
            display: none; }
    .kyc-page-wrapper .kyc-address-wrapper {
      width: 100%;
      background-color: #ffffff;
      border-radius: 8px;
      box-sizing: border-box;
      padding: 50px 80px; }
      .kyc-page-wrapper .kyc-address-wrapper .section-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center; }
        .kyc-page-wrapper .kyc-address-wrapper .section-wrapper .header {
          font-size: 20px;
          font-weight: 700;
          color: #4a4a4a;
          margin-top: 60px; }
        .kyc-page-wrapper .kyc-address-wrapper .section-wrapper .content {
          font-size: 18px;
          line-height: 26px;
          font-weight: 500;
          color: #4a4a4a;
          margin-top: 10px;
          margin-bottom: 40px; }
        .kyc-page-wrapper .kyc-address-wrapper .section-wrapper .address-form-wrapper {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center; }
          .kyc-page-wrapper .kyc-address-wrapper .section-wrapper .address-form-wrapper .form-row {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 20px; }
            .kyc-page-wrapper .kyc-address-wrapper .section-wrapper .address-form-wrapper .form-row .input {
              width: 650px; }
            .kyc-page-wrapper .kyc-address-wrapper .section-wrapper .address-form-wrapper .form-row .left-pane {
              flex: 0.5; }
              .kyc-page-wrapper .kyc-address-wrapper .section-wrapper .address-form-wrapper .form-row .left-pane .input {
                width: 300px;
                margin-right: 25px; }
            .kyc-page-wrapper .kyc-address-wrapper .section-wrapper .address-form-wrapper .form-row .right-pane {
              flex: 0.5;
              padding-left: 25px;
              box-sizing: border-box; }
              .kyc-page-wrapper .kyc-address-wrapper .section-wrapper .address-form-wrapper .form-row .right-pane .input {
                width: 300px; }
            .kyc-page-wrapper .kyc-address-wrapper .section-wrapper .address-form-wrapper .form-row .subcontent {
              width: 650px;
              text-align: center;
              font-size: 18px;
              line-height: 26px;
              color: #4a4a4a;
              font-weight: 500; }
            .kyc-page-wrapper .kyc-address-wrapper .section-wrapper .address-form-wrapper .form-row .upload-section {
              width: 100%;
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: flex-start;
              margin-bottom: 50px; }
              .kyc-page-wrapper .kyc-address-wrapper .section-wrapper .address-form-wrapper .form-row .upload-section .section {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                width: 275px; }
                .kyc-page-wrapper .kyc-address-wrapper .section-wrapper .address-form-wrapper .form-row .upload-section .section .header {
                  font-size: 18px;
                  line-height: 26px;
                  font-weight: 500;
                  color: #4a4a4a;
                  margin-bottom: 15px; }
                .kyc-page-wrapper .kyc-address-wrapper .section-wrapper .address-form-wrapper .form-row .upload-section .section .dropzone {
                  cursor: pointer;
                  width: 320px;
                  height: 190px;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                  border-radius: 8px;
                  background-color: #eaeaea;
                  border: 3px dashed #666666; }
                  .kyc-page-wrapper .kyc-address-wrapper .section-wrapper .address-form-wrapper .form-row .upload-section .section .dropzone .image {
                    width: 100%;
                    height: 100%;
                    object-fit: contain; }
                  .kyc-page-wrapper .kyc-address-wrapper .section-wrapper .address-form-wrapper .form-row .upload-section .section .dropzone .icon {
                    font-size: 45px;
                    color: #666666;
                    margin-bottom: 20px; }
                  .kyc-page-wrapper .kyc-address-wrapper .section-wrapper .address-form-wrapper .form-row .upload-section .section .dropzone .text {
                    width: 110px;
                    text-align: center;
                    font-size: 18px;
                    font-weight: 500; }
        .kyc-page-wrapper .kyc-address-wrapper .section-wrapper .error-zone {
          width: 100%;
          margin-bottom: 30px;
          font-size: 16px; }
        .kyc-page-wrapper .kyc-address-wrapper .section-wrapper .action-zone {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-start; }
          .kyc-page-wrapper .kyc-address-wrapper .section-wrapper .action-zone .back {
            width: 120px;
            height: 40px; }
          .kyc-page-wrapper .kyc-address-wrapper .section-wrapper .action-zone .continue {
            width: 120px;
            height: 40px; }
    .kyc-page-wrapper .kyc-submit-wrapper {
      width: 100%;
      background-color: #ffffff;
      border-radius: 8px;
      box-sizing: border-box;
      padding: 50px 80px; }
      .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center; }
        .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .header {
          font-size: 20px;
          font-weight: 700;
          color: #4a4a4a;
          margin-top: 60px; }
        .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .content {
          font-size: 18px;
          line-height: 26px;
          font-weight: 500;
          color: #4a4a4a;
          margin-top: 10px;
          margin-bottom: 50px; }
        .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .submit-section-wrapper {
          display: flex;
          flex-direction: column;
          width: 100%;
          justify-content: flex-start;
          align-items: flex-start; }
          .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .submit-section-wrapper .personal-row {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            width: 100%; }
            .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .submit-section-wrapper .personal-row .item {
              width: 20%;
              margin-bottom: 40px; }
              .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .submit-section-wrapper .personal-row .item .item-header {
                font-size: 14px;
                line-height: 20px;
                color: #999999; }
              .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .submit-section-wrapper .personal-row .item .item-text {
                font-size: 16px;
                line-height: 20px;
                color: #000000;
                font-weight: 600; }
          .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .submit-section-wrapper .address-row {
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: flex-start;
            align-items: flex-start; }
            .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .submit-section-wrapper .address-row .item {
              width: 20%;
              margin-bottom: 40px; }
              .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .submit-section-wrapper .address-row .item .item-header {
                font-size: 14px;
                line-height: 20px;
                color: #999999; }
              .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .submit-section-wrapper .address-row .item .item-text {
                font-size: 16px;
                line-height: 20px;
                color: #000000;
                font-weight: 600; }
            .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .submit-section-wrapper .address-row .address-item {
              width: 60%;
              box-sizing: border-box;
              padding-right: 10px; }
          .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .submit-section-wrapper .document-row {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            width: 100%; }
            .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .submit-section-wrapper .document-row .item {
              width: 25%;
              margin-bottom: 80px; }
              .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .submit-section-wrapper .document-row .item .item-header {
                font-size: 14px;
                line-height: 20px;
                color: #999999; }
              .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .submit-section-wrapper .document-row .item .document-zone {
                margin-top: 10px;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: flex-start; }
                .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .submit-section-wrapper .document-row .item .document-zone .image {
                  border-radius: 2px;
                  width: 125px;
                  overflow: hidden;
                  height: auto; }
                  .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .submit-section-wrapper .document-row .item .document-zone .image:first-child {
                    margin-right: 20px; }
            .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .submit-section-wrapper .document-row .general-documents-item {
              width: 50%; }
          .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .submit-section-wrapper .mobile-document-row {
            display: none; }
        .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .action-zone {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-start; }
          .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .action-zone .back {
            width: 120px;
            height: 40px; }
          .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .action-zone .continue {
            width: 120px;
            height: 40px; }
    .kyc-page-wrapper .kyc-success-wrapper {
      width: 100%;
      background-color: #ffffff;
      border-radius: 8px;
      box-sizing: border-box;
      padding: 50px 80px; }
      .kyc-page-wrapper .kyc-success-wrapper .section-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center; }
        .kyc-page-wrapper .kyc-success-wrapper .section-wrapper .header {
          font-size: 20px;
          font-weight: 700;
          color: #4a4a4a;
          margin-top: 60px; }
        .kyc-page-wrapper .kyc-success-wrapper .section-wrapper .content {
          font-size: 18px;
          line-height: 26px;
          font-weight: 500;
          color: #4a4a4a;
          margin-top: 10px;
          margin-bottom: 50px; }
        .kyc-page-wrapper .kyc-success-wrapper .section-wrapper .action-zone {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center; }
          .kyc-page-wrapper .kyc-success-wrapper .section-wrapper .action-zone .return {
            width: 120px;
            height: 40px; } }

@media only screen and (max-width: 768px) {
  .kyc-page-wrapper {
    box-sizing: border-box;
    width: 100vw;
    min-height: 100vh;
    margin: 0 auto;
    border-top: 1px solid #999999;
    padding: 30px;
    background-color: #ffffff; }
    .kyc-page-wrapper .step-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center; }
      .kyc-page-wrapper .step-wrapper .step-icon {
        font-size: 24px; }
      .kyc-page-wrapper .step-wrapper .results-icon {
        font-size: 40px; }
      .kyc-page-wrapper .step-wrapper .dots-icon {
        font-size: 10px; }
    .kyc-page-wrapper .kyc-continue-wrapper {
      width: 100%;
      min-height: 420px;
      background-color: #ffffff; }
      .kyc-page-wrapper .kyc-continue-wrapper .section-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center; }
        .kyc-page-wrapper .kyc-continue-wrapper .section-wrapper .header {
          font-size: 20px;
          font-weight: 700;
          color: #4a4a4a;
          margin-top: 45px; }
        .kyc-page-wrapper .kyc-continue-wrapper .section-wrapper .content {
          font-size: 16px;
          line-height: 26px;
          font-weight: 500;
          color: #4a4a4a;
          margin-top: 10px;
          margin-bottom: 60px; }
        .kyc-page-wrapper .kyc-continue-wrapper .section-wrapper .action {
          width: 80%;
          height: 50px; }
    .kyc-page-wrapper .kyc-start-wrapper {
      width: 100%;
      min-height: 420px;
      background-color: #ffffff; }
      .kyc-page-wrapper .kyc-start-wrapper .start-loader {
        width: 100%;
        min-height: 420px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; }
    .kyc-page-wrapper .kyc-personal-wrapper {
      width: 100%;
      min-height: 420px;
      background-color: #ffffff; }
      .kyc-page-wrapper .kyc-personal-wrapper .section-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center; }
        .kyc-page-wrapper .kyc-personal-wrapper .section-wrapper .header {
          font-size: 20px;
          font-weight: 700;
          color: #4a4a4a;
          margin-top: 45px; }
        .kyc-page-wrapper .kyc-personal-wrapper .section-wrapper .content {
          font-size: 16px;
          line-height: 26px;
          font-weight: 500;
          color: #4a4a4a;
          margin-top: 10px;
          margin-bottom: 30px; }
        .kyc-page-wrapper .kyc-personal-wrapper .section-wrapper .personal-form-wrapper {
          width: 300px !important;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center; }
          .kyc-page-wrapper .kyc-personal-wrapper .section-wrapper .personal-form-wrapper form {
            width: 100%; }
            .kyc-page-wrapper .kyc-personal-wrapper .section-wrapper .personal-form-wrapper form .form-zone {
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: flex-start; }
              .kyc-page-wrapper .kyc-personal-wrapper .section-wrapper .personal-form-wrapper form .form-zone .input {
                width: 300px; }
              .kyc-page-wrapper .kyc-personal-wrapper .section-wrapper .personal-form-wrapper form .form-zone .custom-datepicker {
                box-sizing: border-box;
                height: 50px;
                font-size: 1.5em;
                border-radius: 4px;
                border: 1px solid #cccccc;
                color: #4a4a4a;
                padding-left: 15px;
                padding-right: 15px;
                outline: none;
                text-align: left;
                margin-bottom: 10px; }
                .kyc-page-wrapper .kyc-personal-wrapper .section-wrapper .personal-form-wrapper form .form-zone .custom-datepicker:focus {
                  border-color: #3366ff; }
            .kyc-page-wrapper .kyc-personal-wrapper .section-wrapper .personal-form-wrapper form .error-zone {
              width: 100%;
              margin-bottom: 20px;
              font-size: 14px; }
            .kyc-page-wrapper .kyc-personal-wrapper .section-wrapper .personal-form-wrapper form .action-zone {
              width: 100%;
              display: flex;
              flex-direction: row;
              justify-content: flex-end;
              align-items: flex-end; }
    .kyc-page-wrapper .kyc-document-wrapper {
      width: 100%;
      min-height: 420px;
      background-color: #ffffff; }
      .kyc-page-wrapper .kyc-document-wrapper .section-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center; }
        .kyc-page-wrapper .kyc-document-wrapper .section-wrapper .header {
          font-size: 20px;
          font-weight: 700;
          color: #4a4a4a;
          margin-top: 30px; }
        .kyc-page-wrapper .kyc-document-wrapper .section-wrapper .content {
          font-size: 16px;
          line-height: 26px;
          font-weight: 500;
          color: #4a4a4a;
          margin-top: 10px;
          margin-bottom: 30px; }
        .kyc-page-wrapper .kyc-document-wrapper .section-wrapper .button-section {
          width: 100%;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: center; }
          .kyc-page-wrapper .kyc-document-wrapper .section-wrapper .button-section .button {
            width: 48%;
            height: 40px;
            margin-bottom: 20px; }
        .kyc-page-wrapper .kyc-document-wrapper .section-wrapper .upload-section {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-start;
          margin-bottom: 40px; }
          .kyc-page-wrapper .kyc-document-wrapper .section-wrapper .upload-section .section {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            width: 48%; }
            .kyc-page-wrapper .kyc-document-wrapper .section-wrapper .upload-section .section .header {
              font-size: 18px;
              line-height: 26px;
              font-weight: 500;
              color: #4a4a4a;
              margin-bottom: 15px; }
            .kyc-page-wrapper .kyc-document-wrapper .section-wrapper .upload-section .section .dropzone {
              cursor: pointer;
              width: 100%;
              height: 100px;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              border-radius: 8px;
              background-color: #eaeaea;
              border: 2px dashed #666666; }
              .kyc-page-wrapper .kyc-document-wrapper .section-wrapper .upload-section .section .dropzone .image {
                width: 100%;
                height: 100%;
                object-fit: contain; }
              .kyc-page-wrapper .kyc-document-wrapper .section-wrapper .upload-section .section .dropzone .icon {
                font-size: 24px;
                color: #666666;
                margin-bottom: 10px; }
              .kyc-page-wrapper .kyc-document-wrapper .section-wrapper .upload-section .section .dropzone .text {
                width: 80%;
                text-align: center;
                font-size: 14px;
                font-weight: 500; }
        .kyc-page-wrapper .kyc-document-wrapper .section-wrapper .error-zone {
          width: 100%;
          margin-bottom: 20px;
          font-size: 14px; }
        .kyc-page-wrapper .kyc-document-wrapper .section-wrapper .action-zone {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-start; }
          .kyc-page-wrapper .kyc-document-wrapper .section-wrapper .action-zone .back {
            width: 120px;
            height: 40px; }
          .kyc-page-wrapper .kyc-document-wrapper .section-wrapper .action-zone .continue {
            width: 120px;
            height: 40px; }
    .kyc-page-wrapper .kyc-profile-wrapper {
      width: 100%;
      min-height: 420px;
      background-color: #ffffff; }
      .kyc-page-wrapper .kyc-profile-wrapper .section-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center; }
        .kyc-page-wrapper .kyc-profile-wrapper .section-wrapper .header {
          font-size: 20px;
          font-weight: 700;
          color: #4a4a4a;
          margin-top: 30px; }
        .kyc-page-wrapper .kyc-profile-wrapper .section-wrapper .content {
          font-size: 16px;
          line-height: 26px;
          font-weight: 500;
          color: #4a4a4a;
          margin-top: 10px;
          margin-bottom: 30px; }
        .kyc-page-wrapper .kyc-profile-wrapper .section-wrapper .selfie-section {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: flex-start;
          margin-bottom: 50px; }
          .kyc-page-wrapper .kyc-profile-wrapper .section-wrapper .selfie-section .section {
            display: none; }
          .kyc-page-wrapper .kyc-profile-wrapper .section-wrapper .selfie-section .mobile-section {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            width: 275px; }
            .kyc-page-wrapper .kyc-profile-wrapper .section-wrapper .selfie-section .mobile-section .header {
              font-size: 18px;
              line-height: 26px;
              font-weight: 500;
              color: #4a4a4a;
              margin-bottom: 15px; }
            .kyc-page-wrapper .kyc-profile-wrapper .section-wrapper .selfie-section .mobile-section .dropzone {
              cursor: pointer;
              width: 50%;
              height: 100px;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              border-radius: 8px;
              background-color: #eaeaea;
              border: 2px dashed #666666; }
              .kyc-page-wrapper .kyc-profile-wrapper .section-wrapper .selfie-section .mobile-section .dropzone .image {
                width: 100%;
                height: 100%;
                object-fit: contain; }
              .kyc-page-wrapper .kyc-profile-wrapper .section-wrapper .selfie-section .mobile-section .dropzone .icon {
                font-size: 24px;
                color: #666666;
                margin-bottom: 10px; }
              .kyc-page-wrapper .kyc-profile-wrapper .section-wrapper .selfie-section .mobile-section .dropzone .text {
                width: 80%;
                text-align: center;
                font-size: 14px;
                font-weight: 500; }
        .kyc-page-wrapper .kyc-profile-wrapper .section-wrapper .error-zone {
          width: 100%;
          margin-bottom: 20px;
          font-size: 14px; }
        .kyc-page-wrapper .kyc-profile-wrapper .section-wrapper .action-zone {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-start; }
          .kyc-page-wrapper .kyc-profile-wrapper .section-wrapper .action-zone .back {
            width: 120px;
            height: 40px; }
          .kyc-page-wrapper .kyc-profile-wrapper .section-wrapper .action-zone .continue {
            display: none; }
          .kyc-page-wrapper .kyc-profile-wrapper .section-wrapper .action-zone .mobile-continue {
            width: 120px;
            height: 40px; }
    .kyc-page-wrapper .kyc-address-wrapper {
      width: 100%;
      min-height: 420px;
      background-color: #ffffff; }
      .kyc-page-wrapper .kyc-address-wrapper .section-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center; }
        .kyc-page-wrapper .kyc-address-wrapper .section-wrapper .header {
          font-size: 20px;
          font-weight: 700;
          color: #4a4a4a;
          margin-top: 30px; }
        .kyc-page-wrapper .kyc-address-wrapper .section-wrapper .content {
          font-size: 16px;
          line-height: 26px;
          font-weight: 500;
          color: #4a4a4a;
          margin-top: 10px;
          margin-bottom: 30px; }
        .kyc-page-wrapper .kyc-address-wrapper .section-wrapper .address-form-wrapper {
          width: 300px !important;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center; }
          .kyc-page-wrapper .kyc-address-wrapper .section-wrapper .address-form-wrapper .form-row {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center; }
            .kyc-page-wrapper .kyc-address-wrapper .section-wrapper .address-form-wrapper .form-row .input {
              width: 300px; }
            .kyc-page-wrapper .kyc-address-wrapper .section-wrapper .address-form-wrapper .form-row .left-pane {
              flex: 1; }
              .kyc-page-wrapper .kyc-address-wrapper .section-wrapper .address-form-wrapper .form-row .left-pane .input {
                width: 300px; }
            .kyc-page-wrapper .kyc-address-wrapper .section-wrapper .address-form-wrapper .form-row .right-pane {
              flex: 1; }
              .kyc-page-wrapper .kyc-address-wrapper .section-wrapper .address-form-wrapper .form-row .right-pane .input {
                width: 300px; }
            .kyc-page-wrapper .kyc-address-wrapper .section-wrapper .address-form-wrapper .form-row .subcontent {
              width: 300px;
              text-align: center;
              font-size: 14px;
              line-height: 26px;
              color: #4a4a4a;
              font-weight: 500; }
            .kyc-page-wrapper .kyc-address-wrapper .section-wrapper .address-form-wrapper .form-row .upload-section {
              width: 100%;
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: flex-start;
              margin-bottom: 40px; }
              .kyc-page-wrapper .kyc-address-wrapper .section-wrapper .address-form-wrapper .form-row .upload-section .section {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                width: 70%; }
                .kyc-page-wrapper .kyc-address-wrapper .section-wrapper .address-form-wrapper .form-row .upload-section .section .header {
                  font-size: 18px;
                  line-height: 26px;
                  font-weight: 500;
                  color: #4a4a4a;
                  margin-bottom: 15px; }
                .kyc-page-wrapper .kyc-address-wrapper .section-wrapper .address-form-wrapper .form-row .upload-section .section .dropzone {
                  cursor: pointer;
                  width: 100%;
                  height: 150px;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                  border-radius: 8px;
                  margin-top: 10px;
                  background-color: #eaeaea;
                  border: 2px dashed #666666; }
                  .kyc-page-wrapper .kyc-address-wrapper .section-wrapper .address-form-wrapper .form-row .upload-section .section .dropzone .image {
                    width: 100%;
                    height: 100%;
                    object-fit: contain; }
                  .kyc-page-wrapper .kyc-address-wrapper .section-wrapper .address-form-wrapper .form-row .upload-section .section .dropzone .icon {
                    font-size: 24px;
                    color: #666666;
                    margin-bottom: 10px; }
                  .kyc-page-wrapper .kyc-address-wrapper .section-wrapper .address-form-wrapper .form-row .upload-section .section .dropzone .text {
                    width: 80%;
                    text-align: center;
                    font-size: 14px;
                    font-weight: 500; }
        .kyc-page-wrapper .kyc-address-wrapper .section-wrapper .error-zone {
          width: 100%;
          margin-bottom: 20px;
          font-size: 14px; }
        .kyc-page-wrapper .kyc-address-wrapper .section-wrapper .action-zone {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-start; }
          .kyc-page-wrapper .kyc-address-wrapper .section-wrapper .action-zone .back {
            width: 120px;
            height: 40px; }
          .kyc-page-wrapper .kyc-address-wrapper .section-wrapper .action-zone .continue {
            width: 120px;
            height: 40px; }
    .kyc-page-wrapper .kyc-submit-wrapper {
      width: 100%;
      min-height: 420px;
      background-color: #ffffff; }
      .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center; }
        .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .header {
          font-size: 20px;
          font-weight: 700;
          color: #4a4a4a;
          margin-top: 30px; }
        .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .content {
          font-size: 16px;
          line-height: 26px;
          font-weight: 500;
          color: #4a4a4a;
          margin-top: 10px;
          margin-bottom: 30px; }
        .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .submit-section-wrapper {
          display: flex;
          flex-direction: column;
          width: 100%;
          justify-content: flex-start;
          align-items: flex-start; }
          .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .submit-section-wrapper .personal-row {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: flex-start;
            width: 100%; }
            .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .submit-section-wrapper .personal-row .item {
              width: 33%;
              margin-bottom: 20px; }
              .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .submit-section-wrapper .personal-row .item .item-header {
                font-size: 14px;
                line-height: 20px;
                color: #999999; }
              .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .submit-section-wrapper .personal-row .item .item-text {
                font-size: 16px;
                line-height: 20px;
                color: #000000;
                font-weight: 600; }
          .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .submit-section-wrapper .address-row {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            width: 100%;
            justify-content: flex-start;
            align-items: flex-start; }
            .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .submit-section-wrapper .address-row .item {
              width: 50%;
              margin-bottom: 20px; }
              .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .submit-section-wrapper .address-row .item .item-header {
                font-size: 14px;
                line-height: 20px;
                color: #999999; }
              .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .submit-section-wrapper .address-row .item .item-text {
                font-size: 16px;
                line-height: 20px;
                color: #000000;
                font-weight: 600; }
            .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .submit-section-wrapper .address-row .address-item {
              width: 100%;
              box-sizing: border-box;
              padding-right: 10px; }
          .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .submit-section-wrapper .document-row {
            display: none; }
          .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .submit-section-wrapper .mobile-document-row {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            width: 100%; }
            .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .submit-section-wrapper .mobile-document-row .item {
              width: 100%;
              margin-bottom: 20px; }
              .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .submit-section-wrapper .mobile-document-row .item .item-header {
                font-size: 14px;
                line-height: 20px;
                color: #999999; }
              .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .submit-section-wrapper .mobile-document-row .item .document-zone {
                margin-top: 10px;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: flex-start; }
                .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .submit-section-wrapper .mobile-document-row .item .document-zone a {
                  text-decoration: none; }
                  .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .submit-section-wrapper .mobile-document-row .item .document-zone a .document-text {
                    font-size: 16px;
                    color: #0033ff;
                    font-weight: 600; }
                    .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .submit-section-wrapper .mobile-document-row .item .document-zone a .document-text:first-child {
                      margin-right: 30px; }
                .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .submit-section-wrapper .mobile-document-row .item .document-zone .image {
                  border-radius: 2px;
                  width: 125px;
                  overflow: hidden;
                  height: auto; }
                  .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .submit-section-wrapper .mobile-document-row .item .document-zone .image:first-child {
                    margin-right: 20px; }
            .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .submit-section-wrapper .mobile-document-row .general-documents-item {
              width: 100%; }
        .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .action-zone {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-start; }
          .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .action-zone .back {
            width: 120px;
            height: 40px; }
          .kyc-page-wrapper .kyc-submit-wrapper .section-wrapper .action-zone .continue {
            width: 120px;
            height: 40px; }
    .kyc-page-wrapper .kyc-success-wrapper {
      width: 100%;
      min-height: 420px;
      background-color: #ffffff; }
      .kyc-page-wrapper .kyc-success-wrapper .section-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center; }
        .kyc-page-wrapper .kyc-success-wrapper .section-wrapper .header {
          font-size: 20px;
          font-weight: 700;
          color: #4a4a4a;
          margin-top: 45px; }
        .kyc-page-wrapper .kyc-success-wrapper .section-wrapper .content {
          font-size: 16px;
          line-height: 26px;
          font-weight: 500;
          color: #4a4a4a;
          margin-top: 10px;
          margin-bottom: 30px; }
        .kyc-page-wrapper .kyc-success-wrapper .section-wrapper .action-zone {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center; }
          .kyc-page-wrapper .kyc-success-wrapper .section-wrapper .action-zone .return {
            width: 120px;
            height: 40px; } }
