/* Global style guide for UI Lib */
/**
 * Browser support
 */
/**
 * Typography
 */
@import url("https://fonts.googleapis.com/css?family=Rajdhani:300,400,500,600,700&subset=latin-ext");
/**
 * Colors
 */
/**
 * Page -- UILib Specific Variables
 */
/**
 * Card -- UILib Specific Variables
 */
@media only screen and (min-width: 769px) {
  .message {
    position: relative;
    box-sizing: border-box;
    margin: 60px auto 40px auto;
    padding: 30px 40px;
    height: 100%;
    border-radius: 8px;
    font-weight: 500;
    display: flex;
    flex-direction: row; }
    .message .dismiss {
      position: absolute;
      top: 15px;
      right: 15px;
      font-size: 20px !important;
      cursor: pointer; }
    .message .message-icon {
      margin: auto 30px auto 0;
      font-size: 60px; }
    .message .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      width: 800px; }
      .message .content .header {
        font-size: 1.7em;
        line-height: 1.25;
        font-weight: 600; }
      .message .content .content {
        font-size: 1.2em;
        line-height: 1.63;
        font-weight: 500; }
    .message .action {
      display: flex;
      flex-direction: column;
      justify-content: center; }
  .message-background-color-blue-0 {
    background-color: #99ccff; }
  .message-background-color-blue-1 {
    background-color: #6699ff; }
  .message-background-color-blue-2 {
    background-color: #3366ff; }
  .message-background-color-blue-3 {
    background-color: #0033ff; }
  .message-background-color-blue-4 {
    background-color: #0033cc; }
  .message-background-color-blue-5 {
    background-color: #003399; }
  .message-background-color-blue-6 {
    background-color: #003366; }
  .message-background-color-green-0 {
    background-color: #99ff99; }
  .message-background-color-green-1 {
    background-color: #66ff66; }
  .message-background-color-green-2 {
    background-color: #66ff33; }
  .message-background-color-green-3 {
    background-color: #33cb00; }
  .message-background-color-green-4 {
    background-color: #33cc00; }
  .message-background-color-green-5 {
    background-color: #339900; }
  .message-background-color-green-6 {
    background-color: #336600; }
  .message-background-color-orange-0 {
    background-color: #ffcc99; }
  .message-background-color-orange-1 {
    background-color: #ff9966; }
  .message-background-color-orange-2 {
    background-color: #ff9933; }
  .message-background-color-orange-3 {
    background-color: #ff6600; }
  .message-background-color-orange-4 {
    background-color: #cc662b; }
  .message-background-color-orange-5 {
    background-color: #993300; }
  .message-background-color-orange-6 {
    background-color: #663300; }
  .message-background-color-grey-0 {
    background-color: #fafafa; }
  .message-background-color-grey-1 {
    background-color: #eaeaea; }
  .message-background-color-grey-2 {
    background-color: #cccccc; }
  .message-background-color-grey-3 {
    background-color: #999999; }
  .message-background-color-grey-4 {
    background-color: #777777; }
  .message-background-color-grey-5 {
    background-color: #666666; }
  .message-background-color-grey-6 {
    background-color: #333333; }
  .message-background-color-white {
    background-color: #ffffff; }
  .message-background-color-black {
    background-color: #000000; }
  .message-background-color-light-grey {
    background-color: #4a4a4a; }
  .message-background-color-light-pink {
    background-color: #fef0ef; }
  .message-background-color-pink {
    background-color: #fabeb9; }
  .message-border-color-blue-0 {
    border-color: #99ccff; }
  .message-border-color-blue-1 {
    border-color: #6699ff; }
  .message-border-color-blue-2 {
    border-color: #3366ff; }
  .message-border-color-blue-3 {
    border-color: #0033ff; }
  .message-border-color-blue-4 {
    border-color: #0033cc; }
  .message-border-color-blue-5 {
    border-color: #003399; }
  .message-border-color-blue-6 {
    border-color: #003366; }
  .message-border-color-green-0 {
    border-color: #99ff99; }
  .message-border-color-green-1 {
    border-color: #66ff66; }
  .message-border-color-green-2 {
    border-color: #66ff33; }
  .message-border-color-green-3 {
    border-color: #33cb00; }
  .message-border-color-green-4 {
    border-color: #33cc00; }
  .message-border-color-green-5 {
    border-color: #339900; }
  .message-border-color-green-6 {
    border-color: #336600; }
  .message-border-color-orange-0 {
    border-color: #ffcc99; }
  .message-border-color-orange-1 {
    border-color: #ff9966; }
  .message-border-color-orange-2 {
    border-color: #ff9933; }
  .message-border-color-orange-3 {
    border-color: #ff6600; }
  .message-border-color-orange-4 {
    border-color: #cc662b; }
  .message-border-color-orange-5 {
    border-color: #993300; }
  .message-border-color-orange-6 {
    border-color: #663300; }
  .message-border-color-grey-0 {
    border-color: #fafafa; }
  .message-border-color-grey-1 {
    border-color: #eaeaea; }
  .message-border-color-grey-2 {
    border-color: #cccccc; }
  .message-border-color-grey-3 {
    border-color: #999999; }
  .message-border-color-grey-4 {
    border-color: #777777; }
  .message-border-color-grey-5 {
    border-color: #666666; }
  .message-border-color-grey-6 {
    border-color: #333333; }
  .message-border-color-white {
    border-color: #ffffff; }
  .message-border-color-black {
    border-color: #000000; }
  .message-border-color-light-grey {
    border-color: #4a4a4a; }
  .message-border-color-light-pink {
    border-color: #fef0ef; }
  .message-border-color-pink {
    border-color: #fabeb9; } }

@media only screen and (max-width: 768px) {
  .message {
    position: relative;
    box-sizing: border-box;
    margin: 20px auto 20px auto;
    padding: 20px 10px;
    height: 100%;
    border-radius: 8px;
    font-weight: 500;
    display: flex;
    flex-direction: row; }
    .message .dismiss {
      display: none; }
    .message .message-icon {
      display: none; }
    .message .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      width: 80%;
      box-sizing: border-box;
      padding-right: 20px; }
      .message .content .header {
        font-size: 12px;
        line-height: 1.25;
        font-weight: 600; }
      .message .content .content {
        display: none; }
    .message .action {
      font-size: 12px;
      display: flex;
      flex-direction: column;
      justify-content: center; }
  .message-background-color-blue-0 {
    background-color: #99ccff; }
  .message-background-color-blue-1 {
    background-color: #6699ff; }
  .message-background-color-blue-2 {
    background-color: #3366ff; }
  .message-background-color-blue-3 {
    background-color: #0033ff; }
  .message-background-color-blue-4 {
    background-color: #0033cc; }
  .message-background-color-blue-5 {
    background-color: #003399; }
  .message-background-color-blue-6 {
    background-color: #003366; }
  .message-background-color-green-0 {
    background-color: #99ff99; }
  .message-background-color-green-1 {
    background-color: #66ff66; }
  .message-background-color-green-2 {
    background-color: #66ff33; }
  .message-background-color-green-3 {
    background-color: #33cb00; }
  .message-background-color-green-4 {
    background-color: #33cc00; }
  .message-background-color-green-5 {
    background-color: #339900; }
  .message-background-color-green-6 {
    background-color: #336600; }
  .message-background-color-orange-0 {
    background-color: #ffcc99; }
  .message-background-color-orange-1 {
    background-color: #ff9966; }
  .message-background-color-orange-2 {
    background-color: #ff9933; }
  .message-background-color-orange-3 {
    background-color: #ff6600; }
  .message-background-color-orange-4 {
    background-color: #cc662b; }
  .message-background-color-orange-5 {
    background-color: #993300; }
  .message-background-color-orange-6 {
    background-color: #663300; }
  .message-background-color-grey-0 {
    background-color: #fafafa; }
  .message-background-color-grey-1 {
    background-color: #eaeaea; }
  .message-background-color-grey-2 {
    background-color: #cccccc; }
  .message-background-color-grey-3 {
    background-color: #999999; }
  .message-background-color-grey-4 {
    background-color: #777777; }
  .message-background-color-grey-5 {
    background-color: #666666; }
  .message-background-color-grey-6 {
    background-color: #333333; }
  .message-background-color-white {
    background-color: #ffffff; }
  .message-background-color-black {
    background-color: #000000; }
  .message-background-color-light-grey {
    background-color: #4a4a4a; }
  .message-background-color-light-pink {
    background-color: #fef0ef; }
  .message-background-color-pink {
    background-color: #fabeb9; }
  .message-border-color-blue-0 {
    border-color: #99ccff; }
  .message-border-color-blue-1 {
    border-color: #6699ff; }
  .message-border-color-blue-2 {
    border-color: #3366ff; }
  .message-border-color-blue-3 {
    border-color: #0033ff; }
  .message-border-color-blue-4 {
    border-color: #0033cc; }
  .message-border-color-blue-5 {
    border-color: #003399; }
  .message-border-color-blue-6 {
    border-color: #003366; }
  .message-border-color-green-0 {
    border-color: #99ff99; }
  .message-border-color-green-1 {
    border-color: #66ff66; }
  .message-border-color-green-2 {
    border-color: #66ff33; }
  .message-border-color-green-3 {
    border-color: #33cb00; }
  .message-border-color-green-4 {
    border-color: #33cc00; }
  .message-border-color-green-5 {
    border-color: #339900; }
  .message-border-color-green-6 {
    border-color: #336600; }
  .message-border-color-orange-0 {
    border-color: #ffcc99; }
  .message-border-color-orange-1 {
    border-color: #ff9966; }
  .message-border-color-orange-2 {
    border-color: #ff9933; }
  .message-border-color-orange-3 {
    border-color: #ff6600; }
  .message-border-color-orange-4 {
    border-color: #cc662b; }
  .message-border-color-orange-5 {
    border-color: #993300; }
  .message-border-color-orange-6 {
    border-color: #663300; }
  .message-border-color-grey-0 {
    border-color: #fafafa; }
  .message-border-color-grey-1 {
    border-color: #eaeaea; }
  .message-border-color-grey-2 {
    border-color: #cccccc; }
  .message-border-color-grey-3 {
    border-color: #999999; }
  .message-border-color-grey-4 {
    border-color: #777777; }
  .message-border-color-grey-5 {
    border-color: #666666; }
  .message-border-color-grey-6 {
    border-color: #333333; }
  .message-border-color-white {
    border-color: #ffffff; }
  .message-border-color-black {
    border-color: #000000; }
  .message-border-color-light-grey {
    border-color: #4a4a4a; }
  .message-border-color-light-pink {
    border-color: #fef0ef; }
  .message-border-color-pink {
    border-color: #fabeb9; } }
