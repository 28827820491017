/* Global style guide for UI Lib */
/**
 * Browser support
 */
/**
 * Typography
 */
@import url("https://fonts.googleapis.com/css?family=Rajdhani:300,400,500,600,700&subset=latin-ext");
/**
 * Colors
 */
/**
 * Page -- UILib Specific Variables
 */
/**
 * Card -- UILib Specific Variables
 */
@media only screen and (min-width: 769px) {
  .header-ui {
    width: 100%;
    font-size: 18px;
    font-weight: 500;
    text-align: center; }
  .header-ui-lined .content {
    position: relative;
    width: 100%; }
    .header-ui-lined .content .line {
      position: absolute;
      top: 50%;
      width: 100%;
      height: 1px;
      background-color: #4a4a4a;
      z-index: 1; }
    .header-ui-lined .content .text {
      position: absolute;
      left: 15%;
      width: 70%;
      background-color: #fafafa;
      position: relative;
      z-index: 10; }
  .header-ui-color-blue-0 {
    color: #99ccff; }
  .header-ui-color-blue-1 {
    color: #6699ff; }
  .header-ui-color-blue-2 {
    color: #3366ff; }
  .header-ui-color-blue-3 {
    color: #0033ff; }
  .header-ui-color-blue-4 {
    color: #0033cc; }
  .header-ui-color-blue-5 {
    color: #003399; }
  .header-ui-color-blue-6 {
    color: #003366; }
  .header-ui-color-green-0 {
    color: #99ff99; }
  .header-ui-color-green-1 {
    color: #66ff66; }
  .header-ui-color-green-2 {
    color: #66ff33; }
  .header-ui-color-green-3 {
    color: #33cb00; }
  .header-ui-color-green-4 {
    color: #33cc00; }
  .header-ui-color-green-5 {
    color: #339900; }
  .header-ui-color-green-6 {
    color: #336600; }
  .header-ui-color-orange-0 {
    color: #ffcc99; }
  .header-ui-color-orange-1 {
    color: #ff9966; }
  .header-ui-color-orange-2 {
    color: #ff9933; }
  .header-ui-color-orange-3 {
    color: #ff6600; }
  .header-ui-color-orange-4 {
    color: #cc662b; }
  .header-ui-color-orange-5 {
    color: #993300; }
  .header-ui-color-orange-6 {
    color: #663300; }
  .header-ui-color-grey-0 {
    color: #fafafa; }
  .header-ui-color-grey-1 {
    color: #eaeaea; }
  .header-ui-color-grey-2 {
    color: #cccccc; }
  .header-ui-color-grey-3 {
    color: #999999; }
  .header-ui-color-grey-4 {
    color: #777777; }
  .header-ui-color-grey-5 {
    color: #666666; }
  .header-ui-color-grey-6 {
    color: #333333; }
  .header-ui-color-white {
    color: #ffffff; }
  .header-ui-color-black {
    color: #000000; }
  .header-ui-color-light-grey {
    color: #4a4a4a; }
  .header-ui-color-light-pink {
    color: #fef0ef; }
  .header-ui-color-pink {
    color: #fabeb9; }
  .header-ui-background-color-blue-0 {
    background-color: #99ccff; }
  .header-ui-background-color-blue-1 {
    background-color: #6699ff; }
  .header-ui-background-color-blue-2 {
    background-color: #3366ff; }
  .header-ui-background-color-blue-3 {
    background-color: #0033ff; }
  .header-ui-background-color-blue-4 {
    background-color: #0033cc; }
  .header-ui-background-color-blue-5 {
    background-color: #003399; }
  .header-ui-background-color-blue-6 {
    background-color: #003366; }
  .header-ui-background-color-green-0 {
    background-color: #99ff99; }
  .header-ui-background-color-green-1 {
    background-color: #66ff66; }
  .header-ui-background-color-green-2 {
    background-color: #66ff33; }
  .header-ui-background-color-green-3 {
    background-color: #33cb00; }
  .header-ui-background-color-green-4 {
    background-color: #33cc00; }
  .header-ui-background-color-green-5 {
    background-color: #339900; }
  .header-ui-background-color-green-6 {
    background-color: #336600; }
  .header-ui-background-color-orange-0 {
    background-color: #ffcc99; }
  .header-ui-background-color-orange-1 {
    background-color: #ff9966; }
  .header-ui-background-color-orange-2 {
    background-color: #ff9933; }
  .header-ui-background-color-orange-3 {
    background-color: #ff6600; }
  .header-ui-background-color-orange-4 {
    background-color: #cc662b; }
  .header-ui-background-color-orange-5 {
    background-color: #993300; }
  .header-ui-background-color-orange-6 {
    background-color: #663300; }
  .header-ui-background-color-grey-0 {
    background-color: #fafafa; }
  .header-ui-background-color-grey-1 {
    background-color: #eaeaea; }
  .header-ui-background-color-grey-2 {
    background-color: #cccccc; }
  .header-ui-background-color-grey-3 {
    background-color: #999999; }
  .header-ui-background-color-grey-4 {
    background-color: #777777; }
  .header-ui-background-color-grey-5 {
    background-color: #666666; }
  .header-ui-background-color-grey-6 {
    background-color: #333333; }
  .header-ui-background-color-white {
    background-color: #ffffff; }
  .header-ui-background-color-black {
    background-color: #000000; }
  .header-ui-background-color-light-grey {
    background-color: #4a4a4a; }
  .header-ui-background-color-light-pink {
    background-color: #fef0ef; }
  .header-ui-background-color-pink {
    background-color: #fabeb9; } }

@media only screen and (max-width: 768px) {
  .header-ui {
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    text-align: center; }
  .header-ui-lined .content {
    position: relative;
    width: 100%; }
    .header-ui-lined .content .line {
      position: absolute;
      top: 50%;
      width: 100%;
      height: 1px;
      background-color: #4a4a4a;
      z-index: 1; }
    .header-ui-lined .content .text {
      position: absolute;
      text-align: left;
      left: 0%;
      width: 50%;
      background-color: #fafafa;
      position: relative;
      z-index: 10; }
  .header-ui-color-blue-0 {
    color: #99ccff; }
  .header-ui-color-blue-1 {
    color: #6699ff; }
  .header-ui-color-blue-2 {
    color: #3366ff; }
  .header-ui-color-blue-3 {
    color: #0033ff; }
  .header-ui-color-blue-4 {
    color: #0033cc; }
  .header-ui-color-blue-5 {
    color: #003399; }
  .header-ui-color-blue-6 {
    color: #003366; }
  .header-ui-color-green-0 {
    color: #99ff99; }
  .header-ui-color-green-1 {
    color: #66ff66; }
  .header-ui-color-green-2 {
    color: #66ff33; }
  .header-ui-color-green-3 {
    color: #33cb00; }
  .header-ui-color-green-4 {
    color: #33cc00; }
  .header-ui-color-green-5 {
    color: #339900; }
  .header-ui-color-green-6 {
    color: #336600; }
  .header-ui-color-orange-0 {
    color: #ffcc99; }
  .header-ui-color-orange-1 {
    color: #ff9966; }
  .header-ui-color-orange-2 {
    color: #ff9933; }
  .header-ui-color-orange-3 {
    color: #ff6600; }
  .header-ui-color-orange-4 {
    color: #cc662b; }
  .header-ui-color-orange-5 {
    color: #993300; }
  .header-ui-color-orange-6 {
    color: #663300; }
  .header-ui-color-grey-0 {
    color: #fafafa; }
  .header-ui-color-grey-1 {
    color: #eaeaea; }
  .header-ui-color-grey-2 {
    color: #cccccc; }
  .header-ui-color-grey-3 {
    color: #999999; }
  .header-ui-color-grey-4 {
    color: #777777; }
  .header-ui-color-grey-5 {
    color: #666666; }
  .header-ui-color-grey-6 {
    color: #333333; }
  .header-ui-color-white {
    color: #ffffff; }
  .header-ui-color-black {
    color: #000000; }
  .header-ui-color-light-grey {
    color: #4a4a4a; }
  .header-ui-color-light-pink {
    color: #fef0ef; }
  .header-ui-color-pink {
    color: #fabeb9; }
  .header-ui-background-color-blue-0 {
    background-color: #99ccff; }
  .header-ui-background-color-blue-1 {
    background-color: #6699ff; }
  .header-ui-background-color-blue-2 {
    background-color: #3366ff; }
  .header-ui-background-color-blue-3 {
    background-color: #0033ff; }
  .header-ui-background-color-blue-4 {
    background-color: #0033cc; }
  .header-ui-background-color-blue-5 {
    background-color: #003399; }
  .header-ui-background-color-blue-6 {
    background-color: #003366; }
  .header-ui-background-color-green-0 {
    background-color: #99ff99; }
  .header-ui-background-color-green-1 {
    background-color: #66ff66; }
  .header-ui-background-color-green-2 {
    background-color: #66ff33; }
  .header-ui-background-color-green-3 {
    background-color: #33cb00; }
  .header-ui-background-color-green-4 {
    background-color: #33cc00; }
  .header-ui-background-color-green-5 {
    background-color: #339900; }
  .header-ui-background-color-green-6 {
    background-color: #336600; }
  .header-ui-background-color-orange-0 {
    background-color: #ffcc99; }
  .header-ui-background-color-orange-1 {
    background-color: #ff9966; }
  .header-ui-background-color-orange-2 {
    background-color: #ff9933; }
  .header-ui-background-color-orange-3 {
    background-color: #ff6600; }
  .header-ui-background-color-orange-4 {
    background-color: #cc662b; }
  .header-ui-background-color-orange-5 {
    background-color: #993300; }
  .header-ui-background-color-orange-6 {
    background-color: #663300; }
  .header-ui-background-color-grey-0 {
    background-color: #fafafa; }
  .header-ui-background-color-grey-1 {
    background-color: #eaeaea; }
  .header-ui-background-color-grey-2 {
    background-color: #cccccc; }
  .header-ui-background-color-grey-3 {
    background-color: #999999; }
  .header-ui-background-color-grey-4 {
    background-color: #777777; }
  .header-ui-background-color-grey-5 {
    background-color: #666666; }
  .header-ui-background-color-grey-6 {
    background-color: #333333; }
  .header-ui-background-color-white {
    background-color: #ffffff; }
  .header-ui-background-color-black {
    background-color: #000000; }
  .header-ui-background-color-light-grey {
    background-color: #4a4a4a; }
  .header-ui-background-color-light-pink {
    background-color: #fef0ef; }
  .header-ui-background-color-pink {
    background-color: #fabeb9; } }
