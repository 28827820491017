/* Global style guide for UI Lib */
/**
 * Browser support
 */
/**
 * Typography
 */
@import url("https://fonts.googleapis.com/css?family=Rajdhani:300,400,500,600,700&subset=latin-ext");
/**
 * Colors
 */
/**
 * Page -- UILib Specific Variables
 */
/**
 * Card -- UILib Specific Variables
 */
@media only screen and (min-width: 769px) {
  .navbar {
    width: 100vw;
    min-width: 1366px;
    height: 80px; }
  .navbar-fixed-top {
    top: 0;
    left: 0; }
  .navbar-background-color-blue-0 {
    background-color: #99ccff; }
  .navbar-background-color-blue-1 {
    background-color: #6699ff; }
  .navbar-background-color-blue-2 {
    background-color: #3366ff; }
  .navbar-background-color-blue-3 {
    background-color: #0033ff; }
  .navbar-background-color-blue-4 {
    background-color: #0033cc; }
  .navbar-background-color-blue-5 {
    background-color: #003399; }
  .navbar-background-color-blue-6 {
    background-color: #003366; }
  .navbar-background-color-green-0 {
    background-color: #99ff99; }
  .navbar-background-color-green-1 {
    background-color: #66ff66; }
  .navbar-background-color-green-2 {
    background-color: #66ff33; }
  .navbar-background-color-green-3 {
    background-color: #33cb00; }
  .navbar-background-color-green-4 {
    background-color: #33cc00; }
  .navbar-background-color-green-5 {
    background-color: #339900; }
  .navbar-background-color-green-6 {
    background-color: #336600; }
  .navbar-background-color-orange-0 {
    background-color: #ffcc99; }
  .navbar-background-color-orange-1 {
    background-color: #ff9966; }
  .navbar-background-color-orange-2 {
    background-color: #ff9933; }
  .navbar-background-color-orange-3 {
    background-color: #ff6600; }
  .navbar-background-color-orange-4 {
    background-color: #cc662b; }
  .navbar-background-color-orange-5 {
    background-color: #993300; }
  .navbar-background-color-orange-6 {
    background-color: #663300; }
  .navbar-background-color-grey-0 {
    background-color: #fafafa; }
  .navbar-background-color-grey-1 {
    background-color: #eaeaea; }
  .navbar-background-color-grey-2 {
    background-color: #cccccc; }
  .navbar-background-color-grey-3 {
    background-color: #999999; }
  .navbar-background-color-grey-4 {
    background-color: #777777; }
  .navbar-background-color-grey-5 {
    background-color: #666666; }
  .navbar-background-color-grey-6 {
    background-color: #333333; }
  .navbar-background-color-white {
    background-color: #ffffff; }
  .navbar-background-color-black {
    background-color: #000000; }
  .navbar-background-color-light-grey {
    background-color: #4a4a4a; }
  .navbar-background-color-light-pink {
    background-color: #fef0ef; }
  .navbar-background-color-pink {
    background-color: #fabeb9; }
  .navbar-color-blue-0 {
    color: #99ccff; }
  .navbar-color-blue-1 {
    color: #6699ff; }
  .navbar-color-blue-2 {
    color: #3366ff; }
  .navbar-color-blue-3 {
    color: #0033ff; }
  .navbar-color-blue-4 {
    color: #0033cc; }
  .navbar-color-blue-5 {
    color: #003399; }
  .navbar-color-blue-6 {
    color: #003366; }
  .navbar-color-green-0 {
    color: #99ff99; }
  .navbar-color-green-1 {
    color: #66ff66; }
  .navbar-color-green-2 {
    color: #66ff33; }
  .navbar-color-green-3 {
    color: #33cb00; }
  .navbar-color-green-4 {
    color: #33cc00; }
  .navbar-color-green-5 {
    color: #339900; }
  .navbar-color-green-6 {
    color: #336600; }
  .navbar-color-orange-0 {
    color: #ffcc99; }
  .navbar-color-orange-1 {
    color: #ff9966; }
  .navbar-color-orange-2 {
    color: #ff9933; }
  .navbar-color-orange-3 {
    color: #ff6600; }
  .navbar-color-orange-4 {
    color: #cc662b; }
  .navbar-color-orange-5 {
    color: #993300; }
  .navbar-color-orange-6 {
    color: #663300; }
  .navbar-color-grey-0 {
    color: #fafafa; }
  .navbar-color-grey-1 {
    color: #eaeaea; }
  .navbar-color-grey-2 {
    color: #cccccc; }
  .navbar-color-grey-3 {
    color: #999999; }
  .navbar-color-grey-4 {
    color: #777777; }
  .navbar-color-grey-5 {
    color: #666666; }
  .navbar-color-grey-6 {
    color: #333333; }
  .navbar-color-white {
    color: #ffffff; }
  .navbar-color-black {
    color: #000000; }
  .navbar-color-light-grey {
    color: #4a4a4a; }
  .navbar-color-light-pink {
    color: #fef0ef; }
  .navbar-color-pink {
    color: #fabeb9; } }

@media only screen and (max-width: 768px) {
  .navbar {
    width: 100vw;
    height: 80px; }
  .navbar-fixed-top {
    top: 0;
    left: 0; }
  .navbar-background-color-blue-0 {
    background-color: #99ccff; }
  .navbar-background-color-blue-1 {
    background-color: #6699ff; }
  .navbar-background-color-blue-2 {
    background-color: #3366ff; }
  .navbar-background-color-blue-3 {
    background-color: #0033ff; }
  .navbar-background-color-blue-4 {
    background-color: #0033cc; }
  .navbar-background-color-blue-5 {
    background-color: #003399; }
  .navbar-background-color-blue-6 {
    background-color: #003366; }
  .navbar-background-color-green-0 {
    background-color: #99ff99; }
  .navbar-background-color-green-1 {
    background-color: #66ff66; }
  .navbar-background-color-green-2 {
    background-color: #66ff33; }
  .navbar-background-color-green-3 {
    background-color: #33cb00; }
  .navbar-background-color-green-4 {
    background-color: #33cc00; }
  .navbar-background-color-green-5 {
    background-color: #339900; }
  .navbar-background-color-green-6 {
    background-color: #336600; }
  .navbar-background-color-orange-0 {
    background-color: #ffcc99; }
  .navbar-background-color-orange-1 {
    background-color: #ff9966; }
  .navbar-background-color-orange-2 {
    background-color: #ff9933; }
  .navbar-background-color-orange-3 {
    background-color: #ff6600; }
  .navbar-background-color-orange-4 {
    background-color: #cc662b; }
  .navbar-background-color-orange-5 {
    background-color: #993300; }
  .navbar-background-color-orange-6 {
    background-color: #663300; }
  .navbar-background-color-grey-0 {
    background-color: #fafafa; }
  .navbar-background-color-grey-1 {
    background-color: #eaeaea; }
  .navbar-background-color-grey-2 {
    background-color: #cccccc; }
  .navbar-background-color-grey-3 {
    background-color: #999999; }
  .navbar-background-color-grey-4 {
    background-color: #777777; }
  .navbar-background-color-grey-5 {
    background-color: #666666; }
  .navbar-background-color-grey-6 {
    background-color: #333333; }
  .navbar-background-color-white {
    background-color: #ffffff; }
  .navbar-background-color-black {
    background-color: #000000; }
  .navbar-background-color-light-grey {
    background-color: #4a4a4a; }
  .navbar-background-color-light-pink {
    background-color: #fef0ef; }
  .navbar-background-color-pink {
    background-color: #fabeb9; }
  .navbar-color-blue-0 {
    color: #99ccff; }
  .navbar-color-blue-1 {
    color: #6699ff; }
  .navbar-color-blue-2 {
    color: #3366ff; }
  .navbar-color-blue-3 {
    color: #0033ff; }
  .navbar-color-blue-4 {
    color: #0033cc; }
  .navbar-color-blue-5 {
    color: #003399; }
  .navbar-color-blue-6 {
    color: #003366; }
  .navbar-color-green-0 {
    color: #99ff99; }
  .navbar-color-green-1 {
    color: #66ff66; }
  .navbar-color-green-2 {
    color: #66ff33; }
  .navbar-color-green-3 {
    color: #33cb00; }
  .navbar-color-green-4 {
    color: #33cc00; }
  .navbar-color-green-5 {
    color: #339900; }
  .navbar-color-green-6 {
    color: #336600; }
  .navbar-color-orange-0 {
    color: #ffcc99; }
  .navbar-color-orange-1 {
    color: #ff9966; }
  .navbar-color-orange-2 {
    color: #ff9933; }
  .navbar-color-orange-3 {
    color: #ff6600; }
  .navbar-color-orange-4 {
    color: #cc662b; }
  .navbar-color-orange-5 {
    color: #993300; }
  .navbar-color-orange-6 {
    color: #663300; }
  .navbar-color-grey-0 {
    color: #fafafa; }
  .navbar-color-grey-1 {
    color: #eaeaea; }
  .navbar-color-grey-2 {
    color: #cccccc; }
  .navbar-color-grey-3 {
    color: #999999; }
  .navbar-color-grey-4 {
    color: #777777; }
  .navbar-color-grey-5 {
    color: #666666; }
  .navbar-color-grey-6 {
    color: #333333; }
  .navbar-color-white {
    color: #ffffff; }
  .navbar-color-black {
    color: #000000; }
  .navbar-color-light-grey {
    color: #4a4a4a; }
  .navbar-color-light-pink {
    color: #fef0ef; }
  .navbar-color-pink {
    color: #fabeb9; } }
