/* Global style guide for UI Lib */
/**
 * Browser support
 */
/**
 * Typography
 */
@import url("https://fonts.googleapis.com/css?family=Rajdhani:300,400,500,600,700&subset=latin-ext");
/**
 * Colors
 */
/**
 * Page -- UILib Specific Variables
 */
/**
 * Card -- UILib Specific Variables
 */
@media only screen and (min-width: 769px) {
  .activation-message {
    max-width: 1130px; }
  .dashboard-content-wrapper {
    max-width: 1130px;
    margin: 0 auto 100px auto;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between; }
    .dashboard-content-wrapper .investment-pane {
      width: 850px; }
      .dashboard-content-wrapper .investment-pane .invested-projects-zone {
        margin: 60px 0; }
      .dashboard-content-wrapper .investment-pane .investable-projects-zone {
        margin: 60px 0; }
      .dashboard-content-wrapper .investment-pane .dashboard-line-chart {
        box-sizing: border-box;
        width: 100%;
        background-color: #fff;
        border-radius: 8px; }
    .dashboard-content-wrapper .mobile-investment-pane {
      display: none; }
    .dashboard-content-wrapper .wallet-pane {
      width: 230px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start; }
      .dashboard-content-wrapper .wallet-pane .action-buttons {
        display: flex;
        flex-direction: row;
        margin-top: 20px;
        justify-content: space-around; }
        .dashboard-content-wrapper .wallet-pane .action-buttons .button {
          width: 100px;
          padding: 7px 7px;
          font-weight: 600; }
      .dashboard-content-wrapper .wallet-pane .header-section {
        margin: 20px 0 20px 0; }
      .dashboard-content-wrapper .wallet-pane .wallet-margin {
        margin-bottom: 20px; }
      .dashboard-content-wrapper .wallet-pane a {
        text-decoration: none;
        color: #4a4a4a; }
        .dashboard-content-wrapper .wallet-pane a span .management-action {
          cursor: pointer; }
          .dashboard-content-wrapper .wallet-pane a span .management-action:hover {
            text-decoration: underline; }
  .dashboard-content-wrapper-margin-fix {
    margin-top: 60px;
    margin-bottom: 60px; }
  .wallet-deposit-loader {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
  .wallet-deposit-modal-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between; }
    .wallet-deposit-modal-wrapper .header {
      font-size: 36px;
      font-weight: 700; }
    .wallet-deposit-modal-wrapper .wallet-created {
      margin: 0 auto;
      margin-top: 10px;
      margin-bottom: 10px;
      font-weight: 500;
      background-color: #6699ff;
      border: 1px solid #0033ff;
      border-radius: 4px;
      padding: 10px 40px;
      box-sizing: border-box; }
      .wallet-deposit-modal-wrapper .wallet-created .text {
        font-size: 16px;
        color: #ffffff; }
    .wallet-deposit-modal-wrapper .wallet-address {
      display: flex;
      width: calc(100% - 60px);
      margin: 0 auto;
      flex-direction: column; }
      .wallet-deposit-modal-wrapper .wallet-address .header {
        width: 100%;
        text-align: left;
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 5px; }
      .wallet-deposit-modal-wrapper .wallet-address .wallet-address-zone {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #cccccc;
        color: #4a4a4a;
        border-radius: 4px;
        padding: 13px;
        background-color: #ffffff;
        cursor: pointer;
        z-index: 10; }
        .wallet-deposit-modal-wrapper .wallet-address .wallet-address-zone .address {
          font-size: 16px;
          color: #4a4a4a;
          font-weight: 500; }
        .wallet-deposit-modal-wrapper .wallet-address .wallet-address-zone .icon {
          font-size: 18px; }
        .wallet-deposit-modal-wrapper .wallet-address .wallet-address-zone .copied-zone {
          position: absolute;
          box-sizing: border-box;
          top: -30px;
          right: -1px;
          font-size: 12px;
          background-color: #ffffff;
          border: 1px solid #cccccc;
          border-radius: 3px;
          padding: 5px 10px 5px 10px; }
      .wallet-deposit-modal-wrapper .wallet-address .regenerate {
        width: 100%;
        text-align: right;
        font-size: 14px;
        font-weight: 500;
        color: #4a4a4a;
        margin-top: 5px;
        cursor: pointer; }
        .wallet-deposit-modal-wrapper .wallet-address .regenerate:hover {
          text-decoration: underline; }
      .wallet-deposit-modal-wrapper .wallet-address .error {
        margin-top: 20px; }
    .wallet-deposit-modal-wrapper .wallet-qr-code {
      height: 180px;
      width: 180px;
      margin: 0 auto; }
      .wallet-deposit-modal-wrapper .wallet-qr-code .qr-code {
        height: 100%;
        width: 100%; }
    .wallet-deposit-modal-wrapper .actions {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 300px; }
      .wallet-deposit-modal-wrapper .actions .button {
        width: 140px; }
  .disabled-wallet-modal-wrapper {
    height: 90%;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start; }
    .disabled-wallet-modal-wrapper .header {
      margin-top: 50px;
      margin-bottom: 60px;
      font-size: 36px !important;
      font-weight: 700 !important; }
    .disabled-wallet-modal-wrapper .icon {
      font-size: 140px;
      margin-bottom: 60px; }
    .disabled-wallet-modal-wrapper .text {
      margin-bottom: 80px;
      font-size: 18px;
      font-weight: 500; }
    .disabled-wallet-modal-wrapper .action {
      width: 200px;
      height: 50px;
      margin-top: auto; } }

@media only screen and (max-width: 768px) {
  .activation-message {
    max-width: 90vw; }
  .dashboard-content-wrapper {
    max-width: 90vw;
    margin: 0 auto 0 auto;
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    justify-content: space-between; }
    .dashboard-content-wrapper .investment-pane {
      display: none; }
    .dashboard-content-wrapper .mobile-investment-pane {
      width: 100%; }
      .dashboard-content-wrapper .mobile-investment-pane .invested-projects-zone {
        margin: 10px 0 0 0; }
      .dashboard-content-wrapper .mobile-investment-pane .investable-projects-zone {
        margin: 10px 0 0 0; }
    .dashboard-content-wrapper .wallet-pane {
      width: 90vw;
      display: flex;
      flex-direction: column;
      justify-content: flex-start; }
      .dashboard-content-wrapper .wallet-pane .action-buttons {
        display: flex;
        flex-direction: row;
        margin-top: 20px;
        justify-content: space-around;
        width: 90vw; }
        .dashboard-content-wrapper .wallet-pane .action-buttons .button {
          width: 40vw;
          padding: 7px 7px;
          font-weight: 600; }
      .dashboard-content-wrapper .wallet-pane .header-section {
        margin: 20px 0 20px 0; }
      .dashboard-content-wrapper .wallet-pane .wallet-margin {
        margin-bottom: 20px; }
      .dashboard-content-wrapper .wallet-pane a {
        text-decoration: none;
        color: #4a4a4a; }
        .dashboard-content-wrapper .wallet-pane a span .management-action {
          display: none; }
  .dashboard-content-wrapper-margin-fix {
    margin-top: 60px;
    margin-bottom: 60px; }
  .wallet-deposit-loader {
    min-height: 50vh;
    width: 80vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
  .wallet-deposit-modal-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between; }
    .wallet-deposit-modal-wrapper .header {
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 10px; }
    .wallet-deposit-modal-wrapper .wallet-created {
      margin: 0 auto;
      margin-top: 10px;
      margin-bottom: 10px;
      font-weight: 500;
      background-color: #6699ff;
      border: 1px solid #0033ff;
      border-radius: 4px;
      padding: 10px 20px;
      box-sizing: border-box; }
      .wallet-deposit-modal-wrapper .wallet-created .text {
        font-size: 12px;
        color: #ffffff; }
    .wallet-deposit-modal-wrapper .content {
      width: 90%;
      font-size: 12px;
      font-weight: 500;
      margin-bottom: 20px; }
    .wallet-deposit-modal-wrapper .wallet-address {
      display: flex;
      width: 80%;
      margin: 0 auto;
      flex-direction: column; }
      .wallet-deposit-modal-wrapper .wallet-address .header {
        width: 100%;
        text-align: left;
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 5px; }
      .wallet-deposit-modal-wrapper .wallet-address .wallet-address-zone {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #cccccc;
        color: #4a4a4a;
        border-radius: 4px;
        box-sizing: border-box;
        padding: 13px;
        margin-bottom: 20px;
        background-color: #ffffff;
        z-index: 10; }
        .wallet-deposit-modal-wrapper .wallet-address .wallet-address-zone .address {
          font-size: 14px;
          font-weight: 500;
          overflow-x: scroll; }
        .wallet-deposit-modal-wrapper .wallet-address .wallet-address-zone .icon {
          display: none; }
        .wallet-deposit-modal-wrapper .wallet-address .wallet-address-zone .copied-zone {
          position: absolute;
          box-sizing: border-box;
          top: -30px;
          right: -1px;
          font-size: 12px;
          background-color: #ffffff;
          border: 1px solid #cccccc;
          border-radius: 3px;
          padding: 5px 10px 5px 10px; }
      .wallet-deposit-modal-wrapper .wallet-address .regenerate {
        width: 100%;
        text-align: right;
        font-size: 14px;
        font-weight: 500;
        color: #4a4a4a;
        cursor: pointer; }
        .wallet-deposit-modal-wrapper .wallet-address .regenerate:hover {
          text-decoration: underline; }
      .wallet-deposit-modal-wrapper .wallet-address .error {
        margin-top: 20px; }
    .wallet-deposit-modal-wrapper .wallet-qr-code {
      height: 60%;
      width: 60%;
      margin: 20px auto; }
      .wallet-deposit-modal-wrapper .wallet-qr-code .qr-code {
        height: 100%;
        width: 100%; }
    .wallet-deposit-modal-wrapper .actions {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 90%; }
      .wallet-deposit-modal-wrapper .actions .button {
        width: 32vw;
        font-size: 11px; }
  .disabled-wallet-modal-wrapper {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start; }
    .disabled-wallet-modal-wrapper .header {
      margin-top: 30px !important;
      margin-bottom: 30px !important;
      font-size: 32px !important;
      font-weight: 700 !important; }
    .disabled-wallet-modal-wrapper .icon {
      font-size: 80px;
      margin-bottom: 30px; }
    .disabled-wallet-modal-wrapper .text {
      width: 80%;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 60px;
      font-size: 16px;
      font-weight: 500; }
    .disabled-wallet-modal-wrapper .action {
      width: 200px;
      height: 50px;
      margin-top: auto; } }
