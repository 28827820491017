/* Global style guide for UI Lib */
/**
 * Browser support
 */
/**
 * Typography
 */
@import url("https://fonts.googleapis.com/css?family=Rajdhani:300,400,500,600,700&subset=latin-ext");
/**
 * Colors
 */
/**
 * Page -- UILib Specific Variables
 */
/**
 * Card -- UILib Specific Variables
 */
.button {
  box-sizing: border-box;
  padding: 10px 25px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 3px;
  border: 1px solid;
  outline: none;
  -webkit-outline: none;
  -moz-outline: none;
  cursor: pointer; }
  .button:disabled {
    background-color: #fafafa;
    border-color: #cccccc;
    color: #cccccc;
    cursor: auto; }
  .button:hover {
    filter: brightness(125%);
    transition: all 0.5s ease-in-out; }

.button-fluid {
  width: 100%; }

.button-background-color-blue-0 {
  background-color: #99ccff; }

.button-background-color-blue-1 {
  background-color: #6699ff; }

.button-background-color-blue-2 {
  background-color: #3366ff; }

.button-background-color-blue-3 {
  background-color: #0033ff; }

.button-background-color-blue-4 {
  background-color: #0033cc; }

.button-background-color-blue-5 {
  background-color: #003399; }

.button-background-color-blue-6 {
  background-color: #003366; }

.button-background-color-green-0 {
  background-color: #99ff99; }

.button-background-color-green-1 {
  background-color: #66ff66; }

.button-background-color-green-2 {
  background-color: #66ff33; }

.button-background-color-green-3 {
  background-color: #33cb00; }

.button-background-color-green-4 {
  background-color: #33cc00; }

.button-background-color-green-5 {
  background-color: #339900; }

.button-background-color-green-6 {
  background-color: #336600; }

.button-background-color-orange-0 {
  background-color: #ffcc99; }

.button-background-color-orange-1 {
  background-color: #ff9966; }

.button-background-color-orange-2 {
  background-color: #ff9933; }

.button-background-color-orange-3 {
  background-color: #ff6600; }

.button-background-color-orange-4 {
  background-color: #cc662b; }

.button-background-color-orange-5 {
  background-color: #993300; }

.button-background-color-orange-6 {
  background-color: #663300; }

.button-background-color-grey-0 {
  background-color: #fafafa; }

.button-background-color-grey-1 {
  background-color: #eaeaea; }

.button-background-color-grey-2 {
  background-color: #cccccc; }

.button-background-color-grey-3 {
  background-color: #999999; }

.button-background-color-grey-4 {
  background-color: #777777; }

.button-background-color-grey-5 {
  background-color: #666666; }

.button-background-color-grey-6 {
  background-color: #333333; }

.button-background-color-white {
  background-color: #ffffff; }

.button-background-color-black {
  background-color: #000000; }

.button-background-color-light-grey {
  background-color: #4a4a4a; }

.button-background-color-light-pink {
  background-color: #fef0ef; }

.button-background-color-pink {
  background-color: #fabeb9; }

.button-border-color-blue-0 {
  border-color: #99ccff; }

.button-border-color-blue-1 {
  border-color: #6699ff; }

.button-border-color-blue-2 {
  border-color: #3366ff; }

.button-border-color-blue-3 {
  border-color: #0033ff; }

.button-border-color-blue-4 {
  border-color: #0033cc; }

.button-border-color-blue-5 {
  border-color: #003399; }

.button-border-color-blue-6 {
  border-color: #003366; }

.button-border-color-green-0 {
  border-color: #99ff99; }

.button-border-color-green-1 {
  border-color: #66ff66; }

.button-border-color-green-2 {
  border-color: #66ff33; }

.button-border-color-green-3 {
  border-color: #33cb00; }

.button-border-color-green-4 {
  border-color: #33cc00; }

.button-border-color-green-5 {
  border-color: #339900; }

.button-border-color-green-6 {
  border-color: #336600; }

.button-border-color-orange-0 {
  border-color: #ffcc99; }

.button-border-color-orange-1 {
  border-color: #ff9966; }

.button-border-color-orange-2 {
  border-color: #ff9933; }

.button-border-color-orange-3 {
  border-color: #ff6600; }

.button-border-color-orange-4 {
  border-color: #cc662b; }

.button-border-color-orange-5 {
  border-color: #993300; }

.button-border-color-orange-6 {
  border-color: #663300; }

.button-border-color-grey-0 {
  border-color: #fafafa; }

.button-border-color-grey-1 {
  border-color: #eaeaea; }

.button-border-color-grey-2 {
  border-color: #cccccc; }

.button-border-color-grey-3 {
  border-color: #999999; }

.button-border-color-grey-4 {
  border-color: #777777; }

.button-border-color-grey-5 {
  border-color: #666666; }

.button-border-color-grey-6 {
  border-color: #333333; }

.button-border-color-white {
  border-color: #ffffff; }

.button-border-color-black {
  border-color: #000000; }

.button-border-color-light-grey {
  border-color: #4a4a4a; }

.button-border-color-light-pink {
  border-color: #fef0ef; }

.button-border-color-pink {
  border-color: #fabeb9; }

.button-color-blue-0 {
  color: #99ccff; }

.button-color-blue-1 {
  color: #6699ff; }

.button-color-blue-2 {
  color: #3366ff; }

.button-color-blue-3 {
  color: #0033ff; }

.button-color-blue-4 {
  color: #0033cc; }

.button-color-blue-5 {
  color: #003399; }

.button-color-blue-6 {
  color: #003366; }

.button-color-green-0 {
  color: #99ff99; }

.button-color-green-1 {
  color: #66ff66; }

.button-color-green-2 {
  color: #66ff33; }

.button-color-green-3 {
  color: #33cb00; }

.button-color-green-4 {
  color: #33cc00; }

.button-color-green-5 {
  color: #339900; }

.button-color-green-6 {
  color: #336600; }

.button-color-orange-0 {
  color: #ffcc99; }

.button-color-orange-1 {
  color: #ff9966; }

.button-color-orange-2 {
  color: #ff9933; }

.button-color-orange-3 {
  color: #ff6600; }

.button-color-orange-4 {
  color: #cc662b; }

.button-color-orange-5 {
  color: #993300; }

.button-color-orange-6 {
  color: #663300; }

.button-color-grey-0 {
  color: #fafafa; }

.button-color-grey-1 {
  color: #eaeaea; }

.button-color-grey-2 {
  color: #cccccc; }

.button-color-grey-3 {
  color: #999999; }

.button-color-grey-4 {
  color: #777777; }

.button-color-grey-5 {
  color: #666666; }

.button-color-grey-6 {
  color: #333333; }

.button-color-white {
  color: #ffffff; }

.button-color-black {
  color: #000000; }

.button-color-light-grey {
  color: #4a4a4a; }

.button-color-light-pink {
  color: #fef0ef; }

.button-color-pink {
  color: #fabeb9; }
