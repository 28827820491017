/* Global style guide for UI Lib */
/**
 * Browser support
 */
/**
 * Typography
 */
@import url("https://fonts.googleapis.com/css?family=Rajdhani:300,400,500,600,700&subset=latin-ext");
/**
 * Colors
 */
/**
 * Page -- UILib Specific Variables
 */
/**
 * Card -- UILib Specific Variables
 */
.tag-ui {
  box-sizing: border-box;
  padding: 4px 10px;
  background-color: #eaeaea;
  font-size: 14px;
  font-weight: 500;
  color: #777777;
  border-radius: 3px; }
