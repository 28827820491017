/* Global style guide for UI Lib */
/**
 * Browser support
 */
/**
 * Typography
 */
@import url("https://fonts.googleapis.com/css?family=Rajdhani:300,400,500,600,700&subset=latin-ext");
/**
 * Colors
 */
/**
 * Page -- UILib Specific Variables
 */
/**
 * Card -- UILib Specific Variables
 */
.input-wrapper {
  display: flex;
  flex-flow: column-reverse;
  position: relative;
  margin-bottom: 10px; }
  .input-wrapper label,
  .input-wrapper input {
    transition: all ease-in 0.15s; }
  .input-wrapper input[type='number'] {
    -moz-appearance: textfield; }
  .input-wrapper input[type='number']::-webkit-inner-spin-button,
  .input-wrapper input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0; }
  .input-wrapper .input {
    box-sizing: border-box;
    width: 100%;
    height: 50px;
    font-size: 1.5em;
    border-radius: 4px;
    border: 1px solid;
    padding-left: 15px;
    padding-right: 15px;
    outline: none;
    text-align: left; }
    .input-wrapper .input:focus {
      border-color: #3366ff; }
  .input-wrapper .input-background-color-blue-0 {
    background-color: #99ccff; }
  .input-wrapper .input-background-color-blue-1 {
    background-color: #6699ff; }
  .input-wrapper .input-background-color-blue-2 {
    background-color: #3366ff; }
  .input-wrapper .input-background-color-blue-3 {
    background-color: #0033ff; }
  .input-wrapper .input-background-color-blue-4 {
    background-color: #0033cc; }
  .input-wrapper .input-background-color-blue-5 {
    background-color: #003399; }
  .input-wrapper .input-background-color-blue-6 {
    background-color: #003366; }
  .input-wrapper .input-background-color-green-0 {
    background-color: #99ff99; }
  .input-wrapper .input-background-color-green-1 {
    background-color: #66ff66; }
  .input-wrapper .input-background-color-green-2 {
    background-color: #66ff33; }
  .input-wrapper .input-background-color-green-3 {
    background-color: #33cb00; }
  .input-wrapper .input-background-color-green-4 {
    background-color: #33cc00; }
  .input-wrapper .input-background-color-green-5 {
    background-color: #339900; }
  .input-wrapper .input-background-color-green-6 {
    background-color: #336600; }
  .input-wrapper .input-background-color-orange-0 {
    background-color: #ffcc99; }
  .input-wrapper .input-background-color-orange-1 {
    background-color: #ff9966; }
  .input-wrapper .input-background-color-orange-2 {
    background-color: #ff9933; }
  .input-wrapper .input-background-color-orange-3 {
    background-color: #ff6600; }
  .input-wrapper .input-background-color-orange-4 {
    background-color: #cc662b; }
  .input-wrapper .input-background-color-orange-5 {
    background-color: #993300; }
  .input-wrapper .input-background-color-orange-6 {
    background-color: #663300; }
  .input-wrapper .input-background-color-grey-0 {
    background-color: #fafafa; }
  .input-wrapper .input-background-color-grey-1 {
    background-color: #eaeaea; }
  .input-wrapper .input-background-color-grey-2 {
    background-color: #cccccc; }
  .input-wrapper .input-background-color-grey-3 {
    background-color: #999999; }
  .input-wrapper .input-background-color-grey-4 {
    background-color: #777777; }
  .input-wrapper .input-background-color-grey-5 {
    background-color: #666666; }
  .input-wrapper .input-background-color-grey-6 {
    background-color: #333333; }
  .input-wrapper .input-background-color-white {
    background-color: #ffffff; }
  .input-wrapper .input-background-color-black {
    background-color: #000000; }
  .input-wrapper .input-background-color-light-grey {
    background-color: #4a4a4a; }
  .input-wrapper .input-background-color-light-pink {
    background-color: #fef0ef; }
  .input-wrapper .input-background-color-pink {
    background-color: #fabeb9; }
  .input-wrapper .input-border-color-blue-0 {
    border-color: #99ccff; }
  .input-wrapper .input-border-color-blue-1 {
    border-color: #6699ff; }
  .input-wrapper .input-border-color-blue-2 {
    border-color: #3366ff; }
  .input-wrapper .input-border-color-blue-3 {
    border-color: #0033ff; }
  .input-wrapper .input-border-color-blue-4 {
    border-color: #0033cc; }
  .input-wrapper .input-border-color-blue-5 {
    border-color: #003399; }
  .input-wrapper .input-border-color-blue-6 {
    border-color: #003366; }
  .input-wrapper .input-border-color-green-0 {
    border-color: #99ff99; }
  .input-wrapper .input-border-color-green-1 {
    border-color: #66ff66; }
  .input-wrapper .input-border-color-green-2 {
    border-color: #66ff33; }
  .input-wrapper .input-border-color-green-3 {
    border-color: #33cb00; }
  .input-wrapper .input-border-color-green-4 {
    border-color: #33cc00; }
  .input-wrapper .input-border-color-green-5 {
    border-color: #339900; }
  .input-wrapper .input-border-color-green-6 {
    border-color: #336600; }
  .input-wrapper .input-border-color-orange-0 {
    border-color: #ffcc99; }
  .input-wrapper .input-border-color-orange-1 {
    border-color: #ff9966; }
  .input-wrapper .input-border-color-orange-2 {
    border-color: #ff9933; }
  .input-wrapper .input-border-color-orange-3 {
    border-color: #ff6600; }
  .input-wrapper .input-border-color-orange-4 {
    border-color: #cc662b; }
  .input-wrapper .input-border-color-orange-5 {
    border-color: #993300; }
  .input-wrapper .input-border-color-orange-6 {
    border-color: #663300; }
  .input-wrapper .input-border-color-grey-0 {
    border-color: #fafafa; }
  .input-wrapper .input-border-color-grey-1 {
    border-color: #eaeaea; }
  .input-wrapper .input-border-color-grey-2 {
    border-color: #cccccc; }
  .input-wrapper .input-border-color-grey-3 {
    border-color: #999999; }
  .input-wrapper .input-border-color-grey-4 {
    border-color: #777777; }
  .input-wrapper .input-border-color-grey-5 {
    border-color: #666666; }
  .input-wrapper .input-border-color-grey-6 {
    border-color: #333333; }
  .input-wrapper .input-border-color-white {
    border-color: #ffffff; }
  .input-wrapper .input-border-color-black {
    border-color: #000000; }
  .input-wrapper .input-border-color-light-grey {
    border-color: #4a4a4a; }
  .input-wrapper .input-border-color-light-pink {
    border-color: #fef0ef; }
  .input-wrapper .input-border-color-pink {
    border-color: #fabeb9; }
  .input-wrapper .input-error-border-color {
    border-color: #ff6600; }
  .input-wrapper .input-color-blue-0 {
    color: #99ccff; }
  .input-wrapper .input-color-blue-1 {
    color: #6699ff; }
  .input-wrapper .input-color-blue-2 {
    color: #3366ff; }
  .input-wrapper .input-color-blue-3 {
    color: #0033ff; }
  .input-wrapper .input-color-blue-4 {
    color: #0033cc; }
  .input-wrapper .input-color-blue-5 {
    color: #003399; }
  .input-wrapper .input-color-blue-6 {
    color: #003366; }
  .input-wrapper .input-color-green-0 {
    color: #99ff99; }
  .input-wrapper .input-color-green-1 {
    color: #66ff66; }
  .input-wrapper .input-color-green-2 {
    color: #66ff33; }
  .input-wrapper .input-color-green-3 {
    color: #33cb00; }
  .input-wrapper .input-color-green-4 {
    color: #33cc00; }
  .input-wrapper .input-color-green-5 {
    color: #339900; }
  .input-wrapper .input-color-green-6 {
    color: #336600; }
  .input-wrapper .input-color-orange-0 {
    color: #ffcc99; }
  .input-wrapper .input-color-orange-1 {
    color: #ff9966; }
  .input-wrapper .input-color-orange-2 {
    color: #ff9933; }
  .input-wrapper .input-color-orange-3 {
    color: #ff6600; }
  .input-wrapper .input-color-orange-4 {
    color: #cc662b; }
  .input-wrapper .input-color-orange-5 {
    color: #993300; }
  .input-wrapper .input-color-orange-6 {
    color: #663300; }
  .input-wrapper .input-color-grey-0 {
    color: #fafafa; }
  .input-wrapper .input-color-grey-1 {
    color: #eaeaea; }
  .input-wrapper .input-color-grey-2 {
    color: #cccccc; }
  .input-wrapper .input-color-grey-3 {
    color: #999999; }
  .input-wrapper .input-color-grey-4 {
    color: #777777; }
  .input-wrapper .input-color-grey-5 {
    color: #666666; }
  .input-wrapper .input-color-grey-6 {
    color: #333333; }
  .input-wrapper .input-color-white {
    color: #ffffff; }
  .input-wrapper .input-color-black {
    color: #000000; }
  .input-wrapper .input-color-light-grey {
    color: #4a4a4a; }
  .input-wrapper .input-color-light-pink {
    color: #fef0ef; }
  .input-wrapper .input-color-pink {
    color: #fabeb9; }
  .input-wrapper .label {
    position: absolute;
    top: 0;
    left: 0;
    text-transform: none;
    font-size: 1.167em; }
  .input-wrapper .input:placeholder-shown + label {
    cursor: text;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    opacity: 1;
    transform: translate(1.25em, 0.75em) scale(1); }
  .input-wrapper .input:focus + label {
    opacity: 0; }
  .input-wrapper .input:not(:placeholder-shown) + label {
    opacity: 0; }
  .input-wrapper ::-webkit-input-placeholder {
    opacity: 0; }
  .input-wrapper :-moz-placeholder {
    opacity: 0; }
  .input-wrapper ::-moz-placeholder {
    opacity: 0; }
  .input-wrapper :-ms-input-placeholder {
    opacity: 0; }

.checkbox {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  align-items: center;
  font-size: 1em;
  height: 18px; }
  .checkbox input[type='checkbox'] {
    display: none; }
  .checkbox input[type='checkbox'] + label:before {
    font-family: 'Font Awesome 5 Free';
    font-size: 14px;
    font-weight: 400;
    line-height: 1em;
    content: '\f0c8';
    color: #666666;
    letter-spacing: 8px; }
  .checkbox input[type='checkbox']:checked + label:before {
    content: '\f14a'; }
  .checkbox label {
    font-size: 14px;
    line-height: 18px; }

.checkbox-error input[type='checkbox'] + label:before {
  color: #ff6600; }

.validation-errors {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  background-color: #fef0ef;
  border: 1px solid #fabeb9;
  border-radius: 4px;
  padding: 9px 11px;
  font-size: 1em;
  line-height: 1.5em; }
  .validation-errors .icon {
    font-size: 20px;
    margin-right: 11px;
    color: #ff6600; }
  .validation-errors span {
    text-align: left; }

@media only screen and (min-width: 769px) {
  .digit-container {
    margin-bottom: 20px; }
    .digit-container .digit {
      box-sizing: border-box;
      width: 50px;
      height: 50px;
      font-size: 3em;
      border-radius: 4px;
      border: 1px solid #eaeaea;
      text-align: center;
      margin-right: 20px; }
      .digit-container .digit:last-child {
        margin-right: 0px; }
    .digit-container .digit-error {
      border-color: #ff6600; }
    .digit-container input[type='number']::-webkit-inner-spin-button,
    .digit-container input[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
      appearance: none; }
    .digit-container input[type='number'] {
      -moz-appearance: textfield; } }

@media only screen and (max-width: 768px) {
  .digit-container {
    margin-bottom: 20px; }
    .digit-container .digit {
      box-sizing: border-box;
      width: 32px;
      height: 32px;
      font-size: 14px;
      border-radius: 4px;
      border: 1px solid #eaeaea;
      text-align: center;
      margin-right: 10px; }
      .digit-container .digit:last-child {
        margin-right: 0px; }
    .digit-container .digit-error {
      border-color: #ff6600; }
    .digit-container input[type='number']::-webkit-inner-spin-button,
    .digit-container input[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
      appearance: none; }
    .digit-container input[type='number'] {
      -moz-appearance: textfield; } }

.dropdown-container {
  display: inline-block;
  box-sizing: border-box;
  min-width: 75px;
  height: 50px;
  margin-right: 10px;
  border-radius: 4px;
  border: 1px solid;
  outline: none;
  text-align: right;
  transition: all ease-in 0.15s; }
  .dropdown-container:focus {
    border-color: #3366ff; }
  .dropdown-container .select-container {
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; }
    .dropdown-container .select-container span {
      width: 65%;
      font-size: 1.5em;
      text-align: right;
      color: #333333; }
    .dropdown-container .select-container i {
      width: 35%;
      text-align: center;
      font-size: 1.2em;
      color: #cccccc; }
  .dropdown-container .dropdown-menu {
    background-color: #fff;
    width: 100%;
    max-height: 200px;
    margin-top: 5px;
    border: 1px solid #cccccc;
    border-radius: 4px;
    text-align: left;
    display: block;
    overflow-y: scroll;
    z-index: auto;
    position: relative;
    padding: 0;
    list-style: none;
    box-shadow: 0 2px 2px #eaeaea;
    color: #333333; }
    .dropdown-container .dropdown-menu li {
      font-size: 1.5em;
      padding: 15px 0 15px 20%;
      transition: all ease-in 0.15s;
      cursor: pointer;
      border-bottom: 1px solid #cccccc;
      -moz-user-select: -moz-none;
      -khtml-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none; }
      .dropdown-container .dropdown-menu li:last-child {
        border-bottom: none; }
      .dropdown-container .dropdown-menu li:hover {
        background-color: #eaeaea; }

.dropdown-container-fluid {
  width: 100%; }

.dropdown-container-background-color-blue-0 {
  background-color: #99ccff; }

.dropdown-container-background-color-blue-1 {
  background-color: #6699ff; }

.dropdown-container-background-color-blue-2 {
  background-color: #3366ff; }

.dropdown-container-background-color-blue-3 {
  background-color: #0033ff; }

.dropdown-container-background-color-blue-4 {
  background-color: #0033cc; }

.dropdown-container-background-color-blue-5 {
  background-color: #003399; }

.dropdown-container-background-color-blue-6 {
  background-color: #003366; }

.dropdown-container-background-color-green-0 {
  background-color: #99ff99; }

.dropdown-container-background-color-green-1 {
  background-color: #66ff66; }

.dropdown-container-background-color-green-2 {
  background-color: #66ff33; }

.dropdown-container-background-color-green-3 {
  background-color: #33cb00; }

.dropdown-container-background-color-green-4 {
  background-color: #33cc00; }

.dropdown-container-background-color-green-5 {
  background-color: #339900; }

.dropdown-container-background-color-green-6 {
  background-color: #336600; }

.dropdown-container-background-color-orange-0 {
  background-color: #ffcc99; }

.dropdown-container-background-color-orange-1 {
  background-color: #ff9966; }

.dropdown-container-background-color-orange-2 {
  background-color: #ff9933; }

.dropdown-container-background-color-orange-3 {
  background-color: #ff6600; }

.dropdown-container-background-color-orange-4 {
  background-color: #cc662b; }

.dropdown-container-background-color-orange-5 {
  background-color: #993300; }

.dropdown-container-background-color-orange-6 {
  background-color: #663300; }

.dropdown-container-background-color-grey-0 {
  background-color: #fafafa; }

.dropdown-container-background-color-grey-1 {
  background-color: #eaeaea; }

.dropdown-container-background-color-grey-2 {
  background-color: #cccccc; }

.dropdown-container-background-color-grey-3 {
  background-color: #999999; }

.dropdown-container-background-color-grey-4 {
  background-color: #777777; }

.dropdown-container-background-color-grey-5 {
  background-color: #666666; }

.dropdown-container-background-color-grey-6 {
  background-color: #333333; }

.dropdown-container-background-color-white {
  background-color: #ffffff; }

.dropdown-container-background-color-black {
  background-color: #000000; }

.dropdown-container-background-color-light-grey {
  background-color: #4a4a4a; }

.dropdown-container-background-color-light-pink {
  background-color: #fef0ef; }

.dropdown-container-background-color-pink {
  background-color: #fabeb9; }

.dropdown-container-border-color-blue-0 {
  border-color: #99ccff; }

.dropdown-container-border-color-blue-1 {
  border-color: #6699ff; }

.dropdown-container-border-color-blue-2 {
  border-color: #3366ff; }

.dropdown-container-border-color-blue-3 {
  border-color: #0033ff; }

.dropdown-container-border-color-blue-4 {
  border-color: #0033cc; }

.dropdown-container-border-color-blue-5 {
  border-color: #003399; }

.dropdown-container-border-color-blue-6 {
  border-color: #003366; }

.dropdown-container-border-color-green-0 {
  border-color: #99ff99; }

.dropdown-container-border-color-green-1 {
  border-color: #66ff66; }

.dropdown-container-border-color-green-2 {
  border-color: #66ff33; }

.dropdown-container-border-color-green-3 {
  border-color: #33cb00; }

.dropdown-container-border-color-green-4 {
  border-color: #33cc00; }

.dropdown-container-border-color-green-5 {
  border-color: #339900; }

.dropdown-container-border-color-green-6 {
  border-color: #336600; }

.dropdown-container-border-color-orange-0 {
  border-color: #ffcc99; }

.dropdown-container-border-color-orange-1 {
  border-color: #ff9966; }

.dropdown-container-border-color-orange-2 {
  border-color: #ff9933; }

.dropdown-container-border-color-orange-3 {
  border-color: #ff6600; }

.dropdown-container-border-color-orange-4 {
  border-color: #cc662b; }

.dropdown-container-border-color-orange-5 {
  border-color: #993300; }

.dropdown-container-border-color-orange-6 {
  border-color: #663300; }

.dropdown-container-border-color-grey-0 {
  border-color: #fafafa; }

.dropdown-container-border-color-grey-1 {
  border-color: #eaeaea; }

.dropdown-container-border-color-grey-2 {
  border-color: #cccccc; }

.dropdown-container-border-color-grey-3 {
  border-color: #999999; }

.dropdown-container-border-color-grey-4 {
  border-color: #777777; }

.dropdown-container-border-color-grey-5 {
  border-color: #666666; }

.dropdown-container-border-color-grey-6 {
  border-color: #333333; }

.dropdown-container-border-color-white {
  border-color: #ffffff; }

.dropdown-container-border-color-black {
  border-color: #000000; }

.dropdown-container-border-color-light-grey {
  border-color: #4a4a4a; }

.dropdown-container-border-color-light-pink {
  border-color: #fef0ef; }

.dropdown-container-border-color-pink {
  border-color: #fabeb9; }

.dropdown-container-error-border-color {
  border-color: #ff6600; }

.dropdown-container-color-blue-0 {
  color: #99ccff; }

.dropdown-container-color-blue-1 {
  color: #6699ff; }

.dropdown-container-color-blue-2 {
  color: #3366ff; }

.dropdown-container-color-blue-3 {
  color: #0033ff; }

.dropdown-container-color-blue-4 {
  color: #0033cc; }

.dropdown-container-color-blue-5 {
  color: #003399; }

.dropdown-container-color-blue-6 {
  color: #003366; }

.dropdown-container-color-green-0 {
  color: #99ff99; }

.dropdown-container-color-green-1 {
  color: #66ff66; }

.dropdown-container-color-green-2 {
  color: #66ff33; }

.dropdown-container-color-green-3 {
  color: #33cb00; }

.dropdown-container-color-green-4 {
  color: #33cc00; }

.dropdown-container-color-green-5 {
  color: #339900; }

.dropdown-container-color-green-6 {
  color: #336600; }

.dropdown-container-color-orange-0 {
  color: #ffcc99; }

.dropdown-container-color-orange-1 {
  color: #ff9966; }

.dropdown-container-color-orange-2 {
  color: #ff9933; }

.dropdown-container-color-orange-3 {
  color: #ff6600; }

.dropdown-container-color-orange-4 {
  color: #cc662b; }

.dropdown-container-color-orange-5 {
  color: #993300; }

.dropdown-container-color-orange-6 {
  color: #663300; }

.dropdown-container-color-grey-0 {
  color: #fafafa; }

.dropdown-container-color-grey-1 {
  color: #eaeaea; }

.dropdown-container-color-grey-2 {
  color: #cccccc; }

.dropdown-container-color-grey-3 {
  color: #999999; }

.dropdown-container-color-grey-4 {
  color: #777777; }

.dropdown-container-color-grey-5 {
  color: #666666; }

.dropdown-container-color-grey-6 {
  color: #333333; }

.dropdown-container-color-white {
  color: #ffffff; }

.dropdown-container-color-black {
  color: #000000; }

.dropdown-container-color-light-grey {
  color: #4a4a4a; }

.dropdown-container-color-light-pink {
  color: #fef0ef; }

.dropdown-container-color-pink {
  color: #fabeb9; }
