/* Global style guide for UI Lib */
/**
 * Browser support
 */
/**
 * Typography
 */
@import url("https://fonts.googleapis.com/css?family=Rajdhani:300,400,500,600,700&subset=latin-ext");
/**
 * Colors
 */
/**
 * Page -- UILib Specific Variables
 */
/**
 * Card -- UILib Specific Variables
 */
@media only screen and (min-width: 769px) {
  .project-listing-border {
    width: 100vw;
    min-width: 1366px;
    height: 1px;
    background-color: #cccccc; }
  .project-listing-page-wrapper {
    box-sizing: border-box;
    width: 1366px;
    margin: 0 auto;
    padding-top: 60px;
    padding-bottom: 60px;
    margin-bottom: 50px; }
    .project-listing-page-wrapper .listing-header-zone .header {
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 1.5px;
      margin-bottom: 10px; }
    .project-listing-page-wrapper .listing-header-zone .subheader {
      font-size: 36px;
      font-weight: 500;
      color: #4a4a4a;
      margin-bottom: 60px; }
    .project-listing-page-wrapper .listing-header-zone .short-informations-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-bottom: 60px; }
      .project-listing-page-wrapper .listing-header-zone .short-informations-wrapper .short-information {
        box-sizing: border-box;
        padding-left: 30px;
        padding-right: 30px;
        border-right: 1px solid #cccccc; }
        .project-listing-page-wrapper .listing-header-zone .short-informations-wrapper .short-information:last-child {
          border-right: none; }
        .project-listing-page-wrapper .listing-header-zone .short-informations-wrapper .short-information .short-header {
          font-size: 16px;
          line-height: 20px;
          color: #999999;
          font-weight: 500;
          text-align: center;
          margin: 0; }
        .project-listing-page-wrapper .listing-header-zone .short-informations-wrapper .short-information .short-subheader {
          font-size: 16px;
          line-height: 20px;
          color: #000000;
          font-weight: 600;
          text-align: center;
          margin: 0; }
    .project-listing-page-wrapper .filter-section-zone {
      width: 1100px;
      margin: 0 auto;
      box-sizing: border-box;
      padding-bottom: 40px;
      border-bottom: 1px solid #cccccc; }
      .project-listing-page-wrapper .filter-section-zone .general-filter-area {
        box-sizing: border-box;
        width: 1100px;
        margin: 0 auto;
        padding: 0 50px 20px 50px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center; }
        .project-listing-page-wrapper .filter-section-zone .general-filter-area .general-filters {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center; }
          .project-listing-page-wrapper .filter-section-zone .general-filter-area .general-filters .filter-buttons {
            margin-right: 10px;
            padding-left: 12px;
            padding-right: 12px; }
            .project-listing-page-wrapper .filter-section-zone .general-filter-area .general-filters .filter-buttons:last-child {
              margin-right: 0; }
        .project-listing-page-wrapper .filter-section-zone .general-filter-area .more-filter-cta {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: center; }
      .project-listing-page-wrapper .filter-section-zone .more-filter-area {
        box-sizing: border-box;
        width: 1100px;
        margin: 0 auto;
        padding: 0 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center; }
        .project-listing-page-wrapper .filter-section-zone .more-filter-area .single-filter-zone {
          display: flex;
          flex-direction: column; }
          .project-listing-page-wrapper .filter-section-zone .more-filter-area .single-filter-zone .filter-header {
            width: 100%;
            font-size: 18px;
            line-height: 26px;
            color: #777777;
            text-align: left;
            margin-bottom: 10px; }
          .project-listing-page-wrapper .filter-section-zone .more-filter-area .single-filter-zone .filter-selectors {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center; }
            .project-listing-page-wrapper .filter-section-zone .more-filter-area .single-filter-zone .filter-selectors .filter-buttons {
              margin-right: 10px;
              padding-left: 12px;
              padding-right: 12px; }
              .project-listing-page-wrapper .filter-section-zone .more-filter-area .single-filter-zone .filter-selectors .filter-buttons:last-child {
                margin-right: 0; }
            .project-listing-page-wrapper .filter-section-zone .more-filter-area .single-filter-zone .filter-selectors .location-dropdown {
              width: 250px; }
              .project-listing-page-wrapper .filter-section-zone .more-filter-area .single-filter-zone .filter-selectors .location-dropdown span {
                font-size: 18px;
                line-height: 20px;
                color: #777777;
                width: 80%;
                text-align: left;
                padding-left: 20px; }
              .project-listing-page-wrapper .filter-section-zone .more-filter-area .single-filter-zone .filter-selectors .location-dropdown i {
                width: 20%;
                color: #777777; }
    .project-listing-page-wrapper .no-border {
      border-bottom: none !important; }
    .project-listing-page-wrapper .project-card-zone {
      width: 1100px;
      height: 260px;
      margin: 0 auto;
      box-sizing: border-box;
      padding: 30px 0px;
      border-bottom: 1px solid #cccccc;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center; }
      .project-listing-page-wrapper .project-card-zone .no-project-found {
        width: 100%;
        font-size: 24px;
        line-height: 32px;
        color: #666666;
        text-align: center; }
      .project-listing-page-wrapper .project-card-zone .project-image-wrapper {
        width: 25%;
        height: 100%;
        position: relative;
        margin-right: 35px; }
        .project-listing-page-wrapper .project-card-zone .project-image-wrapper .project-image {
          position: relative;
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          z-index: 10; }
        .project-listing-page-wrapper .project-card-zone .project-image-wrapper .project-tag-zone {
          position: absolute;
          top: 5px;
          left: -5px;
          z-index: 100; }
          .project-listing-page-wrapper .project-card-zone .project-image-wrapper .project-tag-zone .project-tag {
            box-sizing: border-box;
            padding: 5px 13px;
            color: #ffffff;
            margin-bottom: 2px;
            border-radius: 3px;
            font-size: 14px;
            font-weight: 500; }
            .project-listing-page-wrapper .project-card-zone .project-image-wrapper .project-tag-zone .project-tag-blue-0 {
              background-color: #99ccff; }
            .project-listing-page-wrapper .project-card-zone .project-image-wrapper .project-tag-zone .project-tag-blue-1 {
              background-color: #6699ff; }
            .project-listing-page-wrapper .project-card-zone .project-image-wrapper .project-tag-zone .project-tag-blue-2 {
              background-color: #3366ff; }
            .project-listing-page-wrapper .project-card-zone .project-image-wrapper .project-tag-zone .project-tag-blue-3 {
              background-color: #0033ff; }
            .project-listing-page-wrapper .project-card-zone .project-image-wrapper .project-tag-zone .project-tag-blue-4 {
              background-color: #0033cc; }
            .project-listing-page-wrapper .project-card-zone .project-image-wrapper .project-tag-zone .project-tag-blue-5 {
              background-color: #003399; }
            .project-listing-page-wrapper .project-card-zone .project-image-wrapper .project-tag-zone .project-tag-blue-6 {
              background-color: #003366; }
            .project-listing-page-wrapper .project-card-zone .project-image-wrapper .project-tag-zone .project-tag-green-0 {
              background-color: #99ff99; }
            .project-listing-page-wrapper .project-card-zone .project-image-wrapper .project-tag-zone .project-tag-green-1 {
              background-color: #66ff66; }
            .project-listing-page-wrapper .project-card-zone .project-image-wrapper .project-tag-zone .project-tag-green-2 {
              background-color: #66ff33; }
            .project-listing-page-wrapper .project-card-zone .project-image-wrapper .project-tag-zone .project-tag-green-3 {
              background-color: #33cb00; }
            .project-listing-page-wrapper .project-card-zone .project-image-wrapper .project-tag-zone .project-tag-green-4 {
              background-color: #33cc00; }
            .project-listing-page-wrapper .project-card-zone .project-image-wrapper .project-tag-zone .project-tag-green-5 {
              background-color: #339900; }
            .project-listing-page-wrapper .project-card-zone .project-image-wrapper .project-tag-zone .project-tag-green-6 {
              background-color: #336600; }
            .project-listing-page-wrapper .project-card-zone .project-image-wrapper .project-tag-zone .project-tag-orange-0 {
              background-color: #ffcc99; }
            .project-listing-page-wrapper .project-card-zone .project-image-wrapper .project-tag-zone .project-tag-orange-1 {
              background-color: #ff9966; }
            .project-listing-page-wrapper .project-card-zone .project-image-wrapper .project-tag-zone .project-tag-orange-2 {
              background-color: #ff9933; }
            .project-listing-page-wrapper .project-card-zone .project-image-wrapper .project-tag-zone .project-tag-orange-3 {
              background-color: #ff6600; }
            .project-listing-page-wrapper .project-card-zone .project-image-wrapper .project-tag-zone .project-tag-orange-4 {
              background-color: #cc662b; }
            .project-listing-page-wrapper .project-card-zone .project-image-wrapper .project-tag-zone .project-tag-orange-5 {
              background-color: #993300; }
            .project-listing-page-wrapper .project-card-zone .project-image-wrapper .project-tag-zone .project-tag-orange-6 {
              background-color: #663300; }
            .project-listing-page-wrapper .project-card-zone .project-image-wrapper .project-tag-zone .project-tag-grey-0 {
              background-color: #fafafa; }
            .project-listing-page-wrapper .project-card-zone .project-image-wrapper .project-tag-zone .project-tag-grey-1 {
              background-color: #eaeaea; }
            .project-listing-page-wrapper .project-card-zone .project-image-wrapper .project-tag-zone .project-tag-grey-2 {
              background-color: #cccccc; }
            .project-listing-page-wrapper .project-card-zone .project-image-wrapper .project-tag-zone .project-tag-grey-3 {
              background-color: #999999; }
            .project-listing-page-wrapper .project-card-zone .project-image-wrapper .project-tag-zone .project-tag-grey-4 {
              background-color: #777777; }
            .project-listing-page-wrapper .project-card-zone .project-image-wrapper .project-tag-zone .project-tag-grey-5 {
              background-color: #666666; }
            .project-listing-page-wrapper .project-card-zone .project-image-wrapper .project-tag-zone .project-tag-grey-6 {
              background-color: #333333; }
            .project-listing-page-wrapper .project-card-zone .project-image-wrapper .project-tag-zone .project-tag-white {
              background-color: #ffffff; }
            .project-listing-page-wrapper .project-card-zone .project-image-wrapper .project-tag-zone .project-tag-black {
              background-color: #000000; }
            .project-listing-page-wrapper .project-card-zone .project-image-wrapper .project-tag-zone .project-tag-light-grey {
              background-color: #4a4a4a; }
            .project-listing-page-wrapper .project-card-zone .project-image-wrapper .project-tag-zone .project-tag-light-pink {
              background-color: #fef0ef; }
            .project-listing-page-wrapper .project-card-zone .project-image-wrapper .project-tag-zone .project-tag-pink {
              background-color: #fabeb9; }
      .project-listing-page-wrapper .project-card-zone .project-information-wrapper {
        min-height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start; }
        .project-listing-page-wrapper .project-card-zone .project-information-wrapper .header-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start; }
          .project-listing-page-wrapper .project-card-zone .project-information-wrapper .header-wrapper a {
            text-decoration: none; }
            .project-listing-page-wrapper .project-card-zone .project-information-wrapper .header-wrapper a .header {
              font-size: 30px;
              font-weight: 700;
              line-height: 38px;
              color: #333333; }
              .project-listing-page-wrapper .project-card-zone .project-information-wrapper .header-wrapper a .header:hover {
                color: #ff6600;
                transition: all ease-in-out 0.2s; }
          .project-listing-page-wrapper .project-card-zone .project-information-wrapper .header-wrapper .subheader {
            font-size: 20px;
            font-weight: 500;
            line-height: 20px;
            color: #4a4a4a; }
        .project-listing-page-wrapper .project-card-zone .project-information-wrapper .information-wrapper {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: flex-start; }
          .project-listing-page-wrapper .project-card-zone .project-information-wrapper .information-wrapper .information {
            margin-right: 20px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start; }
            .project-listing-page-wrapper .project-card-zone .project-information-wrapper .information-wrapper .information:last-child {
              margin-right: none; }
            .project-listing-page-wrapper .project-card-zone .project-information-wrapper .information-wrapper .information .header {
              font-size: 16px;
              line-height: 20px;
              font-weight: 500;
              color: #666666;
              text-align: left; }
            .project-listing-page-wrapper .project-card-zone .project-information-wrapper .information-wrapper .information .subheader {
              font-size: 18px;
              line-height: 20px;
              font-weight: 600;
              color: #000000;
              text-align: left; }
            .project-listing-page-wrapper .project-card-zone .project-information-wrapper .information-wrapper .information .return {
              color: #ff6600; }
      .project-listing-page-wrapper .project-card-zone .project-action-wrapper {
        min-height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        margin-left: auto; }
        .project-listing-page-wrapper .project-card-zone .project-action-wrapper .header-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-end; }
          .project-listing-page-wrapper .project-card-zone .project-action-wrapper .header-wrapper .header {
            font-size: 20px;
            font-weight: 500;
            line-height: 26px;
            color: #4a4a4a; }
          .project-listing-page-wrapper .project-card-zone .project-action-wrapper .header-wrapper .contribution {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            font-size: 36px; }
            .project-listing-page-wrapper .project-card-zone .project-action-wrapper .header-wrapper .contribution .icon {
              margin-right: 8px; }
            .project-listing-page-wrapper .project-card-zone .project-action-wrapper .header-wrapper .contribution .amount {
              font-weight: 600;
              line-height: 45px;
              color: #000000; }
          .project-listing-page-wrapper .project-card-zone .project-action-wrapper .header-wrapper .statistics {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center; }
            .project-listing-page-wrapper .project-card-zone .project-action-wrapper .header-wrapper .statistics .information-zone {
              margin-right: 30px;
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: flex-start; }
              .project-listing-page-wrapper .project-card-zone .project-action-wrapper .header-wrapper .statistics .information-zone:last-child {
                margin-right: 0;
                align-items: flex-end; }
              .project-listing-page-wrapper .project-card-zone .project-action-wrapper .header-wrapper .statistics .information-zone .header {
                font-size: 14px;
                line-height: 20px;
                color: #333333;
                font-weight: 500; }
              .project-listing-page-wrapper .project-card-zone .project-action-wrapper .header-wrapper .statistics .information-zone .subheader {
                font-size: 14px;
                line-height: 20px;
                color: #000000;
                font-weight: 500; }
          .project-listing-page-wrapper .project-card-zone .project-action-wrapper .header-wrapper .start-date-header {
            font-size: 16px;
            line-height: 26px;
            font-weight: 500;
            color: #4a4a4a; }
          .project-listing-page-wrapper .project-card-zone .project-action-wrapper .header-wrapper .start-date-subheader {
            font-size: 20px;
            font-weight: 500;
            color: #000000; }
        .project-listing-page-wrapper .project-card-zone .project-action-wrapper .action-wrapper {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: center; }
          .project-listing-page-wrapper .project-card-zone .project-action-wrapper .action-wrapper .remaining-days {
            box-sizing: border-box;
            width: 40%;
            font-size: 18px;
            line-height: 20px;
            font-weight: 500;
            color: #333333;
            text-align: right;
            padding-right: 13px; }
    .project-listing-page-wrapper .mobile-project-card-zone {
      display: none; }
    .project-listing-page-wrapper .load-more-zone {
      width: 1100px;
      margin: 60px auto;
      display: flex;
      flex-direction: row;
      justify-content: center; }
      .project-listing-page-wrapper .load-more-zone .load-more-button {
        height: 50px; } }

@media only screen and (max-width: 768px) {
  .project-listing-border {
    width: 100vw;
    height: 1px;
    background-color: #cccccc; }
  .project-listing-page-wrapper {
    box-sizing: border-box;
    width: 100vw;
    margin: 0 auto;
    padding: 20px;
    padding-top: 0px;
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center; }
    .project-listing-page-wrapper .listing-header-zone {
      display: none; }
    .project-listing-page-wrapper .filter-section-zone {
      display: none; }
    .project-listing-page-wrapper .no-border {
      border-bottom: none !important; }
    .project-listing-page-wrapper .project-card-zone {
      display: none; }
    .project-listing-page-wrapper .mobile-project-card-zone {
      width: 100%;
      margin: 0 auto;
      box-sizing: border-box;
      padding-bottom: 30px;
      padding-top: 30px;
      border-bottom: 1px solid #cccccc;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start; }
      .project-listing-page-wrapper .mobile-project-card-zone:last-child {
        border-bottom: none; }
      .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start; }
        .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .header-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          margin-bottom: 15px; }
          .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .header-wrapper a {
            text-decoration: none; }
            .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .header-wrapper a .header {
              font-size: 24px;
              font-weight: 700;
              line-height: 30px;
              color: #333333; }
              .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .header-wrapper a .header:hover {
                color: #ff6600;
                transition: all ease-in-out 0.2s; }
          .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .header-wrapper .subheader {
            font-size: 16px;
            font-weight: 500;
            line-height: 20px;
            color: #4a4a4a; }
        .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .information-wrapper {
          display: flex;
          flex-direction: row;
          width: calc(100vw - 40px);
          justify-content: space-between;
          align-items: flex-start;
          margin-bottom: 15px; }
          .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .information-wrapper .information {
            padding-right: 10px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start; }
            .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .information-wrapper .information:last-child {
              padding-right: none; }
            .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .information-wrapper .information .header {
              font-size: 14px;
              line-height: 20px;
              font-weight: 500;
              color: #666666;
              text-align: left; }
            .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .information-wrapper .information .subheader {
              font-size: 14px;
              line-height: 20px;
              font-weight: 600;
              color: #000000;
              text-align: left; }
            .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .information-wrapper .information .return {
              color: #ff6600; }
          .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .information-wrapper .first-column {
            width: 30% !important; }
          .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .information-wrapper .center-column {
            width: 50% !important; }
          .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .information-wrapper .last-column {
            width: 20% !important; }
        .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .project-image-wrapper {
          width: 100%;
          min-height: 200px;
          position: relative;
          margin-bottom: 15px; }
          .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .project-image-wrapper .project-image {
            position: relative;
            width: 100%;
            min-height: 200px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            z-index: 10; }
          .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .project-image-wrapper .project-tag-zone {
            position: absolute;
            top: 5px;
            left: -5px;
            z-index: 100; }
            .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .project-image-wrapper .project-tag-zone .project-tag {
              box-sizing: border-box;
              padding: 5px 13px;
              color: #ffffff;
              margin-bottom: 2px;
              border-radius: 3px;
              font-size: 14px;
              font-weight: 500; }
              .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .project-image-wrapper .project-tag-zone .project-tag-blue-0 {
                background-color: #99ccff; }
              .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .project-image-wrapper .project-tag-zone .project-tag-blue-1 {
                background-color: #6699ff; }
              .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .project-image-wrapper .project-tag-zone .project-tag-blue-2 {
                background-color: #3366ff; }
              .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .project-image-wrapper .project-tag-zone .project-tag-blue-3 {
                background-color: #0033ff; }
              .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .project-image-wrapper .project-tag-zone .project-tag-blue-4 {
                background-color: #0033cc; }
              .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .project-image-wrapper .project-tag-zone .project-tag-blue-5 {
                background-color: #003399; }
              .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .project-image-wrapper .project-tag-zone .project-tag-blue-6 {
                background-color: #003366; }
              .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .project-image-wrapper .project-tag-zone .project-tag-green-0 {
                background-color: #99ff99; }
              .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .project-image-wrapper .project-tag-zone .project-tag-green-1 {
                background-color: #66ff66; }
              .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .project-image-wrapper .project-tag-zone .project-tag-green-2 {
                background-color: #66ff33; }
              .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .project-image-wrapper .project-tag-zone .project-tag-green-3 {
                background-color: #33cb00; }
              .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .project-image-wrapper .project-tag-zone .project-tag-green-4 {
                background-color: #33cc00; }
              .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .project-image-wrapper .project-tag-zone .project-tag-green-5 {
                background-color: #339900; }
              .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .project-image-wrapper .project-tag-zone .project-tag-green-6 {
                background-color: #336600; }
              .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .project-image-wrapper .project-tag-zone .project-tag-orange-0 {
                background-color: #ffcc99; }
              .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .project-image-wrapper .project-tag-zone .project-tag-orange-1 {
                background-color: #ff9966; }
              .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .project-image-wrapper .project-tag-zone .project-tag-orange-2 {
                background-color: #ff9933; }
              .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .project-image-wrapper .project-tag-zone .project-tag-orange-3 {
                background-color: #ff6600; }
              .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .project-image-wrapper .project-tag-zone .project-tag-orange-4 {
                background-color: #cc662b; }
              .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .project-image-wrapper .project-tag-zone .project-tag-orange-5 {
                background-color: #993300; }
              .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .project-image-wrapper .project-tag-zone .project-tag-orange-6 {
                background-color: #663300; }
              .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .project-image-wrapper .project-tag-zone .project-tag-grey-0 {
                background-color: #fafafa; }
              .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .project-image-wrapper .project-tag-zone .project-tag-grey-1 {
                background-color: #eaeaea; }
              .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .project-image-wrapper .project-tag-zone .project-tag-grey-2 {
                background-color: #cccccc; }
              .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .project-image-wrapper .project-tag-zone .project-tag-grey-3 {
                background-color: #999999; }
              .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .project-image-wrapper .project-tag-zone .project-tag-grey-4 {
                background-color: #777777; }
              .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .project-image-wrapper .project-tag-zone .project-tag-grey-5 {
                background-color: #666666; }
              .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .project-image-wrapper .project-tag-zone .project-tag-grey-6 {
                background-color: #333333; }
              .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .project-image-wrapper .project-tag-zone .project-tag-white {
                background-color: #ffffff; }
              .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .project-image-wrapper .project-tag-zone .project-tag-black {
                background-color: #000000; }
              .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .project-image-wrapper .project-tag-zone .project-tag-light-grey {
                background-color: #4a4a4a; }
              .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .project-image-wrapper .project-tag-zone .project-tag-light-pink {
                background-color: #fef0ef; }
              .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .project-image-wrapper .project-tag-zone .project-tag-pink {
                background-color: #fabeb9; }
        .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .project-action-wrapper {
          display: flex;
          flex-direction: row;
          width: calc(100vw - 40px);
          justify-content: space-between;
          align-items: flex-start; }
          .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .project-action-wrapper .information {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start; }
            .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .project-action-wrapper .information .header {
              font-size: 14px;
              line-height: 20px;
              font-weight: 500;
              color: #666666;
              text-align: left; }
            .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .project-action-wrapper .information .subheader {
              font-size: 14px;
              line-height: 20px;
              font-weight: 600;
              color: #000000;
              text-align: left; }
            .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .project-action-wrapper .information .return {
              color: #ff6600;
              font-size: 18px; }
            .project-listing-page-wrapper .mobile-project-card-zone .project-information-wrapper .project-action-wrapper .information .amount {
              font-size: 18px;
              color: #000000;
              font-weight: 600; }
    .project-listing-page-wrapper .load-more-zone {
      width: 100%;
      margin: 30px auto;
      display: flex;
      flex-direction: row;
      justify-content: center; }
      .project-listing-page-wrapper .load-more-zone .load-more-button {
        height: 50px; } }
