/* Global style guide for UI Lib */
/**
 * Browser support
 */
/**
 * Typography
 */
@import url("https://fonts.googleapis.com/css?family=Rajdhani:300,400,500,600,700&subset=latin-ext");
/**
 * Colors
 */
/**
 * Page -- UILib Specific Variables
 */
/**
 * Card -- UILib Specific Variables
 */
@media only screen and (min-width: 769px) {
  .investment-page-wrapper {
    box-sizing: border-box;
    width: 1130px;
    margin: 0 auto;
    padding-top: 40px; }
    .investment-page-wrapper .loading-wrapper {
      width: 100%;
      height: calc(100vh - 140px);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
    .investment-page-wrapper .tags-wrapper {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 10px; }
      .investment-page-wrapper .tags-wrapper .tag {
        font-size: 14px;
        line-height: 18px;
        font-weight: 600;
        text-align: center;
        background-color: #eaeaea;
        color: #777777;
        padding: 4px 10px;
        border-radius: 3px;
        margin-right: 10px; }
    .investment-page-wrapper .header-zone-wrapper {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      margin-bottom: 60px; }
      .investment-page-wrapper .header-zone-wrapper .header-zone {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start; }
        .investment-page-wrapper .header-zone-wrapper .header-zone a {
          text-decoration: none; }
        .investment-page-wrapper .header-zone-wrapper .header-zone .header {
          font-size: 44px;
          color: #000000;
          font-weight: 700; }
          .investment-page-wrapper .header-zone-wrapper .header-zone .header:hover {
            text-decoration: underline; }
        .investment-page-wrapper .header-zone-wrapper .header-zone .subheader {
          font-size: 16px;
          line-height: 24px;
          color: #000000;
          font-weight: 600; }
      .investment-page-wrapper .header-zone-wrapper .statistics-zone .project-statistics {
        border-radius: 3px;
        padding: 15px 0;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: #ffffff; }
        .investment-page-wrapper .header-zone-wrapper .statistics-zone .project-statistics .viewer-zone {
          padding: 0 30px;
          border-right: 1px solid #eaeaea; }
          .investment-page-wrapper .header-zone-wrapper .statistics-zone .project-statistics .viewer-zone .header {
            font-size: 24px;
            line-height: 1;
            font-weight: 700; }
          .investment-page-wrapper .header-zone-wrapper .statistics-zone .project-statistics .viewer-zone .subheader {
            font-size: 16px;
            font-weight: 500; }
        .investment-page-wrapper .header-zone-wrapper .statistics-zone .project-statistics .backer-zone {
          padding: 0 30px; }
          .investment-page-wrapper .header-zone-wrapper .statistics-zone .project-statistics .backer-zone .header {
            font-size: 24px;
            line-height: 1;
            font-weight: 700; }
          .investment-page-wrapper .header-zone-wrapper .statistics-zone .project-statistics .backer-zone .subheader {
            font-size: 16px;
            font-weight: 500; }
    .investment-page-wrapper .require-login-zone-wrapper {
      width: 100%;
      padding-bottom: 100px; }
      .investment-page-wrapper .require-login-zone-wrapper .require-login {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center; }
        .investment-page-wrapper .require-login-zone-wrapper .require-login .header {
          font-size: 36px;
          color: #4a4a4a;
          font-weight: 700; }
        .investment-page-wrapper .require-login-zone-wrapper .require-login .subheader {
          font-size: 18px;
          line-height: 26px;
          color: #4a4a4a;
          font-weight: 500;
          margin: 0 45px; }
        .investment-page-wrapper .require-login-zone-wrapper .require-login .actions {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          align-items: center; }
          .investment-page-wrapper .require-login-zone-wrapper .require-login .actions .action-button {
            height: 60px;
            width: 175px; }
    .investment-page-wrapper .start-step-loading-wrapper {
      width: 100%;
      height: calc(100vh - 400px);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
    .investment-page-wrapper .investment-page-amount-wrapper {
      max-width: 1130px;
      margin: 0 auto 0 auto;
      display: flex;
      padding-bottom: 100px;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between; }
      .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper {
        width: 800px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start; }
        .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-general-information-zone {
          width: 100%;
          height: 105px;
          border-radius: 5px;
          background-color: #ffffff;
          box-sizing: border-box;
          padding: 20px 50px;
          margin-bottom: 80px;
          display: flex;
          flex-direction: row;
          justify-content: flex-start; }
          .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-general-information-zone .information {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            margin-right: 30px; }
            .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-general-information-zone .information:last-child {
              margin-left: auto;
              align-items: flex-end;
              margin-right: 0px; }
            .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-general-information-zone .information .subheader {
              font-size: 16px;
              line-height: 26px;
              font-weight: 500; }
            .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-general-information-zone .information .header {
              font-size: 30px;
              font-weight: 600; }
        .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-amount-selection-panel {
          position: relative;
          width: 100%;
          margin-bottom: 20px; }
          .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-amount-selection-panel .timer-zone {
            box-sizing: border-box;
            position: absolute;
            top: -40px;
            left: 0;
            height: 60px;
            width: 280px;
            background-color: #0033ff;
            border-radius: 8px;
            z-index: 1;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 13px; }
            .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-amount-selection-panel .timer-zone .timer {
              width: 40px;
              font-size: 18px !important;
              font-weight: 700 !important;
              margin-right: 15px; }
            .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-amount-selection-panel .timer-zone .text {
              font-size: 18px;
              font-weight: 500; }
          .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-amount-selection-panel .investment-pane {
            position: relative;
            z-index: 10;
            width: 100%; }
            .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-amount-selection-panel .investment-pane .investment-selection-zone {
              width: 100%;
              height: 430px;
              background-color: #ffffff;
              border-radius: 5px;
              display: flex;
              flex-direction: column;
              align-items: center;
              box-sizing: border-box;
              padding: 80px 50px; }
              .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-amount-selection-panel .investment-pane .investment-selection-zone .investment-pricing-table {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-end;
                margin-bottom: 60px; }
                .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-amount-selection-panel .investment-pane .investment-selection-zone .investment-pricing-table .investment-pricing-active-item {
                  background-color: #ff6600 !important;
                  border-color: #ff6600 !important;
                  color: #ffffff !important; }
                .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-amount-selection-panel .investment-pane .investment-selection-zone .investment-pricing-table .investment-pricing-item {
                  height: 100px;
                  width: 100px;
                  border: 1px solid #777777;
                  border-radius: 4px;
                  color: #777777;
                  position: relative;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  cursor: pointer; }
                  .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-amount-selection-panel .investment-pane .investment-selection-zone .investment-pricing-table .investment-pricing-item .pricing-header {
                    font-size: 20px;
                    line-height: 20px;
                    font-weight: 700;
                    color: inherit;
                    margin: 0; }
                  .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-amount-selection-panel .investment-pane .investment-selection-zone .investment-pricing-table .investment-pricing-item .pricing-subheader {
                    font-size: 20px;
                    line-height: 20px;
                    font-weight: 500;
                    color: inherit; }
                  .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-amount-selection-panel .investment-pane .investment-selection-zone .investment-pricing-table .investment-pricing-item .preferred {
                    position: absolute;
                    top: -30px;
                    height: 25px;
                    width: 100%;
                    border: 1px solid #ff6600;
                    border-radius: 3px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    font-size: 14px;
                    line-height: 18px;
                    color: #ff6600;
                    font-weight: 600; }
              .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-amount-selection-panel .investment-pane .investment-selection-zone .investment-pricing-calculation-area {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center; }
                .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-amount-selection-panel .investment-pane .investment-selection-zone .investment-pricing-calculation-area .header {
                  font-size: 16px;
                  font-weight: 500;
                  margin-bottom: 10px; }
                .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-amount-selection-panel .investment-pane .investment-selection-zone .investment-pricing-calculation-area .form-zone {
                  display: flex;
                  flex-direction: row;
                  justify-content: flex-start;
                  align-items: center;
                  margin-bottom: 10px; }
                  .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-amount-selection-panel .investment-pane .investment-selection-zone .investment-pricing-calculation-area .form-zone .input {
                    width: 200px;
                    height: 50px; }
                    .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-amount-selection-panel .investment-pane .investment-selection-zone .investment-pricing-calculation-area .form-zone .input:first-child {
                      margin-right: 20px; }
                .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-amount-selection-panel .investment-pane .investment-selection-zone .investment-pricing-calculation-area .subheader-zone {
                  cursor: pointer; }
                  .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-amount-selection-panel .investment-pane .investment-selection-zone .investment-pricing-calculation-area .subheader-zone .subheader {
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: 300;
                    text-decoration: underline; }
        .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-total-calculation-panel {
          width: 100%;
          height: 105px;
          border-radius: 5px;
          background-color: #ffffff;
          box-sizing: border-box;
          padding: 20px 50px;
          margin-bottom: 80px;
          display: flex;
          flex-direction: row;
          justify-content: flex-start; }
          .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-total-calculation-panel .information {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            margin-right: 30px; }
            .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-total-calculation-panel .information:last-child {
              margin-left: auto;
              align-items: flex-end;
              margin-right: 0px; }
            .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-total-calculation-panel .information .subheader {
              font-size: 20px;
              font-weight: 500; }
            .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-total-calculation-panel .information .header {
              font-size: 36px;
              font-weight: 700; }
        .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-action-zone {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: flex-end; }
          .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-action-zone .action {
            width: 240px;
            height: 60px;
            font-size: 24px;
            font-weight: 600; }
      .investment-page-wrapper .investment-page-amount-wrapper .wallet-pane {
        width: 230px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start; }
        .investment-page-wrapper .investment-page-amount-wrapper .wallet-pane .action-buttons {
          display: flex;
          flex-direction: row;
          margin-top: 20px;
          justify-content: space-around; }
          .investment-page-wrapper .investment-page-amount-wrapper .wallet-pane .action-buttons .button {
            width: 100px;
            padding: 7px 7px;
            font-weight: 600; }
        .investment-page-wrapper .investment-page-amount-wrapper .wallet-pane .header-section {
          margin: 20px 0 20px 0; }
        .investment-page-wrapper .investment-page-amount-wrapper .wallet-pane .wallet-margin {
          margin-bottom: 20px; }
        .investment-page-wrapper .investment-page-amount-wrapper .wallet-pane .management-action {
          cursor: pointer; }
          .investment-page-wrapper .investment-page-amount-wrapper .wallet-pane .management-action:hover {
            text-decoration: underline; }
    .investment-page-wrapper .investment-page-confirm-wrapper {
      max-width: 1130px;
      margin: 0 auto 0 auto;
      display: flex;
      padding-bottom: 100px;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between; }
      .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper {
        width: 800px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start; }
        .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-general-information-zone {
          width: 100%;
          height: 105px;
          border-radius: 5px;
          background-color: #ffffff;
          box-sizing: border-box;
          padding: 20px 50px;
          margin-bottom: 80px;
          display: flex;
          flex-direction: row;
          justify-content: flex-start; }
          .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-general-information-zone .information {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            margin-right: 30px; }
            .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-general-information-zone .information:last-child {
              margin-left: auto;
              align-items: flex-end;
              margin-right: 0px; }
            .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-general-information-zone .information .subheader {
              font-size: 16px;
              line-height: 26px;
              font-weight: 500; }
            .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-general-information-zone .information .header {
              font-size: 30px;
              font-weight: 600; }
        .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-confirm-selection-panel {
          position: relative;
          width: 100%;
          margin-bottom: 20px; }
          .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-confirm-selection-panel .timer-zone {
            box-sizing: border-box;
            position: absolute;
            top: -40px;
            left: 0;
            height: 60px;
            width: 280px;
            background-color: #0033ff;
            border-radius: 8px;
            z-index: 1;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 13px; }
            .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-confirm-selection-panel .timer-zone .timer {
              width: 40px;
              font-size: 18px !important;
              font-weight: 700 !important;
              margin-right: 15px; }
            .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-confirm-selection-panel .timer-zone .text {
              font-size: 18px;
              font-weight: 500; }
          .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-confirm-selection-panel .confirm-pane {
            position: relative;
            z-index: 10;
            width: 100%; }
            .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-confirm-selection-panel .confirm-pane .confirm-information-zone {
              width: 100%;
              height: 310px;
              background-color: #ffffff;
              border-radius: 5px;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              box-sizing: border-box;
              padding: 30px 40px; }
              .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-confirm-selection-panel .confirm-pane .confirm-information-zone .information-line {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: flex-start; }
                .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-confirm-selection-panel .confirm-pane .confirm-information-zone .information-line:first-child {
                  margin-bottom: 40px; }
                .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-confirm-selection-panel .confirm-pane .confirm-information-zone .information-line:nth-child(2) {
                  margin-bottom: 20px; }
                .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-confirm-selection-panel .confirm-pane .confirm-information-zone .information-line .main-information {
                  display: flex;
                  flex-direction: column;
                  justify-content: flex-start;
                  align-items: flex-start;
                  margin-right: 40px; }
                  .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-confirm-selection-panel .confirm-pane .confirm-information-zone .information-line .main-information .subheader {
                    font-size: 20px;
                    font-weight: 500; }
                  .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-confirm-selection-panel .confirm-pane .confirm-information-zone .information-line .main-information .header {
                    font-size: 36px;
                    font-weight: 700; }
                .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-confirm-selection-panel .confirm-pane .confirm-information-zone .information-line .supplementary-information {
                  display: flex;
                  flex-direction: column;
                  justify-content: flex-start;
                  align-items: flex-start;
                  margin-right: 40px; }
                  .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-confirm-selection-panel .confirm-pane .confirm-information-zone .information-line .supplementary-information .subheader {
                    font-size: 16px;
                    line-height: 20px;
                    font-weight: 500; }
                  .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-confirm-selection-panel .confirm-pane .confirm-information-zone .information-line .supplementary-information .header {
                    font-size: 20px;
                    font-weight: 600; }
        .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-confirmation-total-panel {
          width: 100%;
          height: 105px;
          border-radius: 5px;
          background-color: #ffffff;
          box-sizing: border-box;
          padding: 20px 50px;
          margin-bottom: 40px;
          display: flex;
          flex-direction: row;
          justify-content: flex-end; }
          .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-confirmation-total-panel .information {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end; }
            .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-confirmation-total-panel .information .subheader {
              font-size: 20px;
              font-weight: 500; }
            .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-confirmation-total-panel .information .header {
              font-size: 36px;
              font-weight: 700; }
        .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-action-zone {
          width: 100%; }
          .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-action-zone form {
            width: 100%; }
            .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-action-zone form .form-container {
              width: 100%;
              display: flex;
              flex-direction: row;
              justify-content: flex-end;
              align-items: flex-end; }
              .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-action-zone form .form-container .aggrements {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                margin-right: 50px;
                box-sizing: border-box;
                padding-bottom: 5px; }
                .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-action-zone form .form-container .aggrements .checkbox {
                  margin-bottom: 10px !important; }
                  .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-action-zone form .form-container .aggrements .checkbox:last-child {
                    margin-bottom: 0px !important; }
              .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-action-zone form .form-container .action {
                width: 240px;
                height: 60px;
                font-size: 24px;
                font-weight: 600; }
      .investment-page-wrapper .investment-page-confirm-wrapper .wallet-pane {
        width: 230px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start; }
        .investment-page-wrapper .investment-page-confirm-wrapper .wallet-pane .action-buttons {
          display: flex;
          flex-direction: row;
          margin-top: 20px;
          justify-content: space-around; }
          .investment-page-wrapper .investment-page-confirm-wrapper .wallet-pane .action-buttons .button {
            width: 100px;
            padding: 7px 7px;
            font-weight: 600; }
        .investment-page-wrapper .investment-page-confirm-wrapper .wallet-pane .header-section {
          margin: 20px 0 20px 0; }
        .investment-page-wrapper .investment-page-confirm-wrapper .wallet-pane .wallet-margin {
          margin-bottom: 20px; }
        .investment-page-wrapper .investment-page-confirm-wrapper .wallet-pane .management-action {
          cursor: pointer; }
          .investment-page-wrapper .investment-page-confirm-wrapper .wallet-pane .management-action:hover {
            text-decoration: underline; }
    .investment-page-wrapper .investment-page-result-wrapper {
      max-width: 1130px;
      margin: 0 auto 0 auto;
      display: flex;
      padding-bottom: 100px;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between; }
      .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper {
        width: 800px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start; }
        .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-result-success-header-zone {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 60px; }
          .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-result-success-header-zone .icon {
            font-size: 100px;
            margin-left: 40px;
            margin-right: 40px; }
          .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-result-success-header-zone .text-area {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start; }
            .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-result-success-header-zone .text-area .header {
              font-size: 30px;
              color: #4a4a4a;
              font-weight: 700; }
            .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-result-success-header-zone .text-area .subheader {
              text-align: left;
              font-size: 18px;
              line-height: 26px;
              color: #4a4a4a;
              font-weight: 500; }
        .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-result-selection-panel {
          position: relative;
          width: 100%;
          margin-bottom: 20px; }
          .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-result-selection-panel .result-pane {
            position: relative;
            z-index: 10;
            width: 100%; }
            .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-result-selection-panel .result-pane .result-information-zone {
              width: 100%;
              height: 310px;
              background-color: #ffffff;
              border-radius: 5px;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              box-sizing: border-box;
              padding: 30px 40px; }
              .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-result-selection-panel .result-pane .result-information-zone .information-line {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: flex-start; }
                .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-result-selection-panel .result-pane .result-information-zone .information-line:first-child {
                  margin-bottom: 40px; }
                .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-result-selection-panel .result-pane .result-information-zone .information-line:nth-child(2) {
                  margin-bottom: 20px; }
                .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-result-selection-panel .result-pane .result-information-zone .information-line .main-information {
                  display: flex;
                  flex-direction: column;
                  justify-content: flex-start;
                  align-items: flex-start;
                  margin-right: 40px; }
                  .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-result-selection-panel .result-pane .result-information-zone .information-line .main-information .subheader {
                    font-size: 20px;
                    font-weight: 500; }
                  .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-result-selection-panel .result-pane .result-information-zone .information-line .main-information .header {
                    font-size: 36px;
                    font-weight: 700; }
                .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-result-selection-panel .result-pane .result-information-zone .information-line .pull-left-information {
                  margin-left: auto;
                  align-items: flex-end; }
                .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-result-selection-panel .result-pane .result-information-zone .information-line .supplementary-information {
                  display: flex;
                  flex-direction: column;
                  justify-content: flex-start;
                  align-items: flex-start;
                  margin-right: 40px; }
                  .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-result-selection-panel .result-pane .result-information-zone .information-line .supplementary-information .subheader {
                    font-size: 16px;
                    line-height: 20px;
                    font-weight: 500; }
                  .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-result-selection-panel .result-pane .result-information-zone .information-line .supplementary-information .header {
                    font-size: 20px;
                    font-weight: 600; }
        .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-confirmation-total-panel {
          width: 100%;
          height: 105px;
          border-radius: 5px;
          background-color: #ffffff;
          box-sizing: border-box;
          padding: 20px 50px;
          margin-bottom: 40px;
          display: flex;
          flex-direction: row;
          justify-content: flex-end; }
          .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-confirmation-total-panel .information {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end; }
            .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-confirmation-total-panel .information .subheader {
              font-size: 20px;
              font-weight: 500; }
            .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-confirmation-total-panel .information .header {
              font-size: 36px;
              font-weight: 700; }
        .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-general-information-zone {
          width: 100%;
          height: 105px;
          border-radius: 5px;
          background-color: #ffffff;
          box-sizing: border-box;
          padding: 20px 50px;
          margin-bottom: 80px;
          display: flex;
          flex-direction: row;
          justify-content: flex-start; }
          .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-general-information-zone .information {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            margin-right: 30px; }
            .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-general-information-zone .information:last-child {
              margin-left: auto;
              align-items: flex-end;
              margin-right: 0px; }
            .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-general-information-zone .information .subheader {
              font-size: 16px;
              line-height: 26px;
              font-weight: 500; }
            .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-general-information-zone .information .header {
              font-size: 30px;
              font-weight: 600; }
        .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-action-zone {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: flex-end; }
          .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-action-zone .action {
            width: 240px;
            height: 60px;
            font-size: 24px;
            font-weight: 600; }
      .investment-page-wrapper .investment-page-result-wrapper .wallet-pane {
        width: 230px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start; }
        .investment-page-wrapper .investment-page-result-wrapper .wallet-pane .action-buttons {
          display: flex;
          flex-direction: row;
          margin-top: 20px;
          justify-content: space-around; }
          .investment-page-wrapper .investment-page-result-wrapper .wallet-pane .action-buttons .button {
            width: 100px;
            padding: 7px 7px;
            font-weight: 600; }
        .investment-page-wrapper .investment-page-result-wrapper .wallet-pane .header-section {
          margin: 20px 0 20px 0; }
        .investment-page-wrapper .investment-page-result-wrapper .wallet-pane .wallet-margin {
          margin-bottom: 20px; }
        .investment-page-wrapper .investment-page-result-wrapper .wallet-pane .management-action {
          cursor: pointer; }
          .investment-page-wrapper .investment-page-result-wrapper .wallet-pane .management-action:hover {
            text-decoration: underline; }
    .investment-page-wrapper .investment-insufficient-funds-wrapper {
      max-width: 1130px;
      margin: 0 auto 0 auto;
      display: flex;
      padding-bottom: 100px;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between; }
      .investment-page-wrapper .investment-insufficient-funds-wrapper .investment-insufficient-funds-left-pane-wrapper {
        width: 800px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start; }
        .investment-page-wrapper .investment-insufficient-funds-wrapper .investment-insufficient-funds-left-pane-wrapper .investment-general-information-zone {
          width: 100%;
          height: 105px;
          border-radius: 5px;
          background-color: #ffffff;
          box-sizing: border-box;
          padding: 20px 50px;
          margin-bottom: 80px;
          display: flex;
          flex-direction: row;
          justify-content: flex-start; }
          .investment-page-wrapper .investment-insufficient-funds-wrapper .investment-insufficient-funds-left-pane-wrapper .investment-general-information-zone .information {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            margin-right: 30px; }
            .investment-page-wrapper .investment-insufficient-funds-wrapper .investment-insufficient-funds-left-pane-wrapper .investment-general-information-zone .information:last-child {
              margin-left: auto;
              align-items: flex-end;
              margin-right: 0px; }
            .investment-page-wrapper .investment-insufficient-funds-wrapper .investment-insufficient-funds-left-pane-wrapper .investment-general-information-zone .information .subheader {
              font-size: 16px;
              line-height: 26px;
              font-weight: 500; }
            .investment-page-wrapper .investment-insufficient-funds-wrapper .investment-insufficient-funds-left-pane-wrapper .investment-general-information-zone .information .header {
              font-size: 30px;
              font-weight: 600; }
        .investment-page-wrapper .investment-insufficient-funds-wrapper .investment-insufficient-funds-left-pane-wrapper .investment-deposit-selection-panel {
          position: relative;
          width: 100%;
          margin-bottom: 20px; }
          .investment-page-wrapper .investment-insufficient-funds-wrapper .investment-insufficient-funds-left-pane-wrapper .investment-deposit-selection-panel .insufficient-funds-pane {
            position: relative;
            z-index: 10;
            width: 100%; }
            .investment-page-wrapper .investment-insufficient-funds-wrapper .investment-insufficient-funds-left-pane-wrapper .investment-deposit-selection-panel .insufficient-funds-pane .investment-selection-zone {
              width: 100%;
              height: 470px;
              background-color: #ffffff;
              border-radius: 5px;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              box-sizing: border-box;
              padding: 40px 30px; }
              .investment-page-wrapper .investment-insufficient-funds-wrapper .investment-insufficient-funds-left-pane-wrapper .investment-deposit-selection-panel .insufficient-funds-pane .investment-selection-zone .header {
                font-size: 30px;
                font-weight: 700; }
              .investment-page-wrapper .investment-insufficient-funds-wrapper .investment-insufficient-funds-left-pane-wrapper .investment-deposit-selection-panel .insufficient-funds-pane .investment-selection-zone .subheader {
                text-align: left;
                font-size: 18px;
                line-height: 26px;
                font-weight: 500;
                margin-bottom: 60px; }
              .investment-page-wrapper .investment-insufficient-funds-wrapper .investment-insufficient-funds-left-pane-wrapper .investment-deposit-selection-panel .insufficient-funds-pane .investment-selection-zone .deposit-area {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                width: 100%; }
                .investment-page-wrapper .investment-insufficient-funds-wrapper .investment-insufficient-funds-left-pane-wrapper .investment-deposit-selection-panel .insufficient-funds-pane .investment-selection-zone .deposit-area .button-zone {
                  width: 200px;
                  height: 200px;
                  border: 1px solid #eaeaea;
                  border-radius: 8px;
                  cursor: pointer;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center; }
                  .investment-page-wrapper .investment-insufficient-funds-wrapper .investment-insufficient-funds-left-pane-wrapper .investment-deposit-selection-panel .insufficient-funds-pane .investment-selection-zone .deposit-area .button-zone:first-child {
                    margin-right: 25px;
                    cursor: default; }
                  .investment-page-wrapper .investment-insufficient-funds-wrapper .investment-insufficient-funds-left-pane-wrapper .investment-deposit-selection-panel .insufficient-funds-pane .investment-selection-zone .deposit-area .button-zone .button-icon {
                    font-size: 65px;
                    margin-bottom: 20px; }
                  .investment-page-wrapper .investment-insufficient-funds-wrapper .investment-insufficient-funds-left-pane-wrapper .investment-deposit-selection-panel .insufficient-funds-pane .investment-selection-zone .deposit-area .button-zone .button-text {
                    font-size: 20px;
                    font-weight: 600; }
        .investment-page-wrapper .investment-insufficient-funds-wrapper .investment-insufficient-funds-left-pane-wrapper .investment-total-calculation-panel {
          width: 100%;
          height: 105px;
          border-radius: 5px;
          background-color: #ffffff;
          box-sizing: border-box;
          padding: 20px 50px;
          margin-bottom: 80px;
          display: flex;
          flex-direction: row;
          justify-content: flex-start; }
          .investment-page-wrapper .investment-insufficient-funds-wrapper .investment-insufficient-funds-left-pane-wrapper .investment-total-calculation-panel .information {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            margin-right: 30px; }
            .investment-page-wrapper .investment-insufficient-funds-wrapper .investment-insufficient-funds-left-pane-wrapper .investment-total-calculation-panel .information:last-child {
              margin-left: auto;
              align-items: flex-end;
              margin-right: 0px; }
            .investment-page-wrapper .investment-insufficient-funds-wrapper .investment-insufficient-funds-left-pane-wrapper .investment-total-calculation-panel .information .subheader {
              font-size: 20px;
              font-weight: 500; }
            .investment-page-wrapper .investment-insufficient-funds-wrapper .investment-insufficient-funds-left-pane-wrapper .investment-total-calculation-panel .information .header {
              font-size: 36px;
              font-weight: 700; }
        .investment-page-wrapper .investment-insufficient-funds-wrapper .investment-insufficient-funds-left-pane-wrapper .investment-action-zone {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: flex-end; }
          .investment-page-wrapper .investment-insufficient-funds-wrapper .investment-insufficient-funds-left-pane-wrapper .investment-action-zone .action {
            width: 240px;
            height: 60px;
            font-size: 24px;
            font-weight: 600; }
      .investment-page-wrapper .investment-insufficient-funds-wrapper .wallet-pane {
        width: 230px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start; }
        .investment-page-wrapper .investment-insufficient-funds-wrapper .wallet-pane .action-buttons {
          display: flex;
          flex-direction: row;
          margin-top: 20px;
          justify-content: space-around; }
          .investment-page-wrapper .investment-insufficient-funds-wrapper .wallet-pane .action-buttons .button {
            width: 100px;
            padding: 7px 7px;
            font-weight: 600; }
        .investment-page-wrapper .investment-insufficient-funds-wrapper .wallet-pane .header-section {
          margin: 20px 0 20px 0; }
        .investment-page-wrapper .investment-insufficient-funds-wrapper .wallet-pane .wallet-margin {
          margin-bottom: 20px; }
        .investment-page-wrapper .investment-insufficient-funds-wrapper .wallet-pane .management-action {
          cursor: pointer; }
          .investment-page-wrapper .investment-insufficient-funds-wrapper .wallet-pane .management-action:hover {
            text-decoration: underline; } }

@media only screen and (max-width: 768px) {
  .investment-page-wrapper {
    box-sizing: border-box;
    width: 90vw;
    margin: 0 auto;
    padding-top: 30px; }
    .investment-page-wrapper .loading-wrapper {
      width: 100%;
      height: calc(100vh - 100px);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
    .investment-page-wrapper .tags-wrapper {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      flex-wrap: wrap;
      align-items: center;
      margin-bottom: 10px; }
      .investment-page-wrapper .tags-wrapper .tag {
        font-size: 14px;
        line-height: 18px;
        font-weight: 600;
        text-align: center;
        background-color: #eaeaea;
        color: #777777;
        padding: 4px 10px;
        border-radius: 3px;
        margin-right: 10px; }
    .investment-page-wrapper .header-zone-wrapper {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: 20px; }
      .investment-page-wrapper .header-zone-wrapper .header-zone {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start; }
        .investment-page-wrapper .header-zone-wrapper .header-zone a {
          text-decoration: none; }
        .investment-page-wrapper .header-zone-wrapper .header-zone .header {
          font-size: 30px;
          color: #000000;
          font-weight: 700;
          text-align: left; }
          .investment-page-wrapper .header-zone-wrapper .header-zone .header:hover {
            text-decoration: underline; }
        .investment-page-wrapper .header-zone-wrapper .header-zone .subheader {
          font-size: 16px;
          line-height: 24px;
          color: #4a4a4a;
          font-weight: 500; }
      .investment-page-wrapper .header-zone-wrapper .statistics-zone {
        display: none; }
    .investment-page-wrapper .require-login-zone-wrapper {
      width: 100%;
      padding-bottom: 20px; }
      .investment-page-wrapper .require-login-zone-wrapper .require-login {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center; }
        .investment-page-wrapper .require-login-zone-wrapper .require-login .header {
          font-size: 20px;
          color: #4a4a4a;
          font-weight: 700; }
        .investment-page-wrapper .require-login-zone-wrapper .require-login .subheader {
          font-size: 16px;
          line-height: 26px;
          color: #4a4a4a;
          font-weight: 500;
          margin: 0 20px; }
        .investment-page-wrapper .require-login-zone-wrapper .require-login .actions {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          align-items: center; }
          .investment-page-wrapper .require-login-zone-wrapper .require-login .actions .action-button {
            height: 40px;
            width: 120px; }
    .investment-page-wrapper .start-step-loading-wrapper {
      width: 100%;
      height: calc(100vh - 400px);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
    .investment-page-wrapper .investment-page-amount-wrapper {
      max-width: 94vw;
      width: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between; }
      .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start; }
        .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-general-information-zone {
          width: 100%;
          border-radius: 5px;
          background-color: transparent;
          box-sizing: border-box;
          margin-bottom: 80px;
          display: flex;
          flex-direction: row;
          justify-content: space-between; }
          .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-general-information-zone .information {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start; }
            .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-general-information-zone .information .subheader {
              font-size: 14px;
              line-height: 22px;
              font-weight: 500; }
            .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-general-information-zone .information .header {
              font-size: 16px;
              font-weight: 600; }
        .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-amount-selection-panel {
          position: relative;
          width: 100%;
          max-width: 94vw;
          margin-bottom: 20px; }
          .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-amount-selection-panel .timer-zone {
            box-sizing: border-box;
            position: absolute;
            top: -30px;
            left: 0;
            height: 40px;
            width: 240px;
            background-color: #0033ff;
            border-radius: 8px;
            z-index: 1;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding-top: 8px;
            padding-left: 10px; }
            .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-amount-selection-panel .timer-zone .timer {
              width: 40px;
              font-size: 14px !important;
              font-weight: 700 !important;
              margin-right: 15px; }
            .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-amount-selection-panel .timer-zone .text {
              font-size: 14px;
              font-weight: 500; }
          .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-amount-selection-panel .investment-pane {
            position: relative;
            z-index: 10;
            width: 100%; }
            .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-amount-selection-panel .investment-pane .investment-selection-zone {
              width: 100%;
              min-height: 400px;
              background-color: #ffffff;
              border-radius: 5px;
              display: flex;
              flex-direction: column;
              align-items: center;
              box-sizing: border-box;
              padding: 20px;
              padding-top: 70px; }
              .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-amount-selection-panel .investment-pane .investment-selection-zone .investment-pricing-table {
                width: 100%;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-between;
                align-items: flex-end;
                margin-bottom: 20px; }
                .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-amount-selection-panel .investment-pane .investment-selection-zone .investment-pricing-table .investment-pricing-active-item {
                  background-color: #ff6600 !important;
                  border-color: #ff6600 !important;
                  color: #ffffff !important; }
                .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-amount-selection-panel .investment-pane .investment-selection-zone .investment-pricing-table .investment-pricing-item {
                  height: 80px;
                  width: 30%;
                  margin-bottom: 50px;
                  border: 1px solid #777777;
                  border-radius: 4px;
                  color: #777777;
                  position: relative;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  cursor: pointer; }
                  .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-amount-selection-panel .investment-pane .investment-selection-zone .investment-pricing-table .investment-pricing-item .pricing-header {
                    font-size: 16px;
                    line-height: 20px;
                    font-weight: 700;
                    color: inherit;
                    margin: 0; }
                  .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-amount-selection-panel .investment-pane .investment-selection-zone .investment-pricing-table .investment-pricing-item .pricing-subheader {
                    font-size: 16px;
                    line-height: 20px;
                    font-weight: 500;
                    color: inherit; }
                  .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-amount-selection-panel .investment-pane .investment-selection-zone .investment-pricing-table .investment-pricing-item .preferred {
                    position: absolute;
                    top: -30px;
                    height: 25px;
                    width: 100%;
                    border: 1px solid #ff6600;
                    border-radius: 3px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    font-size: 14px;
                    line-height: 18px;
                    color: #ff6600;
                    font-weight: 600; }
              .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-amount-selection-panel .investment-pane .investment-selection-zone .investment-pricing-calculation-area {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center; }
                .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-amount-selection-panel .investment-pane .investment-selection-zone .investment-pricing-calculation-area .header {
                  font-size: 14px;
                  font-weight: 500;
                  margin-bottom: 10px; }
                .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-amount-selection-panel .investment-pane .investment-selection-zone .investment-pricing-calculation-area .form-zone {
                  width: 100%;
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;
                  margin-bottom: 20px; }
                  .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-amount-selection-panel .investment-pane .investment-selection-zone .investment-pricing-calculation-area .form-zone .input {
                    width: 35vw;
                    margin: 0 auto;
                    height: 50px; }
                .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-amount-selection-panel .investment-pane .investment-selection-zone .investment-pricing-calculation-area .subheader-zone {
                  cursor: pointer; }
                  .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-amount-selection-panel .investment-pane .investment-selection-zone .investment-pricing-calculation-area .subheader-zone .subheader {
                    font-size: 16px;
                    line-height: 34px;
                    font-weight: 300;
                    text-decoration: underline; }
        .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-total-calculation-panel {
          width: 100%;
          height: 105px;
          border-radius: 5px;
          background-color: #ffffff;
          box-sizing: border-box;
          padding: 20px 10px;
          margin-bottom: 40px;
          display: flex;
          flex-direction: row;
          justify-content: space-between; }
          .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-total-calculation-panel .information {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center; }
            .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-total-calculation-panel .information .subheader {
              font-size: 14px;
              line-height: 22px;
              font-weight: 500;
              margin-bottom: 10px; }
            .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-total-calculation-panel .information .header {
              font-size: 16px;
              font-weight: 600; }
        .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-action-zone {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: flex-end; }
          .investment-page-wrapper .investment-page-amount-wrapper .amount-page-left-pane-wrapper .investment-action-zone .action {
            width: 94vw;
            height: 50px;
            font-size: 22px;
            font-weight: 600; }
      .investment-page-wrapper .investment-page-amount-wrapper .wallet-pane {
        display: none; }
    .investment-page-wrapper .investment-page-confirm-wrapper {
      max-width: 94vw;
      width: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between; }
      .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start; }
        .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-general-information-zone {
          width: 100%;
          border-radius: 5px;
          background-color: transparent;
          box-sizing: border-box;
          margin-bottom: 80px;
          display: flex;
          flex-direction: row;
          justify-content: space-between; }
          .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-general-information-zone .information {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start; }
            .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-general-information-zone .information .subheader {
              font-size: 14px;
              line-height: 22px;
              font-weight: 500; }
            .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-general-information-zone .information .header {
              font-size: 16px;
              font-weight: 600; }
        .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-confirm-selection-panel {
          position: relative;
          width: 100%;
          margin-bottom: 40px; }
          .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-confirm-selection-panel .timer-zone {
            box-sizing: border-box;
            position: absolute;
            top: -30px;
            left: 0;
            height: 40px;
            width: 240px;
            background-color: #0033ff;
            border-radius: 8px;
            z-index: 1;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding-top: 8px;
            padding-left: 10px; }
            .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-confirm-selection-panel .timer-zone .timer {
              width: 40px;
              font-size: 14px !important;
              font-weight: 700 !important;
              margin-right: 15px; }
            .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-confirm-selection-panel .timer-zone .text {
              font-size: 14px;
              font-weight: 500; }
          .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-confirm-selection-panel .confirm-pane {
            position: relative;
            z-index: 10;
            width: 100%; }
            .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-confirm-selection-panel .confirm-pane .confirm-information-zone {
              width: 100%;
              background-color: #ffffff;
              border-radius: 5px;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              box-sizing: border-box;
              padding: 20px; }
              .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-confirm-selection-panel .confirm-pane .confirm-information-zone .information-line {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-start; }
                .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-confirm-selection-panel .confirm-pane .confirm-information-zone .information-line .main-information {
                  width: 30%;
                  display: flex;
                  flex-direction: column;
                  justify-content: flex-start;
                  align-items: flex-start;
                  margin-bottom: 20px; }
                  .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-confirm-selection-panel .confirm-pane .confirm-information-zone .information-line .main-information .subheader {
                    text-align: left;
                    font-size: 14px;
                    font-weight: 500; }
                  .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-confirm-selection-panel .confirm-pane .confirm-information-zone .information-line .main-information .header {
                    text-align: left;
                    font-size: 16px;
                    font-weight: 700; }
                .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-confirm-selection-panel .confirm-pane .confirm-information-zone .information-line .supplementary-information {
                  display: flex;
                  flex-direction: column;
                  justify-content: flex-start;
                  align-items: flex-start;
                  margin-bottom: 20px; }
                  .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-confirm-selection-panel .confirm-pane .confirm-information-zone .information-line .supplementary-information .subheader {
                    font-size: 14px;
                    line-height: 20px;
                    font-weight: 500; }
                  .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-confirm-selection-panel .confirm-pane .confirm-information-zone .information-line .supplementary-information .header {
                    font-size: 16px;
                    font-weight: 600; }
        .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-confirmation-total-panel {
          display: none; }
        .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-action-zone {
          width: 100%; }
          .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-action-zone form {
            width: 100%; }
            .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-action-zone form .form-container {
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: center; }
              .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-action-zone form .form-container .aggrements {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                box-sizing: border-box;
                padding-bottom: 5px; }
                .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-action-zone form .form-container .aggrements .checkbox {
                  margin-bottom: 10px !important; }
                  .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-action-zone form .form-container .aggrements .checkbox:last-child {
                    margin-bottom: 0px !important; }
              .investment-page-wrapper .investment-page-confirm-wrapper .confirm-page-left-pane-wrapper .investment-action-zone form .form-container .action {
                margin-top: 20px;
                width: 94vw;
                height: 50px;
                font-size: 22px;
                font-weight: 600; }
      .investment-page-wrapper .investment-page-confirm-wrapper .wallet-pane {
        display: none; }
    .investment-page-wrapper .investment-page-result-wrapper {
      max-width: 94vw;
      width: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between; }
      .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start; }
        .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-result-success-header-zone {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 40px; }
          .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-result-success-header-zone .icon {
            font-size: 30px;
            margin-right: 15px; }
          .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-result-success-header-zone .text-area {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start; }
            .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-result-success-header-zone .text-area .header {
              text-align: left;
              font-size: 16px;
              color: #4a4a4a;
              font-weight: 700;
              margin-bottom: 10px; }
            .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-result-success-header-zone .text-area .subheader {
              text-align: left;
              font-size: 14px;
              line-height: 18px;
              color: #4a4a4a;
              font-weight: 500; }
        .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-result-selection-panel {
          position: relative;
          width: 100%;
          margin-bottom: 20px; }
          .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-result-selection-panel .result-pane {
            position: relative;
            z-index: 10;
            width: 100%; }
            .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-result-selection-panel .result-pane .result-information-zone {
              width: 100%;
              background-color: #ffffff;
              border-radius: 5px;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              box-sizing: border-box;
              padding: 20px; }
              .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-result-selection-panel .result-pane .result-information-zone .information-line {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-start; }
                .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-result-selection-panel .result-pane .result-information-zone .information-line .main-information {
                  width: 30%;
                  display: flex;
                  flex-direction: column;
                  justify-content: flex-start;
                  align-items: flex-start;
                  margin-bottom: 20px; }
                  .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-result-selection-panel .result-pane .result-information-zone .information-line .main-information .subheader {
                    text-align: left;
                    font-size: 14px;
                    font-weight: 500; }
                  .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-result-selection-panel .result-pane .result-information-zone .information-line .main-information .header {
                    text-align: left;
                    font-size: 16px;
                    font-weight: 700; }
                .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-result-selection-panel .result-pane .result-information-zone .information-line .supplementary-information {
                  display: flex;
                  flex-direction: column;
                  justify-content: flex-start;
                  align-items: flex-start;
                  margin-bottom: 20px; }
                  .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-result-selection-panel .result-pane .result-information-zone .information-line .supplementary-information .subheader {
                    font-size: 14px;
                    line-height: 20px;
                    font-weight: 500; }
                  .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-result-selection-panel .result-pane .result-information-zone .information-line .supplementary-information .header {
                    font-size: 16px;
                    font-weight: 600; }
        .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-confirmation-total-panel {
          width: 100%;
          height: 105px;
          border-radius: 5px;
          background-color: #ffffff;
          box-sizing: border-box;
          padding: 20px;
          margin-bottom: 40px;
          display: flex;
          flex-direction: row;
          justify-content: flex-end; }
          .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-confirmation-total-panel .information {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end; }
            .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-confirmation-total-panel .information .subheader {
              font-size: 14px;
              font-weight: 500; }
            .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-confirmation-total-panel .information .header {
              font-size: 16px;
              font-weight: 700; }
        .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-general-information-zone {
          width: 100%;
          border-radius: 5px;
          background-color: #ffffff;
          box-sizing: border-box;
          padding: 20px;
          margin-bottom: 40px;
          display: flex;
          flex-direction: row;
          justify-content: flex-start; }
          .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-general-information-zone .information {
            display: flex;
            flex-direction: column;
            justify-content: space-between; }
            .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-general-information-zone .information .subheader {
              font-size: 14px;
              line-height: 22px;
              font-weight: 500;
              margin-bottom: 10px; }
            .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-general-information-zone .information .header {
              font-size: 16px;
              font-weight: 600; }
        .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-action-zone {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: flex-end; }
          .investment-page-wrapper .investment-page-result-wrapper .investment-page-result-left-pane-wrapper .investment-action-zone .action {
            width: 94vw;
            height: 50px;
            font-size: 22px;
            font-weight: 600; }
      .investment-page-wrapper .investment-page-result-wrapper .wallet-pane {
        display: none; }
    .investment-page-wrapper .investment-insufficient-funds-wrapper {
      max-width: 94vw;
      width: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between; }
      .investment-page-wrapper .investment-insufficient-funds-wrapper .investment-insufficient-funds-left-pane-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start; }
        .investment-page-wrapper .investment-insufficient-funds-wrapper .investment-insufficient-funds-left-pane-wrapper .investment-general-information-zone {
          width: 100%;
          border-radius: 5px;
          background-color: #ffffff;
          box-sizing: border-box;
          padding: 20px;
          margin-bottom: 40px;
          display: flex;
          flex-direction: row;
          justify-content: space-between; }
          .investment-page-wrapper .investment-insufficient-funds-wrapper .investment-insufficient-funds-left-pane-wrapper .investment-general-information-zone .information {
            display: flex;
            flex-direction: column;
            justify-content: space-between; }
            .investment-page-wrapper .investment-insufficient-funds-wrapper .investment-insufficient-funds-left-pane-wrapper .investment-general-information-zone .information .subheader {
              font-size: 14px;
              line-height: 22px;
              font-weight: 500; }
            .investment-page-wrapper .investment-insufficient-funds-wrapper .investment-insufficient-funds-left-pane-wrapper .investment-general-information-zone .information .header {
              font-size: 16px;
              font-weight: 600; }
        .investment-page-wrapper .investment-insufficient-funds-wrapper .investment-insufficient-funds-left-pane-wrapper .investment-deposit-selection-panel {
          position: relative;
          width: 100%;
          margin-bottom: 20px; }
          .investment-page-wrapper .investment-insufficient-funds-wrapper .investment-insufficient-funds-left-pane-wrapper .investment-deposit-selection-panel .insufficient-funds-pane {
            position: relative;
            z-index: 10;
            width: 100%; }
            .investment-page-wrapper .investment-insufficient-funds-wrapper .investment-insufficient-funds-left-pane-wrapper .investment-deposit-selection-panel .insufficient-funds-pane .investment-selection-zone {
              width: 100%;
              background-color: transparent;
              border-radius: 5px;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              box-sizing: border-box;
              padding: 20px; }
              .investment-page-wrapper .investment-insufficient-funds-wrapper .investment-insufficient-funds-left-pane-wrapper .investment-deposit-selection-panel .insufficient-funds-pane .investment-selection-zone .header {
                font-size: 16px;
                font-weight: 700; }
              .investment-page-wrapper .investment-insufficient-funds-wrapper .investment-insufficient-funds-left-pane-wrapper .investment-deposit-selection-panel .insufficient-funds-pane .investment-selection-zone .subheader {
                text-align: left;
                font-size: 14px;
                line-height: 18px;
                font-weight: 500;
                margin-bottom: 30px; }
              .investment-page-wrapper .investment-insufficient-funds-wrapper .investment-insufficient-funds-left-pane-wrapper .investment-deposit-selection-panel .insufficient-funds-pane .investment-selection-zone .deposit-area {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 100%; }
                .investment-page-wrapper .investment-insufficient-funds-wrapper .investment-insufficient-funds-left-pane-wrapper .investment-deposit-selection-panel .insufficient-funds-pane .investment-selection-zone .deposit-area .button-zone {
                  width: 45%;
                  height: 120px;
                  border: 1px solid #eaeaea;
                  border-radius: 8px;
                  cursor: pointer;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center; }
                  .investment-page-wrapper .investment-insufficient-funds-wrapper .investment-insufficient-funds-left-pane-wrapper .investment-deposit-selection-panel .insufficient-funds-pane .investment-selection-zone .deposit-area .button-zone .button-icon {
                    font-size: 40px;
                    margin-bottom: 20px; }
                  .investment-page-wrapper .investment-insufficient-funds-wrapper .investment-insufficient-funds-left-pane-wrapper .investment-deposit-selection-panel .insufficient-funds-pane .investment-selection-zone .deposit-area .button-zone .button-text {
                    font-size: 16px;
                    font-weight: 600;
                    color: #4a4a4a; }
        .investment-page-wrapper .investment-insufficient-funds-wrapper .investment-insufficient-funds-left-pane-wrapper .investment-total-calculation-panel {
          width: 100%;
          border-radius: 5px;
          background-color: #ffffff;
          box-sizing: border-box;
          padding: 20px;
          margin-bottom: 40px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between; }
          .investment-page-wrapper .investment-insufficient-funds-wrapper .investment-insufficient-funds-left-pane-wrapper .investment-total-calculation-panel .information {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center; }
            .investment-page-wrapper .investment-insufficient-funds-wrapper .investment-insufficient-funds-left-pane-wrapper .investment-total-calculation-panel .information .subheader {
              font-size: 14px;
              font-weight: 500; }
            .investment-page-wrapper .investment-insufficient-funds-wrapper .investment-insufficient-funds-left-pane-wrapper .investment-total-calculation-panel .information .header {
              font-size: 16px;
              font-weight: 700; }
        .investment-page-wrapper .investment-insufficient-funds-wrapper .investment-insufficient-funds-left-pane-wrapper .investment-action-zone {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: flex-end; }
          .investment-page-wrapper .investment-insufficient-funds-wrapper .investment-insufficient-funds-left-pane-wrapper .investment-action-zone .action {
            width: 94vw;
            height: 50px;
            font-size: 22px;
            font-weight: 600; }
      .investment-page-wrapper .investment-insufficient-funds-wrapper .wallet-pane {
        display: none; } }
