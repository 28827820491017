/* Global style guide for UI Lib */
/**
 * Browser support
 */
/**
 * Typography
 */
@import url("https://fonts.googleapis.com/css?family=Rajdhani:300,400,500,600,700&subset=latin-ext");
/**
 * Colors
 */
/**
 * Page -- UILib Specific Variables
 */
/**
 * Card -- UILib Specific Variables
 */
.slider {
  position: relative;
  width: 100%;
  height: 100%; }
  .slider .back-arrow {
    position: absolute;
    top: 50%;
    left: 10px;
    color: #ffffff;
    cursor: pointer;
    z-index: 100; }
  .slider .next-arrow {
    position: absolute;
    top: 50%;
    right: 10px;
    color: #ffffff;
    cursor: pointer;
    z-index: 100; }
  .slider .preloader {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; }
  .slider .image {
    position: relative;
    width: 100%;
    max-height: 400px;
    object-fit: cover;
    z-index: 10;
    opacity: 0.2;
    transition: opacity ease-in 0.1s; }
  .slider .image-loaded {
    opacity: 1; }
  .slider .placeholder {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
    .slider .placeholder img {
      height: 30%; }
