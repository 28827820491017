/* Global style guide for UI Lib */
/**
 * Browser support
 */
/**
 * Typography
 */
@import url("https://fonts.googleapis.com/css?family=Rajdhani:300,400,500,600,700&subset=latin-ext");
/**
 * Colors
 */
/**
 * Page -- UILib Specific Variables
 */
/**
 * Card -- UILib Specific Variables
 */
.timeline {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  padding-left: 40px;
  padding-right: 40px; }
  .timeline .event-width-1 {
    width: calc(100% / 1); }
  .timeline .event-width-2 {
    width: calc(100% / 1); }
  .timeline .event-width-3 {
    width: calc(100% / 1); }
  .timeline .event-width-4 {
    width: calc(100% / 1); }
  .timeline .event-width-5 {
    width: calc(100% / 1); }
  .timeline .event-width-6 {
    width: calc(100% / 1); }
  .timeline .event-width-7 {
    width: calc(100% / 1); }
  .timeline .event-width-8 {
    width: calc(100% / 1); }
  .timeline .event-width-9 {
    width: calc(100% / 1); }
  .timeline .event-width-10 {
    width: calc(100% / 1); }
  .timeline .event-width-11 {
    width: calc(100% / 1); }
  .timeline .event-width-12 {
    width: calc(100% / 1); }
  .timeline .event-wrapper {
    box-sizing: border-box;
    position: relative;
    height: 100%;
    z-index: 1; }
    .timeline .event-wrapper .event-circle-past {
      position: absolute;
      top: calc(50% - 9px);
      left: calc(50% - 9px);
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background-color: #0033cc;
      z-index: 100; }
    .timeline .event-wrapper .event-circle-future {
      position: absolute;
      top: calc(50% - 9px);
      left: calc(50% - 9px);
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background-color: #cccccc;
      z-index: 100; }
    .timeline .event-wrapper .event-circle-current-shadow {
      position: absolute;
      top: calc(50% - 21px);
      left: calc(50% - 21px);
      width: 42px;
      height: 42px;
      border-radius: 50%;
      background-color: #6699ff;
      opacity: 0.4; }
    .timeline .event-wrapper .event-circle-sale {
      position: absolute;
      top: calc(50% - 9px);
      left: calc(50% - 9px);
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background-color: #ff6600;
      z-index: 100; }
    .timeline .event-wrapper .event-circle-sale-shadow {
      position: absolute;
      top: calc(50% - 21px);
      left: calc(50% - 21px);
      width: 42px;
      height: 42px;
      border-radius: 50%;
      background-color: #ff9966;
      opacity: 0.4; }
    .timeline .event-wrapper .event-line-past {
      position: absolute;
      top: calc(50% - 0.5px);
      width: 100%;
      height: 1px;
      background-color: #0033cc; }
    .timeline .event-wrapper .event-line-future {
      position: absolute;
      top: calc(50% - 0.5px);
      width: 100%;
      height: 1px;
      background-color: #cccccc; }
    .timeline .event-wrapper .event-text-top {
      position: absolute;
      top: calc(20%);
      width: 100%; }
    .timeline .event-wrapper .event-text-bottom {
      position: absolute;
      bottom: calc(20%);
      width: 100%; }
    .timeline .event-wrapper .event-text-header {
      font-size: 16px;
      line-break: 20px;
      font-weight: 600;
      text-align: left;
      margin-bottom: 5px; }
    .timeline .event-wrapper .event-text-header-past {
      color: #000000; }
    .timeline .event-wrapper .event-text-header-future {
      color: #777777; }
    .timeline .event-wrapper .event-text-header-current {
      color: #0033ff; }
    .timeline .event-wrapper .event-text-header-indicated {
      color: #ff6600; }
    .timeline .event-wrapper .event-text-content {
      font-size: 14px;
      line-break: 20px;
      font-weight: 500;
      text-align: left; }
    .timeline .event-wrapper .event-text-content-past {
      color: #000000; }
    .timeline .event-wrapper .event-text-content-future {
      color: #999999; }
