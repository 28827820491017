/* Global style guide for UI Lib */
/**
 * Browser support
 */
/**
 * Typography
 */
@import url("https://fonts.googleapis.com/css?family=Rajdhani:300,400,500,600,700&subset=latin-ext");
/**
 * Colors
 */
/**
 * Page -- UILib Specific Variables
 */
/**
 * Card -- UILib Specific Variables
 */
.select-search-box {
  width: 300px;
  height: 50px;
  position: relative;
  margin-bottom: 30px;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid #cccccc;
  outline: none;
  text-align: left; }
  .select-search-box:focus {
    border-color: #3366ff; }

.select-search-box--multiple {
  border-radius: 4px; }

.select-search-box::after {
  display: none; }

.select-search-box--multiple::after,
.select-search-box--focus::after {
  display: none; }

.select-search-box__icon--disabled::after {
  content: none !important; }

.select-search-box--input::after {
  display: none !important; }

.select-search-box__out {
  display: none; }

.select-search-box__search {
  display: block;
  width: 100%;
  height: 50px;
  border: none;
  background: none;
  outline: none;
  font-size: 16px;
  padding: 0 15px;
  color: #4a4a4a;
  -webkit-appearance: none;
  box-sizing: border-box;
  position: relative;
  z-index: 2;
  cursor: pointer;
  line-height: 50px; }

input.select-search-box__search {
  line-height: 1; }

.select-search-box--multiple .select-search-box__search {
  box-shadow: none; }

.select-search-box--input .select-search-box__search {
  cursor: text; }

.select-search-box__search:focus {
  cursor: text; }

.select-search-box__search--placeholder {
  font-weight: normal; }

.select-search-box input::-webkit-input-placeholder {
  color: #999999;
  font-weight: normal; }

.select-search-box input::-moz-placeholder {
  color: #999999;
  font-weight: normal; }

.select-search-box input:-moz-placeholder {
  color: #999999;
  font-weight: normal; }

.select-search-box input:-ms-input-placeholder {
  color: #999999;
  font-weight: normal; }

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
  -webkit-appearance: none; }

.select-search-box__select {
  display: none;
  position: absolute;
  width: 100%;
  top: 60px;
  left: 0;
  right: 0;
  background: #fff;
  border-radius: 4px;
  overflow: auto;
  box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
  z-index: 100;
  min-height: 200px; }

.select-search-box--multiple .select-search-box__select {
  display: block;
  position: static;
  border-top: 1px solid #eee;
  border-radius: 0;
  box-shadow: none; }

.select-search-box__select--display {
  display: block; }

.select-search-box__options {
  padding-inline-start: 0px;
  margin-block-start: 0px;
  margin-block-end: 0px; }

.select-search-box__option {
  font-size: 16px;
  font-weight: 400;
  color: #616b74;
  box-sizing: border-box;
  padding: 15px 20px;
  border-top: 1px solid #eaeaea;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none; }

.select-search-box__option:first-child {
  border-top: none; }

.select-search-box__option--hover,
.select-search-box__option:hover {
  background: #fafafa; }

.select-search-box__option--selected {
  background: #0033ff;
  color: #fff;
  border-top-color: #0033ff; }

.select-search-box__option--selected:hover,
.select-search-box__option--selected.select-search-box__option--hover {
  background: #0033ff;
  color: #fff;
  border-top-color: #0033ff; }

.select-search-box__group {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #eee;
  position: relative; }

.select-search-box__group-header {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 0 10px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 12px; }
