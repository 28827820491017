/* Global style guide for UI Lib */
/**
 * Browser support
 */
/**
 * Typography
 */
@import url("https://fonts.googleapis.com/css?family=Rajdhani:300,400,500,600,700&subset=latin-ext");
/**
 * Colors
 */
/**
 * Page -- UILib Specific Variables
 */
/**
 * Card -- UILib Specific Variables
 */
.text a {
  text-decoration: none;
  color: inherit; }
  .text a:hover {
    text-decoration: underline; }

.text-align-left {
  text-align: left; }

.text-align-right {
  text-align: right; }

.text-align-center {
  text-align: center; }

.text-color-blue-0 {
  color: #99ccff; }

.text-color-blue-1 {
  color: #6699ff; }

.text-color-blue-2 {
  color: #3366ff; }

.text-color-blue-3 {
  color: #0033ff; }

.text-color-blue-4 {
  color: #0033cc; }

.text-color-blue-5 {
  color: #003399; }

.text-color-blue-6 {
  color: #003366; }

.text-color-green-0 {
  color: #99ff99; }

.text-color-green-1 {
  color: #66ff66; }

.text-color-green-2 {
  color: #66ff33; }

.text-color-green-3 {
  color: #33cb00; }

.text-color-green-4 {
  color: #33cc00; }

.text-color-green-5 {
  color: #339900; }

.text-color-green-6 {
  color: #336600; }

.text-color-orange-0 {
  color: #ffcc99; }

.text-color-orange-1 {
  color: #ff9966; }

.text-color-orange-2 {
  color: #ff9933; }

.text-color-orange-3 {
  color: #ff6600; }

.text-color-orange-4 {
  color: #cc662b; }

.text-color-orange-5 {
  color: #993300; }

.text-color-orange-6 {
  color: #663300; }

.text-color-grey-0 {
  color: #fafafa; }

.text-color-grey-1 {
  color: #eaeaea; }

.text-color-grey-2 {
  color: #cccccc; }

.text-color-grey-3 {
  color: #999999; }

.text-color-grey-4 {
  color: #777777; }

.text-color-grey-5 {
  color: #666666; }

.text-color-grey-6 {
  color: #333333; }

.text-color-white {
  color: #ffffff; }

.text-color-black {
  color: #000000; }

.text-color-light-grey {
  color: #4a4a4a; }

.text-color-light-pink {
  color: #fef0ef; }

.text-color-pink {
  color: #fabeb9; }
