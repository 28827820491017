/* Global style guide for UI Lib */
/**
 * Browser support
 */
/**
 * Typography
 */
@import url("https://fonts.googleapis.com/css?family=Rajdhani:300,400,500,600,700&subset=latin-ext");
/**
 * Colors
 */
/**
 * Page -- UILib Specific Variables
 */
/**
 * Card -- UILib Specific Variables
 */
@media only screen and (min-width: 769px) {
  .start-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
    .start-card .icon {
      margin-bottom: 40px; }
  .add-card {
    justify-content: flex-start; }
    .add-card .header {
      width: 300px;
      margin: 0 auto;
      margin-bottom: 40px; }
    .add-card .content {
      height: 400px;
      width: 375px;
      margin: 0 auto; }
      .add-card .content .descriptive-text {
        margin-bottom: 40px; }
      .add-card .content form {
        width: 100%; }
      .add-card .content .form-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: center; }
        .add-card .content .form-wrapper .intl-tel-input {
          height: 50px;
          width: 300px;
          outline: none; }
        .add-card .content .form-wrapper .form-control {
          height: 50px;
          width: 300px;
          font-size: 16px;
          border-radius: 4px;
          outline: none;
          border: 1px solid #cccccc; }
      .add-card .content .error-container {
        width: 100%;
        margin-top: 25px;
        margin-bottom: 45px; }
      .add-card .content .submit-container {
        text-align: center; }
      .add-card .content .margin-top-75 {
        margin-top: 75px; }
    .add-card .bottom-section {
      margin-top: auto;
      font-size: 14px; }
  .confirm-card {
    justify-content: flex-start; }
    .confirm-card .header {
      margin-bottom: 40px; }
    .confirm-card .otp-container {
      height: 430px; }
      .confirm-card .otp-container .descriptive-text {
        width: 330px;
        margin: 0 auto; }
      .confirm-card .otp-container .phone-number {
        width: 330px;
        margin: 0 auto;
        margin-bottom: 30px;
        font-weight: 900; }
      .confirm-card .otp-container .time {
        margin-bottom: 20px; }
      .confirm-card .otp-container .digits-container {
        text-align: center; }
        .confirm-card .otp-container .digits-container .tries-text {
          text-align: right;
          margin-right: 15px;
          margin-bottom: 20px; }
      .confirm-card .otp-container .generic-container {
        width: 330px;
        margin: 0 auto;
        margin-bottom: 20px; }
      .confirm-card .otp-container .right-align {
        margin-top: 60px;
        text-align: right; }
    .confirm-card .bottom-section {
      margin-top: auto;
      font-size: 14px; } }

@media only screen and (max-width: 768px) {
  .start-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
    .start-card .icon {
      margin-bottom: 40px; }
  .add-card {
    justify-content: flex-start; }
    .add-card .header {
      font-size: 24px;
      margin: 0 auto;
      margin-bottom: 40px; }
    .add-card .content {
      height: 400px;
      width: 80vw;
      font-size: 12px;
      margin: 0 auto; }
      .add-card .content .descriptive-text {
        margin-bottom: 40px; }
      .add-card .content .form-wrapper {
        display: flex;
        flex-direction: row;
        margin-bottom: 75px; }
      .add-card .content .submit-container {
        text-align: right; }
    .add-card .bottom-section {
      margin-top: auto;
      font-size: 14px; }
  .confirm-card {
    justify-content: flex-start; }
    .confirm-card .header {
      font-size: 24px;
      margin-bottom: 40px; }
    .confirm-card .otp-container {
      height: 430px; }
      .confirm-card .otp-container .descriptive-text {
        font-size: 16px;
        margin: 0 auto; }
      .confirm-card .otp-container .phone-number {
        font-size: 16px;
        margin: 0 auto;
        margin-bottom: 30px;
        font-weight: 900; }
      .confirm-card .otp-container .time {
        font-size: 24px;
        margin-bottom: 20px; }
      .confirm-card .otp-container .digits-container {
        text-align: center; }
        .confirm-card .otp-container .digits-container .tries-text {
          text-align: right;
          margin-right: 15px;
          margin-bottom: 20px; }
      .confirm-card .otp-container .generic-container {
        width: 330px;
        margin: 0 auto;
        margin-bottom: 20px; }
      .confirm-card .otp-container .right-align {
        margin-top: 60px;
        text-align: right; }
    .confirm-card .bottom-section {
      margin-top: auto;
      font-size: 14px; } }
