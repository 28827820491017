/* Global style guide for UI Lib */

/**
 * Browser support
 */

@mixin browser-prefix($declarations, $prefixes: ()) {
  @each $property, $value in $declarations {
    #{$property}: $value;
    @each $prefix in $prefixes {
      #{'-' + $prefix + '-' + $property}: $value;
    }
  }
}

/**
 * Typography
 */

// Font-Family
@import url('https://fonts.googleapis.com/css?family=Rajdhani:300,400,500,600,700&subset=latin-ext');
$font-family: 'Rajdhani', sans-serif;

// Font-Size
$font-size-12: 12px;

// Font-Weight
$font-weight-light: 300;
$font-weight-regular: 500;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;

// line-height
$line-height-125: 1.25;
$line-height-144: 1.44;
$line-height-150: 1.5;

// alignment
$text-align-left: left;
$text-align-right: right;
$text-align-center: center;

$text-aligns: (
  left: $text-align-left,
  right: $text-align-right,
  center: $text-align-center
);

@mixin text-align-modifiers {
  @each $name, $value in $text-aligns {
    &-#{$name} {
      text-align: $value;
    }
  }
}

/**
 * Colors
 */
// Blue Tones
$blue-0: #99ccff;
$blue-1: #6699ff;
$blue-2: #3366ff;
$blue-3: #0033ff;
$blue-4: #0033cc;
$blue-5: #003399;
$blue-6: #003366;

// Green Tones
$green-0: #99ff99;
$green-1: #66ff66;
$green-2: #66ff33;
$green-3: #33cb00;
$green-4: #33cc00;
$green-5: #339900;
$green-6: #336600;

// Orange Tones
$orange-0: #ffcc99;
$orange-1: #ff9966;
$orange-2: #ff9933;
$orange-3: #ff6600;
$orange-4: #cc662b;
$orange-5: #993300;
$orange-6: #663300;

// Grey Tones
$grey-0: #fafafa;
$grey-1: #eaeaea;
$grey-2: #cccccc;
$grey-3: #999999;
$grey-4: #777777;
$grey-5: #666666;
$grey-6: #333333;

// Neutral Tones
$white: #ffffff;
$black: #000000;
$light-grey: #4a4a4a;

// Error Colors
$light-pink: #fef0ef;
$pink: #fabeb9;

$colors: (
  blue-0: $blue-0,
  blue-1: $blue-1,
  blue-2: $blue-2,
  blue-3: $blue-3,
  blue-4: $blue-4,
  blue-5: $blue-5,
  blue-6: $blue-6,
  green-0: $green-0,
  green-1: $green-1,
  green-2: $green-2,
  green-3: $green-3,
  green-4: $green-4,
  green-5: $green-5,
  green-6: $green-6,
  orange-0: $orange-0,
  orange-1: $orange-1,
  orange-2: $orange-2,
  orange-3: $orange-3,
  orange-4: $orange-4,
  orange-5: $orange-5,
  orange-6: $orange-6,
  grey-0: $grey-0,
  grey-1: $grey-1,
  grey-2: $grey-2,
  grey-3: $grey-3,
  grey-4: $grey-4,
  grey-5: $grey-5,
  grey-6: $grey-6,
  white: $white,
  black: $black,
  light-grey: $light-grey,
  light-pink: $light-pink,
  pink: $pink
);

$gridColumns: (
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
  6: 6,
  7: 7,
  8: 8,
  9: 9,
  10: 10,
  11: 11,
  12: 12
);

@mixin color-modifiers {
  @each $name, $hex in $colors {
    &-#{$name} {
      color: $hex;
    }
  }
}

@mixin background-color-modifiers {
  @each $name, $hex in $colors {
    &-#{$name} {
      background-color: $hex;
    }
  }
}

@mixin border-color-modifiers {
  @each $name, $hex in $colors {
    &-#{$name} {
      border-color: $hex;
    }
  }
}

@mixin event-width-modifiers {
  @each $name, $value in $gridColumns {
    &-#{$name} {
      width: calc(100% / 1);
    }
  }
}

/**
 * Page -- UILib Specific Variables
 */
$full-viewport-width: 100vw;
$full-viewport-height: 100vh;

/**
 * Card -- UILib Specific Variables
 */
$content-fill-width: 100%;
$content-fill-height: 100%;
$horizontal-centering: 0 auto;

$auth-card-width: 530px;
$auth-card-height: 675px;
$auth-card-margin-top: 80px;
$auth-card-padding: 60px 50px 40px 50px;

@mixin input-placeholder {
  ::-webkit-input-placeholder {
    @content;
  }
  :-moz-placeholder {
    @content;
  }
  ::-moz-placeholder {
    @content;
  }
  :-ms-input-placeholder {
    @content;
  }
}
