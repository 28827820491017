/* Global style guide for UI Lib */
/**
 * Browser support
 */
/**
 * Typography
 */
@import url("https://fonts.googleapis.com/css?family=Rajdhani:300,400,500,600,700&subset=latin-ext");
/**
 * Colors
 */
/**
 * Page -- UILib Specific Variables
 */
/**
 * Card -- UILib Specific Variables
 */
@media only screen and (min-width: 769px) {
  .withdraw-create-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 1130px;
    box-sizing: border-box;
    margin: 0 auto;
    margin-top: 60px;
    padding-bottom: 60px; }
    .withdraw-create-wrapper .withdraw-header {
      color: #4a4a4a;
      font-weight: 700;
      font-size: 36px;
      margin-bottom: 10px; }
    .withdraw-create-wrapper .withdraw-text {
      color: #4a4a4a;
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      margin-bottom: 40px; }
    .withdraw-create-wrapper .withdraw-zone {
      background-color: #ffffff;
      width: 100%;
      min-height: 630px;
      border-radius: 8px;
      box-sizing: border-box;
      padding: 45px 60px 60px 60px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start; }
      .withdraw-create-wrapper .withdraw-zone .wallet-selection-zone {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
        margin-bottom: 60px; }
        .withdraw-create-wrapper .withdraw-zone .wallet-selection-zone .wallet-selection .header {
          color: #4a4a4a;
          font-size: 24px;
          font-weight: 600; }
        .withdraw-create-wrapper .withdraw-zone .wallet-selection-zone .wallet-selection .subheader {
          color: #4a4a4a;
          font-size: 16px;
          line-height: 26px;
          font-weight: 500;
          margin-bottom: 15px; }
        .withdraw-create-wrapper .withdraw-zone .wallet-selection-zone .wallet-selection .wallet-selector {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: flex-start; }
          .withdraw-create-wrapper .withdraw-zone .wallet-selection-zone .wallet-selection .wallet-selector .wallet-selector-item {
            width: 100px;
            height: 70px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-size: 18px;
            font-weight: 600;
            color: #4a4a4a;
            border: 1px solid #999999;
            border-radius: 8px;
            margin-right: 10px;
            cursor: pointer; }
          .withdraw-create-wrapper .withdraw-zone .wallet-selection-zone .wallet-selection .wallet-selector .selected-wallet {
            background-color: #0033ff;
            border-color: #0033ff;
            color: #ffffff; }
        .withdraw-create-wrapper .withdraw-zone .wallet-selection-zone .wallet-details .wallet {
          box-sizing: border-box;
          min-width: 230px;
          border-radius: 8px;
          height: 120px;
          background-color: transparent;
          color: #333333;
          padding: 10px 15px;
          border: 1px solid #333333;
          display: flex;
          flex-direction: column;
          justify-content: space-between; }
          .withdraw-create-wrapper .withdraw-zone .wallet-selection-zone .wallet-details .wallet .header-zone {
            width: 100%;
            text-align: left; }
            .withdraw-create-wrapper .withdraw-zone .wallet-selection-zone .wallet-details .wallet .header-zone .header {
              font-size: 2em;
              font-weight: 600;
              line-height: 1.25;
              color: inherit; }
            .withdraw-create-wrapper .withdraw-zone .wallet-selection-zone .wallet-details .wallet .header-zone .subheader {
              font-size: 14px;
              font-weight: 500;
              color: inherit; }
          .withdraw-create-wrapper .withdraw-zone .wallet-selection-zone .wallet-details .wallet .balance-zone {
            width: 100%;
            text-align: right;
            color: inherit; }
            .withdraw-create-wrapper .withdraw-zone .wallet-selection-zone .wallet-details .wallet .balance-zone .integer {
              font-size: 28px;
              font-weight: 700; }
            .withdraw-create-wrapper .withdraw-zone .wallet-selection-zone .wallet-details .wallet .balance-zone .float {
              font-size: 28px;
              font-weight: 700; }
            .withdraw-create-wrapper .withdraw-zone .wallet-selection-zone .wallet-details .wallet .balance-zone .currency {
              margin-left: 4px;
              font-size: 14px;
              font-weight: 700; }
      .withdraw-create-wrapper .withdraw-zone .withdraw-form-zone {
        width: 385px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center; }
        .withdraw-create-wrapper .withdraw-zone .withdraw-form-zone form {
          width: 100%; }
          .withdraw-create-wrapper .withdraw-zone .withdraw-form-zone form .form-container {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start; }
            .withdraw-create-wrapper .withdraw-zone .withdraw-form-zone form .form-container .wallet-address {
              width: 100%;
              margin-bottom: 20px; }
              .withdraw-create-wrapper .withdraw-zone .withdraw-form-zone form .form-container .wallet-address .input-text {
                width: 100%;
                margin-left: 15px;
                font-size: 20px;
                font-weight: 700;
                color: #4a4a4a;
                margin-bottom: 5px; }
              .withdraw-create-wrapper .withdraw-zone .withdraw-form-zone form .form-container .wallet-address .input {
                width: 100%;
                height: 50px;
                border-radius: 4px;
                border: 1px solid #d9d9d9;
                outline: none;
                box-sizing: border-box;
                padding: 13px 20px 13px 20px;
                font-size: 18px;
                line-height: 24px; }
              .withdraw-create-wrapper .withdraw-zone .withdraw-form-zone form .form-container .wallet-address .error-input {
                border: 1px solid #ff6600; }
            .withdraw-create-wrapper .withdraw-zone .withdraw-form-zone form .form-container .amount {
              width: 100%;
              margin-bottom: 60px; }
              .withdraw-create-wrapper .withdraw-zone .withdraw-form-zone form .form-container .amount .input-text {
                width: 100%;
                margin-left: 15px;
                font-size: 20px;
                font-weight: 700;
                color: #4a4a4a;
                margin-bottom: 5px; }
              .withdraw-create-wrapper .withdraw-zone .withdraw-form-zone form .form-container .amount .input {
                width: 100%;
                height: 50px;
                border-radius: 4px;
                border: 1px solid #d9d9d9;
                outline: none;
                box-sizing: border-box;
                padding: 13px 20px 13px 20px;
                font-size: 18px;
                line-height: 24px; }
              .withdraw-create-wrapper .withdraw-zone .withdraw-form-zone form .form-container .amount .error-input {
                border: 1px solid #ff6600; }
            .withdraw-create-wrapper .withdraw-zone .withdraw-form-zone form .form-container .error {
              width: 100%;
              margin-bottom: 20px; }
            .withdraw-create-wrapper .withdraw-zone .withdraw-form-zone form .form-container .toc {
              margin: 0 auto;
              margin-bottom: 10px; }
            .withdraw-create-wrapper .withdraw-zone .withdraw-form-zone form .form-container .action {
              margin: 0 auto;
              width: 240px;
              height: 60px;
              font-size: 24px;
              font-weight: 600; }
  .withdraw-confirm-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 1130px;
    box-sizing: border-box;
    margin: 0 auto;
    margin-top: 60px;
    padding-bottom: 60px; }
    .withdraw-confirm-wrapper .withdraw-header {
      color: #4a4a4a;
      font-weight: 700;
      font-size: 36px;
      margin-bottom: 10px; }
    .withdraw-confirm-wrapper .withdraw-text {
      color: #4a4a4a;
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      margin-bottom: 40px; }
    .withdraw-confirm-wrapper .confirm-card {
      margin-top: 0;
      display: flex;
      justify-content: flex-start;
      align-items: center; }
      .withdraw-confirm-wrapper .confirm-card .header {
        color: #4a4a4a;
        font-weight: 700;
        font-size: 36px;
        margin-bottom: 20px; }
      .withdraw-confirm-wrapper .confirm-card .subheader {
        color: #4a4a4a;
        font-weight: 500;
        font-size: 18px;
        line-height: 26px;
        margin-bottom: 60px; }
      .withdraw-confirm-wrapper .confirm-card .time {
        color: #4a4a4a;
        font-weight: 700;
        font-size: 36px;
        margin-bottom: 20px; }
      .withdraw-confirm-wrapper .confirm-card .digits-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-bottom: 94px; }
        .withdraw-confirm-wrapper .confirm-card .digits-container .tries-text {
          width: 100%;
          text-align: right; }
      .withdraw-confirm-wrapper .confirm-card .error {
        width: 100%;
        margin-top: -30px;
        margin-bottom: 20px; }
      .withdraw-confirm-wrapper .confirm-card .action-zone {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: flex-end; }
        .withdraw-confirm-wrapper .confirm-card .action-zone .action-button {
          width: 150px;
          height: 50px;
          font-size: 18px;
          font-weight: 600; }
  .withdraw-failure-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 800px;
    box-sizing: border-box;
    margin: 0 auto;
    margin-top: 80px;
    padding-bottom: 60px; }
    .withdraw-failure-wrapper .failure-header-zone {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      height: 100px;
      margin-bottom: 60px; }
      .withdraw-failure-wrapper .failure-header-zone .icon {
        font-size: 100px; }
      .withdraw-failure-wrapper .failure-header-zone .failure-header-content {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-left: 40px; }
        .withdraw-failure-wrapper .failure-header-zone .failure-header-content .failure-header {
          color: #4a4a4a;
          font-weight: 700;
          font-size: 30px; }
        .withdraw-failure-wrapper .failure-header-zone .failure-header-content .failure-text {
          color: #4a4a4a;
          font-weight: 500;
          font-size: 18px;
          line-height: 26px; }
    .withdraw-failure-wrapper .failure-general-zone {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
      height: 360px;
      box-sizing: border-box;
      padding: 40px;
      margin-bottom: 60px;
      background-color: #ffffff;
      border-radius: 5px; }
      .withdraw-failure-wrapper .failure-general-zone .failure-large-header {
        color: #4a4a4a;
        font-size: 20px;
        font-weight: 500; }
      .withdraw-failure-wrapper .failure-general-zone .failure-large-text {
        color: #4a4a4a;
        font-size: 36px;
        font-weight: 700; }
      .withdraw-failure-wrapper .failure-general-zone .failure-header {
        color: #999999;
        font-size: 16px;
        line-height: 26px;
        font-weight: 500; }
      .withdraw-failure-wrapper .failure-general-zone .failure-text {
        color: #4a4a4a;
        font-size: 20px;
        font-weight: 600; }
    .withdraw-failure-wrapper .failure-action-zone {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start; }
      .withdraw-failure-wrapper .failure-action-zone .button {
        width: 240px;
        height: 60px;
        font-size: 24px;
        font-weight: 600; }
  .withdraw-success-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 800px;
    box-sizing: border-box;
    margin: 0 auto;
    margin-top: 80px;
    padding-bottom: 60px; }
    .withdraw-success-wrapper .success-header-zone {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      height: 100px;
      margin-bottom: 60px; }
      .withdraw-success-wrapper .success-header-zone .icon {
        font-size: 100px; }
      .withdraw-success-wrapper .success-header-zone .success-header-content {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-left: 40px; }
        .withdraw-success-wrapper .success-header-zone .success-header-content .success-header {
          color: #4a4a4a;
          font-weight: 700;
          font-size: 30px; }
        .withdraw-success-wrapper .success-header-zone .success-header-content .success-text {
          color: #4a4a4a;
          font-weight: 500;
          font-size: 18px;
          line-height: 26px; }
    .withdraw-success-wrapper .success-general-zone {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
      height: 360px;
      box-sizing: border-box;
      padding: 40px;
      margin-bottom: 60px;
      background-color: #ffffff;
      border-radius: 5px; }
      .withdraw-success-wrapper .success-general-zone .success-large-header {
        color: #4a4a4a;
        font-size: 20px;
        font-weight: 500; }
      .withdraw-success-wrapper .success-general-zone .success-large-text {
        color: #4a4a4a;
        font-size: 36px;
        font-weight: 700; }
      .withdraw-success-wrapper .success-general-zone .success-header {
        color: #999999;
        font-size: 16px;
        line-height: 26px;
        font-weight: 500; }
      .withdraw-success-wrapper .success-general-zone .success-text {
        color: #4a4a4a;
        font-size: 20px;
        font-weight: 600; }
    .withdraw-success-wrapper .success-action-zone {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-start; }
      .withdraw-success-wrapper .success-action-zone .button {
        width: 240px;
        height: 60px;
        font-size: 24px;
        font-weight: 600; }
  .confirmation-modal {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%; }
    .confirmation-modal .confirmation-modal-header {
      font-size: 32px;
      font-weight: 700; }
    .confirmation-modal .confirmation-modal-subheader {
      font-size: 22px;
      font-weight: 500;
      line-height: 30px;
      margin-top: 40px; }
    .confirmation-modal .confirmation-modal-icon {
      font-size: 100px;
      color: #ff6600;
      text-align: center;
      margin-top: 80px;
      margin-bottom: 100px; }
    .confirmation-modal .confirmation-actions {
      margin-top: auto;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between; }
      .confirmation-modal .confirmation-actions .button {
        width: 46%;
        height: 50px; } }

@media only screen and (max-width: 768px) {
  .withdraw-create-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: calc(100%-40px);
    box-sizing: border-box;
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding-bottom: 60px; }
    .withdraw-create-wrapper .withdraw-header {
      color: #4a4a4a;
      font-weight: 700;
      font-size: 24px;
      margin-bottom: 10px; }
    .withdraw-create-wrapper .withdraw-text {
      color: #4a4a4a;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 30px; }
    .withdraw-create-wrapper .withdraw-zone {
      background-color: transparent;
      width: 100%;
      min-height: 600px;
      border-radius: 8px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: flex-start; }
      .withdraw-create-wrapper .withdraw-zone .wallet-selection-zone {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
        margin-bottom: 40px; }
        .withdraw-create-wrapper .withdraw-zone .wallet-selection-zone .wallet-selection .header {
          color: #4a4a4a;
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 10px; }
        .withdraw-create-wrapper .withdraw-zone .wallet-selection-zone .wallet-selection .subheader {
          display: none; }
        .withdraw-create-wrapper .withdraw-zone .wallet-selection-zone .wallet-selection .wallet-selector {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: flex-start; }
          .withdraw-create-wrapper .withdraw-zone .wallet-selection-zone .wallet-selection .wallet-selector .wallet-selector-item {
            width: 100px;
            height: 50px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-size: 16px;
            font-weight: 600;
            color: #4a4a4a;
            border: 1px solid #999999;
            border-radius: 8px;
            margin-right: 10px;
            cursor: pointer;
            margin-bottom: 40px; }
          .withdraw-create-wrapper .withdraw-zone .wallet-selection-zone .wallet-selection .wallet-selector .selected-wallet {
            background-color: #0033ff;
            border-color: #0033ff;
            color: #ffffff; }
        .withdraw-create-wrapper .withdraw-zone .wallet-selection-zone .wallet-details {
          width: 100%; }
          .withdraw-create-wrapper .withdraw-zone .wallet-selection-zone .wallet-details .wallet {
            box-sizing: border-box;
            width: 100%;
            height: 60px;
            background-color: #ffffff;
            border-radius: 4px;
            color: #333333;
            padding: 10px 15px;
            display: flex;
            flex-direction: row;
            justify-content: space-between; }
            .withdraw-create-wrapper .withdraw-zone .wallet-selection-zone .wallet-details .wallet .header-zone {
              width: 40%;
              text-align: left; }
              .withdraw-create-wrapper .withdraw-zone .wallet-selection-zone .wallet-details .wallet .header-zone .header {
                font-size: 16px;
                font-weight: 600;
                line-height: 1.25;
                color: inherit; }
              .withdraw-create-wrapper .withdraw-zone .wallet-selection-zone .wallet-details .wallet .header-zone .subheader {
                font-size: 10px;
                font-weight: 500;
                color: inherit; }
            .withdraw-create-wrapper .withdraw-zone .wallet-selection-zone .wallet-details .wallet .balance-zone {
              width: 60%;
              text-align: right;
              color: inherit; }
              .withdraw-create-wrapper .withdraw-zone .wallet-selection-zone .wallet-details .wallet .balance-zone .integer {
                font-size: 28px;
                font-weight: 700; }
              .withdraw-create-wrapper .withdraw-zone .wallet-selection-zone .wallet-details .wallet .balance-zone .float {
                font-size: 28px;
                font-weight: 700; }
              .withdraw-create-wrapper .withdraw-zone .wallet-selection-zone .wallet-details .wallet .balance-zone .currency {
                margin-left: 4px;
                font-size: 14px;
                font-weight: 700; }
      .withdraw-create-wrapper .withdraw-zone .withdraw-form-zone {
        width: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center; }
        .withdraw-create-wrapper .withdraw-zone .withdraw-form-zone form {
          width: 100%; }
          .withdraw-create-wrapper .withdraw-zone .withdraw-form-zone form .form-container {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start; }
            .withdraw-create-wrapper .withdraw-zone .withdraw-form-zone form .form-container .wallet-address {
              width: 100%;
              margin-bottom: 20px; }
              .withdraw-create-wrapper .withdraw-zone .withdraw-form-zone form .form-container .wallet-address .input-text {
                width: 100%;
                font-size: 16px;
                font-weight: 500;
                color: #4a4a4a;
                margin-bottom: 5px; }
              .withdraw-create-wrapper .withdraw-zone .withdraw-form-zone form .form-container .wallet-address .input {
                width: 100%;
                height: 50px;
                border-radius: 4px;
                border: 1px solid #d9d9d9;
                outline: none;
                box-sizing: border-box;
                padding: 13px 20px 13px 20px;
                font-size: 18px;
                line-height: 24px; }
              .withdraw-create-wrapper .withdraw-zone .withdraw-form-zone form .form-container .wallet-address .error-input {
                border: 1px solid #ff6600; }
            .withdraw-create-wrapper .withdraw-zone .withdraw-form-zone form .form-container .amount {
              width: 100%;
              margin-bottom: 60px; }
              .withdraw-create-wrapper .withdraw-zone .withdraw-form-zone form .form-container .amount .input-text {
                width: 100%;
                font-size: 16px;
                font-weight: 500;
                color: #4a4a4a;
                margin-bottom: 5px; }
              .withdraw-create-wrapper .withdraw-zone .withdraw-form-zone form .form-container .amount .input {
                width: 100%;
                height: 50px;
                border-radius: 4px;
                border: 1px solid #d9d9d9;
                outline: none;
                box-sizing: border-box;
                padding: 13px 20px 13px 20px;
                font-size: 18px;
                line-height: 24px; }
              .withdraw-create-wrapper .withdraw-zone .withdraw-form-zone form .form-container .amount .error-input {
                border: 1px solid #ff6600; }
            .withdraw-create-wrapper .withdraw-zone .withdraw-form-zone form .form-container .error {
              width: 100%;
              margin-bottom: 20px; }
            .withdraw-create-wrapper .withdraw-zone .withdraw-form-zone form .form-container .toc {
              margin: 0 auto;
              margin-bottom: 10px; }
            .withdraw-create-wrapper .withdraw-zone .withdraw-form-zone form .form-container .action {
              margin: 0 auto;
              width: 240px;
              height: 60px;
              font-size: 24px;
              font-weight: 600; }
  .withdraw-confirm-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: calc(100% - 40px);
    box-sizing: border-box;
    margin: 0 auto;
    margin-top: 30px;
    padding-bottom: 60px; }
    .withdraw-confirm-wrapper .withdraw-header {
      color: #4a4a4a;
      font-weight: 700;
      font-size: 24px;
      margin-bottom: 10px; }
    .withdraw-confirm-wrapper .withdraw-text {
      color: #4a4a4a;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 40px; }
    .withdraw-confirm-wrapper .confirm-card {
      margin-top: 0;
      display: flex;
      justify-content: flex-start;
      align-items: center; }
      .withdraw-confirm-wrapper .confirm-card .header {
        color: #4a4a4a;
        font-weight: 700;
        font-size: 24px;
        margin-bottom: 20px; }
      .withdraw-confirm-wrapper .confirm-card .subheader {
        color: #4a4a4a;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 40px; }
      .withdraw-confirm-wrapper .confirm-card .time {
        color: #4a4a4a;
        font-weight: 700;
        font-size: 24px;
        margin-bottom: 20px; }
      .withdraw-confirm-wrapper .confirm-card .digits-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-bottom: 60px; }
        .withdraw-confirm-wrapper .confirm-card .digits-container .tries-text {
          width: 100%;
          text-align: right; }
      .withdraw-confirm-wrapper .confirm-card .error {
        width: 100%;
        margin-top: -30px;
        margin-bottom: 20px; }
      .withdraw-confirm-wrapper .confirm-card .action-zone {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-end; }
        .withdraw-confirm-wrapper .confirm-card .action-zone .action-button {
          width: 150px;
          height: 50px;
          font-size: 18px;
          font-weight: 600; }
  .withdraw-success-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: calc(100% - 40px);
    box-sizing: border-box;
    margin: 0 auto;
    margin-top: 30px;
    padding-bottom: 60px; }
    .withdraw-success-wrapper .success-header-zone {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 60px; }
      .withdraw-success-wrapper .success-header-zone .icon {
        font-size: 100px;
        margin-bottom: 20px; }
      .withdraw-success-wrapper .success-header-zone .success-header-content {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start; }
        .withdraw-success-wrapper .success-header-zone .success-header-content .success-header {
          color: #4a4a4a;
          font-weight: 700;
          font-size: 24px;
          margin-bottom: 10px; }
        .withdraw-success-wrapper .success-header-zone .success-header-content .success-text {
          color: #4a4a4a;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px; }
    .withdraw-success-wrapper .success-general-zone {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
      box-sizing: border-box;
      padding: 20px;
      margin-bottom: 30px;
      background-color: #ffffff;
      border-radius: 5px; }
      .withdraw-success-wrapper .success-general-zone .success-large-header {
        color: #4a4a4a;
        font-size: 16px;
        font-weight: 500; }
      .withdraw-success-wrapper .success-general-zone .success-large-text {
        color: #4a4a4a;
        font-size: 24px;
        font-weight: 700; }
      .withdraw-success-wrapper .success-general-zone .success-header {
        color: #999999;
        font-size: 16px;
        line-height: 20px;
        font-weight: 500; }
      .withdraw-success-wrapper .success-general-zone .success-text {
        color: #4a4a4a;
        font-size: 20px;
        font-weight: 600; }
    .withdraw-success-wrapper .success-action-zone {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-start; }
      .withdraw-success-wrapper .success-action-zone .button {
        width: 240px;
        height: 60px;
        font-size: 24px;
        font-weight: 600; }
  .withdraw-failure-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: calc(100% - 40px);
    box-sizing: border-box;
    margin: 0 auto;
    margin-top: 30px;
    padding-bottom: 60px; }
    .withdraw-failure-wrapper .failure-header-zone {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 60px; }
      .withdraw-failure-wrapper .failure-header-zone .icon {
        font-size: 100px;
        margin-bottom: 20px; }
      .withdraw-failure-wrapper .failure-header-zone .failure-header-content {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start; }
        .withdraw-failure-wrapper .failure-header-zone .failure-header-content .failure-header {
          color: #4a4a4a;
          font-weight: 700;
          font-size: 24px;
          margin-bottom: 10px; }
        .withdraw-failure-wrapper .failure-header-zone .failure-header-content .failure-text {
          color: #4a4a4a;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px; }
    .withdraw-failure-wrapper .failure-general-zone {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
      box-sizing: border-box;
      padding: 20px;
      margin-bottom: 30px;
      background-color: #ffffff;
      border-radius: 5px; }
      .withdraw-failure-wrapper .failure-general-zone .failure-large-header {
        color: #4a4a4a;
        font-size: 16px;
        font-weight: 500; }
      .withdraw-failure-wrapper .failure-general-zone .failure-large-text {
        color: #4a4a4a;
        font-size: 24px;
        font-weight: 700; }
      .withdraw-failure-wrapper .failure-general-zone .failure-header {
        color: #999999;
        font-size: 16px;
        line-height: 20px;
        font-weight: 500; }
      .withdraw-failure-wrapper .failure-general-zone .failure-text {
        color: #4a4a4a;
        font-size: 20px;
        font-weight: 600; }
    .withdraw-failure-wrapper .failure-action-zone {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
      .withdraw-failure-wrapper .failure-action-zone .button {
        width: 240px;
        height: 60px;
        font-size: 24px;
        font-weight: 600; }
        .withdraw-failure-wrapper .failure-action-zone .button:first-child {
          margin-bottom: 10px; }
  .confirmation-modal {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%; }
    .confirmation-modal .confirmation-modal-header {
      font-size: 24px;
      font-weight: 700; }
    .confirmation-modal .confirmation-modal-subheader {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      margin-top: 20px; }
    .confirmation-modal .confirmation-modal-icon {
      font-size: 60px;
      color: #ff6600;
      text-align: center;
      margin-top: 40px;
      margin-bottom: 40px; }
    .confirmation-modal .confirmation-actions {
      margin-top: 30px;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between; }
      .confirmation-modal .confirmation-actions .button {
        width: 46%;
        height: 50px; } }
