/* Global style guide for UI Lib */
/**
 * Browser support
 */
/**
 * Typography
 */
@import url("https://fonts.googleapis.com/css?family=Rajdhani:300,400,500,600,700&subset=latin-ext");
/**
 * Colors
 */
/**
 * Page -- UILib Specific Variables
 */
/**
 * Card -- UILib Specific Variables
 */
@media only screen and (min-width: 769px) {
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 1000; }
  .modal-main {
    position: fixed;
    box-sizing: border-box;
    background: white;
    width: 550px;
    height: 700px;
    top: 50%;
    left: 50%;
    border-radius: 5px;
    transform: translate(-50%, -50%);
    padding: 50px 50px 60px 50px; }
  .close-icon {
    position: absolute;
    cursor: pointer;
    top: 15px;
    right: 15px; }
  .display-block {
    display: block; }
  .display-none {
    display: none; } }

@media only screen and (max-width: 768px) {
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.6);
    z-index: 1000; }
  .modal-main {
    position: fixed;
    box-sizing: border-box;
    background: white;
    width: 80vw;
    min-height: 50vh;
    top: 50%;
    left: 50%;
    border-radius: 5px;
    transform: translate(-50%, -50%);
    padding: 30px 10px 30px 10px; }
  .close-icon {
    position: absolute;
    cursor: pointer;
    top: 10px;
    right: 10px; }
  .display-block {
    display: block; }
  .display-none {
    display: none; } }
