/* Global style guide for UI Lib */
/**
 * Browser support
 */
/**
 * Typography
 */
@import url("https://fonts.googleapis.com/css?family=Rajdhani:300,400,500,600,700&subset=latin-ext");
/**
 * Colors
 */
/**
 * Page -- UILib Specific Variables
 */
/**
 * Card -- UILib Specific Variables
 */
@media only screen and (min-width: 769px) {
  .investment-table-header {
    font-size: 24px;
    font-weight: 700;
    color: #4a4a4a;
    margin-bottom: 20px; }
  .general-table-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 200px;
    text-align: left; }
    .general-table-wrapper .table-loading {
      height: 200px;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center; }
  .invested-table-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 200px;
    text-align: left; }
    .invested-table-wrapper .table-loading {
      height: 200px;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center; }
    .invested-table-wrapper .table-error-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
      .invested-table-wrapper .table-error-wrapper .error-content {
        box-sizing: border-box;
        font-size: 24px;
        font-weight: 500;
        text-align: center;
        color: #333333;
        margin-top: 40px; }
    .invested-table-wrapper .table-header-zone {
      display: flex;
      flex-direction: row;
      margin-bottom: 10px;
      padding: 0 25px; }
      .invested-table-wrapper .table-header-zone .table-header {
        font-size: 12px;
        font-weight: 600;
        color: #bfc5d2;
        letter-spacing: 1.12px;
        width: 14%; }
        .invested-table-wrapper .table-header-zone .table-header:first-child {
          width: 30%; }
    .invested-table-wrapper .mobile-table-header-zone {
      display: none; }
    .invested-table-wrapper .table-item-zone {
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      height: 76px;
      padding: 20px 25px;
      margin-bottom: 10px;
      background-color: #ffffff;
      border: 1px solid rgba(46, 91, 255, 0.08);
      border-radius: 1px; }
      .invested-table-wrapper .table-item-zone .table-item {
        font-size: 16px;
        font-weight: 500;
        color: #4a4a4a;
        width: 14%;
        text-align: left;
        box-sizing: border-box;
        padding-right: 25px; }
        .invested-table-wrapper .table-item-zone .table-item:first-child {
          width: 30%;
          display: flex;
          flex-direction: row;
          align-items: center; }
          .invested-table-wrapper .table-item-zone .table-item:first-child .project-image {
            height: 36px;
            min-width: 36px;
            border-radius: 50%;
            background-color: rgba(46, 91, 255, 0.15);
            margin-right: 25px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center; }
            .invested-table-wrapper .table-item-zone .table-item:first-child .project-image .image-placeholder {
              font-size: 15px;
              color: #2e5bff; }
      .invested-table-wrapper .table-item-zone .earnings {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center; }
        .invested-table-wrapper .table-item-zone .earnings .indicator {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: #ff9933;
          margin-right: 10px; }
        .invested-table-wrapper .table-item-zone .earnings .active {
          background-color: #66ff66; }
      .invested-table-wrapper .table-item-zone .actions {
        text-align: right;
        font-size: 15px;
        color: #2e5bff; }
        .invested-table-wrapper .table-item-zone .actions a {
          text-decoration: none;
          color: inherit; }
          .invested-table-wrapper .table-item-zone .actions a .action {
            cursor: pointer; }
            .invested-table-wrapper .table-item-zone .actions a .action:hover {
              text-decoration: underline; }
    .invested-table-wrapper .mobile-table-item-zone {
      display: none; }
    .invested-table-wrapper .table-total-zone {
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      height: 50px;
      padding: 12px 25px;
      margin-bottom: 10px;
      background-color: rgba(255, 255, 255, 0.6);
      border: 1px solid rgba(46, 91, 255, 0.08);
      border-radius: 1px; }
      .invested-table-wrapper .table-total-zone .table-total {
        font-size: 18px;
        font-weight: 700;
        color: #4a4a4a;
        width: 14%;
        text-align: left; }
        .invested-table-wrapper .table-total-zone .table-total:first-child {
          width: 44%; }
      .invested-table-wrapper .table-total-zone .earnings {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center; }
        .invested-table-wrapper .table-total-zone .earnings .indicator {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          margin-right: 10px; }
      .invested-table-wrapper .table-total-zone .actions {
        text-align: center;
        font-size: 15px;
        color: #2e5bff;
        font-weight: 500; }
        .invested-table-wrapper .table-total-zone .actions a {
          text-decoration: none;
          color: inherit; }
          .invested-table-wrapper .table-total-zone .actions a .action {
            cursor: pointer; }
            .invested-table-wrapper .table-total-zone .actions a .action:hover {
              text-decoration: underline; }
  .investable-table-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 200px;
    text-align: left; }
    .investable-table-wrapper .table-error-wrapper {
      height: 200px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
      .investable-table-wrapper .table-error-wrapper .error-content {
        box-sizing: border-box;
        font-size: 24px;
        font-weight: 500;
        text-align: center;
        color: #333333;
        margin-top: 40px; }
    .investable-table-wrapper .table-header-zone {
      display: flex;
      flex-direction: row;
      margin-bottom: 10px;
      padding: 0 25px; }
      .investable-table-wrapper .table-header-zone .table-header {
        font-size: 12px;
        font-weight: 600;
        color: #bfc5d2;
        letter-spacing: 1.12px;
        width: 10%; }
        .investable-table-wrapper .table-header-zone .table-header:first-child {
          width: 35%; }
        .investable-table-wrapper .table-header-zone .table-header:nth-child(2) {
          width: 15%; }
        .investable-table-wrapper .table-header-zone .table-header:nth-child(3) {
          width: 20%; }
        .investable-table-wrapper .table-header-zone .table-header:last-child {
          width: 8%; }
    .investable-table-wrapper .mobile-table-header-zone {
      display: none; }
    .investable-table-wrapper .table-item-wrapper {
      position: relative; }
      .investable-table-wrapper .table-item-wrapper .table-item-audited {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        top: -10px;
        left: -15px;
        height: 25px;
        width: 90px;
        background-color: #33cb00;
        border-radius: 3px;
        font-size: 14px;
        font-weight: 600;
        color: white; }
      .investable-table-wrapper .table-item-wrapper .table-item-zone {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        align-items: center;
        min-height: 76px;
        padding: 20px 25px;
        margin-bottom: 20px;
        background-color: #ffffff;
        border: 1px solid rgba(46, 91, 255, 0.08);
        border-radius: 1px; }
        .investable-table-wrapper .table-item-wrapper .table-item-zone .table-item {
          font-size: 16px;
          font-weight: 500;
          color: #4a4a4a;
          width: 10%;
          box-sizing: border-box;
          text-align: left; }
          .investable-table-wrapper .table-item-wrapper .table-item-zone .table-item a {
            text-decoration: none;
            color: #000000; }
            .investable-table-wrapper .table-item-wrapper .table-item-zone .table-item a:hover {
              text-decoration: underline; }
          .investable-table-wrapper .table-item-wrapper .table-item-zone .table-item:first-child {
            width: 35%;
            padding-right: 20px; }
          .investable-table-wrapper .table-item-wrapper .table-item-zone .table-item:nth-child(2) {
            width: 15%; }
          .investable-table-wrapper .table-item-wrapper .table-item-zone .table-item:nth-child(3) {
            width: 20%; }
          .investable-table-wrapper .table-item-wrapper .table-item-zone .table-item:last-child {
            width: 8%; }
        .investable-table-wrapper .table-item-wrapper .table-item-zone .actions {
          text-align: right;
          font-size: 15px;
          color: #ff6600; }
          .investable-table-wrapper .table-item-wrapper .table-item-zone .actions a {
            color: inherit; }
            .investable-table-wrapper .table-item-wrapper .table-item-zone .actions a .action {
              cursor: pointer; }
              .investable-table-wrapper .table-item-wrapper .table-item-zone .actions a .action:hover {
                text-decoration: underline; }
    .investable-table-wrapper .mobile-table-item-wrapper {
      display: none; }
  .committed-table-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100px;
    text-align: left; }
    .committed-table-wrapper .table-header-zone {
      display: flex;
      flex-direction: row;
      margin-bottom: 10px;
      padding: 0 25px; }
      .committed-table-wrapper .table-header-zone .table-header {
        font-size: 12px;
        font-weight: 600;
        color: #bfc5d2;
        letter-spacing: 1.12px;
        width: 5%; }
        .committed-table-wrapper .table-header-zone .table-header:nth-child(1) {
          width: 28%; }
        .committed-table-wrapper .table-header-zone .table-header:nth-child(2) {
          width: 15%; }
        .committed-table-wrapper .table-header-zone .table-header:nth-child(3) {
          width: 17%; }
        .committed-table-wrapper .table-header-zone .table-header:nth-child(4) {
          width: 15%; }
        .committed-table-wrapper .table-header-zone .table-header:nth-child(5) {
          width: 10%; }
        .committed-table-wrapper .table-header-zone .table-header:nth-child(6) {
          width: 10%; }
    .committed-table-wrapper .mobile-table-header-zone {
      display: none; }
    .committed-table-wrapper .table-item-zone {
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      height: 76px;
      padding: 20px 25px;
      margin-bottom: 10px;
      background-color: #ffffff;
      border: 1px solid rgba(46, 91, 255, 0.08);
      border-radius: 1px; }
      .committed-table-wrapper .table-item-zone .table-item {
        font-size: 16px;
        font-weight: 500;
        color: #4a4a4a;
        width: 5%;
        text-align: left;
        box-sizing: border-box; }
        .committed-table-wrapper .table-item-zone .table-item:nth-child(1) {
          width: 28%; }
        .committed-table-wrapper .table-item-zone .table-item:nth-child(2) {
          width: 15%; }
        .committed-table-wrapper .table-item-zone .table-item:nth-child(3) {
          width: 17%; }
        .committed-table-wrapper .table-item-zone .table-item:nth-child(4) {
          width: 15%; }
        .committed-table-wrapper .table-item-zone .table-item:nth-child(5) {
          width: 10%; }
        .committed-table-wrapper .table-item-zone .table-item:nth-child(6) {
          width: 10%; }
      .committed-table-wrapper .table-item-zone a {
        text-decoration: none;
        color: #0033ff; }
        .committed-table-wrapper .table-item-zone a .action {
          cursor: pointer; }
          .committed-table-wrapper .table-item-zone a .action:hover {
            text-decoration: underline; }
    .committed-table-wrapper .mobile-table-item-zone {
      display: none; } }

@media only screen and (max-width: 768px) {
  .investment-table-header {
    display: none; }
  .general-table-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 150px;
    text-align: left; }
    .general-table-wrapper .table-loading {
      height: 150px;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center; }
  .committed-table-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100px;
    text-align: left; }
    .committed-table-wrapper .table-header-zone {
      display: none; }
    .committed-table-wrapper .mobile-table-header-zone {
      display: flex;
      flex-direction: row;
      margin-bottom: 5px;
      margin-top: 10px;
      box-sizing: border-box;
      padding-left: 10px;
      padding-right: 10px; }
      .committed-table-wrapper .mobile-table-header-zone .table-header {
        font-size: 12px;
        font-weight: 600;
        color: #bfc5d2; }
        .committed-table-wrapper .mobile-table-header-zone .table-header:nth-child(1) {
          width: 35%; }
        .committed-table-wrapper .mobile-table-header-zone .table-header:nth-child(2) {
          width: 20%; }
        .committed-table-wrapper .mobile-table-header-zone .table-header:nth-child(3) {
          width: 20%; }
        .committed-table-wrapper .mobile-table-header-zone .table-header:nth-child(4) {
          width: 15%; }
        .committed-table-wrapper .mobile-table-header-zone .table-header:nth-child(5) {
          width: 5%; }
    .committed-table-wrapper .table-item-zone {
      display: none; }
    .committed-table-wrapper .mobile-table-item-zone {
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      min-height: 76px;
      padding: 10px;
      background-color: #ffffff;
      border: 1px solid rgba(46, 91, 255, 0.08);
      border-radius: 1px;
      margin-bottom: 10px; }
      .committed-table-wrapper .mobile-table-item-zone .table-item {
        font-size: 14px;
        font-weight: 500;
        color: #4a4a4a;
        text-align: left;
        box-sizing: border-box; }
        .committed-table-wrapper .mobile-table-item-zone .table-item:nth-child(1) {
          width: 35%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start; }
          .committed-table-wrapper .mobile-table-item-zone .table-item:nth-child(1) .icon {
            font-size: 14px;
            margin-right: 3px; }
          .committed-table-wrapper .mobile-table-item-zone .table-item:nth-child(1) .title {
            font-weight: 600; }
          .committed-table-wrapper .mobile-table-item-zone .table-item:nth-child(1) .location {
            font-size: 10px; }
        .committed-table-wrapper .mobile-table-item-zone .table-item:nth-child(2) {
          width: 20%; }
        .committed-table-wrapper .mobile-table-item-zone .table-item:nth-child(3) {
          width: 20%; }
        .committed-table-wrapper .mobile-table-item-zone .table-item:nth-child(4) {
          width: 15%; }
        .committed-table-wrapper .mobile-table-item-zone .table-item:nth-child(5) {
          width: 5%; }
      .committed-table-wrapper .mobile-table-item-zone a {
        text-decoration: none;
        color: #0033ff; }
        .committed-table-wrapper .mobile-table-item-zone a .action {
          cursor: pointer; }
          .committed-table-wrapper .mobile-table-item-zone a .action:hover {
            text-decoration: underline; }
  .invested-table-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 150px;
    text-align: left; }
    .invested-table-wrapper .table-loading {
      height: 150px;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center; }
    .invested-table-wrapper .table-error-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
      .invested-table-wrapper .table-error-wrapper .error-content {
        box-sizing: border-box;
        font-size: 24px;
        font-weight: 500;
        text-align: center;
        color: #333333;
        margin-top: 40px; }
    .invested-table-wrapper .table-header-zone {
      display: none; }
    .invested-table-wrapper .mobile-table-header-zone {
      display: flex;
      flex-direction: row;
      margin-bottom: 5px;
      margin-top: 10px;
      box-sizing: border-box;
      padding-left: 10px;
      padding-right: 10px; }
      .invested-table-wrapper .mobile-table-header-zone .table-header {
        font-size: 12px;
        font-weight: 600;
        color: #bfc5d2; }
        .invested-table-wrapper .mobile-table-header-zone .table-header:nth-child(1) {
          width: 35%; }
        .invested-table-wrapper .mobile-table-header-zone .table-header:nth-child(2) {
          width: 20%; }
        .invested-table-wrapper .mobile-table-header-zone .table-header:nth-child(3) {
          width: 20%; }
        .invested-table-wrapper .mobile-table-header-zone .table-header:nth-child(4) {
          width: 15%; }
        .invested-table-wrapper .mobile-table-header-zone .table-header:nth-child(5) {
          width: 5%; }
    .invested-table-wrapper .table-item-zone {
      display: none; }
    .invested-table-wrapper .mobile-table-item-zone {
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      min-height: 76px;
      padding: 10px;
      background-color: #ffffff;
      border: 1px solid rgba(46, 91, 255, 0.08);
      border-radius: 1px;
      margin-bottom: 10px; }
      .invested-table-wrapper .mobile-table-item-zone .table-item {
        font-size: 14px;
        font-weight: 500;
        color: #4a4a4a;
        text-align: left;
        box-sizing: border-box; }
        .invested-table-wrapper .mobile-table-item-zone .table-item:nth-child(1) {
          width: 35%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start; }
          .invested-table-wrapper .mobile-table-item-zone .table-item:nth-child(1) .icon {
            font-size: 14px;
            margin-right: 3px; }
          .invested-table-wrapper .mobile-table-item-zone .table-item:nth-child(1) .title {
            font-weight: 600; }
          .invested-table-wrapper .mobile-table-item-zone .table-item:nth-child(1) .location {
            font-size: 10px; }
        .invested-table-wrapper .mobile-table-item-zone .table-item:nth-child(2) {
          width: 20%; }
        .invested-table-wrapper .mobile-table-item-zone .table-item:nth-child(3) {
          width: 20%; }
        .invested-table-wrapper .mobile-table-item-zone .table-item:nth-child(4) {
          width: 15%; }
        .invested-table-wrapper .mobile-table-item-zone .table-item:nth-child(5) {
          width: 5%; }
      .invested-table-wrapper .mobile-table-item-zone .earnings {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center; }
        .invested-table-wrapper .mobile-table-item-zone .earnings .indicator {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: #ff9933;
          margin-right: 2px; }
        .invested-table-wrapper .mobile-table-item-zone .earnings .active {
          background-color: #66ff66; }
      .invested-table-wrapper .mobile-table-item-zone .actions {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-end;
        text-align: right;
        font-size: 15px;
        color: #2e5bff; }
        .invested-table-wrapper .mobile-table-item-zone .actions:first-child {
          margin-bottom: 10px; }
        .invested-table-wrapper .mobile-table-item-zone .actions a {
          text-decoration: none;
          color: inherit; }
          .invested-table-wrapper .mobile-table-item-zone .actions a .action {
            cursor: pointer; }
            .invested-table-wrapper .mobile-table-item-zone .actions a .action:hover {
              text-decoration: underline; }
    .invested-table-wrapper .table-total-zone {
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      height: 50px;
      padding: 10px;
      margin-top: 10px;
      margin-bottom: 10px;
      background-color: rgba(255, 255, 255, 0.6);
      border: 1px solid rgba(46, 91, 255, 0.08);
      border-radius: 1px; }
      .invested-table-wrapper .table-total-zone .table-total {
        font-size: 18px;
        font-weight: 700;
        color: #4a4a4a;
        text-align: left; }
        .invested-table-wrapper .table-total-zone .table-total:nth-child(1) {
          width: 35%; }
        .invested-table-wrapper .table-total-zone .table-total:nth-child(2) {
          width: 25%; }
        .invested-table-wrapper .table-total-zone .table-total:nth-child(3) {
          display: none; }
        .invested-table-wrapper .table-total-zone .table-total:nth-child(4) {
          width: 20%; }
        .invested-table-wrapper .table-total-zone .table-total:nth-child(5) {
          width: 20%; }
      .invested-table-wrapper .table-total-zone .earnings {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: left; }
        .invested-table-wrapper .table-total-zone .earnings .indicator {
          width: 8px;
          height: 8px;
          border-radius: 50%; }
      .invested-table-wrapper .table-total-zone .actions {
        text-align: right;
        font-size: 15px;
        color: #2e5bff;
        font-weight: 500; }
        .invested-table-wrapper .table-total-zone .actions a {
          text-decoration: none;
          color: inherit; }
          .invested-table-wrapper .table-total-zone .actions a .action {
            cursor: pointer; }
            .invested-table-wrapper .table-total-zone .actions a .action:hover {
              text-decoration: underline; }
  .investable-table-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 200px;
    text-align: left; }
    .investable-table-wrapper .table-error-wrapper {
      height: 200px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
      .investable-table-wrapper .table-error-wrapper .error-content {
        box-sizing: border-box;
        font-size: 24px;
        font-weight: 500;
        text-align: center;
        color: #333333;
        margin-top: 40px; }
    .investable-table-wrapper .table-header-zone {
      display: none; }
    .investable-table-wrapper .mobile-table-header-zone {
      display: flex;
      flex-direction: row;
      margin-bottom: 5px;
      margin-top: 10px;
      box-sizing: border-box;
      padding-left: 10px;
      padding-right: 10px; }
      .investable-table-wrapper .mobile-table-header-zone .table-header {
        font-size: 12px;
        font-weight: 600;
        color: #bfc5d2; }
        .investable-table-wrapper .mobile-table-header-zone .table-header:nth-child(1) {
          width: 35%; }
        .investable-table-wrapper .mobile-table-header-zone .table-header:nth-child(2) {
          width: 20%; }
        .investable-table-wrapper .mobile-table-header-zone .table-header:nth-child(3) {
          width: 20%; }
        .investable-table-wrapper .mobile-table-header-zone .table-header:nth-child(4) {
          width: 15%; }
        .investable-table-wrapper .mobile-table-header-zone .table-header:nth-child(5) {
          width: 5%; }
    .investable-table-wrapper .table-item-wrapper {
      display: none; }
    .investable-table-wrapper .mobile-table-item-wrapper .table-item-zone {
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      min-height: 76px;
      padding: 10px;
      background-color: #ffffff;
      border: 1px solid rgba(46, 91, 255, 0.08);
      border-radius: 1px;
      margin-bottom: 10px; }
      .investable-table-wrapper .mobile-table-item-wrapper .table-item-zone .table-item {
        font-size: 14px;
        font-weight: 500;
        color: #4a4a4a;
        text-align: left;
        box-sizing: border-box; }
        .investable-table-wrapper .mobile-table-item-wrapper .table-item-zone .table-item:nth-child(1) {
          width: 35%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start; }
          .investable-table-wrapper .mobile-table-item-wrapper .table-item-zone .table-item:nth-child(1) .icon {
            font-size: 14px;
            margin-right: 3px; }
          .investable-table-wrapper .mobile-table-item-wrapper .table-item-zone .table-item:nth-child(1) .title {
            font-weight: 600; }
          .investable-table-wrapper .mobile-table-item-wrapper .table-item-zone .table-item:nth-child(1) .location {
            font-size: 10px; }
        .investable-table-wrapper .mobile-table-item-wrapper .table-item-zone .table-item:nth-child(2) {
          width: 20%; }
        .investable-table-wrapper .mobile-table-item-wrapper .table-item-zone .table-item:nth-child(3) {
          width: 20%; }
        .investable-table-wrapper .mobile-table-item-wrapper .table-item-zone .table-item:nth-child(4) {
          width: 15%; }
        .investable-table-wrapper .mobile-table-item-wrapper .table-item-zone .table-item:nth-child(5) {
          width: 5%; }
      .investable-table-wrapper .mobile-table-item-wrapper .table-item-zone .actions {
        text-align: right;
        font-size: 15px;
        color: #ff6600; }
        .investable-table-wrapper .mobile-table-item-wrapper .table-item-zone .actions a {
          color: inherit; }
          .investable-table-wrapper .mobile-table-item-wrapper .table-item-zone .actions a .action {
            cursor: pointer; }
            .investable-table-wrapper .mobile-table-item-wrapper .table-item-zone .actions a .action:hover {
              text-decoration: none; } }
