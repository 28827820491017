/* Global style guide for UI Lib */
/**
 * Browser support
 */
/**
 * Typography
 */
@import url("https://fonts.googleapis.com/css?family=Rajdhani:300,400,500,600,700&subset=latin-ext");
/**
 * Colors
 */
/**
 * Page -- UILib Specific Variables
 */
/**
 * Card -- UILib Specific Variables
 */
@media only screen and (min-width: 769px) {
  .card {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
  .card-centered {
    margin: 0 auto; }
  .card-type-auth {
    box-sizing: border-box;
    border-radius: 8px;
    width: 530px;
    min-height: 675px;
    margin-top: 80px;
    padding: 60px 50px 40px 50px; }
  .card-background-color-blue-0 {
    background-color: #99ccff; }
  .card-background-color-blue-1 {
    background-color: #6699ff; }
  .card-background-color-blue-2 {
    background-color: #3366ff; }
  .card-background-color-blue-3 {
    background-color: #0033ff; }
  .card-background-color-blue-4 {
    background-color: #0033cc; }
  .card-background-color-blue-5 {
    background-color: #003399; }
  .card-background-color-blue-6 {
    background-color: #003366; }
  .card-background-color-green-0 {
    background-color: #99ff99; }
  .card-background-color-green-1 {
    background-color: #66ff66; }
  .card-background-color-green-2 {
    background-color: #66ff33; }
  .card-background-color-green-3 {
    background-color: #33cb00; }
  .card-background-color-green-4 {
    background-color: #33cc00; }
  .card-background-color-green-5 {
    background-color: #339900; }
  .card-background-color-green-6 {
    background-color: #336600; }
  .card-background-color-orange-0 {
    background-color: #ffcc99; }
  .card-background-color-orange-1 {
    background-color: #ff9966; }
  .card-background-color-orange-2 {
    background-color: #ff9933; }
  .card-background-color-orange-3 {
    background-color: #ff6600; }
  .card-background-color-orange-4 {
    background-color: #cc662b; }
  .card-background-color-orange-5 {
    background-color: #993300; }
  .card-background-color-orange-6 {
    background-color: #663300; }
  .card-background-color-grey-0 {
    background-color: #fafafa; }
  .card-background-color-grey-1 {
    background-color: #eaeaea; }
  .card-background-color-grey-2 {
    background-color: #cccccc; }
  .card-background-color-grey-3 {
    background-color: #999999; }
  .card-background-color-grey-4 {
    background-color: #777777; }
  .card-background-color-grey-5 {
    background-color: #666666; }
  .card-background-color-grey-6 {
    background-color: #333333; }
  .card-background-color-white {
    background-color: #ffffff; }
  .card-background-color-black {
    background-color: #000000; }
  .card-background-color-light-grey {
    background-color: #4a4a4a; }
  .card-background-color-light-pink {
    background-color: #fef0ef; }
  .card-background-color-pink {
    background-color: #fabeb9; }
  .card-content {
    display: flex;
    flex-direction: column;
    align-items: center; }
  .card-content-align-left {
    text-align: left; }
  .card-content-align-right {
    text-align: right; }
  .card-content-align-center {
    text-align: center; }
  .card-content-color-blue-0 {
    color: #99ccff; }
  .card-content-color-blue-1 {
    color: #6699ff; }
  .card-content-color-blue-2 {
    color: #3366ff; }
  .card-content-color-blue-3 {
    color: #0033ff; }
  .card-content-color-blue-4 {
    color: #0033cc; }
  .card-content-color-blue-5 {
    color: #003399; }
  .card-content-color-blue-6 {
    color: #003366; }
  .card-content-color-green-0 {
    color: #99ff99; }
  .card-content-color-green-1 {
    color: #66ff66; }
  .card-content-color-green-2 {
    color: #66ff33; }
  .card-content-color-green-3 {
    color: #33cb00; }
  .card-content-color-green-4 {
    color: #33cc00; }
  .card-content-color-green-5 {
    color: #339900; }
  .card-content-color-green-6 {
    color: #336600; }
  .card-content-color-orange-0 {
    color: #ffcc99; }
  .card-content-color-orange-1 {
    color: #ff9966; }
  .card-content-color-orange-2 {
    color: #ff9933; }
  .card-content-color-orange-3 {
    color: #ff6600; }
  .card-content-color-orange-4 {
    color: #cc662b; }
  .card-content-color-orange-5 {
    color: #993300; }
  .card-content-color-orange-6 {
    color: #663300; }
  .card-content-color-grey-0 {
    color: #fafafa; }
  .card-content-color-grey-1 {
    color: #eaeaea; }
  .card-content-color-grey-2 {
    color: #cccccc; }
  .card-content-color-grey-3 {
    color: #999999; }
  .card-content-color-grey-4 {
    color: #777777; }
  .card-content-color-grey-5 {
    color: #666666; }
  .card-content-color-grey-6 {
    color: #333333; }
  .card-content-color-white {
    color: #ffffff; }
  .card-content-color-black {
    color: #000000; }
  .card-content-color-light-grey {
    color: #4a4a4a; }
  .card-content-color-light-pink {
    color: #fef0ef; }
  .card-content-color-pink {
    color: #fabeb9; } }

@media only screen and (max-width: 768px) {
  .card {
    width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #ffffff !important; }
  .card-centered {
    margin: 0 auto; }
  .card-type-auth {
    box-sizing: border-box;
    border-radius: 8px;
    width: 90vw;
    min-height: 60vh;
    margin-top: 60px;
    padding: 40px 20px; }
  .card-background-color-blue-0 {
    background-color: #99ccff; }
  .card-background-color-blue-1 {
    background-color: #6699ff; }
  .card-background-color-blue-2 {
    background-color: #3366ff; }
  .card-background-color-blue-3 {
    background-color: #0033ff; }
  .card-background-color-blue-4 {
    background-color: #0033cc; }
  .card-background-color-blue-5 {
    background-color: #003399; }
  .card-background-color-blue-6 {
    background-color: #003366; }
  .card-background-color-green-0 {
    background-color: #99ff99; }
  .card-background-color-green-1 {
    background-color: #66ff66; }
  .card-background-color-green-2 {
    background-color: #66ff33; }
  .card-background-color-green-3 {
    background-color: #33cb00; }
  .card-background-color-green-4 {
    background-color: #33cc00; }
  .card-background-color-green-5 {
    background-color: #339900; }
  .card-background-color-green-6 {
    background-color: #336600; }
  .card-background-color-orange-0 {
    background-color: #ffcc99; }
  .card-background-color-orange-1 {
    background-color: #ff9966; }
  .card-background-color-orange-2 {
    background-color: #ff9933; }
  .card-background-color-orange-3 {
    background-color: #ff6600; }
  .card-background-color-orange-4 {
    background-color: #cc662b; }
  .card-background-color-orange-5 {
    background-color: #993300; }
  .card-background-color-orange-6 {
    background-color: #663300; }
  .card-background-color-grey-0 {
    background-color: #fafafa; }
  .card-background-color-grey-1 {
    background-color: #eaeaea; }
  .card-background-color-grey-2 {
    background-color: #cccccc; }
  .card-background-color-grey-3 {
    background-color: #999999; }
  .card-background-color-grey-4 {
    background-color: #777777; }
  .card-background-color-grey-5 {
    background-color: #666666; }
  .card-background-color-grey-6 {
    background-color: #333333; }
  .card-background-color-white {
    background-color: #ffffff; }
  .card-background-color-black {
    background-color: #000000; }
  .card-background-color-light-grey {
    background-color: #4a4a4a; }
  .card-background-color-light-pink {
    background-color: #fef0ef; }
  .card-background-color-pink {
    background-color: #fabeb9; }
  .card-content {
    display: flex;
    flex-direction: column;
    align-items: center; }
  .card-content-align-left {
    text-align: left; }
  .card-content-align-right {
    text-align: right; }
  .card-content-align-center {
    text-align: center; }
  .card-content-color-blue-0 {
    color: #99ccff; }
  .card-content-color-blue-1 {
    color: #6699ff; }
  .card-content-color-blue-2 {
    color: #3366ff; }
  .card-content-color-blue-3 {
    color: #0033ff; }
  .card-content-color-blue-4 {
    color: #0033cc; }
  .card-content-color-blue-5 {
    color: #003399; }
  .card-content-color-blue-6 {
    color: #003366; }
  .card-content-color-green-0 {
    color: #99ff99; }
  .card-content-color-green-1 {
    color: #66ff66; }
  .card-content-color-green-2 {
    color: #66ff33; }
  .card-content-color-green-3 {
    color: #33cb00; }
  .card-content-color-green-4 {
    color: #33cc00; }
  .card-content-color-green-5 {
    color: #339900; }
  .card-content-color-green-6 {
    color: #336600; }
  .card-content-color-orange-0 {
    color: #ffcc99; }
  .card-content-color-orange-1 {
    color: #ff9966; }
  .card-content-color-orange-2 {
    color: #ff9933; }
  .card-content-color-orange-3 {
    color: #ff6600; }
  .card-content-color-orange-4 {
    color: #cc662b; }
  .card-content-color-orange-5 {
    color: #993300; }
  .card-content-color-orange-6 {
    color: #663300; }
  .card-content-color-grey-0 {
    color: #fafafa; }
  .card-content-color-grey-1 {
    color: #eaeaea; }
  .card-content-color-grey-2 {
    color: #cccccc; }
  .card-content-color-grey-3 {
    color: #999999; }
  .card-content-color-grey-4 {
    color: #777777; }
  .card-content-color-grey-5 {
    color: #666666; }
  .card-content-color-grey-6 {
    color: #333333; }
  .card-content-color-white {
    color: #ffffff; }
  .card-content-color-black {
    color: #000000; }
  .card-content-color-light-grey {
    color: #4a4a4a; }
  .card-content-color-light-pink {
    color: #fef0ef; }
  .card-content-color-pink {
    color: #fabeb9; } }
