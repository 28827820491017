/* Global style guide for UI Lib */
/**
 * Browser support
 */
/**
 * Typography
 */
@import url("https://fonts.googleapis.com/css?family=Rajdhani:300,400,500,600,700&subset=latin-ext");
/**
 * Colors
 */
/**
 * Page -- UILib Specific Variables
 */
/**
 * Card -- UILib Specific Variables
 */
@media only screen and (min-width: 769px) {
  .container-page {
    width: 100vw;
    min-height: 100vh;
    display: inline-table; }
  .container-content {
    margin: 0 auto;
    width: 1366px;
    height: 100%; }
  .container-background-color-blue-0 {
    background-color: #99ccff; }
  .container-background-color-blue-1 {
    background-color: #6699ff; }
  .container-background-color-blue-2 {
    background-color: #3366ff; }
  .container-background-color-blue-3 {
    background-color: #0033ff; }
  .container-background-color-blue-4 {
    background-color: #0033cc; }
  .container-background-color-blue-5 {
    background-color: #003399; }
  .container-background-color-blue-6 {
    background-color: #003366; }
  .container-background-color-green-0 {
    background-color: #99ff99; }
  .container-background-color-green-1 {
    background-color: #66ff66; }
  .container-background-color-green-2 {
    background-color: #66ff33; }
  .container-background-color-green-3 {
    background-color: #33cb00; }
  .container-background-color-green-4 {
    background-color: #33cc00; }
  .container-background-color-green-5 {
    background-color: #339900; }
  .container-background-color-green-6 {
    background-color: #336600; }
  .container-background-color-orange-0 {
    background-color: #ffcc99; }
  .container-background-color-orange-1 {
    background-color: #ff9966; }
  .container-background-color-orange-2 {
    background-color: #ff9933; }
  .container-background-color-orange-3 {
    background-color: #ff6600; }
  .container-background-color-orange-4 {
    background-color: #cc662b; }
  .container-background-color-orange-5 {
    background-color: #993300; }
  .container-background-color-orange-6 {
    background-color: #663300; }
  .container-background-color-grey-0 {
    background-color: #fafafa; }
  .container-background-color-grey-1 {
    background-color: #eaeaea; }
  .container-background-color-grey-2 {
    background-color: #cccccc; }
  .container-background-color-grey-3 {
    background-color: #999999; }
  .container-background-color-grey-4 {
    background-color: #777777; }
  .container-background-color-grey-5 {
    background-color: #666666; }
  .container-background-color-grey-6 {
    background-color: #333333; }
  .container-background-color-white {
    background-color: #ffffff; }
  .container-background-color-black {
    background-color: #000000; }
  .container-background-color-light-grey {
    background-color: #4a4a4a; }
  .container-background-color-light-pink {
    background-color: #fef0ef; }
  .container-background-color-pink {
    background-color: #fabeb9; } }

@media only screen and (max-width: 768px) {
  .container-page {
    width: 100vw;
    min-height: 100vh;
    display: inline-table;
    box-sizing: border-box;
    padding-bottom: 50px; }
  .container-content {
    margin: 0 auto;
    width: 90vw;
    height: 100%; }
  .container-background-color-blue-0 {
    background-color: #99ccff; }
  .container-background-color-blue-1 {
    background-color: #6699ff; }
  .container-background-color-blue-2 {
    background-color: #3366ff; }
  .container-background-color-blue-3 {
    background-color: #0033ff; }
  .container-background-color-blue-4 {
    background-color: #0033cc; }
  .container-background-color-blue-5 {
    background-color: #003399; }
  .container-background-color-blue-6 {
    background-color: #003366; }
  .container-background-color-green-0 {
    background-color: #99ff99; }
  .container-background-color-green-1 {
    background-color: #66ff66; }
  .container-background-color-green-2 {
    background-color: #66ff33; }
  .container-background-color-green-3 {
    background-color: #33cb00; }
  .container-background-color-green-4 {
    background-color: #33cc00; }
  .container-background-color-green-5 {
    background-color: #339900; }
  .container-background-color-green-6 {
    background-color: #336600; }
  .container-background-color-orange-0 {
    background-color: #ffcc99; }
  .container-background-color-orange-1 {
    background-color: #ff9966; }
  .container-background-color-orange-2 {
    background-color: #ff9933; }
  .container-background-color-orange-3 {
    background-color: #ff6600; }
  .container-background-color-orange-4 {
    background-color: #cc662b; }
  .container-background-color-orange-5 {
    background-color: #993300; }
  .container-background-color-orange-6 {
    background-color: #663300; }
  .container-background-color-grey-0 {
    background-color: #fafafa; }
  .container-background-color-grey-1 {
    background-color: #eaeaea; }
  .container-background-color-grey-2 {
    background-color: #cccccc; }
  .container-background-color-grey-3 {
    background-color: #999999; }
  .container-background-color-grey-4 {
    background-color: #777777; }
  .container-background-color-grey-5 {
    background-color: #666666; }
  .container-background-color-grey-6 {
    background-color: #333333; }
  .container-background-color-white {
    background-color: #ffffff; }
  .container-background-color-black {
    background-color: #000000; }
  .container-background-color-light-grey {
    background-color: #4a4a4a; }
  .container-background-color-light-pink {
    background-color: #fef0ef; }
  .container-background-color-pink {
    background-color: #fabeb9; } }
