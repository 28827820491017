/* Global style guide for UI Lib */
/**
 * Browser support
 */
/**
 * Typography
 */
@import url("https://fonts.googleapis.com/css?family=Rajdhani:300,400,500,600,700&subset=latin-ext");
/**
 * Colors
 */
/**
 * Page -- UILib Specific Variables
 */
/**
 * Card -- UILib Specific Variables
 */
@media only screen and (min-width: 769px) {
  .commitment-page-wrapper {
    box-sizing: border-box;
    width: 1130px;
    margin: 0 auto;
    padding-top: 40px; }
    .commitment-page-wrapper .loading-wrapper {
      width: 100%;
      height: calc(100vh - 140px);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
    .commitment-page-wrapper .tags-wrapper {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 10px; }
      .commitment-page-wrapper .tags-wrapper .tag {
        font-size: 14px;
        line-height: 18px;
        font-weight: 600;
        text-align: center;
        background-color: #eaeaea;
        color: #777777;
        padding: 4px 10px;
        border-radius: 3px;
        margin-right: 10px; }
    .commitment-page-wrapper .header-zone-wrapper {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      margin-bottom: 60px; }
      .commitment-page-wrapper .header-zone-wrapper .header-zone {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start; }
        .commitment-page-wrapper .header-zone-wrapper .header-zone a {
          text-decoration: none; }
        .commitment-page-wrapper .header-zone-wrapper .header-zone .header {
          font-size: 44px;
          color: #000000;
          font-weight: 700; }
          .commitment-page-wrapper .header-zone-wrapper .header-zone .header:hover {
            text-decoration: underline; }
        .commitment-page-wrapper .header-zone-wrapper .header-zone .subheader {
          font-size: 16px;
          line-height: 24px;
          color: #000000;
          font-weight: 600; }
      .commitment-page-wrapper .header-zone-wrapper .statistics-zone .project-statistics {
        border-radius: 3px;
        padding: 15px 0;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: #ffffff; }
        .commitment-page-wrapper .header-zone-wrapper .statistics-zone .project-statistics .viewer-zone {
          padding: 0 30px;
          border-right: 1px solid #eaeaea; }
          .commitment-page-wrapper .header-zone-wrapper .statistics-zone .project-statistics .viewer-zone .header {
            font-size: 24px;
            line-height: 1;
            font-weight: 700; }
          .commitment-page-wrapper .header-zone-wrapper .statistics-zone .project-statistics .viewer-zone .subheader {
            font-size: 16px;
            font-weight: 500; }
        .commitment-page-wrapper .header-zone-wrapper .statistics-zone .project-statistics .backer-zone {
          padding: 0 30px; }
          .commitment-page-wrapper .header-zone-wrapper .statistics-zone .project-statistics .backer-zone .header {
            font-size: 24px;
            line-height: 1;
            font-weight: 700; }
          .commitment-page-wrapper .header-zone-wrapper .statistics-zone .project-statistics .backer-zone .subheader {
            font-size: 16px;
            font-weight: 500; }
    .commitment-page-wrapper .require-login-zone-wrapper {
      width: 100%;
      padding-bottom: 100px; }
      .commitment-page-wrapper .require-login-zone-wrapper .require-login {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center; }
        .commitment-page-wrapper .require-login-zone-wrapper .require-login .header {
          font-size: 36px;
          color: #4a4a4a;
          font-weight: 700; }
        .commitment-page-wrapper .require-login-zone-wrapper .require-login .subheader {
          font-size: 18px;
          line-height: 26px;
          color: #4a4a4a;
          font-weight: 500;
          margin: 0 45px; }
        .commitment-page-wrapper .require-login-zone-wrapper .require-login .actions {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          align-items: center; }
          .commitment-page-wrapper .require-login-zone-wrapper .require-login .actions .action-button {
            height: 60px;
            width: 175px; }
    .commitment-page-wrapper .commitment-main-wrapper {
      max-width: 1130px;
      margin: 0 auto 0 auto;
      padding-bottom: 100px;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between; }
      .commitment-page-wrapper .commitment-main-wrapper .wallet-pane {
        width: 230px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start; }
        .commitment-page-wrapper .commitment-main-wrapper .wallet-pane .action-buttons {
          display: flex;
          flex-direction: row;
          margin-top: 20px;
          justify-content: space-around; }
          .commitment-page-wrapper .commitment-main-wrapper .wallet-pane .action-buttons .button {
            width: 100px;
            padding: 7px 7px;
            font-weight: 600; }
        .commitment-page-wrapper .commitment-main-wrapper .wallet-pane .header-section {
          margin: 20px 0 20px 0; }
        .commitment-page-wrapper .commitment-main-wrapper .wallet-pane .wallet-margin {
          margin-bottom: 20px; }
        .commitment-page-wrapper .commitment-main-wrapper .wallet-pane .management-action {
          cursor: pointer; }
          .commitment-page-wrapper .commitment-main-wrapper .wallet-pane .management-action:hover {
            text-decoration: underline; }
      .commitment-page-wrapper .commitment-main-wrapper .commitment-pane {
        width: 800px;
        display: flex;
        flex-direction: column; }
        .commitment-page-wrapper .commitment-main-wrapper .commitment-pane .commitment-selection-zone {
          width: 100%;
          height: 360px;
          background-color: #ffffff;
          border-radius: 5px;
          box-sizing: border-box;
          padding: 50px 50px 80px 50px;
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-bottom: 60px; }
          .commitment-page-wrapper .commitment-main-wrapper .commitment-pane .commitment-selection-zone .header {
            font-size: 20px;
            font-weight: 700;
            color: #4a4a4a;
            margin: 0; }
          .commitment-page-wrapper .commitment-main-wrapper .commitment-pane .commitment-selection-zone .subheader {
            font-size: 16px;
            line-height: 24px;
            font-weight: 500;
            color: #4a4a4a;
            margin-bottom: 50px; }
          .commitment-page-wrapper .commitment-main-wrapper .commitment-pane .commitment-selection-zone .commitment-pricing-table {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-end; }
            .commitment-page-wrapper .commitment-main-wrapper .commitment-pane .commitment-selection-zone .commitment-pricing-table .commitment-pricing-active-item {
              background-color: #ff6600 !important;
              border-color: #ff6600 !important;
              color: #ffffff !important; }
            .commitment-page-wrapper .commitment-main-wrapper .commitment-pane .commitment-selection-zone .commitment-pricing-table .commitment-pricing-item {
              height: 100px;
              width: 100px;
              border: 1px solid #777777;
              border-radius: 4px;
              color: #777777;
              position: relative;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              cursor: pointer; }
              .commitment-page-wrapper .commitment-main-wrapper .commitment-pane .commitment-selection-zone .commitment-pricing-table .commitment-pricing-item .pricing-header {
                font-size: 20px;
                line-height: 20px;
                font-weight: 700;
                color: inherit;
                margin: 0; }
              .commitment-page-wrapper .commitment-main-wrapper .commitment-pane .commitment-selection-zone .commitment-pricing-table .commitment-pricing-item .pricing-subheader {
                font-size: 20px;
                line-height: 20px;
                font-weight: 500;
                color: inherit; }
              .commitment-page-wrapper .commitment-main-wrapper .commitment-pane .commitment-selection-zone .commitment-pricing-table .commitment-pricing-item .preferred {
                position: absolute;
                top: -30px;
                height: 25px;
                width: 100%;
                border: 1px solid #ff6600;
                border-radius: 3px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                font-size: 14px;
                line-height: 18px;
                color: #ff6600;
                font-weight: 600; }
        .commitment-page-wrapper .commitment-main-wrapper .commitment-pane .commitment-calculation-zone {
          width: 100%;
          background-color: #ffffff;
          border-radius: 5px;
          box-sizing: border-box;
          padding: 30px 50px;
          margin-bottom: 50px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center; }
          .commitment-page-wrapper .commitment-main-wrapper .commitment-pane .commitment-calculation-zone div .header {
            font-size: 20px;
            line-height: 20px;
            color: #4a4a4a;
            font-weight: 500; }
          .commitment-page-wrapper .commitment-main-wrapper .commitment-pane .commitment-calculation-zone div .subheader {
            font-size: 36px;
            line-height: 45px;
            color: #4a4a4a;
            font-weight: 700; }
          .commitment-page-wrapper .commitment-main-wrapper .commitment-pane .commitment-calculation-zone div .decide-later {
            font-size: 22px; }
          .commitment-page-wrapper .commitment-main-wrapper .commitment-pane .commitment-calculation-zone div .irr {
            font-size: 28px; }
          .commitment-page-wrapper .commitment-main-wrapper .commitment-pane .commitment-calculation-zone div .calculation {
            color: #ff6600; }
        .commitment-page-wrapper .commitment-main-wrapper .commitment-pane .submission-zone {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center; }
          .commitment-page-wrapper .commitment-main-wrapper .commitment-pane .submission-zone .left-pane {
            font-size: 14px;
            line-height: 24px;
            color: #4a4a4a;
            font-weight: 500;
            text-align: left; }
          .commitment-page-wrapper .commitment-main-wrapper .commitment-pane .submission-zone .action-button {
            width: 300px;
            height: 60px;
            font-size: 24px;
            font-weight: 600;
            margin-left: 75px; }
      .commitment-page-wrapper .commitment-main-wrapper .commitment-success-pane {
        width: 800px;
        display: flex;
        flex-direction: column; }
        .commitment-page-wrapper .commitment-main-wrapper .commitment-success-pane .commitment-information-zone {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 120px;
          margin-top: 40px; }
          .commitment-page-wrapper .commitment-main-wrapper .commitment-success-pane .commitment-information-zone .information-right-zone {
            margin-left: 30px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start; }
            .commitment-page-wrapper .commitment-main-wrapper .commitment-success-pane .commitment-information-zone .information-right-zone .header {
              font-size: 30px;
              line-height: 38px;
              font-weight: 700;
              color: #4a4a4a; }
            .commitment-page-wrapper .commitment-main-wrapper .commitment-success-pane .commitment-information-zone .information-right-zone .subheader {
              font-size: 18px;
              line-height: 26px;
              font-weight: 500;
              color: #4a4a4a; }
        .commitment-page-wrapper .commitment-main-wrapper .commitment-success-pane .commitment-completion-zone {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          background-color: #ffffff;
          border-radius: 5px;
          box-sizing: border-box;
          padding: 35px 40px 35px 50px; }
          .commitment-page-wrapper .commitment-main-wrapper .commitment-success-pane .commitment-completion-zone div .header {
            font-size: 20px;
            line-height: 20px;
            color: #4a4a4a;
            font-weight: 500; }
          .commitment-page-wrapper .commitment-main-wrapper .commitment-success-pane .commitment-completion-zone div .subheader {
            font-size: 36px;
            line-height: 36px;
            color: #4a4a4a;
            font-weight: 700; }
          .commitment-page-wrapper .commitment-main-wrapper .commitment-success-pane .commitment-completion-zone .action {
            width: 240px;
            height: 60px; }
    .commitment-page-wrapper .commitment-failure-wrapper {
      align-items: center;
      padding-left: 70px;
      padding-right: 70px; }
      .commitment-page-wrapper .commitment-failure-wrapper .description {
        padding-left: 30px;
        padding-right: 30px; }
      .commitment-page-wrapper .commitment-failure-wrapper .action {
        width: 200px;
        height: 60px; }
  .commitment-page-padding {
    padding-bottom: 80px; } }

@media only screen and (max-width: 768px) {
  .commitment-page-wrapper {
    box-sizing: border-box;
    width: 90vw;
    margin: 0 auto;
    padding-top: 30px; }
    .commitment-page-wrapper .loading-wrapper {
      width: 100%;
      height: calc(100vh - 100px);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
    .commitment-page-wrapper .tags-wrapper {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      flex-wrap: wrap;
      align-items: center;
      margin-bottom: 10px; }
      .commitment-page-wrapper .tags-wrapper .tag {
        font-size: 14px;
        line-height: 18px;
        font-weight: 600;
        text-align: center;
        background-color: #eaeaea;
        color: #777777;
        padding: 4px 10px;
        border-radius: 3px;
        margin-right: 10px; }
    .commitment-page-wrapper .header-zone-wrapper {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: 20px; }
      .commitment-page-wrapper .header-zone-wrapper .header-zone {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start; }
        .commitment-page-wrapper .header-zone-wrapper .header-zone a {
          text-decoration: none; }
        .commitment-page-wrapper .header-zone-wrapper .header-zone .header {
          font-size: 30px;
          color: #000000;
          font-weight: 700;
          text-align: left; }
          .commitment-page-wrapper .header-zone-wrapper .header-zone .header:hover {
            text-decoration: underline; }
        .commitment-page-wrapper .header-zone-wrapper .header-zone .subheader {
          font-size: 16px;
          line-height: 24px;
          color: #4a4a4a;
          font-weight: 500; }
      .commitment-page-wrapper .header-zone-wrapper .statistics-zone {
        display: none; }
    .commitment-page-wrapper .require-login-zone-wrapper {
      width: 100%;
      padding-bottom: 20px; }
      .commitment-page-wrapper .require-login-zone-wrapper .require-login {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center; }
        .commitment-page-wrapper .require-login-zone-wrapper .require-login .header {
          font-size: 20px;
          color: #4a4a4a;
          font-weight: 700; }
        .commitment-page-wrapper .require-login-zone-wrapper .require-login .subheader {
          font-size: 16px;
          line-height: 26px;
          color: #4a4a4a;
          font-weight: 500;
          margin: 0 20px; }
        .commitment-page-wrapper .require-login-zone-wrapper .require-login .actions {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          align-items: center; }
          .commitment-page-wrapper .require-login-zone-wrapper .require-login .actions .action-button {
            height: 40px;
            width: 120px; }
    .commitment-page-wrapper .commitment-main-wrapper {
      max-width: 94vw;
      width: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between; }
      .commitment-page-wrapper .commitment-main-wrapper .wallet-pane {
        display: none; }
      .commitment-page-wrapper .commitment-main-wrapper .commitment-pane {
        width: 100%;
        display: flex;
        flex-direction: column; }
        .commitment-page-wrapper .commitment-main-wrapper .commitment-pane .commitment-selection-zone {
          width: 100%;
          background-color: #ffffff;
          border-radius: 5px;
          box-sizing: border-box;
          padding: 20px 20px 30px 20px;
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-bottom: 30px; }
          .commitment-page-wrapper .commitment-main-wrapper .commitment-pane .commitment-selection-zone .header {
            font-size: 24px;
            font-weight: 700;
            color: #4a4a4a;
            margin: 0; }
          .commitment-page-wrapper .commitment-main-wrapper .commitment-pane .commitment-selection-zone .subheader {
            font-size: 14px;
            line-height: 24px;
            font-weight: 500;
            color: #4a4a4a;
            margin-bottom: 30px; }
          .commitment-page-wrapper .commitment-main-wrapper .commitment-pane .commitment-selection-zone .commitment-pricing-table {
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: flex-end; }
            .commitment-page-wrapper .commitment-main-wrapper .commitment-pane .commitment-selection-zone .commitment-pricing-table .commitment-pricing-active-item {
              background-color: #ff6600 !important;
              border-color: #ff6600 !important;
              color: #ffffff !important; }
            .commitment-page-wrapper .commitment-main-wrapper .commitment-pane .commitment-selection-zone .commitment-pricing-table .commitment-pricing-item {
              height: 80px;
              width: 30%;
              margin-bottom: 50px;
              border: 1px solid #777777;
              border-radius: 4px;
              color: #777777;
              position: relative;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              cursor: pointer; }
              .commitment-page-wrapper .commitment-main-wrapper .commitment-pane .commitment-selection-zone .commitment-pricing-table .commitment-pricing-item .pricing-header {
                font-size: 16px;
                line-height: 20px;
                font-weight: 700;
                color: inherit;
                margin: 0; }
              .commitment-page-wrapper .commitment-main-wrapper .commitment-pane .commitment-selection-zone .commitment-pricing-table .commitment-pricing-item .pricing-subheader {
                font-size: 16px;
                line-height: 20px;
                font-weight: 500;
                color: inherit; }
              .commitment-page-wrapper .commitment-main-wrapper .commitment-pane .commitment-selection-zone .commitment-pricing-table .commitment-pricing-item .preferred {
                position: absolute;
                top: -30px;
                height: 25px;
                width: 100%;
                border: 1px solid #ff6600;
                border-radius: 3px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                font-size: 14px;
                line-height: 18px;
                color: #ff6600;
                font-weight: 600; }
        .commitment-page-wrapper .commitment-main-wrapper .commitment-pane .commitment-calculation-zone {
          width: 100%;
          background-color: #ffffff;
          border-radius: 5px;
          box-sizing: border-box;
          padding: 20px;
          margin-bottom: 30px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center; }
          .commitment-page-wrapper .commitment-main-wrapper .commitment-pane .commitment-calculation-zone div .header {
            font-size: 16px;
            line-height: 20px;
            color: #4a4a4a;
            font-weight: 500; }
          .commitment-page-wrapper .commitment-main-wrapper .commitment-pane .commitment-calculation-zone div .subheader {
            font-size: 24px;
            line-height: 45px;
            color: #4a4a4a;
            font-weight: 700; }
          .commitment-page-wrapper .commitment-main-wrapper .commitment-pane .commitment-calculation-zone div .decide-later {
            font-size: 18px; }
          .commitment-page-wrapper .commitment-main-wrapper .commitment-pane .commitment-calculation-zone div .irr {
            font-size: 24px; }
          .commitment-page-wrapper .commitment-main-wrapper .commitment-pane .commitment-calculation-zone div .calculation {
            color: #ff6600; }
        .commitment-page-wrapper .commitment-main-wrapper .commitment-pane .submission-zone {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center; }
          .commitment-page-wrapper .commitment-main-wrapper .commitment-pane .submission-zone .left-pane {
            font-size: 14px;
            line-height: 24px;
            color: #4a4a4a;
            font-weight: 500;
            text-align: left;
            margin-bottom: 20px; }
          .commitment-page-wrapper .commitment-main-wrapper .commitment-pane .submission-zone .action-button {
            width: 100%;
            height: 60px;
            font-size: 24px;
            font-weight: 600; }
      .commitment-page-wrapper .commitment-main-wrapper .commitment-success-pane {
        width: 100%;
        display: flex;
        flex-direction: column; }
        .commitment-page-wrapper .commitment-main-wrapper .commitment-success-pane .commitment-information-zone {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 60px;
          margin-top: 40px; }
          .commitment-page-wrapper .commitment-main-wrapper .commitment-success-pane .commitment-information-zone .information-right-zone {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start; }
            .commitment-page-wrapper .commitment-main-wrapper .commitment-success-pane .commitment-information-zone .information-right-zone .header {
              text-align: center;
              margin-top: 20px;
              margin-bottom: 20px;
              font-size: 24px;
              line-height: 30px;
              font-weight: 700;
              color: #4a4a4a; }
            .commitment-page-wrapper .commitment-main-wrapper .commitment-success-pane .commitment-information-zone .information-right-zone .description {
              text-align: center;
              font-size: 16px;
              line-height: 22px;
              font-weight: 500;
              color: #4a4a4a; }
        .commitment-page-wrapper .commitment-main-wrapper .commitment-success-pane .commitment-completion-zone {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          background-color: #ffffff;
          border-radius: 5px;
          box-sizing: border-box;
          padding: 20px; }
          .commitment-page-wrapper .commitment-main-wrapper .commitment-success-pane .commitment-completion-zone div .header {
            font-size: 16px;
            line-height: 20px;
            color: #4a4a4a;
            font-weight: 500; }
          .commitment-page-wrapper .commitment-main-wrapper .commitment-success-pane .commitment-completion-zone div .subheader {
            margin-bottom: 20px;
            font-size: 24px;
            line-height: 36px;
            color: #4a4a4a;
            font-weight: 700; }
          .commitment-page-wrapper .commitment-main-wrapper .commitment-success-pane .commitment-completion-zone .action {
            width: 100%;
            height: 60px; }
    .commitment-page-wrapper .commitment-failure-wrapper {
      align-items: center; }
      .commitment-page-wrapper .commitment-failure-wrapper .icon {
        margin-top: 30px; }
      .commitment-page-wrapper .commitment-failure-wrapper .description {
        padding-left: 30px;
        padding-right: 30px;
        margin-bottom: 30px;
        margin-top: 30px; }
      .commitment-page-wrapper .commitment-failure-wrapper .action {
        width: 100%;
        height: 60px; }
  .commitment-page-padding {
    padding-bottom: 40px; } }
