/* Global style guide for UI Lib */
/**
 * Browser support
 */
/**
 * Typography
 */
@import url("https://fonts.googleapis.com/css?family=Rajdhani:300,400,500,600,700&subset=latin-ext");
/**
 * Colors
 */
/**
 * Page -- UILib Specific Variables
 */
/**
 * Card -- UILib Specific Variables
 */
@media only screen and (min-width: 769px) {
  .wallet {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
    .wallet .header-zone {
      width: 100%;
      text-align: left; }
      .wallet .header-zone .header {
        font-size: 2em;
        font-weight: 600;
        line-height: 1.25;
        color: inherit; }
      .wallet .header-zone .subheader {
        font-size: 14px;
        font-weight: 500;
        color: inherit; }
    .wallet .balance-zone {
      width: 100%;
      text-align: right;
      color: inherit; }
      .wallet .balance-zone .integer {
        font-size: 28px;
        font-weight: 700; }
      .wallet .balance-zone .float {
        font-size: 28px;
        font-weight: 700; }
      .wallet .balance-zone .currency {
        margin-left: 4px;
        font-size: 14px;
        font-weight: 700; }
      .wallet .balance-zone .create-now {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        color: inherit; }
        .wallet .balance-zone .create-now .text {
          font-size: 16px;
          font-weight: 600;
          margin-left: 8px;
          color: inherit; }
        .wallet .balance-zone .create-now .plus {
          font-size: 8px;
          color: inherit; }
  .wallet-small {
    box-sizing: border-box;
    max-width: 230px;
    border-radius: 8px; }
  .wallet-small-total {
    width: 100%;
    height: 135px;
    background-color: #ffffff;
    color: #333333;
    padding: 10px 15px; }
  .wallet-small-wallet {
    position: relative;
    cursor: pointer;
    width: 100%;
    height: 120px;
    background-color: transparent;
    color: #333333;
    padding: 10px 15px;
    border: 1px solid #333333; }
    .wallet-small-wallet .add {
      position: absolute;
      font-size: 12px;
      top: 10px;
      right: 10px; }
    .wallet-small-wallet .deposit {
      position: absolute;
      box-sizing: border-box;
      top: 10px;
      right: 10px;
      background-color: #ffffff;
      color: #0033ff;
      border-radius: 2px;
      font-size: 12px;
      font-weight: 600;
      padding: 3px 6px; }
  .wallet-small-wallet-hovered {
    background-color: #0033ff;
    border-color: #0033ff;
    color: #ffffff !important; }
  .wallet-disabled {
    color: #999999 !important;
    border-color: #999999 !important;
    cursor: pointer; }
  .currency-switch-zone {
    width: 100%;
    margin: 30px 0;
    font-size: 18px;
    font-weight: 500;
    color: #777777; }
  .currency-switch-align-left {
    text-align: left; }
  .currency-switch-align-right {
    text-align: right; }
  .currency-switch-align-center {
    text-align: center; }
  .currency-switch {
    display: inline-flex;
    border: 1px solid #777777;
    border-radius: 4px; }
    .currency-switch .item {
      cursor: pointer;
      padding: 10px; }
      .currency-switch .item:first-child {
        border-right: 1px solid #777777; }
    .currency-switch .active {
      background-color: #777777;
      color: #fafafa; } }

@media only screen and (max-width: 768px) {
  .wallet {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between; }
    .wallet .header-zone {
      width: 40vw;
      text-align: left; }
      .wallet .header-zone .header {
        font-size: 16px;
        font-weight: 600;
        line-height: 1.25;
        color: inherit; }
      .wallet .header-zone .subheader {
        font-size: 12px;
        font-weight: 500;
        color: inherit; }
    .wallet .balance-zone {
      width: 40vw;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      text-align: right;
      margin-top: 4px;
      margin-right: 10px;
      color: inherit; }
      .wallet .balance-zone .integer {
        font-size: 20px;
        font-weight: 700; }
      .wallet .balance-zone .float {
        font-size: 16px;
        font-weight: 700; }
      .wallet .balance-zone .currency {
        margin-left: 4px;
        font-size: 16px;
        font-weight: 700; }
      .wallet .balance-zone .create-now {
        width: 40vw;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        color: inherit; }
        .wallet .balance-zone .create-now .text {
          font-size: 16px;
          font-weight: 600;
          margin-left: 8px;
          color: inherit; }
        .wallet .balance-zone .create-now .plus {
          font-size: 8px;
          color: inherit; }
  .wallet-small {
    box-sizing: border-box;
    border-radius: 8px; }
  .wallet-small-total {
    width: 100%;
    background-color: #ffffff;
    color: #333333;
    padding: 10px 15px; }
  .wallet-small-wallet {
    position: relative;
    cursor: pointer;
    width: 100%;
    background-color: transparent;
    color: #333333;
    padding: 10px 15px;
    border: 1px solid #333333; }
    .wallet-small-wallet .add {
      position: absolute;
      font-size: 12px;
      top: 40%;
      right: 10px; }
    .wallet-small-wallet .deposit {
      display: none; }
  .wallet-small-wallet-hovered {
    background-color: #0033ff;
    border-color: #0033ff;
    color: #ffffff !important; }
  .wallet-disabled {
    color: #999999 !important;
    border-color: #999999 !important;
    cursor: pointer; }
  .currency-switch-zone {
    width: 100%;
    margin: 0px 0px 10px 0px;
    font-size: 12px;
    font-weight: 500;
    color: #777777; }
  .currency-switch-align-left {
    text-align: left; }
  .currency-switch-align-right {
    text-align: right; }
  .currency-switch-align-center {
    text-align: center; }
  .currency-switch {
    display: inline-flex;
    border: 1px solid #777777;
    border-radius: 4px; }
    .currency-switch .item {
      cursor: pointer;
      padding: 8px; }
      .currency-switch .item:first-child {
        border-right: 1px solid #777777; }
    .currency-switch .active {
      background-color: #777777;
      color: #fafafa; } }
