/* Global style guide for UI Lib */
/**
 * Browser support
 */
/**
 * Typography
 */
@import url("https://fonts.googleapis.com/css?family=Rajdhani:300,400,500,600,700&subset=latin-ext");
/**
 * Colors
 */
/**
 * Page -- UILib Specific Variables
 */
/**
 * Card -- UILib Specific Variables
 */
@media only screen and (min-width: 769px) {
  .login-form-zone {
    justify-content: flex-start;
    min-height: 575px; }
    .login-form-zone .header {
      margin-bottom: 20px; }
    .login-form-zone .description {
      margin-bottom: 60px; }
    .login-form-zone .bottom-section {
      margin-top: auto;
      font-size: 14px; }
    .login-form-zone .submit-container-with-error {
      margin-bottom: 40px; }
    .login-form-zone .login-submit-container {
      text-align: right; }
  .register-form-zone {
    justify-content: flex-start; }
    .register-form-zone .description {
      width: 350px;
      margin: 20px auto 50px auto;
      font-size: 18px;
      line-height: 26px;
      font-weight: 500;
      color: #4a4a4a; }
    .register-form-zone .bottom-section {
      margin-top: auto;
      font-size: 14px; }
  .custom-form-zone {
    justify-content: flex-start; }
    .custom-form-zone .header {
      width: 240px;
      margin: 0 auto;
      margin-bottom: 40px; }
    .custom-form-zone .send-verification-header {
      width: 360px;
      margin: 0 auto;
      margin-bottom: 40px; }
    .custom-form-zone .verify-code-otp-header {
      width: 420px;
      margin: 0 auto;
      margin-bottom: 40px; }
    .custom-form-zone .verify-user-info-header {
      width: 420px;
      margin: 0 auto;
      margin-bottom: 40px; }
    .custom-form-zone .execute-header {
      width: 430px;
      margin: 0 auto;
      margin-bottom: 40px; }
    .custom-form-zone .reset-password-start-description {
      width: 340px;
      margin: 0 auto;
      margin-bottom: 40px; }
    .custom-form-zone .verify-code-otp-description {
      width: 400px;
      margin: 0 auto;
      margin-bottom: 56px; }
    .custom-form-zone .verify-user-info-description {
      width: 335px;
      margin: 0 auto;
      margin-bottom: 40px; }
    .custom-form-zone .execute-description {
      width: 335px;
      margin: 0 auto;
      margin-bottom: 66px; }
    .custom-form-zone .timer {
      margin-bottom: 20px; }
    .custom-form-zone .verify-code-otp-action-margin {
      margin-top: 36px; }
    .custom-form-zone .verify-user-information-action {
      margin-top: 55px; }
    .custom-form-zone .bottom-section {
      margin-top: auto;
      font-size: 14px; }
  .custom-form-container {
    width: 330px;
    margin: 0 auto; }
    .custom-form-container .descriptive-text {
      margin-bottom: 20px; }
  .form-container {
    width: 300px; }
    .form-container .reset-password {
      text-align: right;
      margin-top: -5px;
      margin-bottom: 10px; }
    .form-container .error-container {
      min-height: 35px;
      padding-bottom: 10px;
      margin-bottom: 10px; }
    .form-container .recaptcha-container {
      padding-bottom: 10px; }
    .form-container .submit-container {
      text-align: right;
      margin-top: 20px; }
    .form-container .execute-error-container {
      min-height: 35px;
      margin-top: 50px; }
    .form-container .execute-submit-container {
      text-align: right;
      margin-top: 80px; }
    .form-container .execute-submit-container-with-error {
      text-align: right;
      margin-top: 20px; }
    .form-container .submit-container-with-error {
      text-align: right; }
  .authentication-result-container {
    width: 300px;
    margin: 0 auto; }
  .authentication-result-content-container {
    height: 260px;
    text-align: center; }
    .authentication-result-content-container .icon {
      width: 130px;
      height: 130px;
      margin: 0 auto; }
    .authentication-result-content-container .content-text {
      margin: 50px auto 0 auto; }
  .authentication-result-footer-container {
    margin: 0 auto; }
  .authentication-card-centered {
    justify-content: center;
    align-items: center; }
  .send-verification-container {
    height: 430px; }
    .send-verification-container .descriptive-text {
      width: 330px;
      margin: 0 auto;
      margin-bottom: 60px; }
    .send-verification-container .option-selection {
      width: 425px;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      justify-content: space-between; }
      .send-verification-container .option-selection .option-button {
        width: 200px;
        height: 167px;
        box-sizing: border-box;
        border: 1px solid #eaeaea;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center; }
        .send-verification-container .option-selection .option-button .icon {
          margin-bottom: 20px; }
  .verify-code-otp-container .descriptive-text {
    width: 330px;
    margin: 0 auto;
    margin-bottom: 20px; }
  .verify-code-otp-container .digits-container {
    text-align: center; }
    .verify-code-otp-container .digits-container .tries-text {
      text-align: right;
      margin-right: 15px;
      margin-bottom: 20px; }
  .verify-code-otp-container .generic-container {
    width: 330px;
    margin: 0 auto;
    margin-bottom: 20px; }
  .verify-code-otp-container .actions-container {
    display: flex;
    flex-direction: 'row';
    justify-content: space-between; }
  .verify-code-otp-container .right-align {
    text-align: right; } }

@media only screen and (max-width: 768px) {
  .login-form-zone {
    justify-content: flex-start;
    min-height: 575px; }
    .login-form-zone .header {
      margin-bottom: 20px;
      font-size: 24px; }
    .login-form-zone .description {
      margin-bottom: 60px;
      font-size: 16px; }
    .login-form-zone .bottom-section {
      margin-top: auto;
      font-size: 14px; }
    .login-form-zone .submit-container-with-error {
      margin-bottom: 40px; }
    .login-form-zone .login-submit-container {
      text-align: right; }
  .register-form-zone {
    justify-content: flex-start;
    min-height: 575px; }
    .register-form-zone .header {
      margin-bottom: 20px;
      font-size: 24px; }
    .register-form-zone .description {
      width: 80vw;
      margin: 20px auto 50px auto;
      font-size: 16px;
      line-height: 26px;
      font-weight: 500;
      color: #4a4a4a; }
    .register-form-zone .bottom-section {
      margin-top: 20px;
      font-size: 14px; }
  .generic-authentication-header {
    font-size: 24px;
    margin-bottom: 20px; }
  .custom-form-zone {
    justify-content: flex-start;
    min-height: 575px;
    width: 90vw; }
    .custom-form-zone .header {
      font-size: 24px;
      margin: 0 auto;
      margin-bottom: 40px; }
    .custom-form-zone .send-verification-header {
      font-size: 24px;
      margin: 0 auto;
      margin-bottom: 40px; }
    .custom-form-zone .verify-code-otp-header {
      font-size: 24px;
      width: 90%;
      margin: 0 auto;
      margin-bottom: 40px; }
    .custom-form-zone .verify-user-info-header {
      font-size: 24px;
      margin: 0 auto;
      margin-bottom: 40px; }
    .custom-form-zone .execute-header {
      font-size: 24px;
      margin: 0 auto;
      margin-bottom: 40px; }
    .custom-form-zone .reset-password-start-description {
      font-size: 16px;
      margin: 0 auto;
      margin-bottom: 40px; }
    .custom-form-zone .verify-code-otp-description {
      font-size: 16px;
      margin: 0 auto;
      margin-bottom: 56px; }
    .custom-form-zone .verify-user-info-description {
      font-size: 16px;
      margin: 0 auto;
      margin-bottom: 40px; }
    .custom-form-zone .execute-description {
      font-size: 16px;
      margin: 0 auto;
      margin-bottom: 66px; }
    .custom-form-zone .timer {
      font-size: 24px;
      margin-bottom: 20px; }
    .custom-form-zone .verify-code-otp-action-margin {
      margin-top: 36px; }
    .custom-form-zone .verify-user-information-action {
      margin-top: 55px; }
    .custom-form-zone .bottom-section {
      margin-top: 20px;
      font-size: 14px; }
  .custom-form-container {
    width: 80vw;
    margin: 0 auto; }
    .custom-form-container .descriptive-text {
      font-size: 16px;
      margin-bottom: 20px; }
  .form-container {
    width: 80vw;
    margin: 0 auto; }
    .form-container .reset-password {
      text-align: right;
      margin-top: 0px;
      margin-bottom: 10px; }
    .form-container .error-container {
      min-height: 35px;
      padding-bottom: 10px;
      margin-bottom: 10px; }
    .form-container .recaptcha-container {
      padding-bottom: 10px; }
    .form-container .submit-container {
      text-align: right;
      margin-top: 20px; }
    .form-container .execute-error-container {
      min-height: 35px;
      margin-top: 50px; }
    .form-container .execute-submit-container {
      text-align: right;
      margin-top: 80px; }
    .form-container .execute-submit-container-with-error {
      text-align: right;
      margin-top: 20px; }
    .form-container .submit-container-with-error {
      text-align: right; }
  .authentication-result-container {
    width: 80vw;
    margin: 0 auto; }
    .authentication-result-container .result-header {
      font-size: 24px;
      margin-bottom: 40px; }
  .authentication-result-content-container {
    min-height: 300px;
    text-align: center; }
    .authentication-result-content-container .result-header {
      font-size: 24px; }
    .authentication-result-content-container .icon {
      width: 120px;
      height: 120px;
      margin: 0 auto; }
    .authentication-result-content-container .content-text {
      font-size: 12px;
      margin: 50px auto 0 auto; }
  .authentication-result-footer-container {
    margin: 0 auto; }
  .authentication-card-centered {
    justify-content: center;
    align-items: center; }
  .send-verification-container {
    height: 430px; }
    .send-verification-container .descriptive-text {
      font-size: 16px;
      margin: 0 auto;
      margin-bottom: 60px; }
    .send-verification-container .option-selection {
      width: 80vw;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      justify-content: space-between; }
      .send-verification-container .option-selection .option-button {
        width: 35vw;
        height: 165px;
        box-sizing: border-box;
        border: 1px solid #eaeaea;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center; }
        .send-verification-container .option-selection .option-button .icon {
          margin-bottom: 20px; }
  .verify-code-otp-container {
    min-height: 200px; }
    .verify-code-otp-container .descriptive-text {
      width: 80vw;
      margin: 0 auto;
      margin-bottom: 20px;
      font-size: 16px; }
    .verify-code-otp-container .timer-text {
      width: 80vw;
      margin: 0 auto;
      margin-bottom: 20px;
      font-size: 24px; }
    .verify-code-otp-container .digits-container {
      text-align: center; }
      .verify-code-otp-container .digits-container .tries-text {
        text-align: right;
        margin-right: 15px;
        margin-bottom: 20px; }
    .verify-code-otp-container .generic-container {
      width: 80vw;
      margin: 0 auto;
      margin-bottom: 20px; }
    .verify-code-otp-container .actions-container {
      display: flex;
      flex-direction: 'row';
      justify-content: space-between; }
    .verify-code-otp-container .right-align {
      text-align: right; }
  .padded-string {
    padding-top: 40px; } }
